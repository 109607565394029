import { useTheme } from '@material-ui/core/styles';
import Label from '../../shared/Label';
import { sentenceCase } from 'change-case';
import { ServicePlanStatus } from '../../../@types/maintenance'

function ServicePlanStatusLabel({ status, size = 12 }: { status: ServicePlanStatus, size?: number  }) {
    const theme = useTheme();
  
    return (
        <Label
            fontSize={size}
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
            (status === ServicePlanStatus.Disabled && 'default') ||
            'success'
            }
        >
            {sentenceCase(status)}
      </Label>
    );
  }
  
  export default ServicePlanStatusLabel;