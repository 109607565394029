
export enum PhoneType {
    Default = "Default",
    DirectDial = "DirectDial",
    Mobile = "Mobile",
    Fax = "Fax",
}

export type Phone = {
    phoneType: PhoneType;
    phoneCountryCode?: number | null;
    phoneAreaCode?: string | null;
    phoneNumber?: string | null;
}