import Avatar from '@material-ui/core/Avatar';
import { MAvatarProps } from '../@material-extend/MAvatar';
import createAvatar from '../../utils/createAvatar';

interface ClientAvatarProps extends MAvatarProps {
  name: string;
}

export default function ClientAvatar({ name, ...other }: ClientAvatarProps) {

  //let imagePath = getImagePath(make);

  return (
    <Avatar
      //src={imagePath}
      alt={name}
      variant="rounded"
      color={createAvatar(name).color}
      {...other}
    >
      {createAvatar(name).name}
    </Avatar>
  );
}
