import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientSummary } from '../../../@types/client'
import { Stack, TextField, Checkbox, FormControlLabel, Alert } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import { useAddClientMutation } from '../../../services/clients'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

type ClientCreateFormProps = {
  projectId: string;
  name?: string | null;
  onCreated?(client: ClientSummary): void;
};

type ClientCreateFormValues = {
  name: string;
  projectId: string;
  firstName: string;
  lastName: string;
  email: string;
  accountNumber: string;
  isCustomer: boolean;
  isSupplier: boolean;
  phoneNumber: string;
};

export const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Client name is required')
    .min(3),
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email('Email must be a valid email address')
});

export default function ClientCreateForm({ projectId, name, onCreated }: ClientCreateFormProps) {

  const navigate = useNavigate();
  const [addClient] = useAddClientMutation();

  const processFirstName = (name: string | null | undefined) => {
    if (name) {
      var splitted = name.split(' ');
      if (splitted.length > 1) {
        return splitted[0]
      }
    }
    return '';
  }

  const processLastName = (name: string | null | undefined) => {
    if (name) {
      var splitted = name.split(' ');
      if (splitted.length === 2) {
        return splitted[1]
      }
    }
    return '';
  }

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isValid, errors }
  } = useForm<ClientCreateFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      name: name ?? '',
      firstName: processFirstName(name),
      lastName: processLastName(name),
      email: '',

    }
  })

  const onSubmit = async (data: ClientCreateFormValues) => {

    try {

      const client = await addClient({
        projectId: projectId,
        name: data.name,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        accountNumber: data.accountNumber,
        phoneNumber: data.phoneNumber,
      }).unwrap();

      if (onCreated) {
        onCreated(client)
      } else {
        navigate(`../${client.id}/detail`);
      }
      reset();
    }
    catch (response) {
      console.log(errors)
      if (response.data && response.data.errors) {
        Object.keys(response.data.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
            return response.data.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      } else {

      }
    }
  };

  return (
    <form>
      <Stack spacing={3}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              error={Boolean(error)}
              helperText={error?.message}
              autoComplete={'off'}
            />
          )}
        />
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="First Name"
              error={Boolean(error)}
              helperText={error?.message}
              autoComplete={'off'}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Last Name"
              error={Boolean(error)}
              helperText={error?.message}
              autoComplete={'off'}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Email"
              error={Boolean(error)}
              helperText={error?.message}
              autoComplete={'off'}
            />
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PhoneInput
              country={'nz'}
              disableDropdown={true}
              value={value}
              enableAreaCodes={true}
              enableAreaCodeStretch
              masks={{ nz: '(..) ...-......' }}
              areaCodes={{ nz: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '21', '22', '20', '27'] }}
              inputStyle={{
                width: '100%'
              }}
              onChange={(value: any, data: any, event: any, formattedValue: any) => {
                console.log(value)
                console.log(data)
                console.log(formattedValue)
                onChange(formattedValue)
              }}
            />
          )}
        />
        <Controller
          name="accountNumber"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Account Number"
              error={Boolean(error)}
              helperText={error?.message}
              autoComplete={'off'}
            />
          )}
        />
        {
          errors.projectId && <Alert severity="error" sx={{ mb: 3 }}>{errors.projectId.message}</Alert>
        }
        <LoadingButton
          fullWidth
          size="large"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid}
        >
          Create Customer
        </LoadingButton>
      </Stack>
    </form>
  );
}
