import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Checkbox, FormControlLabel, Alert, Paper, Typography, Collapse, InputAdornment } from '@material-ui/core';
import { LoadingButton, DatePicker } from '@material-ui/lab';
import MNumberTextField from '../../@material-extend/MNumberTextField';
import * as Yup from 'yup';
import axios from '../../../utils/axios';
import { Project } from '../../../@types/project';
import { Vehicle, VehicleType } from '../../../@types/vehicle';
import { ComplianceType } from '../../../@types/compliance';
import { ComplianceTypeSelectList } from '../../_vehicle'
import { ClientSelectListControlled } from '../../client'
import { useAddVehicleMutation } from '../../../services/vehicles'
import VehicleIdentifierInput from './components/VehicleIdentifierInput'
import VehicleTypeInput from './components/VehicleTypeInput'

type VehicleCreateFormProps = {
  project: Project | undefined | null;
  registration?: string;
  onVehicleCreated?: (vehicle: Vehicle) => void;
  ownerEntity?: { id: string, name: string } | null;
};

type VehicleCreateFormValues = {
  ownerEntityId: {
    id: string,
    name: string | null,
    type: string,
  } | null;
  vehicleIdentifier: string;
  type: VehicleType;
  complianceType: string;
  projectId: string;
  make: string;
  model: string;
  subModel: string;
  colour: string;
  odometer: number | null;
  hubometer: number | null;
  hourmeter: number | null;
  manuallyEnterVehicleDetails: boolean;
  dateOfLastCompliance: Date | null;
};

export const FormSchema = Yup.object().shape({
  vehicleIdentifier: Yup.string()
    .when('type', {
      is: "Vehicle",
      then: (schema) => schema.max(6, 'Registration must be 6 characters').required('Registration is required'),
    })
    .when('type', {
      is: "Machine",
      then: (schema) => schema.required('Serial number is required'),
    }),
  ownerEntityId: Yup.object()
    .required('Vehicle owner is required')
    .defined(),
  complianceType: Yup.string()
    .required(),
  make: Yup.string().nullable()
    .when('type', {
      is: "Vehicle",
      then: (schema) => schema.required('Vehicle make is required').min(2),
    }),
  model: Yup.string()
    .required('Model is required')
    .min(1)
});

export default function VehicleCreateForm({ project, registration, ownerEntity, onVehicleCreated }: VehicleCreateFormProps) {

  const navigate = useNavigate();
  const [addVehicle] = useAddVehicleMutation();
  const [carJamLoading, setCarJamLoading] = useState(false);
  const [carJamError, setCarJamError] = useState<string | null>(null);

  const {
    watch,
    reset,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting, isValid, errors }
  } = useForm<VehicleCreateFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      type: VehicleType.Vehicle,
      ownerEntityId: ownerEntity ? { id: ownerEntity?.id, name: ownerEntity?.name, type: 'User' } : null,
      vehicleIdentifier: registration || '',
      make: '',
      model: '',
      subModel: '',
      colour: '',
      complianceType: ComplianceType.WOF,
      manuallyEnterVehicleDetails: false,
      dateOfLastCompliance: null,
    }
  })

  const vehicleIdentifierWatch = watch('vehicleIdentifier');
  const ownerEntityIdValue = watch('ownerEntityId');

  const getVehicleDetails = async () => {

    try {
      setCarJamError(null)
      setCarJamLoading(true);

      const response = await axios.get<any, any>(`/api/vehicle/carjam?registration=${vehicleIdentifierWatch}&projectId=${project?.id}&customerId=${ownerEntityIdValue?.id}`);

      setValue('make', response.data.make, { shouldDirty: true, shouldValidate: true })
      setValue('model', response.data.model, { shouldDirty: true, shouldValidate: true })
      setValue('colour', response.data.colour, { shouldDirty: true, shouldValidate: true })
      setValue('subModel', response.data.subModel, { shouldDirty: true, shouldValidate: true })
      setValue('odometer', response.data.odometer, { shouldDirty: true, shouldValidate: true })

      setCarJamLoading(false);
    }
    catch (error) {
      if (error.modelState && Object.keys(error.modelState).length > 0) {
        Object.keys(error.modelState).forEach((key: any) => {
          let errors: string[] = Object.keys(error.modelState[key]).map((errorKey: string) => {
            return error.modelState[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      } else {
        if (error.errors) {
          setCarJamError(error.errors[0])
        } else {
          setCarJamError('Something went wrong retrieving vehicle details')
        }
      }

      setCarJamLoading(false);
    }
  };

  const onSubmit = async (data: VehicleCreateFormValues) => {

    try {
      const vehicle = await addVehicle({
        projectId: project?.id || '',
        manualRequest: data.manuallyEnterVehicleDetails,
        ownerEntityId: data.ownerEntityId?.id || '',
        type: data.type,
        vehicleIdentifier: data.vehicleIdentifier,
        make: data.make,
        model: data.model,
        subModel: data.subModel,
        odometer: data.odometer,
        hubometer: data.hubometer,
        hourmeter: data.hourmeter,
        complianceType: data.type == VehicleType.Vehicle ? data.complianceType : ComplianceType.Unknown,
        dateOfLastCompliance: data.type == VehicleType.Vehicle ? data.dateOfLastCompliance : null,
        colour: data.colour,
      }).unwrap();

      if (onVehicleCreated) {
        onVehicleCreated(vehicle);
        reset();
      } else {
        navigate(`/group/${project?.identifier}/fleet/vehicles/${vehicle.id}/detail`);
        reset();
      }
    }
    catch (response) {
      if (response.data && response.data.errors) {
        Object.keys(response.data.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
            return response.data.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      } else {

      }
    }
  };

  const manuallyEnterVehicleDetails = watch('manuallyEnterVehicleDetails');
  const complianceType = watch('complianceType');
  const vehicleTypeWatch = watch('type');

  useEffect(() => {
    if (vehicleTypeWatch !== VehicleType.Vehicle) {
      setValue('manuallyEnterVehicleDetails', true, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue('manuallyEnterVehicleDetails', false, { shouldDirty: true, shouldValidate: true });
    }
  }, [vehicleTypeWatch])

  return (
    <>
      <form >
        <Stack spacing={2}>
          <Controller
            name="ownerEntityId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ClientSelectListControlled
                label={'Vehicle Owner'}
                field={field}
              />
            )}
          />
          <Stack direction="row" spacing={2}>
            <VehicleTypeInput
              name="type"
              control={control}
              sx={{ maxWidth: '40%' }}
            />
            <VehicleIdentifierInput
              name="vehicleIdentifier"
              control={control}
              vehicleType={vehicleTypeWatch}
            />
          </Stack>
          <Collapse in={vehicleTypeWatch === VehicleType.Vehicle}>
            <Stack direction="row" sx={{ mb: 1 }}>
              <Stack>
                <Paper sx={{ p: 1.5, bgcolor: 'background.neutral' }}>
                  <Typography variant="caption">Vehicle details can be retrieved automatically, type in the registration number and search</Typography>
                </Paper>
              </Stack>
              <Stack sx={{ width: '60%', ml: 1 }}>
                <LoadingButton
                  loading={carJamLoading}
                  size="small"
                  loadingPosition="start"
                  variant="outlined"
                  disabled={vehicleIdentifierWatch.length < 2}
                  onClick={() => getVehicleDetails()}
                >
                  Get Vehicle Details
                </LoadingButton>
              </Stack>
            </Stack>
            <Alert severity="error" sx={{ mb: 2, mt: 2, display: carJamError ? 'flex' : 'none' }}>
              {carJamError}
            </Alert>
            <Stack spacing={1}>
              <Controller
                name="manuallyEnterVehicleDetails"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="Manually enter details"
                    control={
                      <Checkbox
                        {...field}
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                  />
                )}
              />
              <Stack direction="row" spacing={2}>
                <Controller
                  name="complianceType"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <ComplianceTypeSelectList
                      disabled={!manuallyEnterVehicleDetails}
                      field={field}
                    />
                  )}
                />
                <Controller
                  name="dateOfLastCompliance"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      disabled={!manuallyEnterVehicleDetails || !complianceType || complianceType == ComplianceType.Unknown}
                      label={(!complianceType || complianceType == ComplianceType.Unknown) ? 'N/A' : `Date of last ${complianceType || 'compliance inspection'}`}
                      renderInput={(params) => (
                        <TextField
                          fullWidth {...params}
                          error={Boolean(error)}
                          helperText={error?.message}
                        />
                      )}
                      inputFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Collapse>
          <Stack direction="row" spacing={2}>
            <Controller
              name="make"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={!manuallyEnterVehicleDetails}
                  label="Make"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />
            <Controller
              name="model"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Model"
                  fullWidth
                  disabled={!manuallyEnterVehicleDetails}
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Controller
              name="subModel"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Sub-Model"
                  fullWidth
                  disabled={!manuallyEnterVehicleDetails}
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />

            <Controller
              name="colour"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Colour"
                  fullWidth
                  disabled={!manuallyEnterVehicleDetails}
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />

          </Stack>
          <Stack direction="row" spacing={2}>
            <Controller
              name="odometer"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MNumberTextField
                  {...field}
                  label="Odometer"
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kms</InputAdornment>,
                  }}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="hubometer"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MNumberTextField
                  {...field}
                  label="Hubometer"
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kms</InputAdornment>,
                  }}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="hourmeter"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MNumberTextField
                  {...field}
                  label="Hourmeter"
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
                  }}
                  helperText={error?.message}
                />
              )}
            />
          </Stack>
          {
            errors.projectId && <Alert severity="error" sx={{ mb: 3 }}>{errors.projectId.message}</Alert>
          }
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            fullWidth
            size="large"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Create Vehicle
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
