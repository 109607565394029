import { useTheme } from '@material-ui/core/styles';
import Label from '../shared/Label';
import { capitalCase } from 'change-case';
import { NotificationStatus } from '../../@types/notification'

function NotificationStatusLabel({ status }: { status: NotificationStatus }) {
    const theme = useTheme();

    return (
        <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'outlined'}
            color={
                (status === NotificationStatus.Error && 'error') || 'success'
            }
        >
            {capitalCase(status)}
        </Label>
    );
}

export default NotificationStatusLabel;

