import { useNavigate } from 'react-router-dom';
import ConfirmDeleteModal from '../shared/ConfirmDeleteModal'
import { useGetVehicleQuery } from '../../services/vehicles'
import useProject from '../../hooks/useProject'
import { VehicleRegistration } from '../_vehicle'
import { Typography, Stack, Skeleton, Paper } from '@material-ui/core';
import { fNumber } from '../../utils/formatNumber';
import { Vehicle, VehicleType } from '../../@types/vehicle';

export default function VehicleSummaryBar({ vehicleId }: { vehicleId: string }) {

    const navigate = useNavigate();
    const { project } = useProject();
    const { data, isLoading, isFetching } = useGetVehicleQuery(vehicleId, { skip: !vehicleId });

    const getVehicleDefaultMeter = (vehicle: Vehicle) => {
        if (vehicle.type == VehicleType.Machine) {
            return vehicle?.hourmeter ? `${fNumber(vehicle.hourmeter)} hr's` : '-'
        }

        return vehicle?.odometer ? `${fNumber(vehicle.odometer)} km's` : '-'
    }

    if (isLoading) {
        return (
            <Stack>
                <Skeleton height={40} />
            </Stack>
        );
    }

    if (isLoading || isFetching) {
        return (
            <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', height: '40px' }}>
            </Paper>
        )
    }

    if (!vehicleId) {
        return (
            <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        <Stack direction="column" sx={{ mt: 0 }}>
                            <VehicleRegistration size={12} registration={''} />
                        </Stack>
                        <Stack direction="column" sx={{ ml: 2 }}>
                            <Typography variant="subtitle2">No Associated Vehicle</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        )
    }

    if (!data) {
        return (
            <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        <Stack direction="column" sx={{ mt: 0 }}>
                            <VehicleRegistration size={12} registration={''} />
                        </Stack>
                        <Stack direction="column" sx={{ ml: 2 }}>
                            <Typography variant="subtitle2">No Associated Vehicle</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        )
    }

    return (
        <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row">
                    <Stack direction="column" sx={{ mt: 0 }} onClick={() => navigate(`/group/${project.identifier}/fleet/vehicles/${data?.id}/detail`)}>
                        <VehicleRegistration size={14} registration={data?.vehicleIdentifier || ''} />
                    </Stack>
                    <Stack direction="column" sx={{ ml: 2 }}>
                        <Typography variant="subtitle2">{`${data?.make} ${data?.model}`}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="column" sx={{ ml: 2 }}>
                        <Typography variant="caption">{getVehicleDefaultMeter(data)}</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <ConfirmDeleteModal
                confirmTitle="Remove Service from Work Order?"
                confirmMessage="This action will remove the service including all associated items from the given work order."
                open={false}
                onCancel={() => { }}
                onConfirm={() => { }}
            />
        </Paper>
    )
}
