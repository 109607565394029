import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// slices
import userReducer from './slices/user';
import vehicleReducer from './slices/vehicle';
import vehiclesReducer from './slices/vehicles';
import authReducer from './slices/auth';
import invoicesReducer from './slices/invoices';
import clientsReducer from './slices/clients';
import clientReducer from './slices/client';
import tasksReducer from './slices/tasks';
import complianceReducer from './slices/compliance';
import projectsReducer from './slices/projects';
import projectReducer from './slices/project';
import navigationReducer from './slices/navigation';
import workorderReducer from './slices/workorder';
import calendarReducer from './slices/calendar';

import { api } from '../services/api'

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  vehicle: vehicleReducer,
  vehicles: vehiclesReducer,
  auth: authReducer,
  project: projectReducer,
  projects: projectsReducer,
  invoices: invoicesReducer,
  clients: clientsReducer,
  client: clientReducer,
  tasks: tasksReducer,
  compliance: complianceReducer,
  navigation: navigationReducer,
  workorder: workorderReducer,
  calendar: calendarReducer,
  [api.reducerPath]: api.reducer,
});

export { rootPersistConfig, rootReducer };
