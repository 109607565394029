import Router from './routes';
import ThemeConfig from './theme';
import { useAuth0 } from '@auth0/auth0-react';
import Settings from './components/settings';
import RtlLayout from './components/shared/RtlLayout';
import ScrollToTop from './components/shared/ScrollToTop';
import LoadingScreen from './components/shared/LoadingScreen';
import GoogleAnalytics from './components/shared/GoogleAnalytics';
import NotistackProvider from './components/shared/NotistackProvider';
import ThemePrimaryColor from './components/shared/ThemePrimaryColor';
import ThemeLocalization from './components/shared/ThemeLocalization';

export default function App() {

  const { isLoading } = useAuth0();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <Settings />
              <ScrollToTop />
              <GoogleAnalytics />
              {isLoading ? <LoadingScreen /> : <Router />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
