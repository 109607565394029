import { Typography  } from '@material-ui/core';
import { ComplianceType } from '../../@types/compliance'

function ComplianceTypeInfo({ type }: { type: ComplianceType }) {

    switch (type) {
        case ComplianceType.COF:
            return (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    The compliance type of this vehicle is <strong>Certificate of Fitness (COF)</strong>. A <strong>COF</strong> is a regular check to ensure that your vehicle meets required safety standards. Vehicles requiring this certification are heavy vehicles, larger trailers, motor homes, all passenger service vehicles (i.e. taxis, shuttles and buses) and rental vehicles.
                </Typography>
            )
        case ComplianceType.WOF:
            return (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    The compliance type of this vehicle is <strong>Warrant of Fitness (WoF)</strong>. This is a regular check to ensure that your vehicle meets required safety standards. WoFs are required for the lifetime of the vehicle and the frequency can range from every 3 years to 6 months. For more information see the <a href="https://www.nzta.govt.nz/vehicles/warrants-and-certificates/warrant-of-fitness/">NZTA Website</a>
                </Typography>
            )
        default:
            return (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    The compliance type of this vehicle is <strong>Unknown</strong>. This means that the compliance type is not set for this vehicle. Compliance netries cannot be created for this vehicle until this is set. For more information see the <a href="https://www.nzta.govt.nz/vehicles/warrants-and-certificates/warrant-of-fitness/">NZTA Website</a>
                </Typography>
            )
    }
  }
  
  export default ComplianceTypeInfo;



