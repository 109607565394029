import {
  ServiceChecklistItemDeleteRequest,
  ServiceChecklistItemsReorderRequest,
  ServiceChecklistItemCreateRequest,
  ServiceChecklistItemsUpdateRequest,
  ServiceChecklistItemUpdateRequest,
  Service,
  ServiceRecord,
  ServiceItem,
  ServiceInstanceTask,
  ServiceItemRequest,
  ServiceTaskInstanceRequest,
  ServiceUpdateRequest, 
  IServiceSearchCriteria
} from '../@types/maintenance'
import { WorkOrder } from '../@types/workorder'
import { VehicleServiceCreateRequest } from '../@types/vehicle'
import { ISearchResult } from '../@types/search'
import { ChecklistItem, Checklist, ChecklistItemStatus } from '../@types/checklist'
import { api } from './api'
import { jobsApi } from './job'
import path from 'path'

export const servicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getService: builder.query<Service, string>({
      query: (id: string) => ({
        url: `service/${id}`,
        method: 'GET',
      }),
      providesTags: ['Service']
    }),

    getServiceWorkOrder: builder.query<WorkOrder | null, string>({
      query: (id: string) => ({
        url: `service/${id}/work-order`,
        method: 'GET',
      }),
      providesTags: ['Service', 'WorkOrder']
    }),

    getServices: builder.query<ISearchResult<Service>, IServiceSearchCriteria>({
      query: (criteria: IServiceSearchCriteria) => ({
        url: `service/search`,
        method: 'POST',
        body: criteria
      }),
      providesTags: ['Service']
    }),

    addService: builder.mutation<Service, VehicleServiceCreateRequest>({
      query: (request: VehicleServiceCreateRequest) => ({
        url: `service/vehicles/${request.vehicleId}/create`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['Service'],
    }),

    updateService: builder.mutation<ServiceRecord, ServiceUpdateRequest>({
      query: (request: ServiceUpdateRequest) => ({
        url: `services/${request.serviceId}`,
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['Service', 'Job'],
    }),

    updateServiceDescription: builder.mutation<Service, { serviceId: string, description: string }>({
      query: (request: { serviceId: string, description: string }) => ({
        url: `service/${request.serviceId}/description`,
        method: 'PUT',
        body: {
          description: request.description,
        }
      }),
      invalidatesTags: ['Service'],
    }),

    deleteService: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `service/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Service'],
    }),

    getServiceItems: builder.query<ServiceItem[], string>({
      query: (id: string) => ({
        url: `service/${id}/items`,
        method: 'GET',
      }),
      providesTags: ['ServiceItem']
    }),

    getServiceInstanceTasks: builder.query<ServiceInstanceTask[], string>({
      query: (serviceId: string) => ({
        url: `service/${serviceId}/tasks`,
        method: 'GET',
      }),
      providesTags: ['ServiceTask'],
    }),

    updateServiceTask: builder.mutation<boolean, ServiceTaskInstanceRequest>({
      query: (request: ServiceTaskInstanceRequest) => ({
        url: `service/${request.serviceId}/tasks/${request.taskId}?completed=${request.complete}`,
        method: 'PUT',
      }),
      invalidatesTags: ['ServiceTask'],
    }),

    addServiceItem: builder.mutation<ServiceItem, ServiceItemRequest>({
      query: (request: ServiceItemRequest) => ({
        url: `service/${request.serviceId}/items`,
        method: 'POST',
        body: request
      }),
      async onQueryStarted({ serviceId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(servicesApi.util.updateQueryData('getServiceItems', serviceId, (draft) => {
          Object.assign(draft, [...draft, { id: '-1', description: patch.description }])
        }))

        const { data: updatedPost } = await queryFulfilled;
        dispatch(servicesApi.util.updateQueryData('getServiceItems', serviceId, (draft) => {
          const index = draft.findIndex(item => item.itemId === '-1')
          if (index > -1) {
            draft[index] = updatedPost;
          }
        }))
      },
      invalidatesTags: ['Service', 'ServiceItem', 'WorkOrderItem', 'WorkOrder'],
    }),

    updateServiceItem: builder.mutation<ServiceItem, ServiceItemRequest>({
      query: (request: ServiceItemRequest) => ({
        url: `service/${request.serviceId}/items`,
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['Service', 'ServiceItem', 'WorkOrderItem', 'WorkOrder'],
    }),

    deleteServiceItem: builder.mutation<void, { serviceId: string, serviceItemId: string }>({
      query: (request: { serviceId: string, serviceItemId: string }) => ({
        url: `service/${request.serviceId}/items/${request.serviceItemId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ serviceId, serviceItemId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(servicesApi.util.updateQueryData('getServiceItems', serviceId, draft => {
          const index = draft.findIndex(item => item.itemId === serviceItemId)
          if (index > -1) {
            draft.splice(index, 1);
          }
        }))
        try {
          await queryFulfilled
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Service', 'ServiceItem', 'WorkOrderItem', 'WorkOrder'],
    }),

    addServiceChecklistItem: builder.mutation<ChecklistItem, ServiceChecklistItemCreateRequest>({
      query: (request: ServiceChecklistItemCreateRequest) => ({
        url: `services/${request.serviceId}/checklist/items`,
        method: 'POST',
        body: request,
      }),
      async onQueryStarted({ jobId, serviceTypeId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(jobsApi.util.updateQueryData('getJob', jobId, (draft) => {
          const index = draft.serviceTypes.findIndex(item => item.serviceType.id === serviceTypeId);
          if (index > -1) {
            const serviceRecord = draft.serviceTypes[index].serviceRecord;
            if (serviceRecord) {
              var group = serviceRecord.checklist.groups.find(item => item.title === patch.group);
              if (!group) {
                group = { groupId: '-1', index: serviceRecord.checklist.groups.length, title: patch.group || 'default', items: []};
                serviceRecord.checklist.groups.push(group);
              }
              
              serviceRecord.checklist.items.push({
                itemId: '-1',
                title: patch.title,
                description: patch.description ?? '',
                groupId: group.groupId,
                status: ChecklistItemStatus.Default
              })
            }
          }
        }))

        const { data: updatedPost } = await queryFulfilled;
        dispatch(jobsApi.util.updateQueryData('getJob', jobId, (draft) => {
          const index = draft.serviceTypes.findIndex(item => item.serviceType.id === serviceTypeId);
          if (index > -1) {
            const serviceRecord = draft.serviceTypes[index].serviceRecord;
            if (serviceRecord) {
              const itemIndex = serviceRecord.checklist.items.findIndex(item => item.itemId === '-1');
              if (itemIndex > -1)
              {
                serviceRecord.checklist.items[itemIndex] = updatedPost;
              }
              
              const groupIndex = serviceRecord.checklist.groups.findIndex(item => item.groupId === '-1');
              if (groupIndex > -1)
              {
                serviceRecord.checklist.groups[groupIndex].groupId = updatedPost.groupId;
              }
            }
          }
        }))
      },
    }),

    updateServiceChecklistItems: builder.mutation<Checklist, ServiceChecklistItemsUpdateRequest>({
      query: (request: ServiceChecklistItemsUpdateRequest) => ({
        url: `services/${request.serviceId}/checklist/items`,
        method: 'PUT',
        body: request,
      }),
      async onQueryStarted({ jobId, serviceTypeId, ...patch }, { dispatch, queryFulfilled }) {
        const { data: updatedPost } = await queryFulfilled;
        
        dispatch(jobsApi.util.updateQueryData('getJob', jobId, (draft) => {
          const index = draft.serviceTypes.findIndex(item => item.serviceType.id === serviceTypeId);
          if (index > -1) {
            const serviceRecord = draft.serviceTypes[index].serviceRecord;
            if (serviceRecord) {
              serviceRecord.checklist = updatedPost;
            }
          }
        }))
      },
    }),

    updateServiceChecklistItem: builder.mutation<ChecklistItem, ServiceChecklistItemUpdateRequest>({
      query: (request: ServiceChecklistItemUpdateRequest) => ({
        url: `services/${request.serviceId}/checklist/items/${request.itemId}`,
        method: 'PUT',
        body: request,
      }),
      async onQueryStarted({ jobId, itemId, serviceTypeId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(jobsApi.util.updateQueryData('getJob', jobId, (draft) => {
          const jobServiceType = draft.serviceTypes.find(item => item.serviceType.id === serviceTypeId);
          if (jobServiceType) {
            const serviceRecord = jobServiceType.serviceRecord;
            if (serviceRecord && serviceRecord.checklist) {
              var checklist = serviceRecord.checklist;
              var checklistItem = checklist.items.find(_ => _.itemId === itemId);
              if (checklistItem) {
                checklistItem.status = patch.status;
              }

              checklist.isComplete = checklist.items.length !== 0 && checklist.items.every(_ => _.status !== ChecklistItemStatus.Default);
            }
          }
        }))
        
        await queryFulfilled;
      },
    }),

    reorderServiceChecklistItems: builder.mutation<Checklist, ServiceChecklistItemsReorderRequest>({
      query: (request: ServiceChecklistItemsReorderRequest) => ({
        url: `services/${request.serviceId}/checklist/items/reorder`,
        method: 'PUT',
        body: request
      }),
      async onQueryStarted({ jobId, serviceTypeId, ...patch }, { dispatch, queryFulfilled }) {
        const { data: updatedChecklist } = await queryFulfilled;
        dispatch(jobsApi.util.updateQueryData('getJob', jobId, (draft) => {
          const index = draft.serviceTypes.findIndex(item => item.serviceType.id === serviceTypeId);
          if (index > -1) {
            const serviceRecord = draft.serviceTypes[index].serviceRecord;
            if (serviceRecord) {
              serviceRecord.checklist = updatedChecklist;
            }
          }
        }))
      },
    }),

    deleteServiceCheckSheetItem: builder.mutation<ChecklistItem, ServiceChecklistItemDeleteRequest>({
      query: (request: ServiceChecklistItemDeleteRequest) => ({
        url: `services/${request.serviceId}/checklist/items/${request.itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Job']
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetServiceQuery,
  useGetServiceWorkOrderQuery,
  useUpdateServiceDescriptionMutation,
  useGetServicesQuery,
  useGetServiceItemsQuery,
  useDeleteServiceMutation,
  useAddServiceMutation,
  useUpdateServiceMutation,
  useAddServiceItemMutation,
  useUpdateServiceItemMutation,
  useDeleteServiceItemMutation,
  useUpdateServiceTaskMutation,
  useGetServiceInstanceTasksQuery,
  useAddServiceChecklistItemMutation,
  useUpdateServiceChecklistItemsMutation,
  useUpdateServiceChecklistItemMutation,
  useReorderServiceChecklistItemsMutation,
  useDeleteServiceCheckSheetItemMutation,
} = servicesApi;