import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles';
import { Stack, CardHeader, Card, CardContent, Typography, Button, Skeleton, Link, Dialog, Alert, DialogTitle, DialogContent } from '@material-ui/core';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/email-fill';
import { Compliance, ComplianceTypeConfiguration } from '../../../@types/compliance'
import { fDateTime } from '../../../utils/formatTime'
import ComplianceReminderSchedule from './ComplianceReminderSchedule'
import ComplianceReminderHistoryModal from './ComplianceReminderHistoryModal'
import useProject from '../../../hooks/useProject'
import { useGetVehicleComplianceConfigurationQuery } from '../../../services/vehicles'
import SendComplianceReminderForm from './form/SendComplianceReminderForm'

type ComplianceReminderButtonProps = {
  compliance: Compliance;
  configuration: ComplianceTypeConfiguration;
  vehicleId: string;
  loading: boolean;
  disabled: boolean;
};

function ComplianceReminderButton({ compliance, configuration, vehicleId, disabled }: ComplianceReminderButtonProps) {

  const [reminderModalOpen, setReminderModalOpen] = useState(false);

  return (
    <>
      <Button
        size="small"
        disabled={disabled}
        startIcon={<Icon icon={editFill} />}
        onClick={() => setReminderModalOpen(true)}
      >
        Send Reminder
      </Button>
      <Dialog
        fullWidth
        open={reminderModalOpen}
        onClose={() => setReminderModalOpen(false)}
      >
        <DialogTitle>Send Compliance Reminder</DialogTitle>
        <DialogContent dividers>
          <SendComplianceReminderForm
            vehicleId={vehicleId}
            configuration={configuration}
            onReminderSent={() => setReminderModalOpen(false)}
            onCancel={() => setReminderModalOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

type ComplianceReminderProps = {
  vehicleId: string;
  compliance: Compliance | null;
  loading: boolean;
  disabled?: boolean;
};

export default function ComplianceReminder({ vehicleId, compliance, loading, disabled = false }: ComplianceReminderProps) {

  const theme = useTheme();
  const { project } = useProject();
  const [complianceReminderHistoryOpen, setComplianceReminderHistoryOpen] = useState(false);
  const { data: complianceConfiguration, isLoading: complianceConfigLoading } = useGetVehicleComplianceConfigurationQuery(vehicleId);
  const reminderConfigEnabled = complianceConfiguration?.reminderConfiguration.emailConfig.enabled || complianceConfiguration?.reminderConfiguration?.textMessageConfig.enabled;
  const remindersDisabled = disabled || (!reminderConfigEnabled || false);

  if (!compliance || loading || complianceConfigLoading || complianceConfiguration == null) {
    return (
      <Card sx={{ mb: 4 }}>
        <CardHeader title="Compliance Reminder" />
        <CardContent>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Reminder Sent</Typography>
              <Typography variant="subtitle2" sx={{ width: 130 }}><Skeleton /></Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Next Reminder Scheduled</Typography>
              <Typography variant="subtitle2" sx={{ width: 130 }}><Skeleton /></Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 4 }}>
      <CardHeader
        title="Compliance Reminder"
        subheader=""
      />

      <CardContent>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Compliance reminders will be sent automatically or manually when enabled.
            For more details on compliance reminders see the <Link sx={{ color: theme.palette.primary.dark }} href={`/group/${project?.identifier}/settings/vehicles`}>settings</Link></Typography>
        </Stack>
        {!reminderConfigEnabled && (<Alert sx={{ mb: 1 }} severity="info">Compliance reminders are currently disabled for this vehicle</Alert>)}
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Reminder Sent</Typography>
          <Typography variant="subtitle2">{compliance.notification.whenNotificationLastSent ? fDateTime(compliance.notification.whenNotificationLastSent) : '-'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Next Reminder Scheduled</Typography>
          <Typography variant="subtitle2">{!disabled ? compliance.notification.whenNotificationScheduled ? fDateTime(compliance.notification.whenNotificationScheduled) : '-' : 'N/A'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Email Reminders Enabled</Typography>
          <Typography variant="subtitle2">{!disabled ? complianceConfiguration?.reminderConfiguration?.emailConfig.enabled ? 'True' : 'False' : 'N/A'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Text Reminders Enabled</Typography>
          <Typography variant="subtitle2">{!disabled ? complianceConfiguration?.reminderConfiguration?.textMessageConfig.enabled ? 'True' : 'False' : 'N/A'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="end" sx={{ display: compliance.notification.notifications ? 'flex' : 'none' }}>
          <Typography variant="subtitle2" sx={{ cursor: 'pointer', color: theme.palette.primary.dark, mt: 1 }}>
            <Link onClick={() => setComplianceReminderHistoryOpen(true)}>View History</Link>
          </Typography>
          <ComplianceReminderHistoryModal
            modalOpen={complianceReminderHistoryOpen}
            handleModalClose={() => setComplianceReminderHistoryOpen(false)}
            compliance={compliance}
          />
        </Stack>

        <Stack spacing={3} direction="row-reverse" sx={{ pt: 5 }}>
          <ComplianceReminderButton
            compliance={compliance}
            configuration={complianceConfiguration}
            vehicleId={vehicleId}
            loading={false}
            disabled={remindersDisabled}
          />
          <ComplianceReminderSchedule
            compliance={compliance}
            vehicleId={vehicleId}
            loading={false}
            disabled={remindersDisabled}
          />
        </Stack>
        {/* <ComplianceEditModal vehicleId={vehicleId} compliance={compliance} /> */}
      </CardContent>
    </Card>
  );
}
