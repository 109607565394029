import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { dispatch, RootState } from '../store';
import axios from '../../utils/axios';
import { Compliance, ComplianceUpdateRequest, ComplianceReminderUpdateRequest, ComplianceTracking } from '../../@types/compliance';

type ComplianceState = {
  isLoading: boolean;
  notFound: boolean;
  error: boolean;
  compliance: null | Compliance;
  complianceTracking: ComplianceTracking,
  edit: {
    compliance: Compliance | null;
    open: boolean;
  },
  create: {
    open: boolean;
  },
  import: {
    loading: boolean;
    error: string | undefined;
  }
};

const initialState: ComplianceState = {
  isLoading: false,
  notFound: false,
  error: false,
  compliance: null,
  complianceTracking: {
    enabled: false,
  },
  edit: {
    compliance: null,
    open: false,
  },
  create: {
    open: false,
  },
  import: {
    loading: false,
    error: undefined,
  }
};

const slice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload.error;
      state.notFound = action.payload.notFound;
    },

    getComplianceSuccess(state, action) {
      state.isLoading = false;
      state.compliance = action.payload;
      state.notFound = false;
    },

    getComplianceTrackingSuccess(state, action) {
      state.complianceTracking = action.payload;
    },

    toggleEditOpen(state, action) {
      state.edit = {
        ...state.edit,
        compliance: null,
        open: action.payload,
      }
    },

    toggleCreateComplianceModal(state, action) {
      state.create = {
        ...state.create,
        open: action.payload,
      }
    }
  },
});

// Reducer
export default slice.reducer;

export const { toggleEditOpen, getComplianceSuccess, toggleCreateComplianceModal, getComplianceTrackingSuccess } = slice.actions;

export function editCompliance() {
  return async () => {
    dispatch(slice.actions.toggleEditOpen(true));
  };
}

export function closeComplianceEdit() {
  return async () => {
    dispatch(slice.actions.toggleEditOpen(false));
  };
}

// selectors
export const selectCompliance = (state: RootState) => state.compliance.compliance;
export const selectComplianceCreate = (state: RootState) => state.compliance.create;
export const selectComplianceImport = (state: RootState) => state.compliance.import;
export const getComplianceCreateModalOpen = createSelector(selectComplianceCreate, complianceCreated => complianceCreated.open);

// complaince tracking selectors
export const selectComplianceTracking = (state: RootState) => state.compliance.complianceTracking;
