import { useTheme } from '@material-ui/core/styles';
import { Stack, Typography, Box } from '@material-ui/core';
import { Compliance } from '../../@types/compliance'
import alertFill from '@iconify/icons-eva/alert-circle-outline';
import checkmarkFill from '@iconify/icons-eva/checkmark-circle-2-outline';
import { Icon } from '@iconify/react';
import { fDateShort2 } from '../../utils/formatTime'
import { differenceInDays } from 'date-fns';

function ComplianceDateLabel({ compliance, disabled = false, iconPadding = 0.1 }: { compliance: Compliance | null, disabled?: boolean, iconPadding?: number }) {

    const theme = useTheme();
    const days = differenceInDays(new Date(), (compliance && compliance.expiryDate ? new Date(compliance.expiryDate) : new Date()));

    if (!compliance || !compliance.expiryDate) {
        return <>-</>
    }

    return (
        <Stack direction="row">
            {compliance && compliance.expiryDate && days > 0 ?
                <Box sx={{ pt: iconPadding, pr: 0.2 }}><Icon style={{ fontSize: '1rem', color: disabled ? 'text.disabled' : theme.palette.error.dark }} icon={alertFill} /></Box>
                : <Box sx={{ pt: iconPadding, pr: 0.3 }}><Icon style={{ fontSize: '1rem', color: disabled ? 'text.disabled' : theme.palette.success.dark }} icon={checkmarkFill} /></Box>}
            <Typography
                variant="subtitle2"
                sx={{ ...(disabled && { color: 'text.disabled' }) }}
            >
                {compliance?.expiryDate ? fDateShort2(compliance?.expiryDate) : '-'}
            </Typography>
        </Stack>
    );
}

export default ComplianceDateLabel;

