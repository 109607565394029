// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_APP = '/app';
const ROOTS_GROUP = '/group';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_APP = {
  root: ROOTS_APP,
  general: {
    dashboard: path(ROOTS_APP, '/dashboard'),
    analytics: path(ROOTS_APP, '/analytics'),
    groups: path(ROOTS_APP, '/groups'),
    projects: path(ROOTS_APP, '/projects'),
  },
  fleet: {
    root: path(ROOTS_APP, '/fleet'),
    vehicles: path(ROOTS_APP, '/fleet/vehicles'),
  },
  clients: {
    root: path(ROOTS_APP, '/customers'),
    search: path(ROOTS_APP, '/customers'),
  },
  user: {
    root: path(ROOTS_APP, '/user'),
    profile: path(ROOTS_APP, '/user/profile'),
    cards: path(ROOTS_APP, '/user/cards'),
    list: path(ROOTS_APP, '/user/list'),
    newUser: path(ROOTS_APP, '/user/new'),
    editById: path(ROOTS_APP, `/user/reece-chung/edit`),
    account: path(ROOTS_APP, '/user/account')
  },
  blog: {
    root: path(ROOTS_APP, '/blog'),
    posts: path(ROOTS_APP, '/blog/posts'),
    post: path(ROOTS_APP, '/blog/post/:title'),
    postById: path(ROOTS_APP, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_APP, '/blog/new-post')
  }
};

export const PATH_GROUP = {
  root: ROOTS_GROUP,
  general: {
    dashboard: path(ROOTS_GROUP, '/dashboard'),
    analytics: path(ROOTS_GROUP, '/analytics'),
  },
  fleet: {
    root: path(ROOTS_GROUP, '/fleet'),
    vehicles: path(ROOTS_GROUP, '/fleet/vehicles'),
  },
  invoices: {
    root: path(ROOTS_GROUP, '/invoices'),
    vehicles: path(ROOTS_GROUP, '/invoices'),
  },
};

export const PATH_DOCS = 'https://veem.co.nz/introduction';
