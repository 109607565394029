import { useState } from 'react'
import { Card, CardContent, Typography, Stack, Box, Button } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { VehicleCardRow, VehicleLinkCreate } from '../vehicle'
import ConfirmModal from '../shared/modal/ConfirmModal'
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { InvoiceLink, InvoiceLinkType, Invoice } from '../../@types/invoice'
import { fDateTime } from '../../utils/formatTime'
import { useDeleteInvoiceLinkMutation } from '../../services/invoice';

type InvoiceLinkProps = {
    invoice: Invoice;
    invoiceLink: InvoiceLink | null;
}

function InvoiceLinkProvider({ link, invoiceId, onLinkDeleteRequested }: { link: InvoiceLink, invoiceId: string, onLinkDeleteRequested(invoiceId: string): void }) {

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    if (link.linkType === InvoiceLinkType.Vehicle) {
        return (
            <>
                <Typography variant="caption" sx={{ color: 'text.disabled', mb: 2, display: 'flex' }}>
                    This invoice is currently linked to the below vehicle. This link was created by {link.whoCreatedUser ? link.whoCreatedUser.displayName : 'the System'} on {fDateTime(link.updated)}. System linking is based on
                    the details in the invoice reference
                </Typography>
                <VehicleCardRow vehicleId={link.linkedResourceId} />
                <Stack direction="row" justifyContent="flex-end">
                    <Box sx={{ m: 1 }}>
                        <Button size="small" startIcon={<Icon icon={trash2Fill} />} onClick={() => setConfirmDeleteOpen(true)}>Delete Link</Button>
                    </Box>
                    <ConfirmModal
                        title="Confirm Invoice Link Delete"
                        description={"Are you sure you want to delete this invoice link? This will break the link between the invoice and the vehicle. A new link can be created at any time"}
                        modalOpen={confirmDeleteOpen}
                        handleConfirmModalConfirm={() => onLinkDeleteRequested(invoiceId)}
                        handleConfirmModalClose={() => setConfirmDeleteOpen(false)}
                        handleConfirmModalCancel={() => setConfirmDeleteOpen(false)}
                    />
                </Stack>
            </>
        )
    }

    return (
        <div>Unsupported</div>
    )
}

function InvoiceLinkSummary({ invoiceLink, invoice }: InvoiceLinkProps) {

    const [deleteInvoiceLink] = useDeleteInvoiceLinkMutation();
    const breakInvoiceLink = async (invoiceId: string) => {
        try {
            await deleteInvoiceLink(invoiceId).unwrap();
        } catch (error) {
            console.error(error);
        }
    };

    if (!invoiceLink) {
        return (
            <Card>
                <CardContent>
                    <Typography variant="overline" sx={{ mb: 1 }}>Invoice Link</Typography>
                    <VehicleLinkCreate invoiceId={invoice.id} />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="overline" sx={{ mb: 1 }}>Invoice Link</Typography>
                <InvoiceLinkProvider
                    invoiceId={invoice.id}
                    link={invoiceLink}
                    onLinkDeleteRequested={breakInvoiceLink}
                />
            </CardContent>
        </Card>
    );
}

export default InvoiceLinkSummary;

