import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, MenuItem } from '@material-ui/core';
import { LoadingButton, DatePicker } from '@material-ui/lab';
import * as Yup from 'yup';
import { useDispatch } from '../../../redux/store';
import { useAddVehicleComplianceMutation } from '../../../services/vehicles';
import { ComplianceStatus, Compliance } from '../../../@types/compliance'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Dictionary } from '@fullcalendar/common';

type ComplianceCreateFormProps = {
    vehicleId: string;
  };

type ComplianceCreateFormValues = {
    lastComplianceInspectionDate: Date;
    complianceExpiryDate: Date;
    status: ComplianceStatus;
    createComplianceReminder: boolean;
    assignToMe: boolean;
    note: string;
  };

export const FormSchema = Yup.object().shape({
    lastComplianceInspectionDate: Yup.date()
      .required('Last Inspection Date is required')
  });

export default function ComplianceCreateForm({ vehicleId } : ComplianceCreateFormProps) {
  
  const dispatch = useDispatch();
  const {
    watch,
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty }
  } = useForm<ComplianceCreateFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      lastComplianceInspectionDate: new Date(),
      complianceExpiryDate: new Date(),
      createComplianceReminder: false,
      status: ComplianceStatus.Passed,
    }
  })

  const complianceStatusValue = watch('status');
  const [addVehicleCompliance] = useAddVehicleComplianceMutation();

  const onSubmit = async (data: ComplianceCreateFormValues) => {

    try {
      // dispatch the update
      await addVehicleCompliance({
        vehicleId: vehicleId,
        complianceExpiryDate: data.complianceExpiryDate,
        lastComplianceInspectionDate: data.lastComplianceInspectionDate,
        newStatus: data.status,
        createComplianceReminder: data.createComplianceReminder,
        assignToMe: data.assignToMe,
        note: data.note,
      }).unwrap();
      reset();

    } catch (error) {
      var queryError = error as FetchBaseQueryError;
      if (queryError.data) {
        var errorKeys = queryError.data as { errors: Dictionary };
        Object.keys(errorKeys).forEach((key: any) => {
          let errors: string[] = Object.keys(errorKeys.errors[key]).map((errorKey: string) => {
              return errorKeys.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
            <Controller
              name="lastComplianceInspectionDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  label="Last Inspection Date"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth 
                      error={Boolean(error)}
                      helperText={error?.message || "This is the date the vehicle recieved its last compliance"}
                    />
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              )}
            />
            <Controller
              name="status"
              control={control}
              render={({ field, fieldState: { error } }) =>
                  <TextField
                      {...field}
                      select
                      fullWidth
                      error={Boolean(error)}
                      helperText={error?.message || "Select the initial status for the compliance"}
                      label="Compliance Status"
                  >
                      <MenuItem value={ComplianceStatus.Failed}>Failed</MenuItem>
                      <MenuItem value={ComplianceStatus.Passed}>Passed</MenuItem>
                  </TextField>
              }
            />
            { complianceStatusValue == ComplianceStatus.Passed ?
              <Controller
                name="complianceExpiryDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Compliance Expiry Date"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth 
                        error={Boolean(error)}
                        helperText={error?.message || "This is the date the latest compliance is due to expire. This is used to calculate the compliance expiry notification"}
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                )}
              />: null
            }
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  rows={3}
                  fullWidth
                  multiline
                  placeholder="Add a note or reminder to this compliance"
                  label="Compliance Note"
                />
              )}
            />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty}
          >
            Create Compliance
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
