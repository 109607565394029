import { EmailVerification, EmailVerificationRequest, EmailCodeVerificationRequest, EmailVerificationResendRequest } from '../@types/email'
import { api } from './api'

export const emailsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        verifyEmail: builder.mutation<EmailVerification, EmailVerificationRequest>({
            query: (request: EmailVerificationRequest) => ({
                url: `emails/verify`,
                method: 'POST',
                body: request,
            }),
        }),

        verifyEmailResend: builder.mutation<EmailVerification, EmailVerificationResendRequest>({
            query: (request: EmailVerificationResendRequest) => ({
                url: `emails/verify/${request.verificationId}/resend`,
                method: 'POST',
                body: request,
            }),
        }),

        verifyEmailCode: builder.mutation<EmailVerification, EmailCodeVerificationRequest>({
            query: (request: EmailCodeVerificationRequest) => ({
                url: `emails/verify/${request.verificationId}/code`,
                method: 'POST',
                body: request
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useVerifyEmailMutation,
    useVerifyEmailResendMutation,
    useVerifyEmailCodeMutation,
} = emailsApi;