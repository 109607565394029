import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@material-ui/lab';
import { Stack, Typography, Checkbox, FormControlLabel, TextField, Box } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack5';
import { useDispatch } from '../../../redux/store';
import { updateClientConfiguration } from '../../../redux/slices/client';
import { ComplianceTypeConfiguration } from '../../../@types/compliance'
import HandleBarsPreview from '../../shared/text/HandleBarsPreview'
import { Project } from '../../../@types/project';

type ComplianceSettingsFormProps = {
    project: Project;
    clientId: string;
    complianceConfiguration: ComplianceTypeConfiguration;
    complianceTypeCode: 'WOF' | 'COF';
    onSettingsUpdated?(): void;
    onUpdateCancelled?(): void;
};

type ClientComplianceEmailSettingsFormValues = {
    emailReminderFromName: string;
    emailRemindersEnabled: boolean;
    emailReminderSendToClient: boolean;
    emailReminderBody: string | null;
    emailMessageTemplate: string | null;
    reminderAdditionalEmail: string;
    reminderReplyToEmail: string;
    textRemindersEnabled: boolean;
    textMessageTemplate: string | null;
};

export const FormSchema = Yup.object().shape({
    emailReminderFromName: Yup.string().nullable(),
    emailRemindersEnabled: Yup.boolean().required(),
    emailReminderSendToClient: Yup.boolean().required(),
    emailReminderBody: Yup.string().nullable(),
    emailMessageTemplate: Yup.string().nullable(),
    reminderAdditionalEmail: Yup.string().nullable(),
    textRemindersEnabled: Yup.boolean().required(),
    textMessageTemplate: Yup.string().nullable(),
});

export default function ClientComplianceEmailSettingsForm({ project, clientId, complianceTypeCode, complianceConfiguration, onSettingsUpdated, onUpdateCancelled }: ComplianceSettingsFormProps) {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {
        watch,
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting, isDirty, isValid }
    } = useForm<ClientComplianceEmailSettingsFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            emailRemindersEnabled: complianceConfiguration.reminderConfiguration.emailConfig.enabled,
            emailReminderBody: complianceConfiguration.reminderConfiguration.emailConfig.emailBody,
            emailMessageTemplate: complianceConfiguration.reminderConfiguration.emailConfig.messageTemplate,
            emailReminderSendToClient: complianceConfiguration.reminderConfiguration.emailConfig.sendReminderToClient,
            emailReminderFromName: complianceConfiguration.reminderConfiguration.emailConfig.reminderFromName,
            reminderAdditionalEmail: complianceConfiguration.reminderConfiguration.emailConfig.reminderAdditionalEmail,
            reminderReplyToEmail: complianceConfiguration.reminderConfiguration.emailConfig.reminderReplyToEmail,
            textRemindersEnabled: complianceConfiguration.reminderConfiguration.textMessageConfig.enabled,
            textMessageTemplate: complianceConfiguration.reminderConfiguration.textMessageConfig.messageTemplate,
        }
    })

    const cancel = () => {
        reset();
        onUpdateCancelled && onUpdateCancelled();
    }

    const emailRemindersEnabledWatch = watch('emailRemindersEnabled');
    const textRemindersEnabledWatch = watch('textRemindersEnabled');
    const textMessageTemplateWatch = watch('textMessageTemplate');

    const onSubmit = async (data: ClientComplianceEmailSettingsFormValues) => {

        try {
            // post the configuration
            await dispatch(updateClientConfiguration({
                projectIdentifer: project.identifier,
                clientId: clientId,
                payload: {
                    level: 2,
                    levelIdentifier: clientId,
                    complianceType: complianceTypeCode,
                    complianceReminderConfig: {
                        emailRemindersEnabled: data.emailRemindersEnabled,
                        emailReminderBody: data.emailReminderBody,
                        emailReminderMessageTemplate: data.emailMessageTemplate,
                        emailReminderSendToClient: data.emailReminderSendToClient,
                        emailReminderFromName: data.emailReminderFromName,
                        reminderAdditionalEmail: data.reminderAdditionalEmail,
                        reminderReplyToEmail: data.reminderReplyToEmail,
                        textRemindersEnabled: data.textRemindersEnabled,
                        textMessageTemplate: data.textMessageTemplate
                    }
                }
            })).unwrap()

            // load the new configuration state
            enqueueSnackbar('Vehicle settings updated', { variant: 'success' });
            reset(data);

            if (onSettingsUpdated) {
                onSettingsUpdated()
            }
        }
        catch (err) {
            enqueueSnackbar('Error occurred updating client settings', { variant: 'error' });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <Typography variant="subtitle1">Email Reminders</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Email reminders are sent when a vehicle compliance is near its due date. This feature should be enabled if you provide
                    compliance check services so that your clients know when to bring their vehicle to you for an inspection.
                </Typography>
                <Controller
                    name="emailRemindersEnabled"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            label={`Enable ${complianceTypeCode} Email Reminders For Client`}
                            control={
                                <Checkbox
                                    {...field}
                                    onChange={e => field.onChange(e.target.checked)}
                                    checked={field.value}
                                />
                            }
                        />
                    )}
                />
                {emailRemindersEnabledWatch ?
                    <>
                        <Controller
                            name="emailReminderSendToClient"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    label={`Send ${complianceTypeCode} Reminder To Client`}
                                    control={
                                        <Checkbox
                                            {...field}
                                            onChange={e => field.onChange(e.target.checked)}
                                            checked={field.value}
                                        />
                                    }
                                />
                            )}
                        />
                        {/* <Controller
                            name="emailReminderFromName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label={`${complianceTypeCode} Email From Address`}
                                    helperText="This will set the from name for the reminder email that is sent. If not set it will use the name of the project."
                                />
                            )}
                        />
                        <Controller
                            name="reminderReplyToEmail"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label="ReplyTo Email Address"
                                    helperText="This is the email address that will be used when the client replies to the notification. If this is not supplied the project email will be used"
                                />
                            )}
                        />
                        <Controller
                            name="reminderAdditionalEmail"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label="Additional Email Address"
                                    helperText="This is an additional email address to send the reminder to. This will normally be sent to an internal email and used to track the reminders that are being sent to clients."
                                />
                            )}
                        />
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            You can customise the email that is sent using the template below. The variables listed below will be substituted when the message is sent. Remember to include contact details so that your customers can get in touch to schedule an inspection.
                        </Typography>
                        <Controller
                            name="emailMessageTemplate"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label={`${complianceTypeCode} Email Body (optional)`}
                                    helperText={`Type the message template above to view the preview message below`}
                                    multiline
                                    rows={7}
                                />
                            )}
                        />
                        <HandleBarsPreview
                            messageTemplate={emailMessageTemplateWatch}
                            data={{
                                vehicle_make: "TOYOTA",
                                vehicle_model: "COROLLA",
                                vehicle_identifier: "ABC123",
                                compliance_type: "WOF",
                                compliance_expiry_date: "2024/01/01",
                                owner: "Joe Bloggs",
                                organisation_name: project.projectName
                            }}
                        /> */}
                    </>
                    : null}
                <Typography variant="subtitle1">Text Reminders</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>Text reminders can be sent to the vehicle owner if the contact has a mobile phone number set (additional charges appply, please refer to your subscription).</Typography>
                <Controller
                    name="textRemindersEnabled"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            label="Enable Text Message Reminders"
                            control={
                                <Checkbox
                                    {...field}
                                    onChange={e => field.onChange(e.target.checked)}
                                    checked={field.value}
                                />
                            }
                        />
                    )}
                />
                {textRemindersEnabledWatch && (
                    <>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            You can customise the message that is sent using the template below. The variables listed below will be substituted when the message is sent (messages with more than 160 characters will be broken into message parts and each will incur a charge, we recommend keeping the message below 160 characters)
                        </Typography>
                        <Controller
                            name="textMessageTemplate"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label={`${complianceTypeCode} text message`}
                                    helperText={`Type the message template above to view the preview message below`}
                                    multiline
                                    rows={4}
                                />
                            )}
                        />
                        <HandleBarsPreview
                            messageTemplate={textMessageTemplateWatch}
                            data={{
                                vehicle_identifier: "ABC123",
                                vehicle_make: "TOYOTA",
                                vehicle_model: "COROLLA",
                                compliance_type: complianceTypeCode,
                                compliance_expiry_date: "2024/01/01",
                                owner: "Joe Bloggs",
                                organisation_name: project.projectName,
                                organisation_phone: project.branding.contactPhoneNumber,
                            }}
                        />
                    </>
                )}
            </Stack>
            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                >
                    Update
                </LoadingButton>
                <LoadingButton
                    size="large"
                    color="error"
                    variant="text"
                    sx={{ ml: 1 }}
                    onClick={() => cancel()}
                >
                    Cancel
                </LoadingButton>
            </Box>
        </form>
    );
}