import { Card, Stack, CardHeader, ListItemButton, ListItemText, Paper, Avatar, ListItemAvatar, List, Box, MenuItem, Select } from '@material-ui/core';
import { ProjectMember, ProjectMembershipState, ProjectAccessLevel } from '../../@types/project'
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import EmptyContent from '../shared/EmptyContent'
import useProject from '../../hooks/useProject'
import { fDateMonthYear } from '../../utils/formatTime'
import { styled } from '@material-ui/core/styles';
import { updateProjectMember, deleteProjectMember } from '../../redux/slices/project'
import { useDispatch } from '../../redux/store';
import { useAuth0 } from '@auth0/auth0-react';
import { checkUserHasPermission } from '../../utils/auth/checkUserPermission';

type ProjectMembersProps = {
  members: ProjectMember[];
};

const ListWrapperStyle = styled(Paper)(({ theme }) => ({
  width: '100%',
}));

export default function ProjectMembers({ members }: ProjectMembersProps) {

  const dispatch = useDispatch();
  const { project } = useProject();
  const { user } = useAuth0();
  const hasUserUpdatePermission = checkUserHasPermission(user, 'module/identity/user/update');

  return (
    <Card sx={{ overflow: 'unset', position: 'unset', width: '100%' }}>
      <CardHeader title="Members" />
      <Box
        sx={{
          p: 3,
          minHeight: 180,
        }}
      >
        <ListWrapperStyle>
          <List>
            {members.length === 0 ? <EmptyContent title={'No Members'} description={'No project members to display'} imgHeight={80} />
              : members.map((membership) => (
                <ListItemButton key={membership.member.id}>
                  <ListItemAvatar>
                    <Avatar alt={membership.member.displayName} src={membership.member.imageUrl}/>
                  </ListItemAvatar>
                  <ListItemText primary={membership.member.displayName} secondary={membership.membershipState === ProjectMembershipState.Active ? `Added: ${fDateMonthYear(membership.created)}` : 'Invitation Pending'} />
                  <Stack direction="row" justifyContent="space-between">
                    <Select
                      size="small"
                      labelId="project-access-level-select-label"
                      id="project-access-level-select"
                      value={membership.accessLevel}
                      disabled={!hasUserUpdatePermission}
                      onChange={(e) => dispatch(updateProjectMember({
                        memberId: membership.member.id,
                        projectIdentifier: project?.identifier || '',
                        accessLevel: e.target.value
                      }))}
                    >
                      {Object.keys(ProjectAccessLevel).filter(_ => _ !== ProjectAccessLevel.NoAccess.toString()).map((key) => (<MenuItem value={key}>{key}</MenuItem>))}
                    </Select>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <MenuItem
                      sx={{ color: 'error.main' }}
                      disabled={!hasUserUpdatePermission}
                      onClick={(e) => dispatch(deleteProjectMember({
                        memberId: membership.member.id,
                        projectIdentifier: project?.identifier || ''
                      }))}
                    >
                      <Icon icon={trash2Outline} width={20} height={20} />
                    </MenuItem>
                  </Stack>
                </ListItemButton>
              ))}
          </List>
        </ListWrapperStyle>
      </Box>
    </Card>
  );
}
