import { useEffect, ReactNode } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, Drawer, Tooltip, CardActionArea, Divider, ListItemText, ListItemButton, ListItemIcon, ListItemButtonProps } from '@material-ui/core';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { PATH_APP } from '../../routes/paths';
import Logo from '../../components/shared/Logo';
import LogoImageFull from '../../components/shared/brand/LogoImageFull';
import useProject from '../../hooks/useProject'
import Scrollbar from '../../components/shared/Scrollbar';
import NavSection from '../../components/shared/NavSection';
import { MHidden } from '../../components/@material-extend';
import ProjectAvatar from '../../components/project/ProjectAvatar';
import { getSideBarConfig } from './SidebarConfig';
import { DocIllustration } from '../../assets';
import SvgIconStyle from '../../components/shared/SvgIconStyle';
import { varFadeInLeft, MotionInView } from '../../components/animate';
import { DRAWER_WIDTH, COLLAPSE_WIDTH } from '../constants'

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 1.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  },
  '&:hover': {
    //you want this to be the same as the backgroundColor above
    backgroundColor: 'transparent',
  }
}));

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type SettingsSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function SettingsSidebar({ isOpenSidebar, onCloseSidebar }: SettingsSidebarProps) {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const { project } = useProject();

  var sidebarConfig = getSideBarConfig(id, project.accessLevel);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 1,
          pt: 2,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to={PATH_APP.general.projects} sx={{ display: 'inline-flex' }}>
          { isCollapse ? (<Logo />) : (<LogoImageFull />)}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
          </MHidden>
        </Stack>

        {isCollapse && project ? (
          //https://ui-avatars.com/api/?name=Silverdale+Mechanical
          <ProjectAvatar project={project}/>
        ) : (
            <AccountStyle>
              <ListItemStyle
                disableGutters
                component={RouterLink}
                to={`/group/${id}/dashboard`}
                // sx={{
                //   ...(isActiveRoot && activeRootStyle)
                // }}
              >
                <ListItemIconStyle>{getIcon('ic_left-arrow')}</ListItemIconStyle>
                <ListItemText primary={'Back To Project'} />
              </ListItemStyle>
            </AccountStyle>
        )}
      </Stack>
      <Divider />
      <MotionInView variants={varFadeInLeft}>
        <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
      </MotionInView>

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
        >
          <DocIllustration sx={{ width: 1 }} />
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    
      <RootStyle
        sx={{
          width: {
            lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
          },
          ...(collapseClick && {
            position: 'absolute'
          })
        }}
      >
        <MHidden width="lgUp">
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="lgDown">
          <Drawer
            open
            variant="persistent"
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.default',
                ...(isCollapse && {
                  width: COLLAPSE_WIDTH
                }),
                ...(collapseHover && {
                  borderRight: 0,
                  backdropFilter: 'blur(6px)',
                  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                  boxShadow: (theme) => theme.customShadows.z20,
                  bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                })
              }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>

  );
}
