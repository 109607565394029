import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import useProject from '../../../hooks/useProject';
import * as Yup from 'yup';
import { useDispatch } from '../../../redux/store';
import { ServiceTask } from '../../../@types/maintenance';
import { useAddServiceTaskMutation } from '../../../services/servicetasks';
import { Link as RouterLink } from 'react-router-dom';

type ServiceTaskFormProps = {
    serviceTaskId?: string;
    projectIdentifier: string;
    task?: ServiceTask;
    onCreated(): void;
  };

type ServiceTaskFormValues = {
    title: string;
    description: string;
  };

export const FormSchema = Yup.object().shape({
  title: Yup.string()
      .required('Service task title is required')
      .min(3)
  });

export default function ServiceTaskForm({ serviceTaskId, task, projectIdentifier, onCreated } : ServiceTaskFormProps) {
  
  const dispatch = useDispatch();
  const { project } = useProject();
  const [addServiceTask] = useAddServiceTaskMutation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<ServiceTaskFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      title: task?.title || '',
      description: task?.description || ''
    }
  })

  
  const onSubmit = async (data: ServiceTaskFormValues) => {

    try {
      // dispatch the update
      const result = await addServiceTask({
        projectIdentifier: projectIdentifier,
        serviceTaskId: task?.id || '',
        title: data.title,
        description: data.description,
      }).unwrap();

      reset();
      onCreated();
      enqueueSnackbar('Service Task Created', { variant: 'success', autoHideDuration: 6000, action: () => <RouterLink to={`group/${project.identifier}/maintenance/service-tasks/${result?.id}`}>View Task</RouterLink> });

    } catch (error) {
      if (error.errors) {
        Object.keys(error.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(error.errors[key]).map((errorKey: string) => {
              return error.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message}
                    label="Task Title"
                    autoComplete='off'
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState: { error } }) =>
                  <TextField
                      {...field}
                      fullWidth
                      error={Boolean(error)}
                      helperText={error?.message || "A description of the task"}
                      label="Task Description"
                      autoComplete='off'
                  />
              }
            />
       
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            {task ? 'Update Service Task' : 'Create Service Task'}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
