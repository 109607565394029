import { Skeleton } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

type ListSkeletonLoadProps = {
    numberOfItems?: number;
    itemHeight?: number; 
};

export default function ListSkeletonLoad({ numberOfItems = 5, itemHeight = 40 }: ListSkeletonLoadProps) {

    return (
        <>
            {Array.from({length: numberOfItems}).map((value) =>
                <Skeleton
                    key={`${uuidv4()}_Skeleton`}
                    height={itemHeight} component="div"
                />
            )}
        </>
    );
}
