import { Card, CardHeader, Box, Typography } from '@material-ui/core';
import { ProjectAccessLevel } from '../../@types/project'
import useProject from '../../hooks/useProject'
import AddProjectMemberForm from './forms/AddProjectMemberForm'

export default function ProjectMemberAdd() {

    const { project } = useProject();

    return (
        <Card sx={{ overflow: 'unset', position: 'unset', width: '100%' }}>
            <CardHeader title="Add Member" />
            <Box
                sx={{
                p: 3,
                minHeight: 180,
                }}
            >
                { project?.accessLevel === (ProjectAccessLevel.Administrator || ProjectAccessLevel.Maintainer) ?
                    <>
                        <Typography variant="body2" sx={{ color: 'text.secondary', pb: 2 }}>
                            To add a member to this project search for their name or use email to invite
                        </Typography>
                        <AddProjectMemberForm projectIdentifier={project.identifier}/>
                    </>
                : 
                <Typography variant="body2" sx={{ color: 'text.secondary', pb: 2 }}>
                    Your current project access level does not allow you to invite group members. You require Owner or Maintainer
                    access to invite memebers.
                </Typography>
                }
            </Box>
        </Card>
    );
}
