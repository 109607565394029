import { forwardRef } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

const MNumberTextField = forwardRef<HTMLButtonElement, TextFieldProps>(
    ({ children, ...other }, ref) => (
        <TextField
        {...other}
        type="number"
        autoComplete={'off'}
        onWheel={(event) => event.currentTarget.querySelector('input')?.blur()}
    />
      )
);

export default MNumberTextField;
