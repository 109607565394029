import { useEffect } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Typography, InputAdornment } from '@material-ui/core';
import { LoadingButton, DatePicker } from '@material-ui/lab';
import * as Yup from 'yup';
import { Service, ServicePlan } from '../../../@types/maintenance';
import { Vehicle } from '../../../@types/vehicle';
import { SelectOption } from '../../../@types/search';
import { useDispatch } from '../../../redux/store';
import { useAddServiceMutation } from '../../../services/services';
import { useGetServicePlanVehicleQuery, useAddServicePlanVehicleMutation } from '../../../services/serviceplans';
import { useGetVehicleQuery } from '../../../services/vehicles';
import { ServicePlanSelectList } from '../../maintenance';
import axios from '../../../utils/axios';
import LoadingScreen from '../../shared/LoadingScreen';

type ServicePlanDetailSectionProps = {
  servicePlanId: string;
  vehicleId: string;
};

function ServicePlanDetailSection({ servicePlanId, vehicleId }: ServicePlanDetailSectionProps) {

  const { data } = useGetServicePlanVehicleQuery({ servicePlanId: servicePlanId, vehicleId: vehicleId });

  if (data) {
    return (
      <Typography variant="body2">
        This vehicle is already part of the selected service plan.
      </Typography>
    )
  }

  return (
    <Stack>
      <Typography variant="body2">
        This vehicle is not currently part of this service plan. To add this vehicle to the selected plan, you need to specify the mileage this service was last completed
      </Typography>
    </Stack>
  )
}

type VehicleServiceFormProps = {
  isEdit: boolean;
  onServiceCreated?: (service: Service) => void;
  service: Service | null;
  vehicle: Vehicle;
};

type VehicleServiceFormValues = {
  date: Date;
  serviceMileage: Number;
  text: string;
  servicePlan: SelectOption | null;
  tasks: [{
    name: string;
    description: string;
  }]
};

export const FormSchema = Yup.object().shape({

});

export default function VehicleServiceForm({ service, vehicle, onServiceCreated }: VehicleServiceFormProps) {

  const dispatch = useDispatch();
  const [addService] = useAddServiceMutation();
  const [addServicePlanVehilce] = useAddServicePlanVehicleMutation();
  const {
    control,
    handleSubmit,
    setError,
    register,
    watch,
    formState: { isSubmitting, isValid, errors }
  } = useForm<VehicleServiceFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      date: new Date(),
      servicePlan: null,
      text: 'hello'
    }
  })

  const onSubmit = async (data: VehicleServiceFormValues) => {

    try {
      var service = await addServicePlanVehilce({
        vehicleId: vehicle.id,
        //date: data.date,
        serviceMileage: data.serviceMileage,
        servicePlanId: data.servicePlan?.id || '',
      }).unwrap()

      if (onServiceCreated) {
        //onServiceCreated(service);
      }
    }
    catch (error) {
      if (error && error.errors) {
        Object.keys(error.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(error.errors[key]).map((errorKey: string) => {
            return error.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      } else {

      }
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tasks"
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == 'servicePlan') {
        console.log(value.servicePlan?.id)
        if (value.servicePlan?.id) {
          axios.get(`/api/service-plans/${value.servicePlan.id}`).then((result) => {
            var plan = result.data as ServicePlan;
            if (plan) {
              append(plan.tasks.map((task) => {
                return {
                  name: task.title,
                  description: task.description
                }
              }));
            }
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const servicePlanWatch = watch("servicePlan");
  const watchFieldArray = watch("tasks");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography variant="body2">Select a service plan to get started</Typography>
          <Controller
            name="servicePlan"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ServicePlanSelectList field={field} />
            )}
          />
          {servicePlanWatch && servicePlanWatch.id ?
            <>
              <ServicePlanDetailSection
                servicePlanId={servicePlanWatch.id}
                vehicleId={vehicle.id}
              />
              <Controller
                name="date"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Service Date"
                    renderInput={(params) => (
                      <TextField
                        fullWidth {...params}
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                )}
              />
              <Controller
                name="serviceMileage"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Service Mileage"
                    type="number"
                    error={Boolean(error)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">km</InputAdornment>,
                    }}
                    helperText={error?.message}
                    autoComplete={'off'}
                  />
                )}
              />
            </>
            : null}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Create Service
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
