import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../services/users'
import Page from '../components/shared/Page';
import { Container } from '@material-ui/core';
import ErrorComponent from '../components/shared/error/ErrorComponent';

type AuthGuardProps = {
  children: ReactNode;
  requiredPermission?: string;
};

export default function AccountSetupGuard({ children }: AuthGuardProps) {
  
  const { data: user, isLoading } = useGetCurrentUserQuery();

  if (isLoading) {
    return null;
  }
  
  if (!user) {
    return (
      <Page title={`Error: Can't Load Project`}>
          <Container sx={{ pt: 12 }}>
              <ErrorComponent title="Something has gone wrong..." description="We are having trouble loading user details" />
          </Container>
      </Page>
  );
  }

  if (!user.emailVerified) {
    return <Navigate to="/account/setup?email_verified=false" />
  }

  if (!user.setupComplete) {
    return <Navigate to="/account/setup" />
  }

  return <>{children}</>;
}
