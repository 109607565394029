import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from '../../@types/authentication';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
  loading: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    
  }
});

// Reducer
export default slice.reducer;

// export function getUsers() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/api/user/all');
//       dispatch(slice.actions.getUsersSuccess(response.data.users));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
