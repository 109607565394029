import { useState, useRef, ReactFragment } from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Menu } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

type SearchFilterProps = {
    name: string;
    active: boolean;
    searchComponent: ReactFragment;
}

const ButtonStyle = styled('div')<{ active?: boolean }>(({ theme, active }) => ({
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 5,
    backgroundColor: active ? '#CFF5F6' : theme.palette.grey[100],
    paddingLeft: active ? 10 : 15,
    paddingRight: 15,
}));

const CheckIconStyle = styled(CheckIcon)<{ active?: boolean }>(({ theme, active }) => ({
    color: theme.palette.primary.main,
}));

const SpanStyle = styled('span')<{ active?: boolean }>(({ theme, active }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    color: active ? '#0570DE' : 'grey',
}));

export default function SearchFilter({ name, active, searchComponent }: SearchFilterProps) {

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <ButtonStyle
                ref={anchorRef}
                onClick={handleOpen}
                active={active}
            >
                {active && (
                    <Box sx={{ pt: 0.5, pr: 0.5 }}>
                        <CheckIconStyle />
                    </Box>
                )}
                <SpanStyle active={active}>{name}</SpanStyle>
            </ButtonStyle>
            <Menu
                anchorEl={anchorRef.current}
                id={`search-menu-name`}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 0.5,
                        width: '200px',
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                {searchComponent}
            </Menu>
        </>
    )
}