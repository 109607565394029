import { useState, useEffect } from 'react'
import { addDays, format } from 'date-fns'
import { Stack, Typography, Button } from '@material-ui/core';


type NextServiceDueDateComponentProps = {
    label: string;
    current: Date | null;
    dueInDays: number | null;
    enabled: boolean;
    onChange(nextDue: Date | null): void;
}

const NextServiceDueDateComponent = ({ label, current, dueInDays, enabled, onChange }: NextServiceDueDateComponentProps) => {

    const [nextDue, setNextDue] = useState<Date | null>(null);

    useEffect(() => {
        if (current && dueInDays) {
            setNextDue(addDays(new Date(current), dueInDays ?? 0));
        } else {
            setNextDue(null);
        }
    }, [current, dueInDays]);

    if (!enabled) {
        return <></>
    }

    if (!dueInDays) {
        return (
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 0.5 }}>
                <Stack direction="column" justifyContent="space-between">
                    <Typography variant="caption">Schedule has no interval set</Typography>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" justifyContent="space-between">
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" sx={{ mt: 0.5 }}>
                    <Typography variant="caption">{label}</Typography>
                    <Typography variant="caption" sx={{ ml: 0.5, fontWeight: 'bold' }}>{nextDue ? format(nextDue, 'dd/MM/yyyy') : '-'}</Typography>
                </Stack>
                <Button
                    size="small"
                    onClick={() => onChange(nextDue)}
                    disabled={nextDue === null}
                    sx={{ padding: 0 }}
                >
                    Apply
                </Button>
            </Stack>
        </Stack>
    )
}

export default NextServiceDueDateComponent;