import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { store, RootState, createSelector } from '../store';
import axios from '../../utils/axios';
import { ClientSummary } from '../../@types/client';
import { InvoiceSearchCriteria, InvoiceSearchSearchResult, Invoice } from '../../@types/invoice';
import { IVehicleSearchCriteria } from '../../@types/vehicles';
import { ComplianceConfiguration, IComplianceEmailReminderConfigurationUpdateRequest } from '../../@types/compliance';
import { Vehicle } from '../../@types/vehicle';
import { ConfigurationItem } from '../../@types/configuration';
import { GroupMember } from '../../@types/group';

type ClientState = {
  isLoading: boolean;
  notFound: boolean;
  error: boolean;
  client: ClientSummary | null;
  memberships: GroupMember[];
  invoices: {
    isFirstTime: boolean;
    isLoading: boolean;
    data: Invoice[];
    totalRows: number;
    searchCriteria: InvoiceSearchCriteria;
  },
  configuration: {
    compliance: ComplianceConfiguration | undefined;
  },
  vehicles: {
    isFirstTime: boolean;
    isLoading: boolean;
    data: Vehicle[];
    totalRows: number;
    searchCriteria: IVehicleSearchCriteria;
  }
};

const initialState: ClientState = {
  isLoading: false,
  notFound: false,
  error: false,
  client: null,
  memberships: [],
  invoices: {
    isFirstTime: true,
    isLoading: false,
    data: [],
    totalRows: 0,
    searchCriteria: {
      pageNumber: 0,
      pageSize: 25,
      order: 'desc',
      orderBy: 'dueDate',
      text: null,
      unlinkedOnly: false,
      statusFilter: [],
      typeFilter: [],
    }
  },
  configuration: {
    compliance: undefined,
  },
  vehicles: {
    isFirstTime: true,
    isLoading: false,
    data: [],
    totalRows: 0,
    searchCriteria: {
      pageNumber: 0,
      pageSize: 25,
      order: 'desc',
      orderBy: 'updated',
      text: null,
      projectIdentifier: null,
      vehicleIdentifiers: [],
      makes: [],
      clients: []
    }
  }
};

type ComplianceConfigurationResponse = {
  complianceConfiguration: ComplianceConfiguration;
  overrides: ConfigurationItem[];
}

export const fetchClientVehicles = createAsyncThunk(
  'client/fetchVehicles',
  async (request, { rejectWithValue, getState }) => {
    try {
      const { client, project } = getState() as RootState;
      const search = client.vehicles.searchCriteria;
      const response = await axios.post<any, any>(`/api/vehicle/search`, {
        projectIdentifier: project.project?.identifier,
        clients: [client.client?.id],
        makes: search.makes.map((item) => item.id),
        registrations: search.vehicleIdentifiers.map((item) => item.id),
        pageNumber: search.pageNumber,
        pageSize: search.pageSize,
        order: search.order,
        orderBy: search.orderBy,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchClientInvoices = createAsyncThunk(
  'client/fetchInvoices',
  async (request, { rejectWithValue, getState }) => {
    try {
      const { client, project } = getState() as RootState;
      const response = await axios.post<any, any>(`/api/clients/${client.client?.id}/invoices`, {
        projectId: project.project?.id,
        ...client.invoices.searchCriteria,
      });

      return response.data as InvoiceSearchSearchResult;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getClientConfiguration = createAsyncThunk(
  'client/getConfiguration',
  async (request: { projectIdentifer: string, clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/projects/${request.projectIdentifer}/compliance-config/1/${request.clientId}`);
      return response.data as ComplianceConfigurationResponse
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateClientConfiguration = createAsyncThunk(
  'client/updateConfiguration',
  async (request: { projectIdentifer: string, clientId: string, payload: any }, { rejectWithValue }) => {
    try {
      const response = await axios.put<any, any>(`/api/projects/${request.projectIdentifer}/config/compliance`, request.payload);
      return response.data as ComplianceConfigurationResponse
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateComplianceReminderConfiguration = createAsyncThunk(
  'client/updateComplianceReminderConfiguration',
  async (request: { projectIdentifer: string, clientId: string, payload: IComplianceEmailReminderConfigurationUpdateRequest }, { rejectWithValue }) => {
    try {
      const response = await axios.put<any, any>(`/api/projects/${request.projectIdentifer}/config/compliance/reminders`, request.payload);
      return response.data.complianceConfiguration as ComplianceConfiguration
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload.error;
      state.notFound = action.payload.notFound;
    },

    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;

      // reset search values
      state.invoices.isFirstTime = true;
      state.vehicles.isFirstTime = true;
    },

    getClientMembershipsSuccess(state, action) {
      state.isLoading = false;
      state.memberships = action.payload;
    },

    setClientInvoiceSearch(state, action) {
      state.invoices.searchCriteria = action.payload;
    },

    getClientVehiclesSuccess(state, action) {
      state.isLoading = false;
      state.vehicles.data = action.payload.vehicles;
      state.vehicles.totalRows = action.payload.totalCount;
      state.vehicles.isFirstTime = false;
    },

    setClientVehiclesSearchCriteria(state, action) {
      state.vehicles.searchCriteria = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientConfiguration.fulfilled, (state, action) => {
      state.configuration.compliance = action.payload.complianceConfiguration;
    })

    builder.addCase(updateClientConfiguration.fulfilled, (state, action) => {
      state.configuration.compliance = action.payload.complianceConfiguration;
    })

    builder.addCase(fetchClientInvoices.pending, (state, action) => {
      state.invoices.isLoading = true;
    })

    builder.addCase(fetchClientInvoices.fulfilled, (state, action) => {
      state.invoices.data = action.payload.invoices;
      state.invoices.totalRows = action.payload.totalCount;
      state.invoices.isFirstTime = false;
      state.invoices.isLoading = false;
    })

    builder.addCase(fetchClientInvoices.rejected, (state, action) => {
      state.invoices.isLoading = true;
    })

    builder.addCase(fetchClientVehicles.pending, (state, action) => {
      state.vehicles.isLoading = true;
    })

    builder.addCase(fetchClientVehicles.fulfilled, (state, action) => {
      state.vehicles.data = action.payload.vehicles;
      state.vehicles.totalRows = action.payload.totalCount;
      state.vehicles.isFirstTime = false;
      state.vehicles.isLoading = false;
    })

    builder.addCase(fetchClientVehicles.rejected, (state, action) => {
      state.vehicles.isLoading = true;
    })
  },
});

// Reducer
export default slice.reducer;

export const { setClientInvoiceSearch, setClientVehiclesSearchCriteria, getClientVehiclesSuccess } = slice.actions;

export function getClient(id: string) {
  return async () => {
    const { dispatch, getState } = store;
    const { client } = getState();

    if (!client.client || client.client?.id !== id) {

      dispatch(slice.actions.startLoading());

      try {
        const response = await axios.get(`/api/clients/${id}`);
        dispatch(slice.actions.getClientSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError({ error: error, notFound: error.status && error.status === 404 }));
      }
    }
  };
}

export function getClientMemberships(id: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/api/clients/${id}/memberships`);
      dispatch(slice.actions.getClientMembershipsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: error, notFound: false }));
    }
  };
}

// selectors
export const selectClientState = (state: RootState) => state.client;
export const selectClient = createSelector(selectClientState, state => state.client);
export const selectClientConfiguration = createSelector(selectClientState, state => state.configuration);
export const selectClientComplianceConfiguration = createSelector(selectClientState, state => state.configuration.compliance);