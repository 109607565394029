import { Box } from '@material-ui/core';
import { ServiceTask } from '../../@types/maintenance'
import { ServiceTaskListComponent } from '../_maintenance'
import { ServiceTaskSelectList } from './index'
import ListSkeletonLoad from '../shared/list/ListSkeletonLoad'
import { useSnackbar } from 'notistack5';
import { useAddServicePlanTaskMutation, useGetServicePlanTasksQuery, useRemoveServicePlanTaskMutation } from '../../services/serviceplans'

type ServiceTaskListProps = {
    tasks: ServiceTask[];
    loading?: boolean;
    servicePlanId: string;
};

function ServiceTaskList({ tasks, servicePlanId, loading = false }: ServiceTaskListProps) {

    const { enqueueSnackbar } = useSnackbar();
    const [addServicePlanTask] = useAddServicePlanTaskMutation();
    const [removeServicePlanTask] = useRemoveServicePlanTaskMutation();
    const { data = [], isLoading } = useGetServicePlanTasksQuery(servicePlanId);

    if (loading) {
        return <ListSkeletonLoad />;
    }

    const handleTaskRemoved = async (task: ServiceTask) => {
        await removeServicePlanTask({
            servicePlanId: servicePlanId,
            serviceTaskId: task.id,
            requestType: "ServiceTask"
        });

        return true;
    }

    const handleTaskAdded = async (id: string) => {
        try {
            // add the task
            await addServicePlanTask({
                servicePlanId: servicePlanId,
                serviceTaskId: id,
                requestType: "ServiceTask"
            }).unwrap();
        } catch (error) {
            var errorMessage = ""
            if (error.errors && Object.keys(error.errors).length > 0) {
                enqueueSnackbar(`Adding service task failed with error: ${error.errors[Object.keys(error.errors)[0]]}`, { variant: 'error' });
            } else {
                enqueueSnackbar(`Adding service task failed`, { variant: 'error' });
            }
        }
    }

    return (
        <>
            <ServiceTaskSelectList
                label="Add service task..."
                //excludedIds={data.map((task) => task.id)}
                excludedIds={[]}
                onChange={(option) => {
                    if (option) {
                        handleTaskAdded(option.id || '');
                    }
                }}
                //multiple={false}
                //handleSearch={() => Promise.resolve()}
                //error={null}
            />
            <Box sx={{ height: '20px'}}/>
            <ServiceTaskListComponent
                loading={isLoading}
                tasks={data}
                onTaskRemoved={handleTaskRemoved}
            />
        </>
    );
}

export default ServiceTaskList;