
import { Icon } from '@iconify/react';
import { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@material-ui/core/styles';
import { Box, TextField, Typography, Autocomplete, InputAdornment, BoxProps } from '@material-ui/core';
import SearchNotFound from '../SearchNotFound';

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    minWidth: 300,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      minWidth: 300,
      '& .MuiAutocomplete-inputRoot': {
        //boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `0px !important`,
      //borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-of-type)': {
      //borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

const MenuItemStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column'
}));

interface AutoCompleteHighlightedProps<T> extends BoxProps {
  loading?: boolean;
  options: T[];
  query: string;
  getOptionItemMainDescription(item: T): string;
  getOptionItemSecondaryDescription(item: T): string;
  getOptionLabel(item: T): string;
  handleChangeSearch(value: string): void;
  onItemSelected(item: T): void;
}

export type SearchResult<T> =
{
    value?: T;
}

export default function AutoCompleteHighlighted<T>({
  sx,
  loading,
  query,
  options,
  getOptionLabel,
  handleChangeSearch,
  onItemSelected,
  getOptionItemMainDescription,
  getOptionItemSecondaryDescription
}: AutoCompleteHighlightedProps<T>) {
  
  return (
    <RootStyle
      sx={{
        ...(!query && {
          '& .MuiAutocomplete-noOptions': {
            display: 'none'
          }
        }),
        ...sx
      }}
    >
      <Autocomplete
        size="small"
        disablePortal
        //value={""}
        loading={loading}
        popupIcon={null}
        options={options}
        onInputChange={(event, value) => handleChangeSearch(value)}
        getOptionLabel={getOptionLabel}
        noOptionsText={<SearchNotFound searchQuery={query} />}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            placeholder="Search vehicles"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{
                        ml: 1,
                        width: 20,
                        height: 20,
                        color: 'text.disabled'
                      }}
                    />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={(props, item, { inputValue }) => {
          const mainTitle = getOptionItemMainDescription(item);
          const matches = match(mainTitle, inputValue);
          const parts = parse(mainTitle, matches);

          return (
            <li {...props} onClick={() => onItemSelected(item)}>
              <MenuItemStyle>
                <div>
                  {parts.map((part, index) => (
                    <Typography
                      key={index}
                      component="span"
                      variant="subtitle2"
                      color={part.highlight ? 'primary' : 'textPrimary'}
                    >
                      {part.text}
                    </Typography>
                  ))}
                </div>
                <div>
                  <Typography
                    component="span"
                    variant="body1"
                    color={'textPrimary'}
                    >
                      {getOptionItemSecondaryDescription(item)}
                  </Typography>
                </div>
              </MenuItemStyle>
            </li>
          );
        }}
      />
    </RootStyle>
  );
}