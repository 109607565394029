import { ISearchCriteria } from './search'
import { Project } from './project'
import { AllocationType } from './allocation'

export type Task = {
    id: string;
    project: Project;
    projectId: string;
    name: string;
    description: string;
    dueDateUtc?: Date;
    state: TaskState;
    allocation?: TaskAllocation;
    closeOutLink?: TaskCloseOutLink;
}

export type TaskCloseOutLink = {
    resourceId: string;
    state: string;
    resourceType: CloseOutLinkResourceType;
    payload: any;
}

export enum CloseOutLinkResourceType {
    Compliance = "Compliance"
}

export type TaskAllocation = {
    allocationId: string;
    allocationName: string;
    allocatedByName: string;
    whenAllocatedUtc: string;
    allocationImagePath: string;
    allocationType: AllocationType;
}

export type TaskCreateRequest = {
    id?: string;
    projectIdentifier: string | undefined;
    assignToMe?: boolean;
    name: string;
    description: string;
    dueDate?: Date | null;
    state: TaskState;
    allocationId: string | null;
    allocationType?: AllocationType,
}

export enum TaskState {
    Incomplete = 'Incomplete',
    Delayed = 'Delayed',
    Complete = 'Complete',
    Cancelled = 'Cancelled',
    Errored = 'Errored',
}

export interface ITaskSearchCriteria extends ISearchCriteria {
    projectIdentifier: string | undefined;
    showProjectTasks: boolean;
    showCompletedTasks: boolean;
    dueDateFrom?: Date | null;
    dueDateTo?: Date | null;
}

export type TaskSearchResult = {
    groupIdentifier: string;
    totalCount: number;
}
