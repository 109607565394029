import { useState, createContext, ReactNode } from 'react';
import { Project, ProjectStatus } from '../@types/project';
import { useGetProjectQuery } from '../services/projects'
import LoadingScreen from '../components/shared/LoadingScreen';
import ErrorComponent from '../components/shared/error/ErrorComponent';

export type ProjectContextType = {
    project: Project;
};

const ProjectContext = createContext<ProjectContextType | null>(null);

function ProjectProvider({ identifier, children }: { identifier: string, children: ReactNode }) {

  const [error, setError] = useState<string | null>(null);
  const { data: project, isLoading, error: projectError,  } = useGetProjectQuery(identifier);
  
  if (projectError) {
    return null;
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!project) {
    return <ErrorComponent
      title="We are having trouble loading this project..."
      description="This is likely an issue on our end, so please try your request again and if the problem persists reach out to support."
    />;
  }

  if (project.status === ProjectStatus.Disabled) {
    return <ErrorComponent
      title="Project Access Disabled"
      description="Access to this project has been disabled. Please contact support for more information."
    />;
  }

  return (
    <ProjectContext.Provider value={{ project }}>
      {children}
    </ProjectContext.Provider>
  )
}

export { ProjectContext, ProjectProvider };
