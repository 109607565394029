import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import { Box, Typography, Skeleton, Paper } from '@material-ui/core';
import { Icon } from '@iconify/react';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import ProjectRouterLink from '../../shared/ProjectRouterLink';
import { ServiceTask } from '../../../@types/maintenance'

type ServiceTaskListItemProps = {
    task: ServiceTask;
    loading?: boolean;
    onTaskRemoved?: (task: ServiceTask) => Promise<boolean>;
};

const ItemStyle = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

function ServiceTaskListItem({ task, loading = false, onTaskRemoved }: ServiceTaskListItemProps) {

    const { palette } = useTheme();
    const [pending, setPending] = useState(false);

    const handleDelete = async (task: ServiceTask) => {
        if (onTaskRemoved) {
            setPending(true);
            var removeResult = await onTaskRemoved(task);
            if (!removeResult) {
                setPending(false);
            }
        }
    }

    return (
        <ItemStyle key={`service-task-list-item-${task.id}`} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            { pending ? <SkeletonLoad id={task.id}/> :
                <Paper
                    key={task.id}
                    sx={{
                        p: 1,
                        width: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        bgcolor: 'background.neutral'
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ width: '70px' }}><ProjectRouterLink to={`/maintenance/service-tasks/${task.id}`}>{task.slug}</ProjectRouterLink></Typography>
                        <Box sx={{ width: '10px' }}></Box>
                        <Typography>{task.title}</Typography>
                    </Box>
                    <Icon onClick={() => handleDelete(task)} style={{ fontSize: '23px', cursor: 'pointer', color: palette.primary.main }} icon={trashFill} />
                </Paper>
            }
        </ItemStyle>
    );
}

function SkeletonLoad({ id } : { id: string}) {
    return (
        <Box key={`service-task-list-skeleton-${id}`} sx={{ display: 'flex', flexGrow: 1 }}>
            <Box sx={{ width: '70px' }}><Skeleton sx={{ height: '40px' }} /></Box>
            <Box sx={{ width: '10px'}}></Box>
            <Box sx={{ width: '99%' }}><Skeleton sx={{ height: '40px' }} /></Box>
            <Box sx={{ width: '40px', paddingLeft: '5px' }}><Skeleton sx={{ height: '40px' }} /></Box>
        </Box>
    )
}

export default ServiceTaskListItem;