import { useAuth0 } from '@auth0/auth0-react';
import { MAvatar } from '../@material-extend';
import { MAvatarProps } from '../@material-extend/MAvatar';
import createAvatar from '../../utils/createAvatar';
import { useGetUserQuery } from '../../services/users';

type UserAvatarProps = MAvatarProps & {
  id: string;
}
export function UserAvatar({ id, ...other }: UserAvatarProps) {

  const { data: user } = useGetUserQuery(id);

  return (
    <MAvatar
      src={user?.imageUrl}
      alt={user?.displayName}
      title='User'
      color={user?.imageUrl ? 'default' : createAvatar(user?.displayName as string).color}
      {...other}
    >
      {createAvatar(user?.displayName || 'Default').name}
    </MAvatar>
  );
}


export default function MyAvatar({ ...other }: MAvatarProps) {

  const { user } = useAuth0();

  return (
    <MAvatar
      src={user?.picture}
      alt={user?.nickname}
      color={user?.picture ? 'default' : createAvatar(user?.nickname as string).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </MAvatar>
  );
}
