import { ReactElement } from 'react'
import React from 'react'
import { TableCell } from '@material-ui/core';
import { Invoice, InvoiceSearchCriteria } from '../../@types/invoice'
import { Project } from '../../@types/project'
import { fDateShort } from '../../utils/formatTime';
import { fNumberFinancial } from '../../utils/formatNumber';
import VeemTable, { TableHeader } from '../table/VeemTable';
import { InvoiceStatusLabel } from '../_invoice';
import { useNavigate } from 'react-router-dom';

const TABLE_HEAD = [
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sortable: true,
        width: 120,
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: false,
        label: 'Reference',
        sortable: true,
        width: 300,
    },
    {
        id: 'dueDate',
        numeric: false,
        disablePadding: false,
        label: 'Due Date',
        sortable: true,
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        sortable: false,
    },
    {
        id: 'amountDue',
        numeric: true,
        disablePadding: false,
        label: 'Due',
        sortable: false,
    }
];

type InvoiceTableProps = {
    project: Project | null;
    header?: TableHeader[];
    invoices: Invoice[];
    totalRows: number;
    showSearch?: boolean;
    showUnlinkedSearch?: boolean;
    isLoading?: boolean;
    search: InvoiceSearchCriteria;
    selectedRow?: string[];
    setSelectRow?(selectedRow: string[]): void;
    handleChangePage(newPage: number): void;
    handleSearchChange(search: InvoiceSearchCriteria): void;
    handleSort(orderBy: string, order: 'asc' | 'desc'): void;
    handleChangePageSize(newRowsPerPage: number): void;
    renderTableRow?(row: Invoice, selected: boolean, labelId: string): ReactElement;
}

type EntendedInvoiceTableProps = InvoiceTableProps;

export default function InvoiceTable({
    handleChangePage,
    handleSearchChange,
    handleSort,
    handleChangePageSize,
    setSelectRow,
    search,
    selectedRow = [],
    renderTableRow,
    header,
    project,
    invoices,
    totalRows,
    showSearch = true,
    isLoading = false,
    showUnlinkedSearch = true,
}: EntendedInvoiceTableProps) {

    const navigate = useNavigate();

    const handleRowSelect = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: Invoice) => {
        if (event.metaKey || event.ctrlKey || event.button === 1) {
            const win = window.open(`/group/${project?.identifier}/invoices/${row.id}`, "_blank");
            win?.focus();
        }
        else {
            navigate(`/group/${project?.identifier}/invoices/${row.id}`)
        }
    };

    return (
        <VeemTable<Invoice>
            header={header ?? TABLE_HEAD}
            data={invoices}
            page={search.pageNumber}
            loading={isLoading}
            totalRows={totalRows}
            selectedRow={selectedRow}
            rowsPerPage={search.pageSize}
            showSelectAll={false}
            getSelectedItemKey={(row) => row.id}
            order={search.order}
            orderBy={search.orderBy}
            setSelectRow={setSelectRow}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            onRowSelected={handleRowSelect}
            onRowsPerPageChange={handleChangePageSize}
            defaultDense={true}
            renderTableRow={(row: Invoice, selected: boolean, labelId: string) => {

                if (renderTableRow) {
                    return renderTableRow(row, selected, labelId)
                }
                else {
                    return (
                        <>
                            <TableCell align="left" width={100}>
                                <InvoiceStatusLabel status={row.status} />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" >
                                {row.reference}
                            </TableCell>
                            <TableCell align="left" width={120}>{row.dueDate ? fDateShort(row.dueDate) : '-'}</TableCell>
                            <TableCell align="right">${fNumberFinancial(row.total)}</TableCell>
                            <TableCell align="right">{row.amountDue > 0 ? `$${fNumberFinancial(row.amountDue)}` : '-'}</TableCell>
                        </>
                    )
                }
            }}
        />
    );
}
