import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from '../../../utils/axios'

export type FormValuesProps = {
    projectDescription: string;
    projectName: string;
    projectEmail: string;
    projectPhone: string;
};

export const FormSchema = Yup.object().shape({
    projectName: Yup
        .string()
        .required('Project Name is required')
        .max(20),
    projectEmail: Yup
        .string()
        .email()
        .required('Project contact email is required'),
    projectPhone: Yup
        .string()
        .required('Project phone number is required')
});

export default function ProjectCreateForm() {
    const navigate = useNavigate();

    const {
        reset,
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, isDirty, isValid }
    } = useForm<FormValuesProps>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            projectDescription: '',
            projectName: '',
            projectEmail: '',
            projectPhone: '',
        }
    });

    const onSubmit = async (data: FormValuesProps) => {

        try {
            // call the api to create the group
            const response = await axios.post<any, any>(`/api/projects`, {
                projectName: data.projectName,
                projectEmail: data.projectEmail,
                projectPhone: data.projectPhone,
            })

            if (response.data.allow) {
                navigate(`/group/${response.data.result.identifier}/dashboard`)
                reset();
            }
        } catch (error) {
            if (error && error.modelState) {
                Object.keys(error.modelState).forEach((key: any) => {
                    let errors: string[] = Object.keys(error.modelState[key]).map((errorKey: string) => {
                        return error.modelState[key][errorKey];
                    })

                    // set the erorrs on the form
                    setError(key, {
                        type: "manual",
                        message: errors.join(',')
                    })
                })
            } else {

            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                    The project name will be displayed within the application and will be used as the display name for communications
                </Typography>
                <Controller
                    name="projectName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Project Name"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                    The project email is used as the default contact email in communications sent to your clients.
                </Typography>
                <Controller
                    name="projectEmail"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Project Email"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                    The project phone number is used as the default contact phone number in communications sent to your clients.
                </Typography>
                <Controller
                    name="projectPhone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Phone Number"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                >
                    Create Project
                </LoadingButton>
            </Stack>
        </form>
    );
}
