import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { ComplianceUpdateForm } from '../index'
import { Compliance } from '../../../@types/compliance'
import { closeComplianceEdit } from '../../../redux/slices/compliance'
import { RootState, useSelector, useDispatch } from '../../../redux/store';

export default function ComplianceEditModal({ vehicleId, compliance } : { vehicleId: string, compliance: Compliance }) {
  const dispatch = useDispatch();
  const { open } = useSelector((state: RootState) => state.compliance.edit);

  const handleClose = () => {
    dispatch(closeComplianceEdit());
  };

  return (
      <Dialog 
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Update Compliance</DialogTitle>
        <DialogContent dividers>
          <ComplianceUpdateForm vehicleId={vehicleId} compliance={compliance} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
