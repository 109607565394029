import { ReactElement, useState, useEffect } from 'react'
import { Icon } from '@iconify/react';
import { TableCell, Stack, Typography, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { Vehicle, VehicleStatus } from '../../../@types/vehicle'
import { IVehicleSearchCriteria } from '../../../@types/vehicles'
import { Project } from '../../../@types/project'
import VeemTable, { TableHeader } from '../../table/VeemTable';
import { ClientMultiSelectList } from '../../client';
import { VehicleMakeSelectList, VehicleIdentifierSelectList } from '../../vehicle';
import ProjectRouterLink from '../../shared/ProjectRouterLink';
import { useNavigate } from 'react-router-dom';
import { VehicleRegistration } from '../../_vehicle'
import ComplianceDateLabel from '../../_vehicle/ComplianceDateLabel'
import SearchInput from '../../search/SearchInput'
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

const TABLE_HEAD = [
    {
        id: 'registration',
        numeric: false,
        disablePadding: false,
        label: 'Registration/Serial',
        sortable: true,
        width: 70
    },
    {
        id: 'make',
        numeric: false,
        disablePadding: false,
        label: 'Make',
        sortable: true,
        width: 200
    },
    {
        id: 'model',
        numeric: false,
        disablePadding: false,
        label: 'Model',
        sortable: true,
        width: 200
    },
    {
        id: 'client',
        numeric: false,
        disablePadding: false,
        label: 'Client',
        sortable: true,
        width: 260
    },
    {
        id: 'compliance.expiryDate',
        numeric: false,
        disablePadding: false,
        label: 'WOF/COF Expiry',
        sortable: true,
        width: 150
    }
];

type VehicleTableProps = {
    project: Project | null;
    header?: TableHeader[];
    isLoading?: boolean;
    defaultRowsToDisplay?: number;
    vehicles: Vehicle[];
    totalRows: number;
    showSearch?: boolean;
    search: IVehicleSearchCriteria,
    handleChangePage(newPage: number): void;
    handleSearchChange(search: IVehicleSearchCriteria): void;
    handleSort(orderBy: string, order: 'asc' | 'desc'): void;
    handleChangePageSize(newRowsPerPage: number): void;
    renderTableRow?(row: Vehicle, selected: boolean, labelId: string): ReactElement;
}

export default function VehicleTable({
    //getData,
    renderTableRow,
    header,
    project,
    vehicles,
    totalRows,
    search,
    handleChangePage,
    handleSearchChange,
    handleChangePageSize,
    handleSort,
    defaultRowsToDisplay = 5,
    showSearch = false,
    isLoading = false,
}: VehicleTableProps) {

    const navigate = useNavigate();
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const handleRowSelect = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, row: Vehicle) => {
        if (event.metaKey || event.ctrlKey) {
            const win = window.open(`/group/${project?.identifier}/fleet/vehicles/${row.id}/detail`, "_blank");
            win?.focus();
        }
        else {
            navigate(`/group/${project?.identifier}/fleet/vehicles/${row.id}/detail`)
        }
    };

    useEffect(() => {
        if (search.clients.length > 0 || search.makes.length > 0 || search.vehicleIdentifiers.length > 0) {
            setShowAdvancedSearch(true);
        }

        setActiveFilters(search.clients.length + search.makes.length + search.vehicleIdentifiers.length);

    }, [search]);

    return (
        <>
            <Stack sx={{ mt: 2, mb: 2 }} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <SearchInput
                        label={'Search'}
                        placeholder="Search vehicles..."
                        value={search.text}
                        onInputChange={(value: string) => handleSearchChange({
                            ...search,
                            text: value,
                        })}
                    />
                    <FormControlLabel
                        label="Expired WOF/COF"
                        sx={{ minWidth: '160px' }}
                        control={
                            <Checkbox
                                onChange={e => handleSearchChange({
                                    ...search,
                                    expiredCompliances: e.target.checked
                                })}
                                checked={search.expiredCompliances}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Hide Disabled"
                        sx={{ minWidth: '140px' }}
                        control={
                            <Checkbox
                                onChange={e => handleSearchChange({
                                    ...search,
                                    hideDisabled: e.target.checked
                                })}
                                checked={search.hideDisabled}
                            />
                        }
                    />
                </Stack>
                <Stack direction="row">
                    <Button
                        sx={{ display: showSearch ? 'flex' : 'none', minWidth: '180px' }}
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        {showAdvancedSearch ? 'Hide' : 'Show'} Advanced Search {activeFilters > 0 && `(${activeFilters})`}
                    </Button>
                    <Button
                        startIcon={<Icon icon={trash2Outline} />}
                        sx={{ ml: 1, display: showSearch ? 'flex' : 'none', minWidth: '130px' }}
                        onClick={() => handleSearchChange({
                            ...search,
                            hideDisabled: true,
                            clients: [],
                            makes: [],
                            vehicleIdentifiers: [],
                            expiredCompliances: false,
                        })}
                    >
                        Clear Filters
                    </Button>
                </Stack>
            </Stack>
            {showAdvancedSearch && (<Stack mb={2} spacing={1} direction="row" alignItems="center" justifyContent="space-between" sx={{ display: showSearch ? 'flex' : 'none' }}>
                <ClientMultiSelectList
                    onChangeMultiple={values => handleSearchChange({
                        ...search,
                        pageNumber: 0,
                        clients: values,
                    })}
                    value={search.clients}
                    error={null}
                />
                <VehicleMakeSelectList
                    value={search.makes}
                    onChange={values => handleSearchChange({
                        ...search,
                        pageNumber: 0,
                        makes: values,
                    })}
                />
                <VehicleIdentifierSelectList
                    value={search.vehicleIdentifiers}
                    onChange={values => handleSearchChange({
                        ...search,
                        pageNumber: 0,
                        vehicleIdentifiers: values,
                    })}
                />
            </Stack>)}
            <VeemTable<Vehicle>
                header={header ?? TABLE_HEAD}
                data={vehicles}
                page={search.pageNumber}
                defaultDense={true}
                loading={isLoading}
                totalRows={totalRows}
                rowsPerPage={search.pageSize}
                showSelectAll={false}
                getSelectedItemKey={(row: Vehicle) => row.id}
                order={search.order}
                orderBy={search.orderBy}
                handleChangePage={handleChangePage}
                maxHeight={showAdvancedSearch ? 430 : 500}
                handleSort={handleSort}
                onRowsPerPageChange={(newRowsPerPage: number) => handleChangePageSize(newRowsPerPage)}
                renderTableRow={(row: Vehicle, selected: boolean, labelId: string) => {

                    if (renderTableRow) {
                        return renderTableRow(row, selected, labelId)
                    }
                    else {
                        return (
                            <>
                                <TableCell onClick={(event) => handleRowSelect(event, row)}><VehicleRegistration registration={row.vehicleIdentifier} /></TableCell>
                                <TableCell
                                    onClick={(event) => handleRowSelect(event, row)}
                                    sx={{
                                        ...(row.status == VehicleStatus.Disabled && { color: 'text.disabled' })
                                    }}>
                                    {row.make || '-'}
                                </TableCell>
                                <TableCell
                                    onClick={(event) => handleRowSelect(event, row)}
                                    sx={{
                                        ...(row.status == VehicleStatus.Disabled && { color: 'text.disabled' })
                                    }}
                                >
                                    {row.model || '-'}
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <ProjectRouterLink to={`customers/${row.client.id}/detail`}>
                                            <Typography variant="subtitle2">{row.client.name || '-'}</Typography>
                                        </ProjectRouterLink>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <ComplianceDateLabel
                                        compliance={row.compliance}
                                        disabled={row.status == VehicleStatus.Disabled}
                                        iconPadding={0.33}
                                    />
                                </TableCell>
                            </>
                        )
                    }
                }}
            />
        </>
    );
}
