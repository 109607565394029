import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import { useVerifyEmailCodeMutation, useVerifyEmailResendMutation } from '../../../services/email'

type VerifyEmailFormProps = {
  verificationId: string;
  email: string;
  onCodeVerified(code: number): void;
};

type VerifyEmailFormValues = {
  verificationCode: number;
};

export const FormSchema = Yup.object().shape({
  verificationCode: Yup.number().required('Code is required'),
});

export default function VerifyEmailCodeForm({ verificationId, email, onCodeVerified }: VerifyEmailFormProps) {

  const { enqueueSnackbar } = useSnackbar();
  const [verifyEmailCode] = useVerifyEmailCodeMutation();
  const [verifyEmailResend] = useVerifyEmailResendMutation();

  const [resend, setResend] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<VerifyEmailFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {

    }
  })

  const handleResendVerification = async () => {
    try {
      setResend(true);
      await verifyEmailResend({ verificationId: verificationId }).unwrap();
      enqueueSnackbar('Verification code resent successfully', { variant: 'success' });
      setResend(false);
    } catch (response) {
      if (response.data && response.data.errors) {
        Object.keys(response.data.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
            return response.data.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      }
      setResend(false);
    }
  };

  const onSubmit = async (data: VerifyEmailFormValues) => {

    try {
      // assume verified successfully if no error
      await verifyEmailCode({ verificationId: verificationId, verificationCode: data.verificationCode }).unwrap();
      onCodeVerified(data.verificationCode);
    } catch (response) {
      if (response.data && response.data.errors) {
        Object.keys(response.data.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
            return response.data.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      }
    }
  };

  function maxLength(object: any) {
    if (object.target.value.length > object.target.maxLength) {
      return (object.target.value = object.target.value.slice(0, object.target.maxLength));
    }
  }

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        return handleSubmit(onSubmit)(e);
      }}>
      <Stack spacing={3}>
        <Typography variant="body2">A verification code has been emailed to <b>{email}</b>. Please check your inbox (and spam folders) and enter the code below to verify your email.</Typography>
        <Controller
          name="verificationCode"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={Boolean(error)}
              helperText={error?.message}
              type="number"
              placeholder="Paste code here"
              onInput={maxLength}
              inputProps={{
                maxLength: 4,
                sx: {
                  p: 0,
                  textAlign: 'center',
                  height: { xs: 36, sm: 56 }
                }
              }}
            />
          )}
        />
        <Stack direction="row">
          <LoadingButton
            fullWidth
            size="large"
            variant="outlined"
            sx={{ mr: 2, maxWidth: '150px' }}
            loading={resend}
            disabled={isSubmitting}
            onClick={async () => handleResendVerification()}
          >
            Resend Code
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid || !isDirty}
          >
            Verify Code
          </LoadingButton>
        </Stack>
      </Stack>
    </form>

  );
}