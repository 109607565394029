import { isString } from 'lodash';
import { ReactNode } from 'react';
import { Icon } from '@iconify/react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import roundAddAPhoto from '@iconify/icons-ic/round-add-a-photo';
import { alpha, Theme, styled } from '@material-ui/core/styles';
import { Box, Typography, Paper, Button } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import { fData } from '../../utils/formatNumber';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    height: 144,
    margin: 'auto',
    display: 'flex',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`
}));

const DropZoneStyle = styled('div')({
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9
        }
    }
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&:hover': { opacity: 0.72 }
}));

interface CustomFile extends File {
    path?: string;
    preview?: string;
}

interface UploadImageProps extends DropzoneOptions {
    error?: boolean;
    file: CustomFile | string | null;
    caption?: ReactNode;
    sx?: SxProps<Theme>;
    uploadText?: string | null;
    onRemove?: () => void;
}

export default function UploadImage({ error, file, caption, sx, uploadText = 'Upload photo', onRemove, ...other }: UploadImageProps) {

    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple: false,
        ...other
    });

    const ShowRejectionItems = () => (
        <Paper
            variant="outlined"
            sx={{
                py: 1,
                px: 2,
                my: 2,
                borderColor: 'error.light',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
            }}
        >
            {fileRejections.map(({ file, errors }) => {
                const { path, size }: CustomFile = file;
                return (
                    <Box key={path} sx={{ my: 1 }}>
                        <Typography variant="subtitle2" noWrap>
                            {path} - {fData(size)}
                        </Typography>
                        {errors.map((e) => (
                            <Typography key={e.code} variant="caption" component="p">
                                - {e.message}
                            </Typography>
                        ))}
                    </Box>
                );
            })}
        </Paper>
    );

    return (
        <>
            <RootStyle sx={sx}>
                <DropZoneStyle
                    {...getRootProps({})}
                    sx={{
                        ...(isDragActive && { opacity: 0.72 }),
                        ...((isDragReject || error) && {
                            color: 'error.main',
                            borderColor: 'error.light',
                            bgcolor: 'error.lighter'
                        })
                    }}
                >
                    <input {...getInputProps()} />
                    {file && (
                        <Box
                            component="img"
                            alt="avatar"
                            src={isString(file) ? file : file.preview}
                            sx={{ zIndex: 8, objectFit: 'contain' }}
                        />
                    )}

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            ...(file && {
                                opacity: 0,
                                color: 'common.white',
                                bgcolor: 'grey.900',
                                '&:hover': { opacity: 0.72 }
                            })
                        }}
                    >
                        <Box component={Icon} icon={roundAddAPhoto} sx={{ width: 24, height: 24, mb: 1 }} />
                        <Typography variant="caption">{file ? 'Update photo' : uploadText}</Typography>
                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>
            {file && (<Button onClick={() => onRemove && onRemove()}>Remove</Button>)}

            {caption}

            {fileRejections.length > 0 && <ShowRejectionItems />}
        </>
    );
}
