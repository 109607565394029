import { Grid, CardHeader, Card, Alert, Box, Skeleton } from '@material-ui/core';
import { Vehicle, VehicleStatus } from '../../../@types/vehicle'
import { useDispatch } from '../../../redux/store';
import { toggleCreateComplianceModal } from '../../../redux/slices/compliance';
import { CreateComplianceModal, CreateCompliance } from '../index'
import VehicleComplianceDetail from './VehicleComplianceDetail'

type VehicleComplianceProps = {
  vehicle: Vehicle;
  isLoading: boolean;
};

export default function VehicleCompliance({ vehicle, isLoading }: VehicleComplianceProps) {

  const dispatch = useDispatch();

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ mb: 4 }}>
            <CardHeader title="Compliance Information" />
            <Box sx={{ px: 3, py: 3 }}>
              <Skeleton height={170} sx={{ transform: 'scale(1, 0.90)' }} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={"Compliance Reminder"}
              subheader="This list shows all the compliances for the vehicle. Select a given compliance to view more details"
            />
            <Box sx={{ px: 3, py: 1 }}>
              <Skeleton component="div" height={250} sx={{ transform: 'scale(1, 0.80)' }} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    )
  }

  if (!vehicle.compliance) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Alert severity="info" sx={{ mb: 3 }}>
            This vehicle currently has no compliance information. You can import the latest compliance details from NZTA, or you can enter these details manually if you know them. It is recommended
            to import this information as it is more accurate.
          </Alert>
        </Grid>
        <Grid item xs={12} md={6}>
          <CreateCompliance vehicleId={vehicle.id} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <VehicleComplianceDetail
          vehicle={vehicle}
          isLoading={isLoading}
          disabled={vehicle.status !== VehicleStatus.Active}
        />
      </Grid>
      <CreateComplianceModal
        vehicleId={vehicle.id}
        handleModalClose={() => dispatch(toggleCreateComplianceModal(false))}
      />
    </Grid>
  );
}
