import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';


type ConfirmModalProps = {
    title: string;
    description: string;
    modalOpen: boolean;
    confirmButtonText?: string;
    confirmLoading?: boolean;
    buttonColor?: 'error' | 'success';
    handleConfirmModalClose(): void;
    handleConfirmModalCancel(): void;
    handleConfirmModalConfirm(): void;
};

export default function ConfirmModal({ 
  title,
  confirmButtonText = 'Yes, Delete',
  buttonColor = 'error',
  description,
  modalOpen,
  confirmLoading,
  handleConfirmModalClose,
  handleConfirmModalCancel,
  handleConfirmModalConfirm } : ConfirmModalProps) {
  
  return (
      <Dialog 
        fullWidth
        open={modalOpen}
        onClose={handleConfirmModalClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          {description}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={handleConfirmModalConfirm}
            loading={confirmLoading}
            variant="contained"
            color={buttonColor}
            autoFocus
          >
            {confirmButtonText}
          </LoadingButton>
          <Button onClick={handleConfirmModalCancel} autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
