import { styled, makeStyles } from '@material-ui/core/styles';
import { Typography, Box, BoxProps, Theme } from '@material-ui/core';

// const RootStyle = styled(Box)(({ theme }) => ({
//   height: '100%',
//   display: 'flex',
//   textAlign: 'center',
//   alignItems: 'center',
//   flexDirection: 'row',
//   justifyContent: 'center',
//   padding: theme.spacing(4, 2)
// }));



interface EmptyContentProps extends BoxProps {
  title?: string;
  img?: string;
  imgHeight?: number;
  description?: string;
  displayLayout?: "row" | "column"
}

const RootStyle = styled(Box)<EmptyContentProps>(({ theme, displayLayout }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: displayLayout,
  justifyContent: 'flex-start',
  padding: 0,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 2)
  }
}));

const ContentStyle = styled(Box)<EmptyContentProps>(({ theme, displayLayout }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: displayLayout === 'row' ? 'left' : 'center',
  alignItems: displayLayout === 'row' ? 'normal' : 'center',
  justifyContent: displayLayout === 'row' ? 'flex-start' : 'flex-start',
  paddingTop: displayLayout === 'row' ? 0: 2,
  padding: theme.spacing(1, 1),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 2)
  }
}));

export default function EmptyContent({ title, description, img, imgHeight, displayLayout = 'column', ...other }: EmptyContentProps) {

  return (
    <RootStyle {...other} displayLayout={displayLayout} sx={{}}>
      <Box
        component="img"
        alt="empty content"
        src={img || '/static/illustrations/illustration_empty_content.svg'}
        sx={{ height: imgHeight || 240, mb: 3 }}
      />
      <ContentStyle displayLayout={displayLayout}>
        {title && (
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        )}

        {description && (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        )}
      </ContentStyle>
    </RootStyle>
  );
}
