import { useState } from 'react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Skeleton
} from '@material-ui/core';
import Scrollbar from '../shared/Scrollbar';
import SortingSelectingHead from './SortingSelectingHead';

export type TableHeader = {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    sortable: boolean;
    width?: number;
}

const SkeletonComponent = ({ count, colSpan } : { count: number, colSpan: number}) => (
    <TableBody>
        {Array.from({length: count}).map((value) =>
          <TableRow
            key={`${uuidv4()}_TableRow`}
            style={{
              height: 30
            }
          }>
            <TableCell key={`${uuidv4()}_TableCell`} colSpan={colSpan} style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 } }>
              <Skeleton key={`${uuidv4()}_Skeleton`} height={40} component="div" />
            </TableCell>
          </TableRow>
        )}
    </TableBody>
  );

type VeemRenderTableRowProps = {
    onClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>): void;
}

export type VeemTableBaseProps<T> = {
    header: TableHeader[];
    data: T[];
    page: number;
    loading: boolean;
    totalRows: number;
    rowsPerPage: number;
    showSelectAll?: boolean;
    order: 'asc' | 'desc',
    orderBy: string,
    renderTableRow(row: T, selected: boolean, labelId: string, props: VeemRenderTableRowProps) : React.ReactFragment;
    renderLastRow?() : React.ReactFragment;
    getSelectedItemKey(row: T) : string;
    onRowSelected?(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: T) : void;
    handleChangePage(newPage: number): void;
    onRowsPerPageChange(newRowsPerPage: number): void;
    handleSort(orderBy: string, order: 'asc' | 'desc'): void;
}

export default function VeemTableBase<T>({
    header,
    data,
    loading,
    renderTableRow,
    renderLastRow,
    getSelectedItemKey,
    page,
    rowsPerPage,
    totalRows,
    order,
    orderBy,
    onRowSelected,
    handleChangePage,
    onRowsPerPageChange,
    handleSort,
    showSelectAll = false } : VeemTableBaseProps<T>) {

  const [selected, setSelected] = useState<string[]>([]);

  function handleRequestSort(property: string) {
    const isAsc = orderBy === property && order === 'asc';
    handleSort(property, isAsc ? 'desc' : 'asc')
  };

  const isSelected = (row: T) => selected.indexOf(getSelectedItemKey(row)) !== -1;
  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = data.map((n) => getSelectedItemKey(n));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: T) => {
    const selectedIndex = selected.indexOf(getSelectedItemKey(row));
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, getSelectedItemKey(row));
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    if (onRowSelected) {
      onRowSelected(event, row);
    }
  };

  // Avoid a layout jump when reaching the last page with empty SORTING_SELECTING_TABLE.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0;
  const skeletonRowCount = data.length === 0 ? rowsPerPage : data.length;

  return (
    <div>
        <Scrollbar>
          <TableContainer>
            <Table stickyHeader size={'small'}>
              <SortingSelectingHead
                order={order}
                orderBy={orderBy}
                headLabel={header}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                onSelectAllClick={handleSelectAllClick}
                showSelectAll={showSelectAll}
              />
              { loading ? <SkeletonComponent count={skeletonRowCount} colSpan={header.length + 1} /> :
                <TableBody>
                  { data.map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                    
                      return renderTableRow(row, isItemSelected, labelId, {
                        onClick: (event) => handleClick(event, row)
                      });
                    })}
                    { renderLastRow && renderLastRow()}
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Scrollbar>
    </div>
  );
}
