import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, MenuItem } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import { useDispatch } from '../../../redux/store';
import { updateProjectMember } from '../../../redux/slices/project'
import { useSnackbar } from 'notistack5';
import { ProjectAccessLevel } from '../../../@types/project'

export type FormValuesProps = {
    email: string;
    accessLevel: string;
  };

export const FormSchema = Yup.object().shape({
  email: Yup.string()
      .required('Name is required')
  });

export default function AddProjectMemberForm({ projectIdentifier } : { projectIdentifier: string }) {
  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValuesProps>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      email: '',
      accessLevel: 'Default',
    }
  });

  const onSubmit = async (data: FormValuesProps) => {
    
    try {
      // post the project member
      await dispatch(updateProjectMember({
        email: data.email,
        projectIdentifier: projectIdentifier,
        accessLevel: data.accessLevel,
      })).unwrap()

      reset();
    }
    catch (err) {
      enqueueSnackbar(err, { variant: 'error', autoHideDuration: 5000 });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Users Email"
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'off'}
              />
            )}
          />
          <Controller
            name="accessLevel"
            control={control}
            render={({ field, fieldState: { error } }) =>
              <TextField
                {...field}
                select
                fullWidth
                label="Access Level"
                error={Boolean(error)}
                helperText={error?.message}
              >
                {Object.keys(ProjectAccessLevel).filter(_ => _ !== ProjectAccessLevel.NoAccess.toString()).map((key) => (<MenuItem value={key}>{key}</MenuItem>))}
              </TextField>
            }
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            Add Member
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
