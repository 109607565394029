import { Icon } from '@iconify/react';
import { useMemo, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment, BoxProps } from '@material-ui/core';

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-of-type)': {
      borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

interface SearchInputProps extends BoxProps {
  onInputChange(input: string): void;
  label: string;
  value?: string | null;
  placeholder: string;
}

export default function SearchInput({ sx, onInputChange, placeholder, value, label }: SearchInputProps) {

  const [fieldValue, setFieldValue] = useState(value)
  const debouncedChangeHandler = useMemo(() => debounce((userInput) => handleChangeSearch(userInput), 300), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  useEffect(() => {
    setFieldValue(value)
  }, [value]);

  const handleChangeSearch = async (event: any) => {
    //setFieldValue(event.target.value)
    onInputChange(event.target.value)
  };

  return (
    <RootStyle
      sx={{
        ...sx
      }}
    >
      <TextField
        placeholder={placeholder}
        value={fieldValue}
        label={label}
        onChange={(event) => { setFieldValue(event.target.value); onInputChange(event.target.value) }}
        inputProps={{
          style: {
            padding: 10
          }
        }}
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{
                    ml: 1,
                    width: 20,
                    height: 20,
                    color: 'text.disabled'
                  }}
                />
              </InputAdornment>
            </>
          )
        }}
      />
    </RootStyle>
  );
}
