import { Icon } from '@iconify/react';
import { useState, useMemo, useEffect } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import debounce from 'lodash/debounce';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@material-ui/core/styles';
import { Box, TextField, Typography, Autocomplete, InputAdornment, BoxProps } from '@material-ui/core';
import axios from '../../utils/axios';
import useProject from '../../hooks/useProject';
import { Vehicle } from '../../@types/vehicle';
import SearchNotFound from '../shared/SearchNotFound';

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    minWidth: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      minWidth: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-of-type)': {
      borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

const MenuItemStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column'
}));

interface VehicleSearchProps extends BoxProps {
  onVehicleSelected(vehicle: Vehicle): void;
}

export default function VehicleSearchInput({ sx, onVehicleSelected }: VehicleSearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setloading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { project }  = useProject();

  const debouncedChangeHandler = useMemo(
    () => debounce((userInput) => handleChangeSearch(userInput), 500),
    []
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const handleChangeSearch = async (value: string) => {
    try {
      setSearchQuery(value);
      if (value) {
        setloading(true)
        const response = await axios.post<any, any>('/api/vehicle/search', {
          projectIdentifier: project?.identifier,
          pageNumber: 0,
          pageSize: 15,
          text: value,
          vehicleIdentifiers: [],
          makes: [],
          clients: [],
          orderBy: 'updated',
          order: 'asc'
        });
        setSearchResults(response.data.results);
        setloading(false)
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle
      sx={{
        ...(!searchQuery && {
          '& .MuiAutocomplete-noOptions': {
            display: 'none'
          }
        }),
        ...sx
      }}
    >
      <Autocomplete
        size="small"
        disablePortal
        loading={loading}
        popupIcon={null}
        options={searchResults}
        onInputChange={(event, value) => debouncedChangeHandler(value)}
        getOptionLabel={(vehicle: Vehicle) => `${vehicle.make} ${vehicle.model} ${vehicle.vehicleIdentifier}`}
        noOptionsText={<SearchNotFound searchQuery={searchQuery} />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search vehicles"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{
                        ml: 1,
                        width: 20,
                        height: 20,
                        color: 'text.disabled'
                      }}
                    />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={(props, vehicle, { inputValue }) => {
          const { vehicleIdentifier: registration, make, model } = vehicle;
          const mainTitle = `${make} - ${model} (${registration})`;
          const matches = match(mainTitle, inputValue);
          const parts = parse(mainTitle, matches);

          return (
            <li {...props}>
              <MenuItemStyle onClick={() => onVehicleSelected(vehicle)}>
                <div>
                  {parts.map((part, index) => (
                    <Typography
                      key={index}
                      component="span"
                      variant="subtitle2"
                      color={part.highlight ? 'primary' : 'textPrimary'}
                    >
                      {part.text}
                    </Typography>
                  ))}
                </div>
                <div>
                  <Typography
                    component="span"
                    variant="body1"
                    color={'textPrimary'}
                    >
                      {vehicle.client.name}
                  </Typography>
                </div>
              </MenuItemStyle>
            </li>
          );
        }}
      />
    </RootStyle>
  );
}
