import { map } from 'lodash';
import { store } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { Task, TaskState, TaskCreateRequest, ITaskSearchCriteria } from '../../@types/task';

export type TasksState = {
  isLoading: boolean;
  error: boolean;
  tasks: Task[];
  search: {
    pageNumber: number;
    pages: number;
    order: 'asc' | 'desc';
    orderBy: string;
    error: string | null;
    totalCount: number;
    showCompletedTasks: boolean;
    showProjectTasks: boolean;
    dueDateTo: Date | null;
    dueDateFrom: Date | null;
  },
  edit: {
    task: Task | null;
    open: boolean;
  }
};

const current = new Date();
var currentDatePlus10Days = new Date().setDate(current.getDate() + 10);

const initialState: TasksState = {
  isLoading: false,
  error: false,
  tasks: [],
  search: {
    pageNumber: 0,
    pages: 25,
    order: 'desc',
    orderBy: 'dueDateUtc',
    totalCount: 0,
    error: null,
    showProjectTasks: true,
    showCompletedTasks: false,
    dueDateTo: new Date(currentDatePlus10Days),
    dueDateFrom: null,
  },
  edit: {
    task: null,
    open: false,
  }
};

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTasksSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload;
    },

    searchTasksSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload.tasks;
      state.search.pageNumber = action.payload.pageNumber;
      state.search.pages = action.payload.pages;
      state.search.totalCount = action.payload.totalCount;
    },

    setPage(state, action) {
      state.search.pageNumber = action.payload;
    },

    pushTask(state, action) {
      state.tasks = [action.payload, ...state.tasks];
    },

    replaceTask(state, action) {
      const updateTasks = map(state.tasks, (task) => {
        if (task.id === action.payload.id) {
          return action.payload;
        }
        return task;
      });

      state.tasks = updateTasks;
    },

    removeTask(state, action) {
      const index = state.tasks.map(item => item.id).indexOf(action.payload);
      const stateTemp = [
        ...state.tasks.slice(0, index),
        ...state.tasks.slice(index + 1)
      ];
      state.tasks = stateTemp;
    },

    openEditTask(state, action) {
      state.edit = {
        task: action.payload,
        open: true,
      }
    },

    toggleEditOpen(state, action) {
      state.edit = {
        ...state.edit,
        task: null,
        open: action.payload,
      }
    },

    setTaskSearch(state, action) {
      state.search = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setTaskSearch, setPage, replaceTask, toggleEditOpen } = slice.actions;

export function loadTaskForEdit(task: Task) {
  return async () => {
    dispatch(slice.actions.openEditTask(task));
  };
}

export function closeTaskEdit() {
  return async () => {
    dispatch(slice.actions.toggleEditOpen(false));
  };
}

export function getTasks() {
  return async () => {

    const { dispatch, getState } = store;
    const { project } = getState();

    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post<any, any>(`/api/tasks/search`, {
        projectIdentifier: project.project?.identifier,
        showCompletedTasks: false,
        dueDateTo: new Date(currentDatePlus10Days),
        pageNumber: 0,
        pageSize: 50,
        orderBy: "dueDateUtc",
        order: "desc",
      });
      dispatch(slice.actions.searchTasksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchTasks(criteria: ITaskSearchCriteria) {
  return async () => {

    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post<ITaskSearchCriteria>(`/api/tasks/search`, criteria);
      dispatch(slice.actions.searchTasksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addTask(request: TaskCreateRequest) {
  return async () => {

    try {

      const { dispatch, getState } = store;
      const { project } = getState();

      const response = await axios.post<any, any>('/api/tasks', {
        projectIdentifier: project.project?.identifier,
        name: request.name,
        description: request.description,
        assignToMe: request.assignToMe,
        dueDate: request.dueDate,
        allocationId: request.allocationId,
        allocationType: request.allocationType,
      })

      dispatch(slice.actions.pushTask(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTask(task: Task) {
  return async () => {
    try {
      await axios.delete(`/api/tasks/${task.id}`)
      dispatch(slice.actions.removeTask(task.id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function completeTask(task: Task) {
  return async () => {
    if (task.state !== TaskState.Complete) {
      try {
        const response = await axios.put(`/api/tasks/${task.id}/complete`)
        dispatch(slice.actions.replaceTask(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      try {
        const response = await axios.put(`/api/tasks/${task.id}/uncomplete`)
        dispatch(slice.actions.replaceTask(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}