import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios';
import { ClientSummary } from '../../@types/client';

type ClientsState = {
  isLoading: boolean;
  notFound: boolean;
  error: boolean;
  clients: ClientSummary[];
  totalRows: number;
  search: {
    page: number;
    pageSize: number;
    text: string | null;
    order: string;
    orderBy: string;
  }
};

const initialState: ClientsState = {
  isLoading: false,
  notFound: false,
  error: false,
  clients: [],
  totalRows: 0,
  search: {
    page: 0,
    pageSize: 10,
    text: null,
    orderBy: 'updated',
    order: 'desc'
  }
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload.clients;
      state.totalRows = action.payload.total;
    },

    setSearch(state, action) {
      state.search = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function setSearchCriteria(criteria: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.setSearch(criteria));
  }
}

export function getClients(pageNumber: number, pageSize: number, orderBy: string, order: string, textSearch: string | null) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());

    try {
        const response = await axios.post<any, any>(`/api/clients/search`, {
          text: textSearch,
          pageNumber: pageNumber,
          pageSize: pageSize,
          orderBy: orderBy,
          order: order,
        });
        dispatch(slice.actions.getClientsSuccess({ clients: response.data.clients, total: response.data.totalCount }));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
  };
}