import { useEffect } from 'react';
import useProject from '../../hooks/useProject'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import { IServiceTaskSearchCriteria, IServiceTaskSearchResult } from '../../@types/maintenance';
import ServiceTaskTable from './tables/ServiceTaskTable';
import { useGetServiceTasksQuery } from '../../services/servicetasks'

const DEMO_DATA = [{
  id: '1',
  slug: 'ST-0001',
  title: 'Change Oil',
  description: 'Change engine oil filter and oil',
  updated: new Date(),
},
{
  id: '2',
  slug: 'ST-0002',
  title: 'Inspect diff oil',
  description: 'Check the level and quality of the oil in the differential',
  updated: new Date(),
}, {
  id: '3',
  slug: 'ST-0003',
  title: 'Inspect drive belt',
  description: '',
  updated: new Date(),
}, {
  id: '4',
  slug: 'ST-0004',
  title: 'Inspect Tyres',
  description: 'Inspect all tyres for damage and ensure minimum tread depth across entire tire face',
  updated: new Date(),
}]

export type ServiceTasksSearchProps = {
  showSearch?: boolean;
  showDemoData?: boolean;
  search: IServiceTaskSearchCriteria;
  onSetSearch(query: any): void;
  onSearch(): void;
}

export default function ServiceTaskSearch({
  search,
  onSetSearch,
  onSearch,
  showDemoData = false,
}: ServiceTasksSearchProps) {

  const { project } = useProject();

  const DEFAULT_PAGE_SIZE = 25;
  const DEFAULT_ORDER = 'desc';
  const DEFAULT_ORDER_BY = 'dueDate';
  
  const defaultData: IServiceTaskSearchResult = {
    results: showDemoData ? DEMO_DATA :[],
    totalCount: showDemoData ? DEMO_DATA.length : 0,
    pageNumber: 0,
    pageSize: DEFAULT_PAGE_SIZE
  };
  
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
    order: StringParam,
    orderBy: StringParam,
    text: StringParam,
  });

  const { data = defaultData, isLoading } = useGetServiceTasksQuery(search, { skip: showDemoData });

  useEffect(() => {
    const { page, pageSize, order, orderBy, text } = query;

    // set the search query based on the url change
    onSetSearch({
      ...search,
      pageNumber: page ?? 0,
      pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
      order: order ?? DEFAULT_ORDER,
      orderBy: orderBy ?? DEFAULT_ORDER_BY,
      text: text ?? '',
    });

    // search invoices
    onSearch();
  }, [query]);

  function handleChangePage(newPage: number) {
    setQuery({ page: newPage }, 'pushIn');
  };

  function handleChangePageSize(newPageSize: number) {
    setQuery({ pageSize: newPageSize, page: 0 }, 'pushIn');
  };

  function handleSort(orderBy: string, order: 'asc' | 'desc') {
    setQuery({
      orderBy: orderBy == DEFAULT_ORDER_BY ? undefined : orderBy,
      order: order == DEFAULT_ORDER ? undefined : order
    }, 'pushIn');
  }

  const handleChangeSearch = (search: IServiceTaskSearchCriteria) => {
    // sets the search in the url
    setQuery(
      {
        text: search.text,
      }, 'pushIn');
  }

  return (
    <ServiceTaskTable
      project={project}
      tasks={data.results}
      search={search}
      totalRows={data.totalCount}
      isLoading={isLoading}
      handleChangePage={handleChangePage}
      handleChangePageSize={handleChangePageSize}
      handleSort={handleSort}
      handleSearchChange={handleChangeSearch}
    />
  );
}
