import { Autocomplete, TextField } from '@material-ui/core';
import { ComplianceStatus } from '../../@types/compliance'

type ComplianceStatusMultiSelectProps = {
    onStatusListChanged?: (statusList: ComplianceStatus[]) => void;
    selectedValue: ComplianceStatus[];
};

const options : ComplianceStatus[] = [
    ComplianceStatus.Passed,
    ComplianceStatus.Failed,
    ComplianceStatus.Expired,
    ComplianceStatus.Cancelled,
];

export default function ComplianceStatusMultiSelect({ onStatusListChanged, selectedValue }: ComplianceStatusMultiSelectProps) {

  return (
    <Autocomplete
        multiple
        fullWidth
        options={options}
        value={selectedValue}
        getOptionLabel={(option) => option}
        defaultValue={[ComplianceStatus.Failed, ComplianceStatus.Passed]}
        filterSelectedOptions
        renderOption={(props, option) => <li {...props}>{option}</li>}
        renderInput={(params) => (
            <TextField {...params} label="Compliance Status" />
        )}
        onChange={(event, newValue) => {
            onStatusListChanged && onStatusListChanged(newValue);
        }}
    />
  );
}
