import { useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import LoadingScreen from '../components/shared/LoadingScreen';
import ErrorComponent from '../components/shared/error/ErrorComponent';
import Page from '../components/shared/Page';
import { useAuth0 } from "@auth0/auth0-react";

type OrganisationGuardProps = {
    children: ReactNode;
};

export default function OrganisationGuard({ children }: OrganisationGuardProps) {

    const { id } = useParams();
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const [initialised, setInitialised] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const initialize = async () => {

            // reset to false so that loading screen is always shown when project changes
            setInitialised(false);

            if (user && isAuthenticated) {
                try {
                    // validate the id in the token matches the path we are accessing
                    var idInToken = user['https://veem.co.nz/organisationIdentifier'];
                    if (!idInToken || idInToken !== id) {
                        
                        // set the project identifier in storage for use in the redirect
                        localStorage.setItem('veem-project', JSON.stringify({ projectIdentifier: id }))
                        loginWithRedirect({
                            organisationIdentifier: id,
                            redirectUri: `${window.location.origin}/group/callback`,
                            ignoreCache: true,
                        });
                    } else {
                        // only set to true after the loginWithRedirect so that the screen doesn't half load
                        setInitialised(true);
                    }
                }
                catch (err) {
                    console.log(err);
                    setError('Unable to load project details.');
                }
            }
        };

        initialize();
    }, [isAuthenticated, user, id]);

    // if (projectsError) {
    //     return (
    //         <Page title={`Error: Can't Load Project`}>
    //             <Container sx={{ pt: 12 }}>
    //                 <ErrorComponent title="Something has gone wrong..." description={'We are having trouble loading your projects. Please try the request again and if the issue persists please contact support.'} />
    //             </Container>
    //         </Page>
    //     );
    // }

    if (!initialised) {
        return (
            <LoadingScreen />
        )
    }

    if (error) {
        return (
            <Page title={`Error: Can't Load Project`}>
                <Container sx={{ pt: 12 }}>
                    <ErrorComponent title="Something has gone wrong..." description={error} />
                </Container>
            </Page>
        );
    }



    return <>{children}</>;
}
