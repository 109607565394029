import { useTheme } from '@material-ui/core/styles';
import Label from '../shared/Label';
import { sentenceCase } from 'change-case';
import { ServiceStatus } from '../../@types/maintenance'

function ServiceStatusLabel({ status, size = 12 }: { status: ServiceStatus, size?: number }) {
    const theme = useTheme();
  
    return (
        <Label
            fontSize={size}
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
            (status === ServiceStatus.Cancelled && 'default') ||
            (status === ServiceStatus.Scheduled && 'warning') ||
            'success'
            }
        >
            {sentenceCase(status)}
      </Label>
    );
  }
  
  export default ServiceStatusLabel;