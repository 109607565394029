import { useState, useEffect, ReactElement } from 'react'
import React from 'react'
import { TableCell, Stack } from '@material-ui/core';
import { ClientSummary } from '../../../@types/client'
import { Project } from '../../../@types/project'
import VeemTable, { TableHeader } from '../../table/VeemTable';
import { ClientStatusLabel } from '../../_client';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../search/SearchInput'
import { fDate, fDateUrl } from '../../../utils/formatTime'

const TABLE_HEAD = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        sortable: true,
        width: 250
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
        sortable: true,
        width: 150
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: true,
        label: 'Last Name',
        sortable: true,
        width: 150
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email',
        sortable: true,
        //width: 150
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
        sortable: true,
        width: 100
    },
    {
        id: 'updated',
        numeric: false,
        disablePadding: true,
        label: 'Updated',
        sortable: true,
        width: 100
    },
];

type ClientTableProps = {
    project: Project | null;
    header?: TableHeader[];
    clients: ClientSummary[];
    totalRows: number;
    getData(page: number, pageSize: number, orderBy: string, order: 'asc' | 'desc', search: string | null): void;
    renderTableRow?(row: ClientSummary, selected: boolean, labelId: string): ReactElement;
}

type EntendedClientTableProps = ClientTableProps;

export default function ClientTable({ getData, renderTableRow, header, project, clients, totalRows }: EntendedClientTableProps) {

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState<string | null>('');
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [orderBy, setOrderBy] = useState('updated');
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)

                // fetch the invoices using the supplied fucntion
                await getData(page, rowsPerPage, orderBy, order, search);

                setLoading(false);
            }
            catch (err) {
                setLoading(false);
            }
        })()

    }, [setLoading, page, orderBy, order, rowsPerPage, search])

    function handleChangePage(newPage: number) {
        setPage(newPage)
    };

    function handleSort(orderBy: string, order: 'asc' | 'desc') {
        setOrderBy(orderBy);
        setOrder(order);
        setPage(0);
    }

    const handleRowSelect = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: ClientSummary) => {
        if (event.metaKey || event.ctrlKey) {
            const win = window.open(`/group/${project?.identifier}/customers/${row.id}/detail`, "_blank");
            win?.focus();
        }
        else {
            navigate(`/group/${project?.identifier}/customers/${row.id}/detail`)
        }
    };

    return (
        <>
            <Stack mb={2} direction="row" alignItems="center" justifyContent="space-between">
                <SearchInput label={'Search'} placeholder="Search customers..." onInputChange={(value: string) => setSearch(value)} value={search} />
            </Stack>
            <VeemTable<ClientSummary>
                header={header ?? TABLE_HEAD}
                data={clients}
                page={page}
                loading={loading}
                totalRows={totalRows}
                rowsPerPage={rowsPerPage}
                showSelectAll={false}
                getSelectedItemKey={(row: ClientSummary) => row.id}
                order={order}
                orderBy={orderBy}
                handleChangePage={handleChangePage}
                handleSort={handleSort}
                onRowSelected={handleRowSelect}
                onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
                renderTableRow={(row: ClientSummary, selected: boolean, labelId: string) => {

                    if (renderTableRow) {
                        return renderTableRow(row, selected, labelId)
                    }
                    else {
                        return (
                            <>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell component="td" id={labelId} scope="row" padding="none">
                                    {row.firstName || '-'}
                                </TableCell>
                                <TableCell component="td" id={labelId} scope="row" padding="none">
                                    {row.lastName || '-'}
                                </TableCell>
                                <TableCell component="td" id={labelId} scope="row" padding="none">
                                    {row.emailAddress || '-'}
                                </TableCell>
                                <TableCell align="left" width={100} padding="none">
                                    <ClientStatusLabel status={row.status} />
                                </TableCell>
                                <TableCell component="td" id={labelId} scope="row" padding="none">
                                    {row.updated && fDateUrl(row.updated) || '-'}
                                </TableCell>
                            </>
                        )
                    }
                }}
            />
        </>
    );
}
