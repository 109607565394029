import { useState } from 'react'
import { CardContent, Card, CardHeader  } from '@material-ui/core';
import { IVehicleInvoiceSearchCriteria } from '../../@types/vehicle'
import { InvoiceType } from '../../@types/invoice'
import { InvoiceSearch } from '../invoice';
import { useGetVehicleInvoicesQuery } from '../../services/vehicles';

type VehicleInvoicesProps = {
  vehicleId: string;
};

export default function VehicleInvoices({ vehicleId }: VehicleInvoicesProps) {

    const [search, setSearch] = useState<IVehicleInvoiceSearchCriteria>({
      pageNumber: 0,
      pageSize: 25,
      orderBy: 'dueDate',
      order: 'desc',
      text: '',
      unlinkedOnly: false,
      statusFilter: [],
      typeFilter: [InvoiceType.AccountsReceivable],
      vehicleId: vehicleId,
      projectIdentifier: ''
    });

    const { data = { invoices: [], totalCount: 0 }, isLoading } = useGetVehicleInvoicesQuery(search);

    return (
        <Card sx={{ mb: 4 }}>
          <CardHeader title="Vehicle Invoices" />
          <CardContent>
            <InvoiceSearch
              invoices={data.invoices}
              showSearch={false}
              search={search}
              totalRows={0}
              isLoading={isLoading}
              showCustomer={false}
              onSetSearch={(search) => setSearch({
                ...search,
                vehicleId: vehicleId
              })}
              onSearch={() => {}}
            />
          </CardContent>
        </Card>
    );
}
