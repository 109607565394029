import { TextFieldProps } from '@material-ui/core';
import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import { VehicleTypeSelectList } from '../../../_vehicle'

type VehicleTypeInputProps<T extends FieldValues> = TextFieldProps & {
    control: Control<T>;
    name: Path<T>;
}

const VehicleTypeInput = <T extends FieldValues>({  control, name, ...rest }: VehicleTypeInputProps<T>) => {

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <VehicleTypeSelectList
                    {...rest}
                    field={field}
                />
            )}
        />
    )
}

export default VehicleTypeInput;
