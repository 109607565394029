import { useState } from 'react'
import { SelectOption } from '../../@types/search'
import { ServiceTask, IServiceTaskSearchCriteria } from '../../@types/maintenance'
import SelectList2, { OptionType }  from '../shared/list/SelectList2'
import { useGetServiceTasksQuery } from '../../services/servicetasks'

type ServiceTaskSelectListProps = {
    onChange(option: SelectOption): void;
    excludedIds: string[];
    label?: string;
};

export default function ServiceTaskSelectList({ onChange, label, excludedIds }: ServiceTaskSelectListProps) {

    const [search, setSearch] = useState<IServiceTaskSearchCriteria>({
        pageNumber: 0,
        pageSize: 15,
        orderBy: 'title',
        order: 'desc',
        text: '',
    });

    const { data = { results: [] } } = useGetServiceTasksQuery(search);
    const handleChangeSearch = async (value: string) => {
        setSearch({
            pageNumber: 0,
            pageSize: 15,
            orderBy: 'title',
            order: 'desc',
            text: value,
        });
    };

    return (
        <SelectList2<OptionType>
            clearSelectedItemOnChange={true}
            options={data?.results.filter((value: ServiceTask) => !excludedIds.find((id) => value.id == id)).map((task: any) => {
                return {
                    id: task.id,
                    title: task.title,
                }
            })}
            selectedIds={excludedIds}
            onChange={(selected) => {
                if (selected) {
                    onChange({
                        id: selected.id || '',
                        name: selected.title,
                    });
                }
                //handleChangeSearch('');
            }}
            multiple={false}
            handleSearch={handleChangeSearch}
            label={label ?? 'Service Tasks'}
            error={null}
        />
    );
}
