import { useEffect, useState } from 'react';
import { TextField, Typography, Paper } from '@material-ui/core';
import Handlebars from 'handlebars'

export default function HandleBarsPreview({ messageTemplate, data }: { messageTemplate: string | null, data: any }) {

    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        try {
            if (messageTemplate) {
                var template = Handlebars.compile(messageTemplate);
                setMessage(template(data, {}));
                console.log(message)
            } else {
                setMessage('No preview available');
            }
        } catch (err) {
            console.log(err);
        }
    }, [messageTemplate])


    return (
        <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
            <TextField
                variant="standard"
                multiline
                value={message}
                disabled
                fullWidth
                InputProps={{
                    disableUnderline: true,
                }}
            />
            <Typography
                variant="caption"
            >
                Message character count: {message?.length}
            </Typography>
        </Paper>

    )
}
