import { useEffect, useState } from 'react'
import useProject from '../../hooks/useProject'
import { UserGroupSelectOption } from '../../@types/search'
import { AllocationType } from '../../@types/allocation'
import { Autocomplete, TextField, Avatar, ListItemAvatar } from '@material-ui/core';
import { ControllerRenderProps } from 'react-hook-form';

type ProjectUserSelectListProps = {
  label?: string;
  exclusions?: string[];
  field: ControllerRenderProps;
};

export default function ProjectUserSelectList({ label = 'User or Email', exclusions = [], field }: ProjectUserSelectListProps) {

  const { project } = useProject();
  const [options, setOptions] = useState<UserGroupSelectOption[]>([]);
  const [values, setValues] = useState<UserGroupSelectOption[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [value, setValue] = useState<UserGroupSelectOption | null>(null);

  useEffect(() => {
    if (field.value) {
      const selection = project.memberships.find(_ => _.member.id === field.value)
      if (selection) {
        setValue({
          id: selection.member.id,
          name: selection.member.displayName,
          imageUrl: selection.member.imageUrl,
          type: AllocationType.User,
        });
        setSearch(selection.member.displayName);
      } else {
        setValue(null);
      }
    } else {
      setSearch(null);
      setValue(null);
    }
  }, [field.value])

  useEffect(() => {
    if (project) {
      setValues(project?.memberships
        .filter(t => !exclusions.includes(t.member.id))
        .map((membership) => {
          return {
            id: membership.member.id,
            name: membership.member.displayName,
            imageUrl: membership.member.imageUrl,
            type: AllocationType.User,
          }
        }) || [])
    }
  }, [project])

  useEffect(() => {
    setOptions(values
      .filter(t => search === null || t.name.toLowerCase().includes(search.toLowerCase()))
      .map((item) => {
        return {
          id: item.id,
          name: item.name,
          imageUrl: item.imageUrl,
          type: AllocationType.User,
        }
      }) || [])
  }, [values, search])

  return (
    <Autocomplete
      {...field}
      fullWidth
      value={value}
      multiple={false}
      filterOptions={(x) => x}
      options={options || []}
      isOptionEqualToValue={(option, value) => {
        if (value.id === "") {
          return true;
        }

        return option.id === value.id;
      }}
      renderOption={(props, option) => (
        <li key={option.id}{...props}>
          <span>
            <ListItemAvatar>
              <Avatar alt={option.name} src={option.imageUrl} />
            </ListItemAvatar>
          </span>
          {option.name} {option.type ? `(${option.type})` : ''}
        </li>
      )}
      getOptionLabel={(option) => option.name}
      onInputChange={(event, value) => setSearch(value)}
      onChange={(event, newValue) => {
        let enteredText = (newValue as UserGroupSelectOption);
        if (enteredText) {
          field?.onChange(enteredText?.id);
        } else {
          field?.onChange(null);
        }
      }}
      renderInput={(params) =>
        <TextField
          {...params}
          label={label}
        //error={Boolean(field.e)}
        //helperText={error?.message}
        />
      }
    />
  );
}
