import { useState } from 'react'
import { LoadingButton } from '@material-ui/lab';
import { useNavigate } from "react-router-dom"
import { Vehicle } from '../../@types/vehicle'
import { Project, ProjectAccessLevel } from '../../@types/project'
import ConfirmPhraseDeleteModal from '../shared/ConfirmPhraseDeleteModal'
import { useDispatch } from '../../redux/store';
import { deleteVehicle } from '../../redux/slices/vehicle'
import axios from '../../utils/axios'

type VehicleDetailInfoProps = {
  vehicle: Vehicle;
  project: Project | null;
};

export default function DeleteVehicle({ vehicle, project }: VehicleDetailInfoProps) {

    const [confirmDeleteOpen, setConfirmDeleteOpen]= useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    async function handleConfirmDelete() {
        try {
            await dispatch(deleteVehicle(vehicle.id))
            setConfirmDeleteOpen(false);
            navigate(`../..`);
        } catch (err) {

        }
    };

    if (!vehicle || !project) {
        return null
    }

    return (
      <>
        <LoadingButton
            sx={{ mt: 2 }}
            type="submit"
            color="error"
            variant="outlined"
            loading={false}
            disabled={project.accessLevel === ProjectAccessLevel.Guest || project.accessLevel === ProjectAccessLevel.NoAccess}
            onClick={() => setConfirmDeleteOpen(true)}
        >
            Delete Vehicle
        </LoadingButton>
        <ConfirmPhraseDeleteModal
            confirmPhrase={vehicle.vehicleIdentifier}
            open={confirmDeleteOpen}
            onCancel={() => setConfirmDeleteOpen(false)}
            onConfirm={handleConfirmDelete}
        />
    </>
  );
}
