import axios from '../../utils/axios'
import useProject from '../../hooks/useProject'
import GenericMultiSelectList from '../shared/list/GenericMultiSelectList'
import { SelectOption } from '../../@types/search'

type VehicleMakeListProps = {
    value: SelectOption[];
    onChange: (selected: SelectOption[]) => void;
};

export default function VehicleMakeSelectList({ value, onChange }: VehicleMakeListProps) {

    const { project } = useProject();

    const handleChangeSearch = async (value: string) => {
        try {
            var response = await axios.get<any, any>(`/api/vehicle/makes?projectId=${project?.id}&searchValue=${value ? value : ''}`);

            return response.data.map((make: any) => {
                return {
                    id: make,
                    name: make,
                }
            });
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    return (
        <GenericMultiSelectList
            value={value}
            handleSearch={handleChangeSearch}
            onChange={onChange}
            label="Make"
            error={null}
        />
    );
}
