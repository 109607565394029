import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { store, RootState } from '../store';
import axios from '../../utils/axios';
import { VehiclesState } from '../../@types/vehicles'
import { AllocationType } from '../../@types/allocation'

const initialState: VehiclesState = {
  isLoading: false,
  error: false,
  vehicles: [],
  isFirstTime: true,
  search: {
    pageNumber: 0,
    pageSize: 25,
    order: 'desc',
    orderBy: 'updated',
    clients: [],
    makes: [],
    vehicleIdentifiers: [],
    text: null,
    projectIdentifier: undefined,
  },
  totalRows: 0,
  configuration: {
    isLoading: false,
    current: {
      wofConfiguration: {
        enabled: false,
        trackingEnabled: false,
        reminderConfiguration: {
          createComplianceReminders: false,
          complianceTrackingEnabled: false,
          defaultAllocationId: '',
          defaultAllocationType: AllocationType.Group,
          defaultAllocation: {
            allocationId: '',
            allocationName: '',
            allocationType: AllocationType.Group,
          },
          reminderToleranceDays: 10,
          emailConfig: {
            enabled: false,
            sendReminderToClient: false,
            reminderFromName: '',
            emailBody: '',
            messageTemplate: null,
            reminderAdditionalEmail: '',
            reminderReplyToEmail: '',
          },
          textMessageConfig: {
            enabled: false,
            messageTemplate: null,
          },
        },
      },
      cofConfiguration: {
        enabled: false,
        trackingEnabled: false,
        reminderConfiguration: {
          createComplianceReminders: false,
          complianceTrackingEnabled: false,
          defaultAllocationId: '',
          defaultAllocationType: AllocationType.Group,
          defaultAllocation: {
            allocationId: '',
            allocationName: '',
            allocationType: AllocationType.Group,
          },
          reminderToleranceDays: 10,
          emailConfig: {
            enabled: false,
            sendReminderToClient: false,
            reminderFromName: '',
            emailBody: '',
            messageTemplate: null,
            reminderAdditionalEmail: '',
            reminderReplyToEmail: '',
          },
          textMessageConfig: {
            enabled: false,
            messageTemplate: null,
          },
        },
      },
      createComplianceOnVehicleCreation: true,
      complianceTrackingEnabled: false,
    }
  }
};

export const fetchVehicles = createAsyncThunk(
  'vehicles/fetch',
  async (req, { rejectWithValue, getState }) => {
    try {
      const { vehicles } = getState() as RootState;
      var response = await axios.post<any, any>('/api/vehicle/search', {
        clients: vehicles.search.clients.map((item) => item.id),
        makes: vehicles.search.makes.map((item) => item.id),
        vehicleIdentifiers: vehicles.search.vehicleIdentifiers.map((item) => item.id),
        pageNumber: vehicles.search.pageNumber,
        pageSize: vehicles.search.pageSize,
        order: vehicles.search.order,
        orderBy: vehicles.search.orderBy,
      })
      return response.data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const slice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getVehiclesInitial(state, action) {
      state.isLoading = false;
      state.vehicles = action.payload;
    },

    removeVehicle(state, action) {
      state.vehicles = state.vehicles.filter(vehicle => vehicle.id !== action.payload);
    },

    setSearch(state, action) {
      state.search.orderBy = action.payload.orderBy;
      state.search.order = action.payload.order;
      state.search.pageNumber = action.payload.pageNumber;
      state.search.pageSize = action.payload.pageSize;
      state.search.clients = action.payload.clients;
      state.search.makes = action.payload.makes;
      state.search.vehicleIdentifiers = action.payload.registrations;
    },

    setPage(state, action) {
      state.search.pageNumber = action.payload;
    },

    setPageSize(state, action) {
      state.search.pageSize = action.payload;
    },

    startConfigurationLoading(state) {
      state.configuration.isLoading = true;
    },

    setConfiguration(state, action) {
      state.configuration.isLoading = false;
      state.configuration.current = action.payload.complianceConfiguration;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVehicles.pending, (state, action) => {
      state.isLoading = true;
    })

    builder.addCase(fetchVehicles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vehicles = action.payload.vehicles;
      state.totalRows = action.payload.totalCount;
      state.isFirstTime = false;
    })

    builder.addCase(fetchVehicles.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    })
  },
});

// Reducer
export default slice.reducer;

export const { setSearch, setPage, setPageSize, setConfiguration, removeVehicle } = slice.actions;


export function getVehicleConfiguration(projectIdentifier: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startConfigurationLoading());

    try {
      // perform search against the api
      var response = await axios.get(`/api/projects/${projectIdentifier}/compliance-config`)
      dispatch(slice.actions.setConfiguration(response.data));
    } catch (error) {
      ///dispatch(slice.actions.hasError(error));
    }
  };
}

// selectors
export const selectVehiclesState = (state: RootState) => state.vehicles;
export const selectVehicleConfiguration = createSelector(selectVehiclesState, state => state.configuration.current);