export enum AllocationType {
    Project = "Project",
    Group = "Group",
    User = "User",
}

export type AllocationSummary = {
    allocationId: string;
    allocationName: string;
    allocationType?: AllocationType;
}