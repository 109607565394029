import { TableCell } from '@material-ui/core';
import { ComplianceHistory } from '../../../../@types/compliance'
import VeemTable, { TableHeader } from '../../../table/VeemTable';
import { ComplianceStatusLabel } from '../../../../components/_vehicle'
import { fDateShort, fDateTime } from '../../../../utils/formatTime'

const TABLE_HEAD = [
    {
        id: 'whenCreatedUtc',
        numeric: false,
        disablePadding: false,
        label: 'Created',
        sortable: false,
        width: 180
    },
    {
        id: 'complianceExpiryDate',
        numeric: false,
        disablePadding: false,
        label: 'Expiry Date',
        sortable: false,
        width: 130
    },
    {
        id: 'note',
        numeric: false,
        disablePadding: false,
        label: 'Note',
        sortable: false,
    },
    {
        id: 'audit',
        numeric: false,
        disablePadding: false,
        label: 'Audit',
        sortable: false,
    },
    {
        id: 'user',
        numeric: false,
        disablePadding: false,
        label: 'User',
        sortable: false,
        width: 70
    }
  ];

type ComplianceHistoryTableProps = {
    histories: ComplianceHistory[];
}

export default function ComplianceHistoryTable({ histories }: ComplianceHistoryTableProps) {

    return (
        <VeemTable<ComplianceHistory>
            header={TABLE_HEAD}
            data={histories}
            page={0}
            loading={false}
            totalRows={histories.length}
            rowsPerPage={10}
            showSelectAll={false}
            getSelectedItemKey={(row: ComplianceHistory) => row.created}
            order={"asc"}
            orderBy={"whenCreatedUtc"}
            handleChangePage={() => {}}
            handleSort={() => {}}
            onRowsPerPageChange={() => {}}
            defaultDense={true}
            renderTableRow={(row: ComplianceHistory, selected: boolean, labelId: string) => {
                return (
                    <>
                        <TableCell>{row.created ? fDateTime(row.created) : '-'}</TableCell>
                        <TableCell>{row.expiryDate ? fDateShort(row.expiryDate) : '-'}</TableCell>
                        <TableCell>{row.note || '-'}</TableCell>
                        <TableCell>{row.audit || '-'}</TableCell>
                        <TableCell>{row.user?.displayName}</TableCell>
                    </>
                )
            }}
        />
    );
}
