import { useState, useMemo, useEffect } from 'react'
import { lighten, darken } from '@material-ui/system';
import { TextField, Avatar, ListItemAvatar, Typography, Stack } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';
import debounce from 'lodash/debounce';
import { ControllerRenderProps } from 'react-hook-form';
import { alpha, useTheme, styled, Theme } from '@material-ui/core/styles';

type SelectList2Props<T> = {
  options: OptionType[];
  label: string;
  handleSearch(searchValue: string | null): Promise<void>;
  onChange?: (selected: OptionType | null) => void;
  groupBy?: (option: OptionType) => string;
  multiple?: boolean;
  error: any;
  field?: ControllerRenderProps;
  clearSelectedItemOnChange?: boolean;
  selectedIds?: string[]
  loading?: boolean;
  disabled?: boolean;
};

export type OptionType = {
  id?: string;
  category?: string;
  title: string;
  description?: string;
  imageUrl?: string;
  inputValue?: string;
}

// note this may need be updated
const filter = createFilterOptions<OptionType>();
// const filter = createFilterOptions<OptionType>({
//   stringify: (option) => `${option.title} ${option.description}`,
// });

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  //color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.grey[500], 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default function SelectList2<T>({
  options,
  error,
  label,
  handleSearch,
  onChange,
  groupBy,
  loading = false,
  multiple = false,
  field,
  clearSelectedItemOnChange = false,
  selectedIds = [],
  disabled = false
}: SelectList2Props<T>) {

  const [selectedItem, setSelectedItem] = useState('');
  const [value, setValue] = useState<OptionType | null>(null);
  const debouncedChangeHandler = useMemo(() => debounce((userInput) => { handleSearch(userInput) }, 200), []);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value])

  return (
    <>
    <Autocomplete
      value={value}
      fullWidth
      selectOnFocus
      disabled={disabled}
      clearOnBlur
      handleHomeEndKeys
      inputValue={selectedItem}
      groupBy={groupBy}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      loading={loading}
      multiple={false}
      options={options}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader><Typography variant="subtitle2">{params.group}</Typography></GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <span>
              {option.imageUrl && (<ListItemAvatar>
                <Avatar alt={option.title} src={option.imageUrl} />
              </ListItemAvatar>)}
            </span>
            {option.description ?
              <Stack direction="column">
                <Typography variant="subtitle1">{option.title}</Typography>
                <Typography variant="body2">{option.description}</Typography>
              </Stack>
              : <Typography variant="body1">{option.title}</Typography>}
          </li>
        )
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      onInputChange={(event, value, reason) => {
        // clear the item if required
        if (clearSelectedItemOnChange) {
          if (event && (event.type === 'blur' || event.type === 'click')) {
            setSelectedItem('');
            handleSearch('');
          } else if (reason !== 'reset') {
            // reset looks to be triggered on option select
            setSelectedItem(value);
            handleSearch(value);
          }
        } else {
          setSelectedItem(value);
          debouncedChangeHandler(value);
        }
      }}
      onChange={(event, newValue) => {
        console.log('onchange', newValue)
        if (typeof newValue === 'string') {
          setValue({
            //id: null,
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            //id: null,
            title: newValue.inputValue,
          });

          // clear the item after creating it if required
          if (clearSelectedItemOnChange) {
            setSelectedItem('');
          }
        } else {
          setValue(newValue);
        }
      }}
      //freeSolo
      renderInput={(params) =>
        <TextField
          {...params}
          label={label}
          error={Boolean(error)}
          helperText={error?.message}
        />
      }
    />
    </>
  );
}
