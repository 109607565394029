import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import ComplianceReminderHistoryTable from './grid/ComplianceReminderHistoryTable'
import { Compliance } from '../../../@types/compliance';

type ComplianceReminderHistoryModalProps = {
  compliance: Compliance,
  handleModalClose(): void,
  modalOpen: boolean,
};

export default function ComplianceReminderHistoryModal({ compliance, modalOpen, handleModalClose }: ComplianceReminderHistoryModalProps) {

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={modalOpen}
      onClose={handleModalClose}
    >
      <DialogTitle>Compliance Reminder History</DialogTitle>
      <DialogContent dividers>
        {compliance && compliance.notification ?
          <ComplianceReminderHistoryTable complianceNotification={compliance.notification} /> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
