import React from 'react'
import { useTheme } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import { ServiceItem } from '../../../@types/maintenance'
import VeemTableBase from '../../table/VeemTableBase'
import ListSkeletonLoad from '../../shared/list/ListSkeletonLoad'
import WorkOrderItemActionMenu from '../WorkOrderItemActionMenu'
import { fCurrencyDecimal } from '../../../utils/formatNumber'

const HEADER = [
    {
        id: 'title',
        label: 'Item Code',
        numeric: false,
        sortable: false,
        disablePadding: false,
    },
    {
        id: 'description',
        label: 'Description',
        numeric: false,
        sortable: false,
        disablePadding: false,
    },
    {
        id: 'quantity',
        label: 'Qty.',
        numeric: true,
        sortable: false,
        disablePadding: false,
    },
    {
        id: 'price',
        label: 'Price',
        numeric: true,
        sortable: false,
        disablePadding: false,
    },
    {
        id: 'total',
        label: 'Amount',
        numeric: true,
        sortable: false,
        disablePadding: false,
    },
    {
        id: 'edit',
        label: '',
        numeric: false,
        sortable: false,
        disablePadding: false,
    }
]

type ServiceItemTableProps = {
    items: ServiceItem[];
    loading?: boolean;
    onEditItem(item: ServiceItem): void;
    onDeleteItem(item: ServiceItem): void;
};

function ServiceItemTable({ items, onEditItem, onDeleteItem, loading = false }: ServiceItemTableProps) {

    const { palette } = useTheme();

    if (loading) {
        return <ListSkeletonLoad />;
    }

    return (
        <VeemTableBase<ServiceItem>
            header={HEADER}
            data={items}
            getSelectedItemKey={(item: ServiceItem) => `service-item-${item.itemId}`}
            handleChangePage={() => { }}
            handleSort={() => { }}
            loading={false}
            onRowsPerPageChange={() => { }}
            order="asc"
            orderBy="id"
            page={0}
            renderTableRow={(row, selected, labelId, { onClick }) => {
                return (
                    <React.Fragment key={`service-item-${row.itemId}`}>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover key={`service-item-${row.itemId}`}>
                            <TableCell component="th" scope="row">{row.title}</TableCell>
                            <TableCell component="th" scope="row">{row.description}</TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">{row.price ? fCurrencyDecimal(row.price) : '-'}</TableCell>
                            <TableCell align="right">{row.subTotal ? fCurrencyDecimal(row.subTotal) : '-'}</TableCell>
                            <TableCell align="right">
                                <WorkOrderItemActionMenu
                                    showEdit={true}
                                    deleteText="Remove Item"
                                    onDelete={() => onDeleteItem(row)}
                                    onOpenEdit={() => onEditItem(row)}
                                />
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            }}
            rowsPerPage={100}
            totalRows={items.length}
            onRowSelected={() => { }}
        />
    );
}

export default ServiceItemTable;