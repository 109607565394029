import  { useState } from 'react'
import { Card, CardHeader, Box, Typography, Alert } from '@material-ui/core';
import { ComplianceCreateForm } from '../index'
import { LoadingButton } from '@material-ui/lab';
import { useSelector, useDispatch } from '../../../redux/store';
import { selectComplianceImport } from '../../../redux/slices/compliance';
import { useGetVehicleComplianceQuery, useImportVehicleComplianceMutation } from '../../../services/vehicles';

type CreateComplianceProps = {
    vehicleId: string;
}

export default function CreateCompliance({ vehicleId } : CreateComplianceProps) {

  const [complianceError, setComplianceError] = useState('')
  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectComplianceImport);
  const [importVehicleCompliance] = useImportVehicleComplianceMutation();
  
  const handleComplianceImport = async () => {
    try {
      await importVehicleCompliance(vehicleId).unwrap();
    } catch (err) {
      console.log(err)
      setComplianceError(err)
    }
  }

  return (
    <Card>
      <CardHeader title="Create Compliance" />
      <Box sx={{ p: 3 }}>
        <Typography variant="body2">
          Compliance information can be imported automatically from NZTA to provide the current compliance inspection details including the expiry date
        </Typography>
      </Box>
      <Box sx={{ pl: 3, pr: 3 }}>
        {
          error ? 
            <Alert severity="error" sx={{ mb: 3 }}>
              An error has occurred retrieving compliance information from NZTA. Message={error}
            </Alert> :
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                onClick={handleComplianceImport}
                loading={loading}
                disabled={loading}
              >
                Import Compliance
              </LoadingButton>
        }
      </Box>
      <Box sx={{ px: 3, py: 3 }}>
        <Typography  variant="body2">
          Alternatively, you can enter the details manually below. Choosing this option will mean that no information will be retrieved from NZTA and you are managing your compliance information yourself
        </Typography>
      </Box>
      <Box sx={{ px: 3, py: 3 }}>
        <ComplianceCreateForm vehicleId={vehicleId} />
      </Box>
    </Card>
  );
}
