import { Link as RouterLink, Outlet } from 'react-router-dom';
import { styled, alpha } from '@material-ui/core/styles';
import { Stack, Toolbar, Box, AppBar } from '@material-ui/core';
import LogoFull from '../components/shared/LogoFull';
import AccountPopover from './app/AccountPopover';

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    //backdropFilter: 'blur(6px)',
    //WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.background.default,
    // [theme.breakpoints.up('lg')]: {
    //   width: `calc(100% - ${DRAWER_WIDTH}px)`
    // }
  }));

const HeaderStyle = styled('header')(({ theme }) => ({
    // top: 0,
    //left: 0,
    // lineHeight: 0,
    //width: '100%',
    //position: 'absolute',
    backgroundColor: 'white',
    padding: theme.spacing(0.5, 1, 0.5),
    // [theme.breakpoints.up('sm')]: {
    //     padding: theme.spacing(1, 1, 1)
    // }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: '30px',
    [theme.breakpoints.up('lg')]: {
       // minHeight: APP_BAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

export default function NavbarOnlyLayout() {
    return (
        <>
        <RootStyle>
            <ToolbarStyle>
                <RouterLink to="/">
                    <LogoFull width={160} />
                </RouterLink>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <AccountPopover />
                </Stack>
            </ToolbarStyle>
            </RootStyle>
            <Outlet />
        </>
    );
}
