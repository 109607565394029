import { ClientSummary, ClientCreateRequest, IClientSearchCriteria } from '../@types/client'
import { VehicleSearchResult, IVehicleSearchCriteria } from '../@types/vehicles'
import * as jsonpatch from 'fast-json-patch';
import { api } from './api'

export const clientsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    addClient: builder.mutation<ClientSummary, ClientCreateRequest>({
      query: (request: ClientCreateRequest) => ({
        url: `clients`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['Clients']
    }),

    patchClient: builder.mutation<ClientSummary, { clientId: string, patch: any }>({
      query: (request: { clientId: string, patch: any }) => ({
        url: `clients/${request.clientId}`,
        method: 'PATCH',
        body: request.patch
      }),
      invalidatesTags: ['Clients']
      // async onQueryStarted({ clientId, ...patch }, { dispatch, queryFulfilled }) {
      //   dispatch(clientsApi.util.updateQueryData('getClient', clientId, (draft) => {
      //     var updated = jsonpatch.applyPatch(document, patch.patch).newDocument;
      //     Object.assign(draft, { ...updated })
      //   }))

      //   const { data: updatedInvoice } = await queryFulfilled;
      //   dispatch(clientsApi.util.updateQueryData('getClient', clientId, (draft) => {
      //     Object.assign(draft, { ...updatedInvoice })
      //   }))
      // }
    }),

    getClient: builder.query<ClientSummary, string>({
      query: (id: string) => ({
        url: `clients/${id}`,
        method: 'GET',
      }),
      providesTags: ['Clients']
    }),

    syncClient: builder.mutation<ClientSummary, string>({
      query: (id: string) => ({
        url: `clients/${id}/sync`,
        method: 'POST',
      })
    }),

    getClients: builder.query<{ entities: ClientSummary[], totalCount: number }, IClientSearchCriteria>({
      query: (request: IClientSearchCriteria) => ({
        url: `clients/search`,
        method: 'POST',
        body: request
      }),
      providesTags: ['Clients']
    }),

    getClientVehicles: builder.query<VehicleSearchResult, IVehicleSearchCriteria>({
      query: (request: IVehicleSearchCriteria) => ({
        url: `vehicle/search`,
        method: 'POST',
        body: request
      }),
      providesTags: ['Vehicle']
    }),

  }),
  overrideExisting: false,
})

export const {
  useGetClientQuery,
  useGetClientsQuery,
  usePatchClientMutation,
  useAddClientMutation,
  useGetClientVehiclesQuery,
  useSyncClientMutation,
} = clientsApi;