import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ServiceTask } from '../../../@types/maintenance'
import EmptyContent from '../../shared/EmptyContent'
import ServiceTaskListItem from './ServiceTaskListItem'

type ServiceTaskListComponentProps = {
    tasks: ServiceTask[];
    loading?: boolean;
    onTaskRemoved?: (task: ServiceTask) => Promise<boolean>;
};

const ListStyle = styled(Box)(({ theme }) => ({
    //marginTop: theme.spacing(2)
}));

function ServiceTaskList({ tasks, onTaskRemoved }: ServiceTaskListComponentProps) {

    if (tasks.length == 0) {
        return <EmptyContent
            imgHeight={100}
            displayLayout="row"
            title="No service tasks"
            description="Add or create service tasks for this plan using the list above."
        />
    }

    return (
        <ListStyle>
            {
                tasks.map((task: ServiceTask) => (
                    <ServiceTaskListItem
                        key={`service-task-list-${task.id}`}
                        task={task}
                        onTaskRemoved={onTaskRemoved}
                    />
                ))
            }
        </ListStyle >
    );
}

export default ServiceTaskList;