import { useState, useEffect} from 'react'
import { Stack, Typography, Button } from '@material-ui/core';

import numeral from 'numeral';


type NextServiceDueNumericComponentProps = {
    label: string;
    current: number | null;
    dueIn: number | null;
    enabled: boolean;
    onChange(nextDue: number | null): void;
}

export const NextServiceDueNumericComponent = ({ label, current, dueIn, enabled, onChange }: NextServiceDueNumericComponentProps) => {

    const [nextDue, setNextDue] = useState<number | null>(null);

    useEffect(() => {
        if (current && dueIn) {
            setNextDue(numeral(current).add(dueIn ?? 0).value() ?? null);
        } else {
            setNextDue(null);
        }
    }, [current, dueIn]);

    if (!enabled) {
        return <></>
    }

    if (!dueIn) {
        return (
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 0.5 }}>
                <Stack direction="column" justifyContent="space-between">
                    <Typography variant="caption">Schedule has no interval set</Typography>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column" justifyContent="space-between">
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" sx={{ mt: 0.5 }}>
                    <Typography variant="caption">Suggested {label}:</Typography>
                    <Typography variant="caption" sx={{ ml: 0.5, fontWeight: 'bold' }}>{nextDue ? numeral(nextDue).format('0,0') : '-'}</Typography>
                </Stack>
                <Button
                    size="small"
                    variant="text"
                    onClick={() => onChange(nextDue)}
                    disabled={nextDue === null}
                    sx={{ padding: 0 }}
                >
                    Apply
                </Button>
            </Stack>
        </Stack>
    )
}