import * as jsonpatch from 'fast-json-patch';
import { api } from './api'
import {
  Invoice,
  InvoiceLink,
  InvoiceSearchSearchResult,
  InvoiceSearchCriteria,
  InvoiceLineItem,
  InvoiceLineItemRequest,
  InvoiceLineItemUpdateRequest,
  InvoiceCreateRequest,
  InvoiceUpdateRequest
} from '../@types/invoice'

export const invoiceApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getInvoice: builder.query<Invoice, string>({
      query: (id: string) => ({
        url: `invoices/${id}`,
        method: 'GET',
      }),
      providesTags: ['Invoice']
    }),

    addInvoice: builder.mutation<Invoice, InvoiceCreateRequest>({
      query: (request: InvoiceCreateRequest) => ({
        url: `invoices`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['Invoice', 'Job']
    }),

    updateInvoiceStatus: builder.mutation<Invoice, InvoiceUpdateRequest>({
      query: (request: InvoiceUpdateRequest) => ({
        url: `invoices/${request.invoiceId}/status`,
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['Invoice', 'Job']
    }),

    patchInvoice: builder.mutation<Invoice, { invoiceId: string, patch: any }>({
      query: (request: { invoiceId: string, patch: any }) => ({
        url: `invoices/${request.invoiceId}`,
        method: 'PATCH',
        body: request.patch
      }),
      async onQueryStarted({ invoiceId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(invoiceApi.util.updateQueryData('getInvoice', invoiceId, (draft) => {
          var updated = jsonpatch.applyPatch(document, patch.patch).newDocument;
          Object.assign(draft, { ...updated })
        }))

        const { data: updatedInvoice } = await queryFulfilled;
        dispatch(invoiceApi.util.updateQueryData('getInvoice', invoiceId, (draft) => {
          Object.assign(draft, { ...updatedInvoice })
        }))
      }
    }),

    requestInvoiceSync: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `invoices/${id}/sync`,
        method: 'PUT',
      }),
      invalidatesTags: ['Invoice']
    }),

    updateInvoiceDescription: builder.mutation<Invoice, { invoiceId: string, description: string | null }>({
      query: (request: { invoiceId: string, description: string | null }) => ({
        url: `invoices/${request.invoiceId}/description`,
        method: 'PUT',
        body: {
          description: request.description
        }
      }),
    }),

    getInvoices: builder.query<InvoiceSearchSearchResult, InvoiceSearchCriteria>({
      query: (query: InvoiceSearchCriteria) => ({
        url: `invoices/search`,
        method: 'POST',
        body: query,
      }),
      providesTags: ['Invoice']
    }),

    deleteInvoiceLink: builder.mutation<Invoice, string>({
      query: (id: string) => ({
        url: `invoices/${id}/link`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Invoice']
    }),

    updateInvoiceLink: builder.mutation<InvoiceLink, { vehicleId: string, invoiceId: string }>({
      query: (request: { vehicleId: string, invoiceId: string }) => ({
        url: `invoices/link`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Invoice']
    }),

    getInvoiceItems: builder.query<InvoiceLineItem[], string>({
      query: (id: string) => ({
        url: `invoices/${id}/items`,
        method: 'GET',
      }),
      providesTags: ['Invoice'],
    }),

    addInvoiceItem: builder.mutation<InvoiceLineItem, InvoiceLineItemRequest>({
      query: (request: InvoiceLineItemRequest) => ({
        url: `invoices/${request.invoiceId}/items`,
        method: 'POST',
        body: request
      }),
      async onQueryStarted({ invoiceId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(invoiceApi.util.updateQueryData('getInvoice', invoiceId, (draft) => {
          Object.assign(draft.lineItems, [...draft.lineItems, { lineItemId: '-1', description: patch.description }])
        }))

        const { data: updatedPost } = await queryFulfilled;
        dispatch(invoiceApi.util.updateQueryData('getInvoice', invoiceId, (draft) => {
          const index = draft.lineItems.findIndex(item => item.lineItemId === '-1')
          if (index > -1) {
            draft.lineItems[index] = updatedPost;
          }
        }))
      },
      invalidatesTags: ['Invoice'],
    }),

    updateInvoiceItem: builder.mutation<InvoiceLineItem, InvoiceLineItemUpdateRequest>({
      query: (request: InvoiceLineItemUpdateRequest) => ({
        url: `invoices/${request.invoiceId}/items/${request.lineItemId}`,
        method: 'PUT',
        body: request
      }),
      async onQueryStarted({ invoiceId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(invoiceApi.util.updateQueryData('getInvoice', invoiceId, (draft) => {
          var lineItem = draft.lineItems.find(item => item.lineItemId === patch.lineItemId);
          if (lineItem) {
            lineItem.description = patch.description;
            lineItem.quantity = patch.quantity;
            lineItem.unitAmount = patch.unitAmount;
          }
        }))

        const { data: updatedPost } = await queryFulfilled;
        dispatch(invoiceApi.util.updateQueryData('getInvoice', invoiceId, (draft) => {
          const index = draft.lineItems.findIndex(item => item.lineItemId === patch.lineItemId)
          if (index > -1) {
            draft.lineItems[index] = updatedPost;
          }
        }))
      },
      invalidatesTags: ['Invoice'],
    }),

    deleteInvoiceItem: builder.mutation<InvoiceLineItem, { invoiceId: string, lineItemId: string }>({
      query: (request: { invoiceId: string, lineItemId: string }) => ({
        url: `invoices/${request.invoiceId}/items/${request.lineItemId}`,
        method: 'DELETE',
        body: request
      }),
      invalidatesTags: ['Invoice'],
      async onQueryStarted({ invoiceId, lineItemId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(invoiceApi.util.updateQueryData('getInvoice', invoiceId, draft => {
          const index = draft.lineItems.findIndex(item => item.lineItemId === lineItemId)
          if (index > -1) {
            draft.lineItems.splice(index, 1);
          }
        }))
        try {
          await queryFulfilled
        } catch {
          patchResult.undo();
        }
      }
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetInvoiceQuery,
  useAddInvoiceMutation,
  useRequestInvoiceSyncMutation,
  useUpdateInvoiceStatusMutation,
  usePatchInvoiceMutation,
  useUpdateInvoiceDescriptionMutation,
  useGetInvoicesQuery,
  useDeleteInvoiceLinkMutation,
  useUpdateInvoiceLinkMutation,
  useAddInvoiceItemMutation,
  useUpdateInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
} = invoiceApi;