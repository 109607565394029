import { WorkOrder, WorkItem, WorkOrderCreateRequest, WorkOrderUpdateRequest, WorkOrderItemRequest, WorkOrderItemUpdateRequest, IWorkOrderSearchCriteria } from '../@types/workorder'
import { ISearchResult } from '../@types/search'
import { api } from './api'

export const workordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWorkOrder: builder.query<WorkOrder, string>({
      query: (id: string) => ({
        url: `work-orders/${id}`,
        method: 'GET',
      }),
      providesTags: ['WorkOrder'],
    }),

    addWorkOrder: builder.mutation<WorkOrder, WorkOrderCreateRequest>({
      query: (request: WorkOrderCreateRequest) => ({
        url: `work-orders`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['WorkOrder'],
    }),

    updateWorkOrder: builder.mutation<WorkOrder, WorkOrderUpdateRequest>({
      query: (request: WorkOrderUpdateRequest) => ({
        url: `work-orders/${request.workOrderId}`,
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['WorkOrder'],
    }),

    deleteWorkOrder: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `work-orders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WorkOrder'],
    }),

    getWorkOrderItems: builder.query<WorkItem[], string>({
      query: (id: string) => ({
        url: `work-orders/${id}/items`,
        method: 'GET',
      }),
      providesTags: ['WorkOrderItem'],
    }),

    addWorkOrderItem: builder.mutation<WorkItem, WorkOrderItemRequest>({
      query: (request: WorkOrderItemRequest) => ({
        url: `work-orders/${request.workOrderId}/items`,
        method: 'PUT',
        body: {
          serviceTaskIds: [request.serviceTaskId]
        }
      }),
      async onQueryStarted({ workOrderId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(workordersApi.util.updateQueryData('getWorkOrderItems', workOrderId, (draft) => {
          Object.assign(draft, [...draft, { id: '-1', description: patch.title }])
        }))

        const { data: updatedPost } = await queryFulfilled;
        dispatch(workordersApi.util.updateQueryData('getWorkOrderItems', workOrderId, (draft) => {
          const index = draft.findIndex(item => item.id === '-1')
          if (index > -1) {
            draft[index] = updatedPost;
          }
        }))
      },
      invalidatesTags: ['WorkOrder'],
    }),

    updateWorkOrderItem: builder.mutation<WorkItem, WorkOrderItemUpdateRequest>({
      query: (request: WorkOrderItemUpdateRequest) => ({
        url: `work-orders/${request.workOrderId}/items/${request.itemId}`,
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['WorkOrder', 'WorkOrderItem'],
    }),

    deleteWorkOrderItem: builder.mutation<void, { workOrderId: string, itemId: string }>({
      query: (request: { workOrderId: string, itemId: string }) => ({
        url: `work-orders/${request.workOrderId}/items/${request.itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WorkOrder'],
      async onQueryStarted({ workOrderId, itemId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(workordersApi.util.updateQueryData('getWorkOrderItems', workOrderId, draft => {
          const index = draft.findIndex(item => item.id === itemId)
          if (index > -1) {
            draft.splice(index, 1);
          }
        }))
        try {
          await queryFulfilled
        } catch {
          patchResult.undo();
        }
      }
    }),

    deleteWorkOrderService: builder.mutation<void, { workOrderId: string, serviceId: string }>({
      query: (request: { workOrderId: string, serviceId: string }) => ({
        url: `work-orders/${request.workOrderId}/service/${request.serviceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WorkOrder', 'WorkOrderItem'],
    }),

    getWorkOrders: builder.query<ISearchResult<WorkOrder>, IWorkOrderSearchCriteria>({
      query: (search: IWorkOrderSearchCriteria) => ({
        url: `work-orders/search`,
        method: 'POST',
        body: search
      }),
      providesTags: ['WorkOrder'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetWorkOrderQuery,
  useGetWorkOrdersQuery,
  useGetWorkOrderItemsQuery,
  useAddWorkOrderItemMutation,
  useDeleteWorkOrderItemMutation,
  useUpdateWorkOrderItemMutation,
  useDeleteWorkOrderServiceMutation,
  useAddWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useDeleteWorkOrderMutation
} = workordersApi;