import axios, { AxiosRequestConfig, Method } from 'axios';
import { saveAs } from 'file-saver';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY
});

let getAccessTokenFunction: (options: any) => Promise<string>;

export function setAuthHeader(token: string) {
  let headers = axiosInstance.defaults.headers as Record<string, string>;
  headers['Authorization'] = token ? 'Bearer ' + token : ''
}

export function setAccessTokenRetriever(getAccessToken: any) {
  getAccessTokenFunction = getAccessToken;
}

export function apiDownloadRequest<T>(url: string, method: Method, downloadName: string) { 
  let options: AxiosRequestConfig = { 
    url,
    method,
    responseType: 'blob' // don't forget this
  };  
  return axiosInstance.request<any>(options)
    .then(response => {
      if (response && response.headers) { 
        let url = window.URL.createObjectURL(new Blob([response.data]));     
        saveAs(url, downloadName);   
      } 
  });
}

axiosInstance.interceptors.request.use(
  async config => {
    // get the access token for the request
    const value = await getAccessTokenFunction({
      //ignoreCache: true
    });
    config.headers = { 
      'Authorization': `Bearer ${value}`,
      'Accept': 'application/json',
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.data) {
        return Promise.reject(error.response.data)
      }

      if (error.response.status === 403) {
        return Promise.reject('Request was forbiden');
      }
    }

    return Promise.reject('Something went wrong');
  }
);

export default axiosInstance;
