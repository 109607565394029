import Label from '../../components/shared/Label';


type VehicleRegistrationProps = {
    registration: string;
    size?: number;
};

export default function VehicleRegistration({ registration, size = 12 }: VehicleRegistrationProps) {

    return (
        <Label
            sx={{ textDecoration: 'none', cursor: 'pointer', fontSize: size }}
            variant={'ghost'}
        >
            {registration}
        </Label>
    )
}
