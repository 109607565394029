import { useState, useEffect } from 'react'
import { IVehicleSearchCriteria } from '../../@types/vehicles'
import { Vehicle, VehicleStatus } from '../../@types/vehicle'
import { ControllerRenderProps } from 'react-hook-form';
import useProject from '../../hooks/useProject';
import ModalContainer from '../shared/modal/ModalContainer'
import VehicleCreateForm from '../vehicle/form/VehicleCreateForm'
import { useGetVehiclesQuery } from '../../services/vehicles'
import { TextField, Avatar, ListItemAvatar, Typography, Stack } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';

type VehicleSelectListProps = {
    onChange(option: VehicleOptionType | null): void;
    restrictToClient?: { id: string, name: string } | null;
    excludedIds: string[];
    label?: string;
    initialValue?: VehicleOptionType | null;
    clearSelectedItemOnChange?: boolean;
    field: ControllerRenderProps;
    error?: string | null;
};

export type VehicleOptionType = {
    id?: string;
    enabled: boolean;
    customer?: { id: string | null, name: string | null } | null;
    category?: string;
    title: string;
    description?: string;
    imageUrl?: string;
    inputValue?: string;
}

// note this may need be updated
const filter = createFilterOptions<VehicleOptionType>();

export default function VehicleSelectListControlled({ initialValue, field, error, onChange, label, excludedIds, restrictToClient, clearSelectedItemOnChange = false }: VehicleSelectListProps) {

    const { project } = useProject();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [client, setClient] = useState<{ id: string, name: string } | null>(null);
    const [selectedItem, setSelectedItem] = useState('');
    const [value, setValue] = useState<VehicleOptionType | null>(initialValue || null);
    const [search, setSearch] = useState<IVehicleSearchCriteria>({
        projectIdentifier: project?.identifier,
        pageNumber: 0,
        pageSize: 15,
        text: '',
        vehicleIdentifiers: [],
        makes: [],
        clients: [],
        orderBy: 'make',
        order: 'desc'
    });

    const { data = { results: [] }, isFetching, isLoading } = useGetVehiclesQuery(search);
    const options: VehicleOptionType[] = data.results.map((vehicle: Vehicle) => {
        return {
            id: vehicle.id,
            enabled: vehicle.status === VehicleStatus.Active,
            title: vehicle.vehicleIdentifier,
            description: `${vehicle.make}, ${vehicle.model}`,
            category: vehicle.make ?? '',
            customer: vehicle.client ? { id: vehicle.client.id, name: vehicle.client.name } : null,
        }
    });

    useEffect(() => {
        setValue(initialValue ?? null);
    }, [initialValue]);

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    useEffect(() => {
        setClient(restrictToClient ?? null);
    }, [restrictToClient]);

    useEffect(() => {
        // when the restricted client changes, update the search criteria
        setSearch({ ...search, clients: restrictToClient ? [{ id: restrictToClient.id, name: '' }] : [] });
    }, [restrictToClient]);

    const handleSearch = (value: string) => {
        setSearch({
            ...search,
            text: value,
        });
    };

    return (
        <>
            <Autocomplete
                value={value}
                inputValue={selectedItem}
                fullWidth
                selectOnFocus
                disabled={false}
                clearOnBlur
                handleHomeEndKeys
                filterSelectedOptions
                getOptionDisabled={(option) => option.enabled === false}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;

                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            enabled: true,
                            title: `Add vehicle "${inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                loading={isLoading || isFetching}
                multiple={false}
                options={options}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            <span>
                                {option.imageUrl && (<ListItemAvatar>

                                    <Avatar alt={option.title} src={option.imageUrl} />
                                </ListItemAvatar>)}
                            </span>
                            {option.description ?
                                <Stack direction="column">

                                    <Typography variant="subtitle1">{option.title}</Typography>
                                    <Typography variant="body2">{option.description}</Typography>
                                </Stack>
                                : <Typography variant="body1">{option.title}</Typography>}
                        </li>
                    )
                }}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                }}
                onInputChange={(event, value, reason) => {
                    // clear the item if required
                    if (clearSelectedItemOnChange) {
                        if (event && (event.type === 'blur' || event.type === 'click')) {
                            setSelectedItem('');
                            handleSearch('');
                        } else if (reason !== 'reset') {
                            // reset looks to be triggered on option select
                            setSelectedItem(value);
                            handleSearch(value);
                        }
                    } else {
                        setSelectedItem(value);
                        handleSearch(value);
                    }
                }}
                onBlur={field.onBlur}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setCreateModalOpen(true);
                        setValue({
                            title: newValue,
                            enabled: true,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setCreateModalOpen(true);
                        setValue({
                            title: newValue.inputValue,
                            enabled: true,
                        });

                        // clear the item after creating it if required
                        if (clearSelectedItemOnChange) {
                            setSelectedItem('');
                        }
                    } else {
                        setValue(newValue);
                        if (newValue) {
                            onChange({
                                id: newValue?.id || '',
                                enabled: true,
                                title: newValue?.title,
                                customer: newValue?.customer
                            });
                        } else {
                            onChange(null);
                        }
                    }
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={label}
                        error={Boolean(error)}
                        helperText={error}
                    />
                }
            />
            <ModalContainer
                open={createModalOpen}
                title="Add Vehicle"
                key=''
                handleConfirmModalClose={() => {
                    setValue(null);
                    field.onChange(null);
                    setCreateModalOpen(false);
                }}
                component={
                    <VehicleCreateForm
                        project={project}
                        ownerEntity={client}
                        registration={value?.title}
                        onVehicleCreated={(vehicle) => {
                            field.onChange({
                                id: vehicle.id,
                                title: vehicle.vehicleIdentifier,
                                customer: {
                                    id: vehicle.client?.id,
                                    name: vehicle.client?.name,
                                }
                            });
                            setCreateModalOpen(false);
                        }}
                    />}
            />
        </>
    );
}
