import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import { Card, CardContent, Button, CardHeader, Typography, Stack, Grid, Box, Paper, Skeleton, Checkbox } from '@material-ui/core';
import { Icon } from '@iconify/react';
import chevronFill from '@iconify/icons-eva/chevron-left-outline';
import checkmarkFill from '@iconify/icons-eva/checkmark-circle-2-fill';
import flagOutline from '@iconify/icons-eva/flag-fill';
import calendarOutline from '@iconify/icons-eva/calendar-fill';
import clockOutline from '@iconify/icons-eva/clock-outline';
import TextEdit from '../../shared/TextEdit'
import { ServiceItemList } from '../../maintenance';
import { fDate } from '../../../utils/formatTime';
import { fNumber } from '../../../utils/formatNumber';
import { ServiceStatus } from '../../../@types/maintenance'
import { useGetServiceQuery, useDeleteServiceMutation, useUpdateServiceTaskMutation, useGetServiceInstanceTasksQuery, useUpdateServiceDescriptionMutation } from '../../../services/services'
import { useGetVehicleQuery } from '../../../services/vehicles'
import { ServiceWorkOrder } from '../../workorder'
import ConfirmDeleteModal from '../../shared/ConfirmDeleteModal'
import ProjectRouterLink from '../../shared/ProjectRouterLink'
import { ServiceStatusLabel } from '../../_maintenance'
import ModalContainer from '../../shared/modal/ModalContainer'


type VehicleServiceProps = {
  vehicleId: string;
};

export default function VehicleService({ vehicleId }: VehicleServiceProps) {

  const { serviceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [completeServiceModalOpen, setCompleteServiceModalOpen] = useState(false);

  // data
  const { data, isLoading } = useGetServiceQuery(serviceId);
  const { data: vehicle, isLoading: vehicleIsLoading } = useGetVehicleQuery(vehicleId);
  const { data: tasks = [] } = useGetServiceInstanceTasksQuery(serviceId);
  const [updateServiceTask] = useUpdateServiceTaskMutation();
  const [deleteService] = useDeleteServiceMutation();
  const [updateDescription] = useUpdateServiceDescriptionMutation();

  const handleConfirmDelete = async () => {
    try {
      await deleteService(serviceId).unwrap();
      setConfirmDeleteOpen(false);
      navigate("..");
    } catch (response) {
      var error = undefined;
      if (response.data.errors) {
        error = response.data.errors['serviceId'][0]
      }
      setConfirmDeleteOpen(false);
      enqueueSnackbar(error ?? "Failed to delete service", { variant: 'error', autoHideDuration: 4000 })
    }
  };

  if (vehicleIsLoading || isLoading) {
    return (
      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack direction="row" alignItems="center" onClick={() => navigate('..')} sx={{ cursor: 'pointer' }}>
              <Icon icon={chevronFill} style={{ fontSize: '30px' }} />
              <Typography variant="h6">All Vehicle Services</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Button
                variant="contained"
                disabled={true}
                startIcon={<Icon icon={checkmarkFill} />}
              >
                Complete Service
              </Button>
              <Button variant="outlined" color="error" sx={{ ml: 1 }} disabled={true}>
                Delete
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardHeader title={"Service Details"} />
            <CardContent>
              <Stack direction="column" justifyContent="space-between" flexGrow={1}>
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardHeader title={"Service Schedule"} />
            <CardContent sx={{ pt: 0, pb: 0 }}>
              <Stack direction="column">
                <Skeleton width="100%" height={100} />
              </Stack>
            </CardContent>
            <CardHeader title={"Work Order"} />
            <CardContent sx={{ pt: 0, pb: 0 }}>
              <Stack direction="column">
                <Skeleton width="100%" height={100} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  if (!data || !vehicle) {
    return null
  }

  return (
    <Grid container spacing={{ xs: 2, md: 4 }}>
      <Grid item xs={12} sm={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack direction="row" alignItems="center" onClick={() => navigate('..')} sx={{ cursor: 'pointer' }}>
            <Icon icon={chevronFill} style={{ fontSize: '30px' }} />
            <Typography variant="h6">All Vehicle Services</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Button
              variant="contained"
              disabled={data.status !== ServiceStatus.Scheduled}
              onClick={() => setCompleteServiceModalOpen(true)}
              startIcon={<Icon icon={checkmarkFill} />}
            >
              Complete Service
            </Button>
            <Button variant="outlined" color="error" sx={{ ml: 1 }} onClick={() => setConfirmDeleteOpen(true)}>
              Delete
            </Button>
          </Stack>
        </Box>
        <ConfirmDeleteModal
          confirmTitle="Confirm Delete Service"
          confirmMessage="Deleting this service is an irreversible action, please confirm you would like to proceed"
          open={confirmDeleteOpen}
          onCancel={() => setConfirmDeleteOpen(false)}
          onConfirm={handleConfirmDelete}
        />
      </Grid>
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
              <CardHeader
                title={"Service Details"}
                action={<Box sx={{ pr: 1, pt: 1 }}><ServiceStatusLabel status={data.status} size={14} /></Box>} />
              <CardContent sx={{ py: 1 }}>
                <Stack spacing={0.5}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Service ID</Typography>
                    <Typography variant="subtitle2">{data.slug}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Title</Typography>
                    <Typography variant="subtitle2">{data.title}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Completion Date</Typography>
                    <Typography variant="subtitle2">{data.completionDate ? fDate(data.completionDate) : '-'}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Completion Mileage</Typography>
                    <Typography variant="subtitle2">{data.completionMileage ? `${fNumber(data.completionMileage)} km's` : '-'}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Mechanic</Typography>
                    <Typography variant="subtitle2">-</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Service Plan</Typography>
                    <ProjectRouterLink to={`../../../../../../../maintenance/service-plans/${data.servicePlan?.id}`}><Typography variant="subtitle2">{data.servicePlan?.slug} - {data.servicePlan?.title}</Typography></ProjectRouterLink>
                  </Stack>

                </Stack>
              </CardContent>
              <CardHeader title={"Service Description"} sx={{ pt: 1 }} />
              <CardContent>
                <TextEdit
                  id={`service-description-${data.id}`}
                  value={data.description}
                  placeholder="Add service description here.."
                  onValueUpdated={async (value: string) => {
                    if (value !== data.description) {
                      await updateDescription({ serviceId: data.id, description: value }).unwrap();
                    }
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
              <CardHeader title={"Service Schedule"} />
              <CardContent sx={{ pt: 1, pb: 0.5 }}>
                <Typography sx={{ mb: 1 }} variant="body2">{data.servicePlan && data.servicePlan.schedule ? 'This service is controlled by the schedule on the associated service plan' : 'This serivce is not controlled by a schedule'}</Typography>
                <Paper sx={{ p: 1, flexGrow: 1, bgcolor: 'background.neutral' }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', m: 0.5 }}>
                    <Box sx={{ mr: 1 }}>
                      <Stack direction="row" justifyContent="flex-start" >
                        <Icon icon={clockOutline} style={{ fontSize: '20px' }} />
                        <Typography variant="body2" sx={{ pl: 1 }}>{data.servicePlan && data.servicePlan.schedule ? `Every ${fNumber(data.servicePlan.schedule?.mileageInterval || 0)} km's` : 'No schedule set'}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="flex-start">
                        <Icon icon={flagOutline} style={{ fontSize: '20px' }} />
                        <Typography variant="body2" sx={{ pl: 1 }}>{data.dueMileage ? `Due at ${fNumber(data.dueMileage)} km\'s` : 'N/A'}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="flex-start">
                        <Icon icon={calendarOutline} style={{ fontSize: '20px' }} />
                        <Typography variant="body2" sx={{ pl: 1 }}>{data.date ? `Due on ${fDate(data.date)}` : 'No service date set'}</Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Paper>
              </CardContent>
              <CardHeader title={"Work Order"} />
              <CardContent sx={{ pt: 1 }}>
                <ServiceWorkOrder service={data} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card>
          <CardHeader title={"Service Tasks"} />
          <CardContent>
            <Paper sx={{ p: 1, flexGrow: 1, bgcolor: 'background.neutral' }}>
              {tasks ? tasks.map((task) => {
                return (
                  <>
                    <Box key={`service-instance-task-${task.taskId}`}>
                      <Checkbox
                        disabled={data.status !== ServiceStatus.Scheduled}
                        checked={task.completed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          updateServiceTask({
                            serviceId: serviceId,
                            taskId: task.taskId,
                            complete: event.target.checked,
                          })
                        }
                      />
                      {task.title}
                    </Box>
                    {/* <UserAvatar id={task.completedBy || ''} /> */}
                  </>
                )
              }) : <Typography variant="subtitle2">No service tasks to complete</Typography>}
            </Paper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card>
          <CardHeader
            title={"Service Items"}
          />
          <CardContent>
            <ServiceItemList
              serviceId={data.id}
              disabled={data.status === ServiceStatus.Complete || data.status === ServiceStatus.Cancelled}
            />
          </CardContent>
        </Card>
      </Grid>
      <ModalContainer
        title="Complete Service"
        open={completeServiceModalOpen}
        component={
          <Box>
            <Paper sx={{ p: 1.5, mb: 2, flexGrow: 1, bgcolor: 'background.neutral' }}>
              <Typography variant="body2">Enter the date the service was completed and the mileage of the vehicle at the time of service.</Typography>
            </Paper>
            {/* <VehicleServiceUpdateForm
              service={data}
              vehicleMileage={vehicle?.odometer ?? 0}
              onServiceUpdated={() => setCompleteServiceModalOpen(false)}
            /> */}
          </Box>
        }
        handleConfirmModalClose={() => setCompleteServiceModalOpen(false)}
      />
    </Grid>
  );
}
