import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Button, Typography, Stack, Paper } from '@material-ui/core';
import { Icon } from '@iconify/react';
import ProjectRouterLink from '../shared/ProjectRouterLink';
import { Service } from '../../@types/maintenance'
import { WorkOrderStatus, WorkItemType } from '../../@types/workorder'
import { useAddWorkOrderMutation, useDeleteWorkOrderServiceMutation } from '../../services/workorders'
import { useGetServiceWorkOrderQuery } from '../../services/services'
import ConfirmDeleteModal from '../shared/ConfirmDeleteModal'
import { WorkOrderStatusLabel } from '../_workorder'
import trashFill from '@iconify/icons-eva/trash-fill';

export default function ServiceWorkOrder({ service }: { service: Service }) {

    const { palette } = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [addWorkOrder] = useAddWorkOrderMutation();
    const [removeWorkOrder] = useDeleteWorkOrderServiceMutation();
    const { data: workOrder, isLoading, isFetching } = useGetServiceWorkOrderQuery(service.id);

    const handleConfirmDelete = async () => {
        await removeWorkOrder({
            workOrderId: workOrder?.id as string,
            serviceId: service.id,
        });
        enqueueSnackbar("Work order removed from service", { variant: "success" })
        setConfirmDeleteOpen(false);
    };

    const handleCreate = async () => {
        await addWorkOrder({
            vehicleId: service.vehicleId,
            date: service.completionDate ?? new Date(),
            items: [{
                id: service.id,
                type: WorkItemType.Service,
            }]
        }).unwrap();
    }

    if (isLoading || isFetching) {
        return (
            <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', height: '40px' }}>
            </Paper>
        )
    }

    if (!workOrder) {
        return (
            <Stack direction={{ default: 'row', xs: 'row', lg: 'row' }}>
                <Stack>
                    <Typography variant="body2" sx={{ mb: 2 }}>This service has no associated work order. You can create a new or add to an existing.</Typography>
                </Stack>
                <Stack>

                    <Button
                        fullWidth={false}
                        variant="outlined"
                        sx={{ ml: 1, minWidth: 160 }}
                        onClick={() => handleCreate()}
                    >
                        Create Work Order
                    </Button>
                </Stack>
            </Stack>
        )
    }

    return (
        <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row">
                    <ProjectRouterLink to={`../../../../../../../maintenance/work-orders/${workOrder.id}`}>
                        <Typography sx={{ mr: 1, pr: 2 }} variant="subtitle2">{workOrder.slug}</Typography>
                    </ProjectRouterLink>
                    <WorkOrderStatusLabel size={13} status={workOrder.status} />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    {workOrder.status === WorkOrderStatus.Pending && (
                        <Icon
                            icon={trashFill}
                            style={{ marginLeft: '5px', fontSize: '20px', cursor: 'pointer', color: palette.grey[500] }}
                            onClick={() => setConfirmDeleteOpen(true)} />)}
                </Stack>
            </Stack>
            <ConfirmDeleteModal
                confirmTitle="Remove Service from Work Order?"
                confirmMessage="This action will remove the service including all associated items from the given work order."
                open={confirmDeleteOpen}
                onCancel={() => setConfirmDeleteOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </Paper>
    )
}
