import { useTheme } from '@material-ui/core/styles';
import  Label, { LabelProps } from '../shared/Label';
import { sentenceCase } from 'change-case';
import { WorkOrderStatus } from '../../@types/workorder'

function WorkOrderStatusLabel({ status, size = 12, sx }: { status: WorkOrderStatus | undefined, size?: number, sx?: LabelProps }) {
    
    const theme = useTheme();
  
    return (
        <Label
            {...sx}
            fontSize={size}
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
                (status === WorkOrderStatus.Pending && 'warning') ||
                'success'
            }
        >
            {sentenceCase(status ?? '-')}
      </Label>
    );
  }
  
  export default WorkOrderStatusLabel;

