import { useState } from 'react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { styled } from '@material-ui/core';
import { Box, Tooltip, IconButton, DialogActions, Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Invoice } from '../../@types/invoice';
import Iconify from '../shared/Iconify';
import { DialogAnimate } from '../animate';
import InvoicePDF from './InvoicePDF';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(5),
}));


type Props = {
  invoice: Invoice;
};

export default function InvoiceToolbar({ invoice, ...other }: Props) {
  const [openPDF, setOpenPDF] = useState(false);

  const handleOpenPreview = () => {
    setOpenPDF(true);
  };

  const handleClosePreview = () => {
    setOpenPDF(false);
  };

  return (
    <RootStyle {...other}>
      <Button
        color="info"
        size="small"
        variant="contained"
        onClick={handleOpenPreview}
        endIcon={<Iconify icon={'eva:eye-fill'} />}
        sx={{ mx: 1 }}
      >
        Preview
      </Button>

      <DialogAnimate fullScreen open={openPDF}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={handleClosePreview}>
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </Tooltip>
          </DialogActions>
        </Box>
      </DialogAnimate>
    </RootStyle>
  );
}
