import { createContext, ReactNode } from 'react';
import { SubscriptionPlan } from '../@types/subscription';
import { useGetProjectSubscriptionQuery } from '../services/projects'
import ErrorComponent from '../components/shared/error/ErrorComponent';

export type SubscriptionContextType = {
    subscription: SubscriptionPlan;
};

const SubscriptionContext = createContext<SubscriptionContextType | null>(null);

function SubscriptionProvider({ identifier, children }: { identifier: string, children: ReactNode }) {
    
  const { isLoading, error, data: subscription } = useGetProjectSubscriptionQuery(identifier);

  if (isLoading) {
    return <></>
  }

  if (!subscription) {
    return <ErrorComponent
      title="We can't seem to load the subscription for this project..."
      description="This is likely an issue on our end, so please try your request again and if the problem persists reach out to support."
    />;
  }

  return (
    <SubscriptionContext.Provider value={{ subscription }}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export { SubscriptionContext, SubscriptionProvider };
