import { Link as RouterLink } from 'react-router-dom';
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Grid, Typography, CardContent } from '@material-ui/core';
import { PATH_GROUP } from '../../routes/paths';
import { fDate } from '../../utils/formatTime';
import { Vehicle } from '../../@types/vehicle';
import { Group } from '../../@types/group';
import SvgIconStyle from '../shared/SvgIconStyle';
import { getImagePath } from './vehicleImages'

const CardMediaStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: 'calc(100% * 2 / 4)'
}));

const TitleStyle = styled(RouterLink)(({ theme }) => ({
  ...theme.typography.subtitle1,
  height: 44,
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const DetailStyle = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  height: 30,
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
}));

const CoverImgStyle = styled('img')(({ theme }) => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
}));

type VehicleCardProps = {
  vehicle: Vehicle;
  group: Group | null;
  index: number;
};

export default function VehicleCard({ vehicle, index, group }: VehicleCardProps) {
  const { id, vehicleIdentifier: registration, make, model, updated } = vehicle;
  const linkTo = `${PATH_GROUP.root}/${group?.identifier}/fleet/vehicles/${id}`;
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;
  var imagePath = getImagePath(vehicle.make);
  
  return (
    <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              ...((latestPostLarge || latestPost) && { display: 'none' })
            }}
          />
          {/* <AvatarStyle
            alt={vehicle.make}
            src={'https://vectorlogo4u.com/wp-content/uploads/2020/11/Isuzu-logo-Vector.png'}
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 100,
                height: 0
              })
            }}
          /> */}

          <CoverImgStyle alt={'Background image'} src={imagePath} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
            ...((latestPostLarge || latestPost) && {
              bottom: 0,
              width: '100%',
              position: 'absolute'
            })
          }}
        >
          

          <TitleStyle
            to={linkTo}
            sx={{
              ...(latestPostLarge && { typography: 'h5', height: 60 }),
              ...((latestPostLarge || latestPost) && {
                color: 'common.white'
              })
            }}
          >
            {registration}
          </TitleStyle>
          <DetailStyle
            sx={{
              ...(latestPostLarge && { typography: 'p1', height: 30 }),
              ...((latestPostLarge || latestPost) && {
                color: 'common.white'
              })
            }}
          >
            {make}
          </DetailStyle>
          <DetailStyle
            sx={{
              ...(latestPostLarge && { typography: 'p1', height: 30 }),
              ...((latestPostLarge || latestPost) && {
                color: 'common.white'
              })
            }}
          >
            {model}
          </DetailStyle>
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            Updated: {fDate(updated)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
