import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Alert, InputAdornment, Collapse } from '@material-ui/core';
import MNumberTextField from '../../@material-extend/MNumberTextField';
import { LoadingButton, DatePicker } from '@material-ui/lab';
import * as Yup from 'yup';
import { Project } from '../../../@types/project';
import { Vehicle, VehicleType } from '../../../@types/vehicle';
import { ComplianceType } from '../../../@types/compliance';
import { ComplianceTypeSelectList } from '../../_vehicle'
import { useUpdateVehicleMutation } from '../../../services/vehicles'
import VehicleIdentifierInput from './components/VehicleIdentifierInput'
import VehicleTypeInput from './components/VehicleTypeInput'

type VehicleUpdateFormProps = {
  project: Project | undefined | null;
  onVehicleCreated?: (vehicle: Vehicle) => void;
  ownerEntity?: { id: string, name: string };
  vehicle: Vehicle;
};

type VehicleUpdateFormValues = {
  ownerEntityId: {
    id: string,
    name: string | null,
    type: string,
  } | null;
  vehicleIdentifier: string;
  complianceType: ComplianceType;
  complianceExpiryDate: Date | null;
  type: VehicleType;
  projectId: string;
  make: string;
  model: string;
  subModel: string;
  colour: string;
  engineCapacity: number | null;
  odometer: number | null;
  hubometer: number | null;
  hourmeter: number | null;
  engineNumber: string;
  chassisNumber: string;
  vehicleIdentificationNumber: string
  manufactureYear: number | null;
  manuallyEnterVehicleDetails: boolean;
};

export const FormSchema = Yup.object().shape({
  vehicleIdentifier: Yup.string().required('Serial number is required')
    .when('type', {
      is: "Vehicle",
      then: (schema) => schema.max(6, 'Registration must be 6 characters').required('Registration is required'),
    }),
  ownerEntityId: Yup.object()
    .required('Client is required'),
  complianceType: Yup.string()
    .required(),
  make: Yup.string()
    .required('Make is required')
    .min(2),
  model: Yup.string()
    .required('Model is required')
    .min(1),
  manufactureYear: Yup.string().nullable()
});

export default function VehicleUpdateForm({ vehicle, onVehicleCreated }: VehicleUpdateFormProps) {

  const [updateVehicle] = useUpdateVehicleMutation();

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isSubmitting, isValid, isDirty, errors }
  } = useForm<VehicleUpdateFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      ownerEntityId: { id: vehicle.client.id, name: vehicle.client.name },
      vehicleIdentifier: vehicle.vehicleIdentifier,
      make: vehicle.make ?? undefined,
      model: vehicle.model ?? undefined,
      subModel: vehicle.subModel ?? undefined,
      colour: vehicle.colour ?? undefined,
      type: vehicle.type  ?? VehicleType.Vehicle,
      complianceType: vehicle.compliance?.type,
      engineCapacity: vehicle.engineCapacity ?? undefined,
      odometer: vehicle.odometer ?? undefined,
      hubometer: vehicle.hubometer ?? undefined,
      hourmeter: vehicle.hourmeter ?? undefined,
      engineNumber: vehicle.engineNumber ?? undefined,
      chassisNumber: vehicle.chassisNumber ?? undefined,
      vehicleIdentificationNumber: vehicle.vehicleIdentificationNumber ?? undefined,
      manufactureYear: vehicle.manufactureYear ?? undefined,
      manuallyEnterVehicleDetails: false,
      complianceExpiryDate: vehicle.compliance?.expiryDate ?? null,
    }
  })

  const onSubmit = async (data: VehicleUpdateFormValues) => {

    try {
      var updatedVehicle = await updateVehicle({
        ownerEntityId: data.ownerEntityId?.id || '',
        vehicleId: vehicle?.id || '',
        vehicleIdentifier: data.vehicleIdentifier,
        make: data.make,
        model: data.model,
        subModel: data.subModel,
        type: data.type,
        complianceType: data.complianceType,
        complianceExpiryDate: data.complianceExpiryDate,
        colour: data.colour,
        engineCapacity: data.engineCapacity,
        odometer: data.odometer,
        hubometer: data.hubometer,
        hourmeter: data.hourmeter,
        engineNumber: data.engineNumber,
        chassisNumber: data.chassisNumber,
        vehicleIdentificationNumber: data.vehicleIdentificationNumber,
        manufactureYear: data.manufactureYear ?? undefined,
      }).unwrap()

      if (onVehicleCreated) {
        onVehicleCreated(updatedVehicle);
      }
    }
    catch (response) {
      if (response.data && response.data.errors) {
        Object.keys(response.data.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
            return response.data.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      } else {

      }
    }
  };

  const vehicleTypeWatch = watch('type');
  const complianceTypeWatch = watch('complianceType');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            <VehicleTypeInput
              name="type"
              control={control}
              sx={{ maxWidth: '40%' }}
            />
            <VehicleIdentifierInput
              name="vehicleIdentifier"
              control={control}
              vehicleType={vehicleTypeWatch}
            />
          </Stack>
          <Collapse in={vehicleTypeWatch === VehicleType.Vehicle}>
            <Stack direction="row" spacing={1}>
              <Controller
                name="complianceType"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <ComplianceTypeSelectList
                    field={field}
                  />
                )}
              />
              <Controller
                name="complianceExpiryDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={`Date of last ${complianceTypeWatch || 'compliance inspection'}`}
                    renderInput={(params) => (
                      <TextField
                        fullWidth {...params}
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                )}
              />
            </Stack>
          </Collapse>

          <Stack direction="row" spacing={1}>
            <Controller
              name="make"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Make"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />
            <Controller
              name="model"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Model"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
          <Controller
            name="subModel"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Sub-Model"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'off'}
              />
            )}
          />
          <Controller
              name="colour"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Colour"
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Controller
              name="odometer"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MNumberTextField
                  {...field}
                  label="Odometer"
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kms</InputAdornment>,
                  }}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="hubometer"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MNumberTextField
                  {...field}
                  label="Hubometer"
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kms</InputAdornment>,
                  }}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="hourmeter"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MNumberTextField
                  {...field}
                  label="Hourmeter"
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
                  }}
                  helperText={error?.message}
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Controller
              name="manufactureYear"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MNumberTextField
                  fullWidth
                  {...field}
                  label="Manufacture Year"
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            
          </Stack>
          <Stack direction="row" spacing={1}>
            <Controller
              name="vehicleIdentificationNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="VIN Number"
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />
            <Controller
              name="chassisNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Chassis Number"
                  error={Boolean(error)}
                  helperText={error?.message}
                  autoComplete={'off'}
                />
              )}
            />
          </Stack>
          <Controller
            name="engineNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Engine Number"
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'off'}
              />
            )}
          />
          <Controller
            name="engineCapacity"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Engine Capacity"
                type="number"
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'off'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">CC</InputAdornment>,
                }}
              />
            )}
          />
          {
            errors.projectId && <Alert severity="error" sx={{ mb: 3 }}>{errors.projectId.message}</Alert>
          }
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid || !isDirty}
          >
            Update Vehicle
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
