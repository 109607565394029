import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack5';
import { Stack, TextField, MenuItem, Alert } from '@material-ui/core';
import { LoadingButton, DatePicker } from '@material-ui/lab';
import * as Yup from 'yup';
import { useDispatch } from '../../../redux/store';
import { toggleEditOpen } from '../../../redux/slices/compliance';
import { ComplianceStatus, Compliance } from '../../../@types/compliance'
import { useUpdateComplianceMutation } from '../../../services/vehicles'

type ComplianceUpdateFormProps = {
  vehicleId: string;
  compliance: Compliance;
};

type ComplianceUpdateFormValues = {
  lastComplianceInspectionDate: Date | null;
  complianceExpiryDate: Date | null;
  newStatus: ComplianceStatus;
  note: string;
};

export const FormSchema = Yup.object().shape({
  complianceExpiryDate: Yup.date().required('Compliance Expiry Date is required')
});

export default function ComplianceUpdateForm({ compliance, vehicleId }: ComplianceUpdateFormProps) {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    watch,
    control,
    setError,
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useForm<ComplianceUpdateFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      complianceExpiryDate: compliance.expiryDate,
      note: compliance.note,
    }
  })

  const statusValue = watch('newStatus');
  const complianceExpiryDateValue = watch('complianceExpiryDate');
  const [updateCompliance] = useUpdateComplianceMutation();

  const onSubmit = async (data: ComplianceUpdateFormValues) => {

    try {
      // dispatch the compliance update
      await updateCompliance({
        vehicleId: vehicleId,
        complianceExpiryDate: data.complianceExpiryDate,
        lastComplianceInspectionDate: data.lastComplianceInspectionDate,
        newStatus: data.newStatus,
        note: data.note,
      }).unwrap();

      enqueueSnackbar('Update success', { variant: 'success', autoHideDuration: 1000 });
      dispatch(toggleEditOpen(false))
    } catch (response) {
      // process for errors
      console.log(response)
      if (response.data && response.data.errors) {
        const apiErrors = response.data.errors;
        Object.keys(apiErrors).forEach((key: any) => {
          let errors: string[] = Object.keys(apiErrors[key]).map((errorKey: string) => {
            return apiErrors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      }
      else {
        enqueueSnackbar('Error occurred updating compliance', { variant: 'error' });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Controller
            name="complianceExpiryDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                label="Expiry Date"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    fullWidth {...params}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
          {
            complianceExpiryDateValue && complianceExpiryDateValue < new Date() && statusValue !== ComplianceStatus.Expired ?
              <Alert severity="warning" sx={{ mb: 3 }}>
                Compliance expiry date is in the past and the compliance will be marked as expired
              </Alert>
              : null
          }
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                rows={3}
                fullWidth
                multiline
                placeholder="Add a note or reminder to this compliance"
                label="Compliance Note"
              />
            )}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting}
          >
            Update Compliance
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
