import { useEffect, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form';
import ModalContainer from '../shared/modal/ModalContainer'
import { lighten, darken } from '@material-ui/system';
import { IClientSearchCriteria, ClientStatus } from '../../@types/client'
import ClientCreateForm from './form/ClientCreateForm'
import { TextField, Avatar, ListItemAvatar, Typography, Stack } from '@material-ui/core';
import { useGetClientsQuery } from '../../services/clients'
import useProject from '../../hooks/useProject'
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';
import { styled } from '@material-ui/core/styles';

type ClientSelectListControlledProps = {
  label?: string;
  initial?: ClientOptionType | null;
  field: ControllerRenderProps;
};

export type ClientOptionType = {
  id?: string;
  category?: string;
  name: string;
  description?: string;
  status?: ClientStatus | null;
  imageUrl?: string;
  inputValue?: string;
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.grey[500], 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const filter = createFilterOptions<ClientOptionType>();

export default function ClientSelectListControlled({ label = 'User or Email', field }: ClientSelectListControlledProps) {

  const { project } = useProject();
  const [selectedItem, setSelectedItem] = useState('');
  const [value, setValue] = useState<ClientOptionType | null>(field.value);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newClientName, setNewClientName] = useState('');
  const [search, setSearch] = useState<IClientSearchCriteria>({
    pageNumber: 0,
    pageSize: 50,
    orderBy: 'updated',
    order: 'desc',
    text: '',
    activeOnly: true
  });

  const { data = { entities: [] }, isLoading } = useGetClientsQuery(search);
  const handleChangeSearch = async (value: string) => {
    setSearch({
      ...search,
      text: value,
      // return inactive clients when searching to show to the user
      activeOnly: value.length === 0
    });
  };

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <>
      <Autocomplete
        value={value}
        fullWidth
        selectOnFocus
        disabled={false}
        clearOnBlur
        handleHomeEndKeys
        inputValue={selectedItem}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionDisabled={option => option.status && option.status !== ClientStatus.Active || false}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        loading={isLoading}
        multiple={false}
        options={data.entities.map((task: any) => {
          var opt: ClientOptionType = {
            id: task.id,
            name: task.name,
            status: task.status,
            description: task.emailAddress ?? '-',
            imageUrl: '',
          }
          return opt;
        })}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader><Typography variant="subtitle2">{params.group}</Typography></GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        renderOption={(props, option) => {
          return (
            <li key={option.id} {...props}>
              <span>
                <ListItemAvatar>
                  <Avatar alt={option.name} src={option.imageUrl} />
                </ListItemAvatar>
              </span>
              {option.description ?
                <Stack direction="column">
                  <Typography variant="subtitle1">{option.name}</Typography>
                  <Typography variant="body2">{option.description}</Typography>
                </Stack>
                : <Typography variant="body1">{option.name}</Typography>}
            </li>
          )
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        onInputChange={(event, value, reason) => {
          setSelectedItem(value);
          handleChangeSearch(value);
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setNewClientName(newValue);
            setCreateModalOpen(true);
            setValue({
              name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setNewClientName(newValue.inputValue);
            setCreateModalOpen(true);
            setValue({
              name: newValue.inputValue,
            });
          } else {
            setValue(newValue);
            if (newValue) {
              field.onChange({
                id: newValue?.id || '',
                name: newValue?.name,
                type: ''
              });
            } else {
              field.onChange(null)
            }
          }
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            label={label}
          />
        }
      />
      <ModalContainer
        open={createModalOpen}
        title="Add Customer Details"
        key=''
        handleConfirmModalClose={() => {
          setNewClientName('');
          setValue(null);
          field.onChange(null);
          setCreateModalOpen(false);
        }}
        component={
          <ClientCreateForm
            projectId={project.id}
            name={newClientName}
            onCreated={(client) => {
              field.onChange({
                id: client.id,
                name: client.name,
                type: ''
              });
              setCreateModalOpen(false);
            }}
          />}
      />
    </>
  );
}