import { TextField } from '@material-ui/core';
import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import { VehicleType } from '../../../../@types/vehicle'

type VehicleRegistrationProps<T extends FieldValues> = {
    vehicleType: VehicleType;
    control: Control<T>;
    name: Path<T>;
}

const VehicleIdentifierInput = <T extends FieldValues>({ control, name, vehicleType }: VehicleRegistrationProps<T>) => {

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    label={vehicleType == VehicleType.Vehicle ? `Registration` : `Serial Number`}
                    error={Boolean(error)}
                    helperText={error?.message}
                    autoComplete={'off'}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                />
            )}
        />
    )
}

export default VehicleIdentifierInput;
