import { Autocomplete, TextField } from '@material-ui/core';
import { InvoiceStatus } from '../../@types/invoice'

type InvoiceStatusMultiSelectProps = {
    onStatusListChanged?: (statusList: InvoiceStatus[]) => void;
    selectedValue: InvoiceStatus[];
};

const options : InvoiceStatus[] = [
    InvoiceStatus.Authorised,
    InvoiceStatus.Deleted,
    InvoiceStatus.Draft,
    InvoiceStatus.Paid,
    InvoiceStatus.Submitted,
    InvoiceStatus.Pending,
    InvoiceStatus.Voided,
];

export default function InvoiceStatusMultiSelect({ onStatusListChanged, selectedValue }: InvoiceStatusMultiSelectProps) {

  return (
    <Autocomplete
        multiple
        fullWidth
        options={options}
        value={selectedValue}
        getOptionLabel={(option) => option}
        defaultValue={[InvoiceStatus.Authorised, InvoiceStatus.Draft]}
        filterSelectedOptions
        renderOption={(props, option) => <li {...props}>{option}</li>}
        renderInput={(params) => (
            <TextField {...params} label="Invoice Status" />
        )}
        onChange={(event, newValue) => {
            onStatusListChanged && onStatusListChanged(newValue);
        }}
    />
  );
}
