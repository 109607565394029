import { Stack, CardHeader, Card, CardContent, Typography, Button, Skeleton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Compliance, ComplianceType } from '../../../@types/compliance'
import { fDateShort2 } from '../../../utils/formatTime'
import { useDispatch } from '../../../redux/store';
import { editCompliance } from '../../../redux/slices/compliance';
import { ComplianceEditModal } from '../index'
import ComplianceDateLabel from '../../_vehicle/ComplianceDateLabel'
import vehicle from 'redux/slices/vehicle';

type ComplianceDetailProps = {
  vehicleId: string;
  compliance: Compliance | null;
  loading: boolean;
  disabled?: boolean;
};

export default function ComplianceDetail({ vehicleId, compliance, loading, disabled = false }: ComplianceDetailProps) {
  const dispatch = useDispatch();

  if (!compliance || loading) {
    return (
      <Card sx={{ mb: 4 }}>
        <CardHeader title="Compliance Information" />
        <CardContent>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Compliance Expiry Date</Typography>
              <Typography variant="subtitle2" sx={{ width: 130 }}><Skeleton /></Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Updated</Typography>
              <Typography variant="subtitle2" sx={{ width: 130 }}><Skeleton /></Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Latest Note</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" sx={{
              p: 2,
              width: 1,
              borderRadius: 1,
              bgcolor: 'background.neutral'
            }}>
              <Typography variant="subtitle2"><Skeleton /></Typography>
            </Stack>
          </Stack>
          <Stack spacing={3} alignItems="flex-end" sx={{ pt: 3 }}>
            <Button size="small" disabled={disabled} startIcon={<Icon icon={editFill} />} onClick={() => dispatch(editCompliance())}>
              Edit Compliance
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 4 }}>
      <CardHeader title="Compliance Information" />
      <CardContent>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>{compliance ? compliance.type == ComplianceType.Unknown ? 'Compliance Expiry Date' : `${compliance.type} Expiry Date` : 'Compliance Expiry Date'}</Typography>
            <ComplianceDateLabel compliance={compliance} />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Compliance Type</Typography>
            <Typography variant="subtitle2">{compliance.type}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Updated</Typography>
            <Typography variant="subtitle2">{fDateShort2(compliance.updated)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Latest Note</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" sx={{
            mt: 1,
            p: 2,
            width: 1,
            borderRadius: 1,
            bgcolor: 'background.neutral'
          }}>
            <Typography variant="subtitle2">{compliance.note || '-'}</Typography>
          </Stack>
        </Stack>
        <Stack spacing={3} alignItems="flex-end" sx={{ pt: 3 }}>
          <Button size="small" disabled={disabled} startIcon={<Icon icon={editFill} />} onClick={() => dispatch(editCompliance())}>
            Edit Compliance
          </Button>
        </Stack>
        <ComplianceEditModal vehicleId={vehicleId} compliance={compliance} />
      </CardContent>
    </Card>
  );
}
