import { useState, useEffect } from 'react'
import { ControllerRenderProps } from 'react-hook-form';
import axios from '../../utils/axios';
import NewSelectList from '../shared/list/NewSelectList'

type ServicePlanSelectListProps = {
    field: ControllerRenderProps;
    onSelected?(id: any): void;
};

export default function ServicePlanSelectList({ field }: ServicePlanSelectListProps) {

    const [items, setItems] = useState([]);
    const handleChangeSearch = async (value: string) => {
        try {
            var response = await axios.post<any, any>(`/api/service-plans/search`, {
                pageNumber: 0,
                pageSize: 15,
                orderBy: 'title',
                order: 'asc',
                text: value ?? null,
            });
            
            setItems(response.data.results.map((task: any) => {
                return {
                    id: task.id,
                    name: task.title,
                }
            }));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // initialise the select options
        (async () => handleChangeSearch(''))();
    }, []);
    
    return (
        <NewSelectList
            field={field}
            multiple={false}
            options={items}
            handleSearch={handleChangeSearch}
            label="Service Plan"
            error={null}
        />
    );
}
