import { useTheme } from '@material-ui/core/styles';
import Label, { LabelProps } from '../shared/Label';
import { sentenceCase } from 'change-case';
import { ClientStatus } from '../../@types/client'

function ClientStatusLabel({ status, size = 12, sx }: { status: ClientStatus | undefined, size?: number, sx?: LabelProps }) {
    const theme = useTheme();

    return (
        <Label
            sx={{ ...sx }}
            fontSize={size}
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
                (status === ClientStatus.Archived && 'default') ||
                'success'
            }
        >
            {status ? sentenceCase(status) : 'Default'}
        </Label>
    );
}

export default ClientStatusLabel;

