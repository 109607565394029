import { alpha, styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ClientStatusLabel from '../_client/ClientStatusLabel'
import ClientAvatar from './ClientAvatar';
import { ClientSummary } from '../../@types/client';

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    top: 0,
    zIndex: 9,
    width: '100%',
    content: "''",
    height: '100px',
    position: 'absolute',
    backgroundColor: alpha(theme.palette.grey[300], 0.72),
  }
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: theme.spacing(3),
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(3),
  right: 'auto',
  display: 'flex',
  alignItems: 'center',
}));

type ClientCoverProps = {
  client: ClientSummary | null;
};

export default function ClientCover({ client }: ClientCoverProps) {

  return (
    <RootStyle>
      <InfoStyle>
        <ClientAvatar
          name={client?.name || ''}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: 60,
            height: 60
          }}
        />
        <Box
          sx={{
            ml: { xs: 2, md: 2 },
            mt: { xs: 1, md: 0 },
            textAlign: 'left'
          }}
        >
          <Typography variant="h4" >{client?.name}</Typography>
          <ClientStatusLabel status={client?.status} size={16} />
        </Box>
      </InfoStyle>
      {/* <CoverImgStyle alt="profile cover" src={cover} /> */}
    </RootStyle>
  );
}
