import { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import actionLinkFill from '@iconify/icons-eva/link-2-fill';
import { Menu, Divider, MenuItem, Typography } from '@material-ui/core';
import { MIconButton } from '../@material-extend';

type WorkOrderItemActionMenuProps = {
  deleteText?: string;
  showEdit: boolean;
  onOpenEdit() : void;
  onDelete() : void;
}

function WorkOrderItemActionMenu({ onOpenEdit, onDelete, showEdit, deleteText = 'Delete' } : WorkOrderItemActionMenuProps) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleActionClickClick = (clickFunction: () => void) => {
      clickFunction();
      setOpen(false);
    };
  
    return (
      <>
        <MIconButton ref={anchorRef} size="large" onClick={handleOpen} sx={{ padding: 0.8 }}>
          <Icon icon={moreVerticalFill} width={15} height={15} />
        </MIconButton>
        <Menu
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          { showEdit && (<>
            <MenuItem onClick={() => handleActionClickClick(onOpenEdit)}>
            <Icon icon={editFill} width={20} height={20} />
            <Typography variant="body2" sx={{ ml: 2 }}>
              Edit
            </Typography>
          </MenuItem>
          <Divider />
          </>)}
          <MenuItem onClick={() => handleActionClickClick(onDelete)} sx={{ color: 'error.main' }}>
            <Icon icon={trash2Outline} width={20} height={20} />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {deleteText}
            </Typography>
          </MenuItem>
        </Menu>
      </>
    );
  }

  export default WorkOrderItemActionMenu;