import { motion } from 'framer-motion';
import { styled } from '@material-ui/core/styles';
import { Box, Typography, Container } from '@material-ui/core';
import { MotionContainer, varBounceIn } from '../../animate';
import Page from '../../shared/Page';
import { PageNotFoundIllustration } from '../../../assets';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

export default function ComplianceNotFound({ vehicleId } : { vehicleId?: string }) {

    return (
        <RootStyle title="Compliance Not Found | AutoHive">
        <Container>
            <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                <motion.div variants={varBounceIn}>
                <Typography variant="h3" paragraph>
                    Oops.. we cant find that compliance!
                </Typography>
                </motion.div>
                <Typography sx={{ color: 'text.secondary' }}>
                Sorry, we couldn’t find the compliance you are looking for. It looks like the compliance may have been deleted. If you 
                need help please contact support
                </Typography>

                <motion.div variants={varBounceIn}>
                <PageNotFoundIllustration sx={{ height: 150, my: { xs: 5, sm: 10 } }} />
                </motion.div>

                {/* <Button onClick={() => navigate(`groups/sme/fleet/vehicles/${vehicleId}/detail`)} size="large" variant="contained" >
                Go to vehicle
                </Button> */}
            </Box>
            </MotionContainer>
        </Container>
        </RootStyle>
    );
}
