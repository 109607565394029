import { Stack, Typography, Box } from '@material-ui/core';
import { fDateTime } from '../../utils/formatTime';
import { useGetUserQuery } from '../../services/users';

type LastUpdatedComponentProps = {
    userId?: string;
    updated: Date | null
}

export default function LastUpdatedComponent({ userId, updated }: LastUpdatedComponentProps) {

    return (
        <Box justifyContent="flex-end" sx={{ display: 'flex', mt: 2 }}>
            <Typography variant="caption">Last updated: {updated ? fDateTime(updated) : '-'}</Typography>
        </Box>
    );
}
