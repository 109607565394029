import { useState, useEffect } from 'react'
import VehicleSelectListControlled, { VehicleOptionType } from './VehicleSelectListControlled'
import { Skeleton, Stack } from '@material-ui/core';
import { ControllerRenderProps } from 'react-hook-form';
import { useGetVehicleQuery } from '../../services/vehicles';
import { VehicleStatus } from '../../@types/vehicle';


type VehicleSelectListProps = {
    vehicleId: string;
    field: ControllerRenderProps;
    label?: string;
    restrictToClient?: { id: string, name: string | null };
    onChange(value: VehicleOptionType | null): void;
    error?: string | null;
}

export default function VehicleSelectList({ vehicleId, field, error, restrictToClient, label = 'Vehicle', onChange }: VehicleSelectListProps) {

    const { data: vehicle, isLoading } = useGetVehicleQuery(vehicleId, { skip: !vehicleId });
    const [value, setValue] = useState<VehicleOptionType | null>(null);

    useEffect(() => {
        setValue({
            id: vehicleId,
            enabled: vehicle?.status === VehicleStatus.Active,
            title: vehicle?.vehicleIdentifier || '',
            customer: vehicle?.client,
        })
    }, [vehicle]);

    useEffect(() => {
        if (!vehicleId) {
            setValue(null)
        }

    }, [vehicleId]);

    if (isLoading) {
        return (
            <Stack>
                <Skeleton height="60px" />
            </Stack>
        )
    }

    return (
        <VehicleSelectListControlled
            label={label}
            initialValue={value}
            restrictToClient={restrictToClient ? { id: restrictToClient.id, name: restrictToClient.name || '' } : null}
            excludedIds={[]}
            onChange={(value) => {
                setValue(value);
                onChange(value);
            }}
            field={field}
            error={error}
        />
    );
}