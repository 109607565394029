import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { SeverErrorIllustration, MaintenanceIllustration } from '../../../assets';

export default function ErrorComponent({ title, description, showMaintenance = false }: { title?: string, description?: string, showMaintenance?: boolean }) {
    return (
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
                {title ?? 'Something has gone wrong...'}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
                { description ?? 'Well this is a bit embarrassing, something has gone wrong on our end. Please try your request again and if the issue persists get in touch with support.'}
            </Typography>

            { showMaintenance ? <MaintenanceIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> : <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> }
            <Button to="/app/projects" size="large" variant="contained" component={RouterLink}>
                Go to Home
            </Button>
        </Box>
    );
}
