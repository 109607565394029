import { ReactElement } from 'react'
import { Typography, TextField, TableCell, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { DatePicker } from '@material-ui/lab';
import { Compliance, ComplianceStatus, IComplianceSearchCriteria } from '../../../../@types/compliance'
import { Vehicle } from '../../../../@types/vehicle'
import VeemTable, { TableHeader } from '../../../table/VeemTable';
import { ComplianceStatusMultiSelect, ComplianceStatusLabel } from '../../../../components/_vehicle'
import { fDateShort } from '../../../../utils/formatTime'
import { useNavigate } from 'react-router-dom';

const TABLE_HEAD = [
    {
        id: 'client',
        numeric: false,
        disablePadding: false,
        label: 'Client',
        sortable: true,
        width: 200
    },
    {
        id: 'vehicle.registration',
        numeric: false,
        disablePadding: false,
        label: 'Registration',
        sortable: true,
        width: 130
    },
    {
        id: 'vehicle.make',
        numeric: false,
        disablePadding: false,
        label: 'Make',
        sortable: true,
        width: 130
    },
    {
        id: 'vehicle.model',
        numeric: false,
        disablePadding: false,
        label: 'Model',
        sortable: true,
        width: 220
    },
    {
        id: 'complianceType',
        numeric: false,
        disablePadding: false,
        label: 'Type',
        sortable: false,
        width: 70
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sortable: true,
        width: 70
    },
    {
        id: 'complianceExpiryDate',
        numeric: false,
        disablePadding: false,
        label: 'Expires',
        sortable: true,
        width: 140
    },
    {
        id: 'tracked',
        numeric: false,
        disablePadding: false,
        label: 'Tracked',
        sortable: false,
    }
];

type ComplianceTableProps = {
    header?: TableHeader[];
    defaultRowsToDisplay?: number;
    vehicles: Vehicle[];
    projectIdentifier: string;
    totalRows: number;
    showSearch?: boolean;
    loading?:boolean;
    search: IComplianceSearchCriteria,
    handleChangePage(newPage: number): void;
    handleSearchChange(search: IComplianceSearchCriteria): void;
    handleSort(orderBy: string, order: 'asc' | 'desc'): void;
    handleChangePageSize(newRowsPerPage: number): void;
    renderTableRow?(row: Vehicle, selected: boolean, labelId: string): ReactElement;
}

export default function ComplianceTable({
    renderTableRow,
    header,
    vehicles,
    totalRows,
    search,
    loading = false,
    handleChangePage,
    handleSearchChange,
    handleChangePageSize,
    handleSort,
    projectIdentifier,
    defaultRowsToDisplay = 5,
    showSearch = false
}: ComplianceTableProps) {

    const navigate = useNavigate();
    const handleRowSelect = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, row: Vehicle) => {
        if (event.metaKey || event.ctrlKey) {
            const win = window.open(`/group/${projectIdentifier}/fleet/vehicles/${row.id}/compliance`, "_blank");
            win?.focus();
        }
        else {
            navigate(`/group/${projectIdentifier}/fleet/vehicles/${row.id}/compliance`)
        }
    };

    return (
        <>
            <Typography variant="body2" sx={{ mb: 3 }}>
                This search shows the latest compliance for each vehicle. The full compliance history is available on the complinaces tab for each vehicle
            </Typography>
            <Grid container spacing={3} sx={{ mb: 1 }}>
                <Grid item xs={12} md={3}>
                    <DatePicker
                        onChange={(value) => {
                            handleSearchChange({
                                ...search,
                                complianceExpiryFrom: value,
                            })
                        }
                        }
                        value={search?.complianceExpiryFrom}
                        label="Expiry From"
                        renderInput={(params) => (
                            <TextField fullWidth {...params} />
                        )}
                        inputFormat="dd/MM/yyyy"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DatePicker
                        onChange={(value) => handleSearchChange({
                            ...search,
                            complianceExpiryTo: value,
                        })}
                        value={search?.complianceExpiryTo}
                        label="Expiry To"
                        renderInput={(params) => (
                            <TextField fullWidth {...params} />
                        )}
                        inputFormat="dd/MM/yyyy"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ComplianceStatusMultiSelect
                        onStatusListChanged={(statusList) => handleSearchChange({
                            ...search,
                            statusList: statusList
                        })}
                        selectedValue={search.statusList}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        label="Show Tracked Compliances Only"
                        control={
                            <Checkbox
                                value={search.trackedOnly}
                                checked={search.trackedOnly}
                                onChange={(event) =>
                                    handleSearchChange({
                                        ...search,
                                        trackedOnly: event.target.checked,
                                    })
                                }
                            />
                        }
                    />
                </Grid>
            </Grid>
            <VeemTable<Vehicle>
                header={header ?? TABLE_HEAD}
                data={vehicles}
                page={search.pageNumber}
                loading={loading}
                totalRows={totalRows}
                rowsPerPage={search.pageSize}
                showSelectAll={false}
                getSelectedItemKey={(row: Vehicle) => row.id}
                order={search.order}
                orderBy={search.orderBy}
                handleChangePage={handleChangePage}
                handleSort={handleSort}
                onRowsPerPageChange={(newRowsPerPage: number) => handleChangePageSize(newRowsPerPage)}
                renderTableRow={(row: Vehicle, selected: boolean, labelId: string) => {

                    if (renderTableRow) {
                        return renderTableRow(row, selected, labelId)
                    }
                    else {
                        return (
                            <>
                                <TableCell onClick={(event) => handleRowSelect(event, row)}>{row.entity.name}</TableCell>
                                <TableCell onClick={(event) => handleRowSelect(event, row)}>{row.vehicleIdentifier}</TableCell>
                                <TableCell onClick={(event) => handleRowSelect(event, row)}>{row.make || '-'}</TableCell>
                                <TableCell onClick={(event) => handleRowSelect(event, row)}>{row.model || '-'}</TableCell>
                                <TableCell onClick={(event) => handleRowSelect(event, row)}>{row.complianceType}</TableCell>
                                <TableCell onClick={(event) => handleRowSelect(event, row)}>
                                    {/* <ComplianceStatusLabel status={row.compliance ? row.compliance.status : ComplianceStatus.Passed} /> */}
                                </TableCell>
                                <TableCell>{row.compliance?.expiryDate ? fDateShort(row.compliance.expiryDate) : '-'}</TableCell>
                                <TableCell>
                                    <Checkbox checked={row.compliance ? row.compliance.tracking.enabled : false} disabled={true} />
                                </TableCell>
                            </>
                        )
                    }
                }}
            />
        </>
    );
}
