import { useEffect, useState, useCallback } from 'react'
import { Typography, Skeleton, Stack } from '@material-ui/core';
import { Vehicle } from '../../@types/vehicle';
import useProject from '../../hooks/useProject';
import axios from '../../utils/axios';
import ProjectRouterLink from '../shared/ProjectRouterLink'

interface VehicleCardRowProps {
  vehicleId: string;
};

export default function VehicleCardRow({ vehicleId, ...other }: VehicleCardRowProps) {

    const { project } = useProject();
    const [vehicle, setVehicle] = useState<Vehicle | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const getVehicle = useCallback(async () => {
        try {
            const response = await axios.get(`/api/vehicle/${vehicleId}`);
            setLoading(false);
            setVehicle(response.data);
        } catch (error) {
          console.error(error);
        }
    }, [vehicleId]);

    useEffect(() => {
        getVehicle();
    }, [vehicleId, getVehicle])

    if (loading) {
        return (
            <Stack direction="row">
                <Skeleton animation="wave" variant="circular" width={40} height={40} sx={{ m: 1}}/>
                <Skeleton animation="wave" variant="text" width={'20%'} height={40}/>
                <Skeleton animation="wave" variant="text" width={'80%'} height={40}/>
            </Stack>
        )
    }

    if (!vehicle) {
        return (
            <Stack direction="row">
                <Typography>Error retrieving vehicle</Typography>
            </Stack> 
        )
    }

    return (
        <Stack direction="row" spacing={2} sx={{
            p: 2,
            width: 1,
            borderRadius: 1,
            bgcolor: 'background.neutral'
        }}>
            <ProjectRouterLink to={`/fleet/vehicles/${vehicle.id}/invoices`}><Typography variant="subtitle2">{vehicle.vehicleIdentifier}</Typography></ProjectRouterLink>
            <Typography>{vehicle?.make}</Typography>
            <Typography>{vehicle?.model}</Typography>
        </Stack>
    );
}
