import { Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from '@material-ui/core';
import React, { ReactNode } from 'react'
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-outline';
import { IconButton,Box } from '@material-ui/core';

type ModalContainerProps = DialogProps & {
    title: string;
    component: ReactNode;
    handleConfirmModalClose(): void;
    actions?: ReactNode;
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon icon={closeFill} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalContainer(props : ModalContainerProps) {
  
  const { title, open, component, actions, handleConfirmModalClose, maxWidth, ...other } = props;
  
  return (
      <Dialog 
        fullWidth
        open={open}
        onClose={handleConfirmModalClose}
        maxWidth={maxWidth}
      >
        <BootstrapDialogTitle id={title} onClose={handleConfirmModalClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1}}>
            {component}
          </Box>
        </DialogContent>
        { actions && (
        <DialogActions>
          {actions}
        </DialogActions>
        )}
      </Dialog>
  );
}
