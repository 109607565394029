import ModalContainer from '../../shared/modal/ModalContainer'
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import { toggleVehicleCreateModal } from '../../../redux/slices/vehicle';
import VehicleCreateForm from '../form/VehicleCreateForm'
import useProject from '../../../hooks/useProject';


export default function CreateVehicleModal() {
    
  const dispatch = useDispatch();
  const { project } = useProject();
  const { modalOpen, selectedOwnerEntity } = useSelector((state: RootState) => state.vehicle.create);

  return (
    <ModalContainer
        title="Create Vehicle"
        open={modalOpen}
        component={
          <VehicleCreateForm
            project={project}
            ownerEntity={selectedOwnerEntity}
            onVehicleCreated={() => dispatch(toggleVehicleCreateModal(false))}
          />
        }
        handleConfirmModalClose={() => dispatch(toggleVehicleCreateModal(false))}
      />
  );
}

