import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from '../../utils/axios';
import { NavigationConfig, NavigationState } from '../../@types/navigation';

const initialState: NavigationState = {
  initialized: false,
  config: [],
  error: null,
  loading: false,
};

export const loadNavigationConfiguration = createAsyncThunk('navigation/load', async (projectIdentifier: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<any, any>(`/api/projects/${projectIdentifier}/config/ui`);
      return response.data.menu as NavigationConfig[]
    } catch (error) {
      return rejectWithValue(error)
    }
  })

const slice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(loadNavigationConfiguration.fulfilled, (state, action) => {
      state.config = action.payload;
      state.initialized = true;
    })
  },
});

// Reducer
export default slice.reducer;

// selectors
export const selectNavigationState = (state: RootState) => state.navigation;
export const selectMenuConfig = createSelector(selectNavigationState, state => state.config)
