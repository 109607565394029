import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import { VehicleType } from '../../@types/vehicle'
import { ControllerRenderProps } from 'react-hook-form';

type VehicleTypeSelectListProps = TextFieldProps & {
    field: ControllerRenderProps;
    disabled?: boolean;
};

export default function VehicleTypeSelectList({ field, disabled, ...rest }: VehicleTypeSelectListProps) {

    return (
        <TextField
            {...field}
            {...rest}
            select
            fullWidth
            disabled={disabled}
            label="Type"
        >
            {Object.keys(VehicleType).map((key) => (
                    <MenuItem key={`vehicle-type-select-${key}`} value={key}>
                       {key}
                    </MenuItem>
                ))}
        </TextField>
    );
}
