import { useContext } from 'react';
import { SubscriptionContext } from '../contexts/SubscriptionContext';

const useSubscription = () => {
  const context = useContext(SubscriptionContext);

  if (!context) throw new Error('Subscription context must be use inside SubscriptionProvider');

  return context;
};

export default useSubscription;
