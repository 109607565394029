import { VehicleSummary } from './vehicle'
import { ServiceType } from './servicetype'
import { Checklist, ChecklistItemUpdateRequest, ChecklistItemCreateRequest, ChecklistItemsReorderRequest } from './checklist'
import { ISearchCriteria, ISearchResult } from './search'

export type Service = {
    id: string;
    vehicleId: string;
    servicePlanId: string;
    servicePlan?: ServicePlan;
    status: ServiceStatus;
    date?: Date;
    completionDate: Date;
    dueMileage?: number;
    completionMileage?: number;
    slug: string;
    title: string;
    description: string;
    updated: Date;
    total: number | null;
    subTotal: number | null;
    tasks: ServiceInstanceTask[];
    items: ServiceItem[];
    workOrderId: string | null;
    workOrderSlug: string | null;
    record?: ServiceRecord;
    vehicle?: VehicleSummary;
}

export type ServiceRecord = {
    id: string;
    jobId: string | null;
    dateDue?: Date | null;
    dateCompleted?: Date | null;
    odometerDue?: number | null;
    odometerCompleted?: number | null;
    hubometerDue?: number | null;
    hubometerCompleted?: number | null;
    hoursDue?: number | null;
    hoursCompleted?: number | null;
    serviceType: ServiceType;
    checklist: Checklist;
}

export type ServiceTask = {
    id: string;
    slug: string;
    title: string;
    description: string;
    updated: Date;
}

export type ServiceInstanceTask = {
    taskId: string;
    completed: boolean;
    completedBy?: string;
    slug: string;
    title: string;
    description: string;
    updated: Date;
}

export type ServiceTaskRequest = {
    projectIdentifier: string;
    serviceTaskId?: string;
    title: string
    description?: string;
}

export type ServiceItem = {
    itemId: string;
    inventoryItemId: string | null;
    slug: string;
    title: string;
    description: string;
    updated: Date;
    quantity: number;
    price: number;
    taxRate: number | null; 
    total: number | null;
    subTotal: number | null;
}

export type ServicePlan = {
    id: string;
    slug: string;
    title: string;
    description: string;
    updated: Date;
    autoCreateWorkOrder: boolean;
    schedule?: ServicePlanSchedule;
    tasks: ServiceTask[];
    items: ServiceItem[];
    status: ServicePlanStatus;
}

export type ServiceChecklistItemsUpdateRequest = {
    jobId: string;
    serviceId: string;
    serviceTypeId: string;
    items: ChecklistItemUpdateRequest[];
}

export type ServiceChecklistItemUpdateRequest = ChecklistItemUpdateRequest & {
    jobId: string;
    serviceId: string;
    serviceTypeId: string;
}

export type ServiceChecklistItemCreateRequest = ChecklistItemCreateRequest & {
    jobId: string;
    serviceId: string;
    serviceTypeId: string;
}

export type ServiceChecklistItemsReorderRequest = ChecklistItemsReorderRequest & {
    jobId: string;
    serviceId: string;
    serviceTypeId: string;
}

export type ServiceChecklistItemDeleteRequest = {
    jobId: string;
    serviceId: string;
    itemId: string;
}

export type ServicePlanSchedule = {
    updated: Date;
    mileageInterval?: number;
}

export type ServicePlanScheduleUpdateRequest = {
    servicePlanId: string;
    mileageInterval: number;
    applyToActiveServices: boolean;
}

export type ServicePlanVehicle = {
    id: string;
    servicePlanId: string;
    vehicleId: string;
    created: Date;
    updated: Date;
    vehicle: VehicleSummary;
}

export type ServicePlanVehicleRequest = {
    servicePlanId: string;
    vehicleId: string;
    serviceMileage: Number;
}

export type ServicePlanCreateRequest = {
    projectIdentifier: string;
    title: string;
    description?: string;
    mileageInterval: number;
    tasks: ServicePlanTaskRequest[];
}

export type ServicePlanUpdateRequest = {
    servicePlanId: string;
    title: string;
    description?: string;
    autoCreateWorkOrder: boolean;
}

export type ServicePlanTaskRequest = {
    servicePlanId: string;
    serviceTaskId?: string;
    title?: string
    description?: string;
    requestType: "Service" | "ServiceTask" | "New" 
}

export type ServiceItemRequest = {
    serviceId: string;
    itemId?: string;
    inventoryItemId?: string;
    title: string,
    description?: string,
    price: number,
    quantity: number,
}

export type ServiceTaskInstanceRequest = {
    serviceId: string;
    taskId: string;
    complete: boolean;
}

export type ServiceItemTaskRequest = {
    servicePlanId: string;
    inventoryItemId?: string;
    title: string,
    description: string,
    price: number,
    quantity: number,
}

export type ServiceItemUpdateRequest = {
    serviceId: string;
    itemId: string;
    inventoryItemId?: string;
    title: string,
    description: string,
    price: number,
    quantity: number,
}

export type ServiceUpdateRequest = {
    serviceId: string;
    dateDue?: Date | null;
    dateCompleted?: Date | null;
    odometerDue?: number | null;
    odometerCompleted?: number | null;
    hubometerDue?: number | null;
    hubometerCompleted?: number | null;
    hoursDue?: number | null;
    hoursCompleted?: number | null;
}

export type ServicePlanItemUpdateRequest = {
    servicePlanId: string;
    itemId: string;
    inventoryItemId?: string;
    title: string,
    description: string,
    price: number,
    quantity: number,
}

export enum ServicePlanStatus {
    Active = "Active",
    Disabled = "Disabled",
}

export enum ServiceStatus {
    Scheduled = "Scheduled",
    Complete = "Complete",
    Cancelled = "Canelled"
}

export interface IServiceTaskSearchCriteria extends ISearchCriteria {
  
}

export interface IServicePlanSearchCriteria extends ISearchCriteria {
  
}

export interface IServiceSearchCriteria extends ISearchCriteria {
  
}

export interface IServicePlanVehicleSearchCriteria extends ISearchCriteria {
  servicePlanId: string;
}

export interface IServiceTaskSearchResult extends ISearchResult<ServiceTask> {

}

export interface IServiceSearchResult extends ISearchResult<Service> {

}

export interface IServicePlanSearchResult extends ISearchResult<ServicePlan> {

}

export interface IServicePlanVehicleSearchResult extends ISearchResult<ServicePlanVehicle> {

}