import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { WorkOrder, WorkItem, WorkOrderState, WorkOrderCreateRequest, IWorkOrderSearchResult, IWorkOrderSearchCriteria } from '../../@types/workorder';
import { RootState } from '../store';
import axios from '../../utils/axios';

const initialState: WorkOrderState = {
  order: {
    modalOpen: false,
    loading: true,
    data: null,
  },
  orders: {
    loading: true,
    totalCount: 0,
    data: [],
  }
};

export const fetchWorkOrder = createAsyncThunk('workOrder/fetch', async (id: string, { rejectWithValue }) => {
  try {
    const response = await axios.get<any, any>(`/api/work-orders/${id}`);
    return response.data as WorkOrder;
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const fetchWorkOrderItems = createAsyncThunk('workOrder/fetchItems', async (id: string, { rejectWithValue }) => {
  try {
    const response = await axios.get<any, any>(`/api/work-orders/${id}/items`);
    return response.data as WorkItem[];
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const fetchWorkOrders = createAsyncThunk('workOrders/fetch', async (request: IWorkOrderSearchCriteria, { rejectWithValue }) => {
  try {
    const response = await axios.post<any, any>(`/api/work-orders/search`, request);
    return response.data as IWorkOrderSearchResult;
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const createWorkOrder = createAsyncThunk('workOrder/create', async (request: WorkOrderCreateRequest, { rejectWithValue }) => {
  try {
    const response = await axios.post<any, any>(`/api/work-orders`, request);
    return response.data as WorkOrder;
  } catch (error) {
    return rejectWithValue(error)
  }
})

const slice = createSlice({
  name: 'workorder',
  initialState,
  reducers: {
    toggleWorkOrderModal(state, action) {
      state.order.modalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkOrders.pending, (state, action) => {
      state.orders.loading = true;
    })

    builder.addCase(fetchWorkOrders.fulfilled, (state, action) => {
      state.orders.loading = false;
      state.orders.totalCount = action.payload.totalCount;
      state.orders.data = action.payload.results;
    })

    builder.addCase(fetchWorkOrder.pending, (state, action) => {
      state.order.loading = true;
    })

    builder.addCase(fetchWorkOrder.fulfilled, (state, action) => {
      state.order.loading = true;
      state.order.data = action.payload;
    })

    builder.addCase(createWorkOrder.fulfilled, (state, action) => {
      state.order.loading = false;
      state.order.data = action.payload;
    })
  },
});

export const { toggleWorkOrderModal } = slice.actions;

// Reducer
export default slice.reducer;

// selectors
export const selectWorkOrderState = (state: RootState) => state.workorder;
export const selectWorkOrder = createSelector(selectWorkOrderState, state => state.order.data);
export const selectWorkOrderModal = createSelector(selectWorkOrderState, state => state.order.modalOpen);
export const selectWorkOrders = createSelector(selectWorkOrderState, state => state.orders.data);
