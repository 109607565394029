import { useState } from 'react'
import { LoadingButton } from '@material-ui/lab';
import { Box, Grid, Card, Stack, Button, Divider, CardHeader, Typography, CardContent, Alert } from '@material-ui/core';
import { Vehicle, VehicleStatus, VehicleType } from '../../@types/vehicle'
import { Project } from '../../@types/project'
import { fDateTime } from '../../utils/formatTime';
import { fNumber } from '../../utils/formatNumber';
import DeleteVehicle from './DeleteVehicle'
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Link as RouterLink } from 'react-router-dom';
import UpdateVehicleModal from './modal/UpdateVehicleModal'
import { VehicleRegistration } from '../_vehicle'

type VehicleDetailInfoProps = {
    vehicle: Vehicle;
    project: Project | null;
};

export default function VehicleDetailInfo({ vehicle, project }: VehicleDetailInfoProps) {

    const [modalOpen, setModalOpen] = useState(false);

    if (!vehicle) {
        return null
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                    <Card sx={{ mb: 4 }}>
                        <CardHeader title="Main Details" />
                        <CardContent>
                            <Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>{vehicle.type == VehicleType.Vehicle ? 'Registration' : 'Serial Number'}</Typography>
                                    <VehicleRegistration registration={vehicle.vehicleIdentifier} />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Make</Typography>
                                    <Typography variant="subtitle2">{vehicle.make}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Model</Typography>
                                    <Typography variant="subtitle2">{vehicle.model}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Sub-Model</Typography>
                                    <Typography variant="subtitle2">{vehicle.subModel}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Type</Typography>
                                    <Typography variant="subtitle2">{vehicle.type || ''}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Odometer</Typography>
                                    <Typography variant="subtitle2">{vehicle.odometer ? fNumber(vehicle.odometer) : '-'}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Hubometer</Typography>
                                    <Typography variant="subtitle2">{vehicle.hubometer ? fNumber(vehicle.hubometer) : '-'}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Hourmeter</Typography>
                                    <Typography variant="subtitle2">{vehicle.hourmeter ? fNumber(vehicle.hourmeter) : '-'}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Manufacture Year</Typography>
                                    <Typography variant="subtitle2">{vehicle.manufactureYear ?? '-'}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Colour</Typography>
                                    <Typography variant="subtitle2">{vehicle.colour}</Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                        <CardHeader title="Engine and Chassis" />
                        <CardContent>
                            <Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>VIN Number</Typography>
                                    <Typography variant="subtitle2">{vehicle.vehicleIdentificationNumber}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Chassis Number</Typography>
                                    <Typography variant="subtitle2">{vehicle.chassisNumber}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Engine Number</Typography>
                                    <Typography variant="subtitle2">{vehicle.engineNumber}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Engine Capacity</Typography>
                                    <Typography variant="subtitle2">{vehicle.engineCapacity ? fNumber(vehicle.engineCapacity) : ''}</Typography>
                                </Stack>
                            </Stack>
                            <Stack spacing={3} alignItems="flex-end" sx={{ pt: 3 }}>
                                <Button size="small" startIcon={<Icon icon={editFill} />} onClick={() => setModalOpen(true)} disabled={vehicle.status !== VehicleStatus.Active}>
                                    Edit Vehicle
                                </Button>
                            </Stack>
                            <UpdateVehicleModal
                                modalOpen={modalOpen}
                                vehicle={vehicle}
                                onClose={() => setModalOpen(false)}
                            />
                        </CardContent>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                {vehicle.status !== VehicleStatus.Active && (<Alert severity="info" sx={{ mb: 2 }}>This vehicle is currently disabled and cannot be updated.</Alert>)}
                <Stack spacing={4}>
                    <Card sx={{ mb: 4 }}>
                        <CardHeader
                            title="Owner Details"
                            action={
                                <Button
                                    to={`../../../../customers/${vehicle.client.id}/detail`}
                                    size="small"
                                    color="inherit"
                                    component={RouterLink}
                                    endIcon={<Icon icon={arrowIosForwardFill} />}
                                >
                                    View
                                </Button>
                            }
                        />
                        <CardContent>
                            <Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Name</Typography>
                                    <Typography variant="subtitle2">{vehicle.client.name}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>First Name</Typography>
                                    <Typography variant="subtitle2">{vehicle.client.firstName}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Name</Typography>
                                    <Typography variant="subtitle2">{vehicle.client.lastName}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Phone</Typography>
                                    <Typography variant="subtitle2">{vehicle.client.phoneNumber}</Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack spacing={4}>
                    <Card sx={{ mb: 4 }}>
                        <CardHeader title="External Details" />
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Information about this vehicle can be retrieved from an external service to provide
                                        addional details to help with management. These details are retrieved when the vehicle is created and can be refreshed when required.
                                    </Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Details Last Updated</Typography>
                                    <Typography variant="subtitle2">{vehicle.whenExternalDetailsLastRefreshed ? fDateTime(vehicle.whenExternalDetailsLastRefreshed) : '-'}</Typography>
                                </Stack>
                                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => window.open(`https://www.carjam.co.nz/car/?plate=${vehicle.vehicleIdentifier}`)}>View External Details</Button>
                                    <LoadingButton type="submit" variant="contained" loading={false} disabled={true}>
                                        {'Refresh Details'}
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>

                </Stack>
                <Divider />
                <Stack spacing={4}>
                    <DeleteVehicle vehicle={vehicle} project={project} />
                </Stack>
            </Grid>
        </Grid>
    );
}
