import { Autocomplete, TextField } from '@material-ui/core';
import { ComplianceType } from '../../@types/compliance'
import { ControllerRenderProps } from 'react-hook-form';

type ComplianceStatusMultiSelectProps = {
    field: ControllerRenderProps;
    disabled?: boolean;
};

const options : ComplianceType[] = [
    ComplianceType.Unknown,
    ComplianceType.WOF,
    ComplianceType.COF,
];

const complianceLabel = (type: ComplianceType) => type == ComplianceType.Unknown ? 'Unknown' : type == ComplianceType.WOF ? 'Warrant of Fitness (WOF)' : type == ComplianceType.COF ? 'Certificate of Fitness (COF)' : ``;

export default function ComplianceStatusSelectList({ field, disabled }: ComplianceStatusMultiSelectProps) {

  return (
    <Autocomplete
        {...field}
        multiple={false}
        fullWidth
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => complianceLabel(option)}
        defaultValue={ComplianceType.WOF}
        renderOption={(props, option) => <li {...props}>{complianceLabel(option)}</li>}
        renderInput={(params) => (
            <TextField {...params} label="Compliance Type" />
        )}
        onChange={(event, newValue) => {
            field.onChange(newValue);
        }}
    />
  );
}
