import { useContext } from 'react';
import { ProjectContext } from '../contexts/ProjectContext';

const useProject = () => {
  const context = useContext(ProjectContext);

  if (!context) throw new Error('Project context must be use inside ProjectProvider');

  return context;
};

export default useProject;
