import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Checkbox, FormControlLabel, Typography, DialogActions, Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import { useSendComplianceReminderMutation } from '../../../../services/vehicles'
import { ComplianceTypeConfiguration } from '../../../../@types/compliance'
import { useSnackbar } from 'notistack5';

type SendComplianceReminderFormProps = {
    vehicleId: string;
    configuration: ComplianceTypeConfiguration;
    onReminderSent(): void;
    onCancel(): void;
};

type SendComplianceReminderFormValues = {
    sendText: boolean;
    sendEmail: boolean;
};

export const FormSchema = Yup.object().shape({

});

export default function SendComplianceReminderForm({ vehicleId, configuration, onReminderSent, onCancel }: SendComplianceReminderFormProps) {

    const { enqueueSnackbar } = useSnackbar();
    const [sendComplianceReminder] = useSendComplianceReminderMutation();
    const { emailConfig, textMessageConfig } = configuration.reminderConfiguration
    const {
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = useForm<SendComplianceReminderFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            sendText: textMessageConfig.enabled,
            sendEmail: emailConfig.enabled,
        }
    });

    const onSubmit = async (data: SendComplianceReminderFormValues) => {

        try {
            // dispatch the update
            await sendComplianceReminder({
                vehicleId: vehicleId,
                sendEmail: data.sendEmail,
                sendText: data.sendText,
            }).unwrap();
            enqueueSnackbar('Compliance reminder sent successfully!', { variant: 'success' });
            reset();
            onReminderSent();

        } catch (error) {
            if (error.data) {
                enqueueSnackbar(error.data.errors[0], { variant: 'error' });
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                    <Typography sx={{ mb: 1 }}>Reminders will be sent to the vehicle owners maintenance contact if set, else it will be sent to the owner directly.</Typography>
                    <Controller
                        name="sendEmail"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Send reminder by email"
                                disabled={!emailConfig.enabled}
                                control={
                                    <Checkbox
                                        {...field}
                                        onChange={e => field.onChange(e.target.checked)}
                                        checked={field.value}
                                    />
                                }
                            />
                        )}
                    />
                    <Controller
                        name="sendText"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Send reminder by text message"
                                disabled={!textMessageConfig.enabled}
                                control={
                                    <Checkbox
                                        {...field}
                                        onChange={e => field.onChange(e.target.checked)}
                                        checked={field.value}
                                    />
                                }
                            />
                        )}
                    />
                    {/* <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={!isValid}
                        sx={{ mt: 3 }}
                    >
                        Send Reminder
                    </LoadingButton> */}
                    <DialogActions>
                        <LoadingButton
                            loading={isSubmitting}
                            disabled={!isValid}
                            variant="contained"
                            color="success"
                            type="submit"
                            autoFocus
                        >
                            Send Reminder
                        </LoadingButton>
                        <Button onClick={onCancel} autoFocus>Cancel</Button>
                    </DialogActions>
                </Stack>
            </form>
        </>
    );
}
