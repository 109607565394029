
import { useState } from 'react';
import { useSnackbar } from 'notistack5';
import { Stack, Button, Paper, TextField } from '@material-ui/core';
import VerifyEmailCodeForm from './VerifyEmailCodeForm'
import VerifyEmailForm from './VerifyEmailForm'
import ModalContainer from '../../shared/modal/ModalContainer'
import { useVerifyEmailMutation } from '../../../services/email'
import { EmailVerification } from '../../../@types/email'

export type EmailVerificationComponentProps = {
    email?: string | null;
    onEmailChange: (verification: EmailVerification) => void;
}

function EmailVerificationComponent({ email, onEmailChange }: EmailVerificationComponentProps) {

    const [newEmail, setNewEmail] = useState('');
    const [verificationId, setVerificationId] = useState<string | null>(null);
    const [verificationSent, setVerificationSent] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [verifyEmail] = useVerifyEmailMutation();

    const handleEmailChange = async (email: string) => {
        var result = await verifyEmail({ email }).unwrap();
        setVerificationId(result.verificationId);
        setNewEmail(result.email);
        setVerificationSent(true);
    }

    const handleVerification = async (code: number) => {
        setVerificationSent(true);
        onEmailChange({
            verificationId: verificationId || '',
            email: newEmail,
        });
        enqueueSnackbar('Email verified successfully', { variant: 'success' })
    };

    return (
        <div>
            {!verificationSent && (
                <VerifyEmailForm
                    email={email}
                    onEmailUpdated={handleEmailChange}
                />
            )}
            {verificationSent && (
                <VerifyEmailCodeForm
                    email={newEmail}
                    verificationId={verificationId ?? ''}
                    onCodeVerified={handleVerification}
                />
            )}
        </div>
    );
}

type EmailVerificationModalProps = {
    email: string | null | undefined;
    onEmailChange: (verification: EmailVerification) => void;
    onCodeVerified: (email: string) => void;
}

export default function EmailVerificationModal({ email, onEmailChange }: EmailVerificationModalProps) {

    const [open, setOpen] = useState(false);
    const [updatedEmail, setUpdatedEmail] = useState<string | null>('');

    const handleEmailChange = (verification: EmailVerification) => {
        setUpdatedEmail(verification.email);
        onEmailChange(verification);
        setOpen(false);
    }

    return (
        <Paper>
            <Stack direction="row" justifyContent="space-between">
                <TextField
                    value={email}
                    variant="outlined"
                    fullWidth
                    label="Project Email"
                    onClick={() => setOpen(true)}
                //helperText="Email updates require verification"
                />
                <Button sx={{ ml: 2 }} onClick={() => setOpen(true)}>Change</Button>
            </Stack>
            <ModalContainer
                title='Email Verification'
                component={
                    <EmailVerificationComponent
                        email={email}
                        onEmailChange={handleEmailChange}
                    />
                }
                open={open}
                handleConfirmModalClose={() => setOpen(false)}
            />
        </Paper>
    );
}


