import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@material-ui/core';
import ModalContainer from './modal/ModalContainer'

export type ConfirmDeleteModalProps = {
    confirmTitle?: string;
    confirmMessage?: string;
    onCancel: () => void;
    onConfirm: () => void;
    open: boolean;
}

export default function ConfirmDeleteModal({ open, confirmMessage, confirmTitle, onCancel, onConfirm }: ConfirmDeleteModalProps) {

    return (
        <ModalContainer
            title={confirmTitle ?? 'Confirm Delete'}
            open={open}
            handleConfirmModalClose={onCancel}
            component={
                <Typography>
                    {confirmMessage ?? 'Please confirm you really want to delete this item'}
                </Typography>
            }
            actions={
                <>
                    <Button onClick={onConfirm} autoFocus variant="contained">Confirm</Button>
                    <Button onClick={onCancel} autoFocus>Cancel</Button>
                </>
            }
        />
    );
}
