import { useState, useEffect } from 'react' 
import { CardContent, Card, CardHeader, Button  } from '@material-ui/core';
import { ClientSummary } from '../../@types/client'
import { IVehicleSearchCriteria } from '../../@types/vehicles'
import { Icon } from '@iconify/react';
import { VehicleSearch } from '../vehicle';
import { useDispatch } from '../../redux/store';
import useProject from '../../hooks/useProject';
import { promptVehicleCreate } from '../../redux/slices/vehicle';
import { useGetVehiclesQuery } from '../../services/vehicles';
import arrowIosForwardFill from '@iconify/icons-eva/plus-fill';

type ClientVehiclesProps = {
  client: ClientSummary | null;
};

export default function ClientVehicles({ client }: ClientVehiclesProps) {

    const dispatch = useDispatch();
    const { project } = useProject();
    const [search, setSearch] = useState<IVehicleSearchCriteria>({
      pageNumber: 0,
      pageSize: 25,
      order: 'desc',
      orderBy: 'updated',
      clients: [{
        id: client?.id || '',
        name: client?.name || '',
      }],
      makes: [],
      vehicleIdentifiers: [],
      text: null,
      projectIdentifier: undefined,
    });
    
    const { data = { results: [], totalCount: 0 }, isLoading } = useGetVehiclesQuery(search);
    
    useEffect(() => {
      setSearch({
        ...search,
        clients: [{
          id: client?.id || '',
          name: client?.name || '',
        }],
      })
    }, [client])

    if (!client) {
      return null
    }

    return (
        <Card sx={{ mb: 4 }}>
          <CardHeader
            title="Customer Vehicles"
            action={
              <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<Icon icon={arrowIosForwardFill} />}
                onClick={() => dispatch(promptVehicleCreate({ selectedOwnerEntity: { id: client.id, name: client.name } }))}
              >
                Create Vehicle
              </Button>
            }
          />
          <CardContent>
            <VehicleSearch
              project={project}
              vehicles={data.results}
              totalRows={data.totalCount}
              showSearch={false}
              search={search}
              isLoading={isLoading}
              onSetSearch={(search) => setSearch(search)}
            />
          </CardContent>
        </Card>
    );
}
