import { Icon } from '@iconify/react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import trendingUpFill from '@iconify/icons-eva/trending-up-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
import { alpha, useTheme, styled, Theme } from '@material-ui/core/styles';
import { Box, Card, Typography, Stack, Skeleton } from '@material-ui/core';
import { fNumber, fPercent } from '../../utils/formatNumber';
import InfoToolTip from '../shared/InfoToolTip'

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

interface TrendChartProps {
  loading?: boolean;
  title: string;
  description?: string;
  labels: string[];
  percent: number;
  total: number;
  chartData: [{ data: number[] }];
  colors?: string[];
}

const IconComponent = ({ loading, percent, theme }: { loading: boolean, percent: number, theme: Theme }) => {

  return (
    <IconWrapperStyle
      sx={{
        ...(percent < 0 && {
          color: 'error.main',
          bgcolor: alpha(theme.palette.error.main, 0.16)
        })
      }}
    >
      {loading ? <Skeleton width={16} component="div" /> :
        <Icon width={16} height={16} icon={percent >= 0 ? trendingUpFill : trendingDownFill} />}
    </IconWrapperStyle>
  )
};

const PercentageComponent = ({ loading, percent, theme }: { loading: boolean, percent: number, theme: Theme }) => {

  if (loading) {
    return (
      <Typography component="span" variant="subtitle2">
        <Skeleton width={60} component="div" />
      </Typography>
    )
  }
  return (
    <Typography component="span" variant="subtitle2">
      {percent > 0 && '+'}
      {fPercent(percent)}
    </Typography>
  )
};

const NumberComponent = ({ loading, total }: { loading: boolean, total: number }) => {

  return (
    <Typography variant="h3">
      {loading ? <Skeleton width={60} component="div" /> : fNumber(total)}
    </Typography>
  )
};

export default function TrendChart({ title, description, labels, percent, total, chartData, colors, loading = false }: TrendChartProps) {
  const theme = useTheme();

  const chartOptions: ApexOptions = {
    colors: colors ?? [theme.palette.primary.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    labels: labels,
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName: number | string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: number | string) => ''
        }
      },
      marker: { show: false }
    }
  };

  return (
    <Card sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">{title}</Typography>
        <InfoToolTip description={description} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
          <NumberComponent loading={loading} total={total} />
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
            <IconComponent loading={loading} percent={percent} theme={theme} />
            <PercentageComponent loading={loading} percent={percent} theme={theme} />
          </Stack>
        </Box>

        {loading ? <Skeleton width={80} height={80} component="div" /> :
          <ReactApexChart
            type="bar"
            series={chartData}
            options={chartOptions}
            width={80}
            height={36}
          />
        }

      </Box>
    </Card>
  );
}
