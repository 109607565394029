import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from '../../utils/axios';
import { Invoice, InvoiceSearchCriteria } from '../../@types/invoice';

type InvoicesState = {
  isLoading: boolean;
  notFound: boolean;
  error: boolean;
  invoices: Invoice[];
  totalRows: number;
  isFirstTime: boolean;
  search: InvoiceSearchCriteria;
  selectedRow: string[]
};

const initialState: InvoicesState = {
  isLoading: false,
  notFound: false,
  error: false,
  invoices: [],
  totalRows: 0,
  isFirstTime: true,
  search: {
    pageNumber: 0,
    pageSize: 25,
    order: 'desc',
    orderBy: 'dueDate',
    text: null,
    unlinkedOnly: false,
    statusFilter: [],
    typeFilter: [],
  },
  selectedRow: []
};

export const fetchInvoices = createAsyncThunk(
  'invoices/fetch',
  async (req, { rejectWithValue, getState }) => {
    try {
        const { invoices, project} = getState() as RootState;
        const response = await axios.post<any, any>(`/api/invoices/search`, {
          projectId: project.project?.id,
          pageNumber: invoices.search.pageNumber,
          pageSize: invoices.search.pageSize,
          orderBy: invoices.search.orderBy,
          order: invoices.search.order,
          text: invoices.search.text,
          statusFilter: invoices.search.statusFilter,
          unlinkedOnly: invoices.search.unlinkedOnly,
        });
        return response.data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    setSearch(state, action) {
      state.search = action.payload;
    },

    setSearchStatus(state, action) {
      state.search.statusFilter = action.payload;
    },

    setSelectRow(state, action) {
      state.selectedRow = action.payload;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload.invoices;
      state.totalRows = action.payload.total;
      state.isFirstTime = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvoices.pending, (state, action) => {
      state.isLoading = true;
    })

    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      state.totalRows = action.payload.totalCount;
      state.invoices = action.payload.invoices;
      state.isLoading = false;
    })

    builder.addCase(fetchInvoices.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    })
  },
});

// Reducer
export default slice.reducer;

export const { setSearch, setSelectRow, setSearchStatus } = slice.actions;