import { UseControllerProps, useController } from 'react-hook-form';
import { Stack, TextField, Paper } from '@material-ui/core';
import { Schedule } from '../../../../@types/servicetype';
import { NextServiceDueNumericComponent } from '../../NextServiceDueNumericComponent'
import { ServiceTypesFormValues } from '../../../job/form/components/JobServiceTypesFormSection';

type ServiceIntervalFormComponentProps = {
    inputLabel: string;
    current: number | null;
    label: string;
    showSuggestedSchedule?: boolean;
    schedule: Schedule | null;
    onChange(nextDue: number | null): void;
    props: UseControllerProps<ServiceTypesFormValues>
}

export const ServiceIntervalFormComponent = ({ inputLabel, label, current, schedule, props, showSuggestedSchedule = true }: ServiceIntervalFormComponentProps) => {

    const { field, fieldState: { error } } = useController(props);

    return (
        <Stack direction="row">
            <Stack flexGrow={1}>
                <TextField
                    inputRef={field.ref}
                    {...field}
                    label={inputLabel}
                    type="number"
                    fullWidth
                    size="small"
                    error={Boolean(error)}
                    helperText={error?.message}
                    autoComplete={'off'}
                    InputLabelProps={{ shrink: field.value !== null }}
                />
            </Stack>
            {showSuggestedSchedule && (<Paper sx={{ ml: 1, p: 1, flexGrow: 1, bgcolor: 'background.neutral', width: '240px' }}>
                <NextServiceDueNumericComponent
                    label={label}
                    current={current}
                    enabled={schedule?.enabled ?? false}
                    dueIn={schedule?.interval ?? null}
                    onChange={(value) => field.onChange(value)}
                />
            </Paper>)}
        </Stack>
    )
}