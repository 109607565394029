import { Theme } from '@material-ui/core/styles';

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            borderRadius: theme.shape.borderRadius
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: 20
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: theme.spacing(1),
          
          '&.Mui-expanded': {
            borderBottom: '2px solid',
            borderBottomColor: theme.palette.background.neutral,
          },
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit'
            }
          }
        },
        expandIconWrapper: {
          color: 'inherit'
        }
      }
    }
  };
}
