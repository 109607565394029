import { api } from './api'
import {
    ServicePlanCreateRequest,
    ServicePlanUpdateRequest,
    ServicePlan,
    ServicePlanVehicle,
    ServicePlanVehicleRequest,
    ServiceTask,
    ServiceItemTaskRequest,
    ServicePlanItemUpdateRequest,
    ServicePlanScheduleUpdateRequest,
    ServiceItem,
    ServicePlanTaskRequest,
    IServicePlanSearchCriteria,
    IServicePlanVehicleSearchCriteria,
    IServicePlanSearchResult,
    IServicePlanVehicleSearchResult
} from '../@types/maintenance'

export const servicePlansApi = api.injectEndpoints({
    endpoints: (builder) => ({
        
        getServicePlan: builder.query<ServicePlan, string>({
            query: (id: string) => ({
                url: `service-plans/${id}`,
                method: 'GET',
            }),
            providesTags: ['ServicePlan']
        }),

        getServicePlans: builder.query<IServicePlanSearchResult, IServicePlanSearchCriteria>({
            query: (search: IServicePlanSearchCriteria) => ({
                url: `service-plans/search`,
                method: 'POST',
                body: search,
            }),
            providesTags: ['ServicePlan']
        }),

        addServicePlan: builder.mutation<ServicePlan, ServicePlanCreateRequest>({
            query: (request: ServicePlanCreateRequest) => ({
                url: `service-plans`,
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['ServicePlan'],
        }),

        updateServicePlan: builder.mutation<ServicePlan, ServicePlanUpdateRequest>({
            query: (request: ServicePlanUpdateRequest) => ({
                url: `service-plans/${request.servicePlanId}`,
                method: 'PUT',
                body: request
            }),
            invalidatesTags: ['ServicePlan'],
        }),

        getServicePlanItems: builder.query<ServiceItem[], string>({
            query: (servicePlanId: string) => ({
                url: `service-plans/${servicePlanId}/items`,
                method: 'GET',
            }),
            providesTags: ['ServicePlanItem']
        }),

        addServicePlanItem: builder.mutation<ServiceItem, ServiceItemTaskRequest>({
            query: (request: ServiceItemTaskRequest) => ({
                url: `service-plans/${request.servicePlanId}/items`,
                method: 'PUT',
                body: request
            }),
            invalidatesTags: ['ServicePlanItem', 'InventoryItem'],
        }),

        updateServicePlanItem: builder.mutation<ServiceItem, ServicePlanItemUpdateRequest>({
            query: (request: ServicePlanItemUpdateRequest) => ({
                url: `service-plans/${request.servicePlanId}/items/${request.itemId}`,
                method: 'PUT',
                body: request
            }),
            invalidatesTags: ['ServicePlanItem'],
        }),

        deleteServicePlanItem: builder.mutation<ServiceItem, { servicePlanId: string, servicePlanItemId: string }>({
            query: (request: { servicePlanId: string, servicePlanItemId: string }) => ({
                url: `service-plans/${request.servicePlanId}/items/${request.servicePlanItemId}`,
                method: 'DELETE',
                body: request
            }),
            invalidatesTags: ['ServicePlanItem'],
        }),

        getServicePlanTasks: builder.query<ServiceTask[], string>({
            query: (servicePlanId: string) => ({
                url: `service-plans/${servicePlanId}`,
                method: 'GET',
            }),
            transformResponse: (response: ServicePlan, meta, arg) => response.tasks,
            providesTags: ['ServicePlanTask']
        }),

        addServicePlanTask: builder.mutation<ServiceTask, ServicePlanTaskRequest>({
            query: (request: ServicePlanTaskRequest) => ({
                url: `service-plans/${request.servicePlanId}/tasks`,
                method: 'PUT',
                body: request
            }),
            invalidatesTags: ['ServicePlanTask'],
        }),

        removeServicePlanTask: builder.mutation<ServiceTask, ServicePlanTaskRequest>({
            query: (request: ServicePlanTaskRequest) => ({
                url: `service-plans/${request.servicePlanId}/tasks/${request.serviceTaskId}`,
                method: 'DELETE',
                body: request
            }),
            invalidatesTags: ['ServicePlanTask'],
        }),

        addServicePlanVehicle: builder.mutation<ServicePlanVehicle, ServicePlanVehicleRequest>({
            query: (request: ServicePlanVehicleRequest) => ({
                url: `service-plans/${request.servicePlanId}/vehicles`,
                method: 'PUT',
                body: request
            }),
            invalidatesTags: ['ServicePlanVehicle'],
        }),

        getServicePlanVehicles: builder.query<IServicePlanVehicleSearchResult, IServicePlanVehicleSearchCriteria>({
            query: (request: IServicePlanVehicleSearchCriteria) => ({
                url: `service-plans/${request.servicePlanId}/vehicles`,
                method: 'GET',
            }),
            providesTags: ['ServicePlanVehicle'],
        }),

        getServicePlanVehicle: builder.query<ServicePlanVehicle | null, { servicePlanId: string, vehicleId: string }>({
            query: (request: { servicePlanId: string, vehicleId: string }) => ({
                url: `service-plans/${request.servicePlanId}/vehicles/${request.vehicleId}`,
                method: 'GET',
            }),
            providesTags: ['ServicePlanVehicle'],
        }),

        updateServicePlanSchdule: builder.mutation<ServicePlan, ServicePlanScheduleUpdateRequest>({
            query: (request: ServicePlanScheduleUpdateRequest) => ({
                url: `service-plans/${request.servicePlanId}/schedule`,
                method: 'PUT',
                body: request
            }),
            invalidatesTags: ['ServicePlan'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetServicePlanQuery,
    useGetServicePlansQuery,
    useGetServicePlanVehiclesQuery,
    useAddServicePlanMutation,
    useUpdateServicePlanMutation,
    useGetServicePlanTasksQuery,
    useAddServicePlanTaskMutation,
    useRemoveServicePlanTaskMutation,
    useUpdateServicePlanItemMutation,
    useGetServicePlanItemsQuery,
    useDeleteServicePlanItemMutation,
    useAddServicePlanItemMutation,
    useGetServicePlanVehicleQuery,
    useAddServicePlanVehicleMutation,
    useUpdateServicePlanSchduleMutation,
} = servicePlansApi;