import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { dispatch, RootState } from '../store';
import axios from '../../utils/axios';
import { ProjectMemberUpdateRequest, ProjectState, ProjectSubscriptionBilling } from '../../@types/project';
import { AllocationType } from '../../@types/allocation';

const initialState: ProjectState = {
  isLoading: false,
  notFound: false,
  error: false,
  project: null,
  members: {
    current: [],
  }
};

export const updateProjectMember = createAsyncThunk('project/updateMembership', async (request: ProjectMemberUpdateRequest, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post<any, any>(`/api/projects/${request.projectIdentifier}/members`, request);
    dispatch(slice.actions.getProjectMembersSuccess(response.data.result));
    return response.data as ProjectSubscriptionBilling;
  } catch (error) {
    return rejectWithValue(error)
  }
})

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.notFound = action.payload.notFound;
      state.error = action.payload.error;
    },

    getProjectSuccess(state, action) {
      state.isLoading = false;
      state.notFound = false;
      state.project = action.payload;
    },

    getProjectMembersSuccess(state, action) {
      state.members.current = action.payload;
    },

    replaceProjectMember(state, action) {
      const updatedMembers = map(state.members.current, (member) => {
        if (member.memberId === action.payload.id) {
          return action.payload;
        }
        return member;
      });

      state.members.current = updatedMembers;
    },
  },
  extraReducers: (builder) => {
  },
});

// Reducer
export default slice.reducer;

export function getProjectMembers(projectIdentifier: string) {
  return async () => {

    try {
      const response = await axios.get<any, any>(`/api/projects/${projectIdentifier}/members`);
      dispatch(slice.actions.getProjectMembersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: error, notFound: false }));
    }
  };
}

export function deleteProjectMember(request: ProjectMemberUpdateRequest) {
  return async () => {

    try {
      const response = await axios.delete<any, any>(`/api/projects/${request.projectIdentifier}/members/${request.memberId}`);

      if (response.data.allow) {
        dispatch(slice.actions.getProjectMembersSuccess(response.data.result));
      }
    } catch (error) {
      dispatch(slice.actions.hasError({ error: error, notFound: error.status && error.status === 404 }));
    }
  };
}

export const selectProjectState = (state: RootState) => state.project;
export const selectProject = createSelector(selectProjectState, projectState => projectState.project);
export const selectProjectMembers = createSelector(selectProject, project => project?.memberships || [])
export const selectProjectMembersForList = createSelector([selectProjectMembers], (members) => {
  return members.map((member) => {
    return {
      id: member.member.id,
      name: member.member.displayName,
      imageUrl: member.member.imageUrl,
      type: AllocationType.User,
    }
  })
})
