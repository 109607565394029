import { useNavigate } from 'react-router-dom';
import { Stack, Paper, Card, Box, Typography, Button, CardHeader } from '@material-ui/core';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import clipboardFill from '@iconify/icons-eva/clipboard-outline';
import { ServiceRecord } from '../../../@types/maintenance'
import { Vehicle } from '../../../@types/vehicle'
import vehicle, { toggleVehicleServiceModal } from '../../../redux/slices/vehicle';
import { fDate } from '../../../utils/formatTime'
import { fNumber } from '../../../utils/formatNumber'
import EmptyContent from '../../shared/EmptyContent'
import { useGetVehicleServiceRecordsQuery } from '../../../services/vehicles'
import { useCreateJobMutation } from '../../../services/job'

type VehicleServiceListProps = {
  vehicle: Vehicle;
};

export default function VehicleServiceList({ vehicle }: VehicleServiceListProps) {

  const navigate = useNavigate();
  const [createJob] = useCreateJobMutation();
  const { data = [], isLoading } = useGetVehicleServiceRecordsQuery(vehicle.id);

  const sort1 = (a: ServiceRecord, b: ServiceRecord) => {

    const aOdometerDue = a.odometerCompleted ?? a.odometerDue ?? 0;
    const bOdometerDue = b.odometerCompleted ?? b.odometerDue ?? 0;

    const aDate = a.dateCompleted ?? a.dateDue ?? new Date(0);
    const bDate = b.dateCompleted ?? b.dateDue ?? new Date(0);

    if (aDate > bDate) {
      return aOdometerDue > bOdometerDue ? 1 : -1;
    } else {
      return 0
    }

    return 0;
  }

  const services = [...data].sort((a, b) => sort1(a, b));

  const addServiceJob = async (serviceId: string, serviceTypeId: string, jobTitle: string) => {
    await createJob({
      vehicleId: vehicle.id,
      customerId: vehicle.client.id,
      title: jobTitle,
      date: new Date(),
      services: [{
        serviceId: serviceId,
        createService: false,
        serviceTypeId: serviceTypeId,
      }]
    })
  }

  if (isLoading) {
    return (
      <Card>
        <CardHeader
          title={"Service Schedule"}
          subheader="This list shows all the services for the vehicle. Select a given service to view more details"
        />
        <Stack spacing={3} alignItems="flex-start" sx={{ p: 3 }}>
          <Paper
            key={'service-skeleton-1'}
            sx={{
              p: 3,
              width: 1,
              height: '90px',
              bgcolor: 'background.neutral',
              cursor: 'pointer'
            }}
          >
          </Paper>
          <Paper
            key={'service-skeleton-2'}
            sx={{
              p: 3,
              width: 1,
              height: '90px',
              bgcolor: 'background.neutral',
              cursor: 'pointer'
            }}
          >
          </Paper>
          <Paper
            key={'service-skeleton-3'}
            sx={{
              p: 3,
              width: 1,
              height: '90px',
              bgcolor: 'background.neutral',
              cursor: 'pointer'
            }}
          >
          </Paper>
        </Stack>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader
        title={"Service Schedule"}
        subheader="This list shows all the service records for the vehicle. Select a given service to view more details"
      />
      <Stack spacing={3} alignItems="flex-start" sx={{ p: 3 }}>
        {services.length > 0 ? services.map((service) => (
          <Paper
            key={service.id}
            sx={{
              p: 3,
              width: 1,
              bgcolor: 'background.neutral',
              cursor: 'pointer'
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                {service.serviceType?.name || 'New Service'}
              </Typography>
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="body2" gutterBottom>
                  <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                    Due Mileage: &nbsp;
                  </Typography>
                  {service.odometerDue ? `${fNumber(service.odometerDue)} km's` : 'Not Set'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                    Due Date: &nbsp;
                  </Typography>
                  {service.dateDue ? fDate(service.dateDue) : '-'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                    Completed Mileage: &nbsp;
                  </Typography>
                  {service.odometerCompleted ? `${fNumber(service.odometerCompleted)} km's` : 'Not Set'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                    Completed Date: &nbsp;
                  </Typography>
                  {service.dateCompleted ? fDate(service.dateCompleted) : '-'}
                </Typography>
              </Box>
              {service.jobId && (
                <Box onClick={() => navigate(`../../../../jobs/${service.jobId}`)} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <Button size="medium" startIcon={<Icon icon={clipboardFill}  />}>View Job</Button>
                </Box>)}
              {!service.jobId && (
                <Box onClick={() => addServiceJob(service.id, service.serviceType.id, service.serviceType.name)} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <Button size="medium" variant="outlined" startIcon={<Icon icon={clipboardFill}  />}>Create Job</Button>
                </Box>)}
            </Box>
          </Paper>
        )) :

        <EmptyContent
          sx={{ maxWidth: '600px' }}
          title="No Service Records Found"
          description="This vehicle has no service records. Services can be scheduled based on a service plan or created manually."
          displayLayout="row"
          imgHeight={150}
        />
        }

        <Button
          sx={{ display: services.length < 4 ? 'none' : 'inline-flex', alignContent: 'right' }}
          size="small"
          onClick={() => toggleVehicleServiceModal(true)}
          startIcon={
            <Icon icon={plusFill}
            />}>
          Create Service
        </Button>
      </Stack>
    </Card>
  );
}
