import { Project, ProjectComplianceConfiguration, ProjectSubscriptionBilling, ProjectSubscription, ProjectUpdateRequest, ProjectSubscriptionUpdateRequest } from '../@types/project'
import { SubscriptionPlan } from '../@types/subscription'
import { api } from './api'

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<Project[], void>({
      query: () => ({
        url: `projects/search`,
        method: 'POST',
        body: {},
        transformResponse: (response: { results: Project[] }) => response.results,
        refetchOnFocus: false,
      })
    }),

    getProject: builder.query<Project, string>({
      query: (id: string) => ({
        url: `projects/${id}`,
        method: 'GET',
      }),
      providesTags: ['Project']
    }),

    updateProject: builder.mutation<Project, ProjectUpdateRequest>({
      query: (request: ProjectUpdateRequest) => ({
        url: `projects/${request.projectIdentifier}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Project']
    }),

    updateProjectEmail: builder.mutation<Project, ProjectUpdateRequest>({
      query: (request: ProjectUpdateRequest) => ({
        url: `projects/${request.projectIdentifier}/verify-email`,
        method: 'POST',
        body: {
          email: request.contactEmail
        },
      }),
      invalidatesTags: ['Project']
    }),

    getProjectBilling: builder.query<ProjectSubscriptionBilling, string>({
      query: (id: string) => ({
        url: `billing/${id}`,
        method: 'GET',
      }),
      providesTags: ['Project']
    }),

    getProjectSubscription: builder.query<SubscriptionPlan, string>({
      query: (id: string) => ({
        url: `projects/${id}/config/ui`,
        method: 'GET',
      }),
    }),

    updateProjectSubscription: builder.mutation<ProjectSubscription, ProjectSubscriptionUpdateRequest>({
      query: (request: ProjectSubscriptionUpdateRequest) => ({
        url: `projects/${request.projectIdentifier}/subscription`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Project']
    }),

    getProjectComplianceConfig: builder.query<ProjectComplianceConfiguration, string>({
      query: (id: string) => ({
        url: `projects/${id}/compliance-config`,
        method: 'GET',
      }),
      providesTags: ['Compliance', 'ComplianceConfig']
    }),

    updateProjectComplianceConfig: builder.mutation<ProjectComplianceConfiguration, ProjectSubscriptionUpdateRequest>({
      query: (request: ProjectSubscriptionUpdateRequest) => ({
        url: `projects/${request.projectIdentifier}/subscription`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Project']
    }),

  }),
  overrideExisting: false,
})

export const {
  useGetProjectsQuery,
  useUpdateProjectMutation,
  useUpdateProjectEmailMutation,
  useGetProjectQuery,
  useGetProjectBillingQuery,
  useGetProjectSubscriptionQuery,
  useUpdateProjectSubscriptionMutation,
  useGetProjectComplianceConfigQuery,
} = projectsApi;