import { styled } from '@material-ui/core/styles';
import { Typography, Card, CardContent, CardProps } from '@material-ui/core';
import { SeoIllustration } from '../../assets';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  maxHeight: '300px',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

interface ProjectWelcomeProps extends CardProps {
  projectName?: string;
  projectIdentifier?: string;
}

export default function ProjectWelcome({ projectName, projectIdentifier }: ProjectWelcomeProps) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800'
        }}
      >
        <Typography gutterBottom variant="h5">
          Welcome to
          <br /> {!projectName ? '...' : projectName}
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: 'auto', display: { xs: 'none', lg: 'initial' } }}>
          Here's your project data at a glance. You can customise your project settings and add members <RouterLink to={`/group/${projectIdentifier}/settings/members`}>here</RouterLink>
        </Typography>
      </CardContent>

      <SeoIllustration
        sx={{
          p: 3,
          width: 360,
          margin: { xs: 'auto', md: 'inherit' }
        }}
      />
    </RootStyle>
  );
}
