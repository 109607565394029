import { PATH_GROUP } from '../../routes/paths';
import SvgIconStyle from '../../components/shared/SvgIconStyle';
import { fDateUrl } from '../../utils/formatTime'

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  fleet: getIcon('ic_vehicle'),
  maintenance: getIcon('ic_spanner'),
  compliance: getIcon('ic_checkbox'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  clients: getIcon('ic_clients'),
  tasks: getIcon('ic_reminders'),
  integrations: getIcon('ic_integration'),
  settings: getIcon('ic_settings')
};

const current = new Date();
var currentDatePlus30Days = new Date().setDate(current.getDate() + 30);

export function getSideBarConfig(namespace: string, accessLevel: string | undefined) {
  
  // if (accessLevel && accessLevel === "Owner") {
  //   return getAdminRoutes(namespace)
  // }

  // default routes
  return [
    {
      subheader: 'general',
      items: [
        {
          title: 'dashboard',
          path: `${PATH_GROUP.root}/${namespace}/dashboard`,
          icon: ICONS.dashboard
        },
        { 
          title: 'customers',
          path: `${PATH_GROUP.root}/${namespace}/customers`,
          icon: ICONS.clients,
        },
        
        { 
          title: 'invoices & sales',
          path: `${PATH_GROUP.root}/${namespace}/invoices`,
          icon: ICONS.banking,
        },
        { 
          title: 'vehicles',
          requiredPermission: 'module/fleet',
          path: `${PATH_GROUP.root}/${namespace}/fleet/vehicles`,
          icon: ICONS.fleet,
        },
        { 
          title: 'jobs & bookings',
          requiredPermission: 'module/fleet',
          path: `${PATH_GROUP.root}/${namespace}/jobs`,
          icon: ICONS.calendar,
        },
        { 
          title: 'inventory',
          requiredPermission: 'module/fleet',
          path: `${PATH_GROUP.root}/${namespace}/inventory`,
          icon: ICONS.maintenance,
        },
        { 
          title: 'tasks',
          path: `${PATH_GROUP.root}/${namespace}/tasks`,
          icon: ICONS.tasks
        },
        { 
          title: 'integrations',
          path: `${PATH_GROUP.root}/${namespace}/integrations`,
          icon: ICONS.integrations
        },
        { 
          title: 'settings',
          path: `${PATH_GROUP.root}/${namespace}/settings/members`,
          icon: ICONS.settings,
        },
      ]
    },
    {
      subheader: 'accounting',
      items: [
        {
          title: 'analytics',
          path: `${PATH_GROUP.root}/${namespace}/analytics`,
          icon: ICONS.analytics
        },
      ]
    },
  ]
}

function getAdminRoutes(namespace: string) {
  return [
    {
      subheader: 'general',
      items: [
        {
          title: 'dashboard',
          path: `${PATH_GROUP.root}/${namespace}/dashboard`,
          icon: ICONS.dashboard
        },
        { 
          title: 'vehicles',
          path: `${PATH_GROUP.root}/${namespace}/fleet`,
          icon: ICONS.fleet,
          children: [
            {
              title: 'search',
              path: `${PATH_GROUP.root}/${namespace}/fleet/vehicles`
            },
            {
              title: 'create',
              path: `${PATH_GROUP.root}/${namespace}/fleet/create`
            },
          ]
        },
        { 
          title: 'compliance',
          path: `${PATH_GROUP.root}/${namespace}/compliances`,
          search: `?statusList=Passed&expiryTo=${fDateUrl(new Date(currentDatePlus30Days))}`,
          icon: ICONS.compliance,
        },
        { 
          title: 'clients',
          path: `${PATH_GROUP.root}/${namespace}/customers`,
          icon: ICONS.clients,
          children: [
            {
              title: '',
              path: `${PATH_GROUP.root}/${namespace}/customers`,
            },
            {
              title: 'create',
              path: `${PATH_GROUP.root}/${namespace}/customers/create`,
            },
          ]
        },
        { 
          title: 'tasks',
          path: `${PATH_GROUP.root}/${namespace}/tasks`,
          icon: ICONS.tasks
        },
        { 
          title: 'integrations',
          path: `${PATH_GROUP.root}/${namespace}/integrations`,
          icon: ICONS.integrations
        },
        { 
          title: 'settings',
          path: `${PATH_GROUP.root}/${namespace}/settings/members`,
          icon: ICONS.settings,
        },
      ]
    },
    {
      subheader: 'accounting',
      items: [
        {
          title: 'analytics',
          path: `${PATH_GROUP.root}/${namespace}/analytics`,
          icon: ICONS.analytics
        },
        { 
          title: 'invoices',
          path: `${PATH_GROUP.root}/${namespace}/invoices`,
          icon: ICONS.banking,
          children: [
            {
              title: 'search',
              path: `${PATH_GROUP.root}/${namespace}/invoices`
            },
          ]
        },
      ]
    }
  ]
}


