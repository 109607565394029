import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@material-ui/core/styles';
import { useAuth0, User } from '@auth0/auth0-react';
import { Box, Stack, Drawer, Tooltip, CardActionArea, Divider } from '@material-ui/core';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useProject from '../../hooks/useProject';
import { PATH_APP } from '../../routes/paths';
import Logo from '../../components/shared/Logo';
import LogoImageFull from '../../components/shared/brand/LogoImageFull';
import LogoFull from '../../components/shared/LogoFull';
import MyAvatar from '../../components/shared/MyAvatar';
import Scrollbar from '../../components/shared/Scrollbar';
import NavSection from '../../components/shared/NavSection';
import { MHidden } from '../../components/@material-extend';
import ProjectSelect from '../../components/project/ProjectSelect';
import { varFadeInLeft, MotionInView } from '../../components/animate';
import { getSideBarConfig } from './SidebarConfig';
import { DocIllustration } from '../../assets';
import useSubscription from '../../hooks/useSubscription';
import { NavItem, NavigationSection } from '../../@types/navigation';
import { SubscriptionPlan } from '../../@types/subscription';
import { checkUserHasPermission } from '../../utils/auth/checkUserPermission'
import { useGetProjectsQuery } from '../../services/projects'

const DRAWER_WIDTH = 250;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 1.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type GroupSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export const checkMenuItemIsEnabled = (
  menuItem: NavItem,
  user: User | undefined,
): boolean => {
  return menuItem.requiredPermission ? checkUserHasPermission(user, menuItem.requiredPermission) : true;
};

const shouldSecurePath = (path: string) => {
  return path.toLowerCase() !== 'dashboard' && path.toLowerCase() !== 'clients'
}

const transformMenu = (navItems: NavItem[], subscription: SubscriptionPlan, user: User | undefined): NavItem[] => {
  
  return navItems.map((navItem) => {
    let subMenuItems: NavItem[] = [];
    if (navItem.children && navItem.children?.length > 0) {
      subMenuItems = transformMenu(navItem.children, subscription, user);
    }

    var product = subscription.products.find((product) => product.productName.toLowerCase() == navItem.title.toLowerCase());
    if (subMenuItems.length === 0) {
      return {
        ...navItem,
        disabled: shouldSecurePath(navItem.title) && !product,
        authorised: checkMenuItemIsEnabled(navItem, user),
      };
    }

    return {
      ...navItem,
      disabled: shouldSecurePath(navItem.title) && !product,
      authorised: checkMenuItemIsEnabled(navItem, user),
      children: subMenuItems,
    };
  });
};

const transformSection = (sections: NavigationSection[], subscription: SubscriptionPlan, user: User | undefined): NavigationSection[] => {
  
  return sections.map((section) => {
    let subMenuItems: NavItem[] = [];
    if (section.items && section.items?.length > 0) {
      subMenuItems = transformMenu(section.items, subscription, user);
    }

    return {
      ...section,
      items: subMenuItems,
    };
  });
};

export default function GroupSidebar({ isOpenSidebar, onCloseSidebar }: GroupSidebarProps) {
  
  const { user } = useAuth0();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const { project } = useProject();
  const { subscription } = useSubscription();
  const { data: projects = [] } = useGetProjectsQuery();
  const navigate = useNavigate();
  
  var config = getSideBarConfig(id, project?.accessLevel);
  var sidebarConfig = transformSection(config, subscription, user);
  
  function handleProjectSelect(id: string): void {
    // reload the auth provider with the new value
    var project = projects.find((project) => project.identifier == id);
    if (project) {
      navigate(`/group/${project.identifier}/dashboard`);
    } else {
      console.log('Project not found')
    }
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 1,
          pt: 2,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to={PATH_APP.general.projects} sx={{ display: 'inline-flex' }}>
            { isCollapse ? (<Logo />) : (<LogoImageFull />)}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
          </MHidden>
        </Stack>

        {isCollapse ? (
          //https://ui-avatars.com/api/?name=Silverdale+Mechanical
          <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
        ) : (
            <AccountStyle>
              { project ?
                <ProjectSelect
                  onSelected={handleProjectSelect}
                  selected={project}
                  projects={projects}/> : <></>
              }
            </AccountStyle>
            
        )}
      </Stack>
      <Divider />
      <MotionInView variants={varFadeInLeft}>
        <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
      </MotionInView>

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
        >
          <DocIllustration sx={{ width: 1 }} />
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
