import { ISearchCriteria, ISearchResult } from './search'
import { VehicleSummary } from './vehicle'

export type WorkOrderState = {
    order: {
        loading: boolean;
        data: WorkOrder | null;
        modalOpen: boolean;
    },
    orders: {
        loading: boolean;
        totalCount: number;
        data: WorkOrder[];
    }
}

export type WorkOrdersState = {

}

export type WorkOrder = {
    id: string;
    entityId?: string | null;
    entityName?: string | null;
    assetId: string;
    updated: Date;
    date: Date | null;
    total: number | null;
    subTotal: number | null;
    completionDate: Date | null;
    slug: string;
    name: string;
    status: WorkOrderStatus;
    description: string;
    items: WorkItem[];
    vehicle?: VehicleSummary | null;
    invoice?: WorkOrderInvoice | null;
}

export type WorkOrderInvoice = {
    invoiceId: string;
    externalInvoiceId: string;
    updated: Date;
}

export enum WorkOrderStatus {
    Pending = "Pending",
    Complete = "Complete",
}

export type WorkItem = {
    id: string;
    itemReferenceId: string;
    type: WorkItemType;
    slug: string;
    title: string;
    description: string;
    quantity: number;
    price: number | null;
    taxRate: number | null; 
    total: number | null;
    subTotal: number | null;
}

export enum WorkItemType {
    Service = "Service",
    ServicePlan = "ServicePlan",
    ServiceTask = "ServiceTask",
}

// requests
export interface WorkOrderCreateRequest {
    vehicleId: string;
    date: Date;
    items?: {
        id: string;
        type: WorkItemType;
    }[];
}

export interface WorkOrderUpdateRequest {
    workOrderId: string;
    completionDate: Date;
    vehicleKilometers: number;
    createInvoice: boolean;
}

export interface WorkOrderItemRequest {
    workOrderId: string;
    serviceTaskId: string;
    title: string | undefined;
}

export interface WorkOrderItemUpdateRequest {
    workOrderId: string;
    itemId: string;
    title: string;
    description: string;
    quantity: number;
    price: number | null;
    taxRate: number | null; 
    total: number | null;
    subTotal: number | null;
}

// results
export type WorkOrderCreateResult = {
    allow: boolean;
    result: WorkOrder;
}

export interface IWorkOrderSearchCriteria extends ISearchCriteria {
    vehicles: string[];
    status: WorkOrderStatus[];
}

export interface IWorkOrderSearchResult extends ISearchResult<WorkOrder> {
  
}