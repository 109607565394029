import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack5';
import { Stack, Typography, Checkbox, FormControlLabel, TextField, Box, Grid, Paper } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import axios from '../../../utils/axios'
import useProject from '../../../hooks/useProject'
import { useDispatch } from '../../../redux/store';
import * as Yup from 'yup';
import { setConfiguration } from '../../../redux/slices/vehicles'
import { ComplianceTypeConfiguration, ComplianceType } from '../../../@types/compliance'
import HandleBarsPreview from '../../shared/text/HandleBarsPreview'

type ComplianceSettingsFormProps = {
  projectIdentifer: string;
  complianceConfiguration: ComplianceTypeConfiguration;
  complianceTypeName: string;
  complianceTypeCode: 'WOF' | 'COF';
};

type ComplianceSettingsFormValues = {
  createComplianceReminder: boolean;
  complianceTrackingEnabled: boolean;
  processingEnabled: boolean;
  emailReminderFromName: string;
  emailRemindersEnabled: boolean;
  emailReminderSendToClient: boolean;
  emailReminderBody: string;
  emailMessageTemplate: string | null;
  reminderAdditionalEmail: string;
  reminderReplyToEmail: string;
  textRemindersEnabled: boolean;
  textMessageTemplate: string | null;
  reminderToleranceDays: number;
};

export const FormSchema = Yup.object().shape({
  createComplianceReminder: Yup.boolean().required(),
  complianceTrackingEnabled: Yup.boolean().required(),
  processingEnabled: Yup.boolean().required(),
  emailReminderFromName: Yup.string().nullable(),
  emailRemindersEnabled: Yup.boolean().required(),
  emailReminderSendToClient: Yup.boolean().required(),
  emailReminderBody: Yup.string().nullable(),
  emailMessageTemplate: Yup.string().nullable(),
  reminderAdditionalEmail: Yup.string().nullable(),
  textRemindersEnabled: Yup.boolean().required(),
  textMessageTemplate: Yup.string().nullable(),
  reminderToleranceDays: Yup.number().min(1, 'Must be greater than zero days').max(60, 'Must be less than 60 days'),
});

export default function ComplianceSettingsForm({ projectIdentifer, complianceConfiguration, complianceTypeName, complianceTypeCode }: ComplianceSettingsFormProps) {

  const dispatch = useDispatch();
  const { project } = useProject();
  const { enqueueSnackbar } = useSnackbar();
  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<ComplianceSettingsFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      createComplianceReminder: true,
      complianceTrackingEnabled: complianceConfiguration.trackingEnabled,
      processingEnabled: complianceConfiguration.enabled,
      emailRemindersEnabled: complianceConfiguration.reminderConfiguration.emailConfig.enabled,
      emailReminderBody: complianceConfiguration.reminderConfiguration.emailConfig.emailBody,
      emailMessageTemplate: complianceConfiguration.reminderConfiguration.emailConfig.messageTemplate,
      emailReminderSendToClient: complianceConfiguration.reminderConfiguration.emailConfig.sendReminderToClient,
      emailReminderFromName: complianceConfiguration.reminderConfiguration.emailConfig.reminderFromName,
      reminderAdditionalEmail: complianceConfiguration.reminderConfiguration.emailConfig.reminderAdditionalEmail,
      reminderReplyToEmail: complianceConfiguration.reminderConfiguration.emailConfig.reminderReplyToEmail,
      textRemindersEnabled: complianceConfiguration.reminderConfiguration.textMessageConfig.enabled,
      textMessageTemplate: complianceConfiguration.reminderConfiguration.textMessageConfig.messageTemplate,
      reminderToleranceDays: complianceConfiguration.reminderConfiguration.reminderToleranceDays,
    }
  })

  const emailRemindersEnabledWatch = watch('emailRemindersEnabled');
  const testRemindersEnabledWatch = watch('textRemindersEnabled');
  const textMessageTemplateWatch = watch('textMessageTemplate');
  const emailMessageTemplateWatch = watch('emailMessageTemplate');

  const onSubmit = async (data: ComplianceSettingsFormValues) => {

    try {
      // post the configuration
      var response = await axios.put<any, any>(`/api/projects/${projectIdentifer}/compliance-config`, {
        complianceType: complianceTypeCode == "WOF" ? ComplianceType.WOF : ComplianceType.COF,
        createComplianceReminder: data.createComplianceReminder,
        complianceTrackingEnabled: data.complianceTrackingEnabled,
        processingEnabled: data.processingEnabled,
        emailRemindersEnabled: data.emailRemindersEnabled,
        emailReminderBody: data.emailReminderBody,
        emailMessageTemplate: data.emailMessageTemplate,
        emailReminderSendToClient: data.emailReminderSendToClient,
        emailReminderFromName: data.emailReminderFromName,
        reminderAdditionalEmail: data.reminderAdditionalEmail,
        reminderReplyToEmail: data.reminderReplyToEmail,
        textRemindersEnabled: data.textRemindersEnabled,
        textMessageTemplate: data.textMessageTemplate,
        reminderToleranceDays: data.reminderToleranceDays,
      });

      // load the new configuration state
      dispatch(setConfiguration(response.data));
      enqueueSnackbar('Vehicle settings updated', { variant: 'success' });

      var repsonseData = complianceTypeCode == "WOF" ? response.data.complianceConfiguration.wofConfiguration : response.data.complianceConfiguration.cofConfiguration;
      reset({
        createComplianceReminder: true,
        complianceTrackingEnabled: repsonseData.trackingEnabled,
        processingEnabled: repsonseData.enabled,
        emailRemindersEnabled: repsonseData.reminderConfiguration.emailConfig.enabled,
        emailReminderBody: repsonseData.reminderConfiguration.emailConfig.emailBody,
        emailReminderSendToClient: repsonseData.reminderConfiguration.emailConfig.sendReminderToClient,
        emailReminderFromName: repsonseData.reminderConfiguration.emailConfig.reminderFromName,
        reminderAdditionalEmail: repsonseData.reminderConfiguration.emailConfig.reminderAdditionalEmail,
        reminderReplyToEmail: repsonseData.reminderConfiguration.emailConfig.reminderReplyToEmail,
        textRemindersEnabled: repsonseData.reminderConfiguration.textMessageConfig.enabled,
        textMessageTemplate: repsonseData.reminderConfiguration.textMessageConfig.messageTemplate,
        reminderToleranceDays: repsonseData.reminderConfiguration.reminderToleranceDays,
      });
    }
    catch (err) {
      enqueueSnackbar('Error occurred updating vehicle settings', { variant: 'error' });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} md={4} sm={6}>
            <Paper
              sx={{
                p: 2,
                width: 1,
                bgcolor: 'background.neutral'
              }}
            >
              <Typography variant="subtitle1">General Settings</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} sm={6}>
            <Stack spacing={2}>
              <Typography variant="subtitle1">Compliance Processing</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                If this feature is enabled, when a vehicle is created the latest compliance information will be
                populated automatically (i.e the latest {complianceTypeCode} details including the expiry of date of the last inspection)
              </Typography>
              <Controller
                name="processingEnabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label={`Enable ${complianceTypeName} Processing`}
                    control={
                      <Checkbox
                        {...field}
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} md={4} sm={6}>
            <Paper
              sx={{
                p: 2,
                width: 1,
                bgcolor: 'background.neutral'
              }}
            >
              <Typography variant="subtitle1">Reminders</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} sm={6}>
            {/* <Stack spacing={2} sx={{ mb: 3 }}>
              <Typography variant="subtitle1">Compliance Tracking</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Compliance tracking allows vehicle compliances to subscribe to updates from NZTA and ensures that all compliance information is kept up to date automatically. This feature may incur additional costs if subscription thresholds are exceeded.
              </Typography>
              <Controller
                name="complianceTrackingEnabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="Compliance Tracking Enabled"
                    control={
                      <Checkbox
                        {...field}
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                  />
                )}
              />
            </Stack> */}
            <Stack spacing={2}>
              <Typography variant="subtitle1">Compliance Reminders</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Reminders can be sent by email or text when a vehicle compliance is near its due date. This feature should be enabled if you provide
                compliance inspection services so that your customers know when to bring their vehicle to you for an inspection.
              </Typography>
              <Typography variant="subtitle1">Reminder Tolerance Days</Typography>
              <Stack direction="row">
                <Typography variant="body2" sx={{ mb: 2, mr: 2 }}>
                  The number of days before compliance expiry that notifications should be sent. We recommend setting this to 14 days minimum.
                </Typography>
                <Controller
                  name="reminderToleranceDays"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Reminder Tolerance Days"
                      type="number"
                      sx={{ width: 300 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>


              <Typography variant="subtitle1">Email Reminders</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                This section configures settings for compliance reminder emails. It is important that the below fields are configured with your branding so your customers can identify your business.
              </Typography>
              <Controller
                name="emailRemindersEnabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label={`Enable ${complianceTypeCode} Email Reminders`}
                    control={
                      <Checkbox
                        {...field}
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                  />
                )}
              />
              {emailRemindersEnabledWatch ?
                <>
                  <Controller
                    name="emailReminderSendToClient"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label={`Send ${complianceTypeCode} Reminder To Client`}
                        control={
                          <Checkbox
                            {...field}
                            onChange={e => field.onChange(e.target.checked)}
                            checked={field.value}
                          />
                        }
                      />
                    )}
                  />
                  <Controller
                    name="emailReminderFromName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        label={`${complianceTypeCode} Email From Address`}
                        helperText="This will set the from name for the reminder email that is sent. If not set it will use the name of the project."
                      />
                    )}
                  />
                  <Controller
                    name="reminderReplyToEmail"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        label="ReplyTo Email Address"
                        helperText="This is the email address that will be used when the client replies to the notification. If this is not supplied the project email will be used"
                      />
                    )}
                  />
                  <Controller
                    name="reminderAdditionalEmail"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        label="Additional Email Address"
                        helperText="This is an additional email address to send the reminder to. This will normally be sent to an internal email and used to track the reminders that are being sent to clients."
                      />
                    )}
                  />
                  <Controller
                    name="emailReminderBody"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        label={`${complianceTypeCode} Email Body (optional)`}
                        helperText={`The email will highlight the details of the vehicle and when the ${complianceTypeCode} is due. Populate this section with details about how your client should get in touch to book the inspection`}
                        multiline
                        rows={7}
                      />
                    )}
                  />
                </>
                : null}
            </Stack>
            <Stack spacing={2} sx={{ mt: 3 }}>
              <Typography variant="subtitle1">Text Reminders</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Text reminders can be sent to the vehicle owner if the contact has a mobile phone number set (additional charges apply, please refer to your subscription).
              </Typography>
              <Controller
                name="textRemindersEnabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label={`Enable Text Message Reminders`}
                    control={
                      <Checkbox
                        {...field}
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                  />
                )}
              />
              {testRemindersEnabledWatch && (
                <>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    You can customise the message that is sent using the template below. The variables listed below will be substituted when the message is sent (messages with more than 160 characters will be broken into message parts and each will incur a charge, we recommend keeping the message below 160 characters)
                  </Typography>
                  <Controller
                    name="textMessageTemplate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id={`compliance-text-template-preview-${complianceTypeCode}`}
                        label={`${complianceTypeCode} text message`}
                        helperText={`Type the message template above to view the preview message below`}
                        multiline
                        rows={4}
                      />
                    )}
                  />
                  <HandleBarsPreview
                    messageTemplate={textMessageTemplateWatch}
                    data={{
                      vehicle_identifier: "ABC123",
                      vehicle_make: "TOYOTA",
                      vehicle_model: "COROLLA",
                      compliance_type: complianceTypeCode,
                      compliance_expiry_date: "2024/01/01",
                      owner: "Joe Bloggs",
                      organisation_name: project.projectName,
                      organisation_phone: project.branding.contactPhoneNumber,
                    }}
                  />
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            Update Settings
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}