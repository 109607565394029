import { Box, Typography, Select, InputBase, MenuItem } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Project } from '../../@types/project'
import ProjectAvatar from '../project/ProjectAvatar'

type ProjectSelectProps = {
  onSelected?(identifier: string) : void;
  selected: Project | null;
  projects: Project[];
};

const MenuStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
  }));

export default function ProjectSelect({ projects, selected, onSelected }: ProjectSelectProps) {

  return (
    <Select
        autoWidth={true}
        value={selected?.identifier || ""}
        defaultValue=""
        input={<InputBase></InputBase>}
        onChange={(event) => {
            onSelected && onSelected(event.target.value)
        }}
    >
        {
            projects?.map((project) => (
                <MenuItem key={project.identifier} value={project.identifier}>
                    <MenuStyle>
                        <ProjectAvatar project={project}/>
                        <Box sx={{ ml: 1 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                {project?.projectName}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {project?.accessLevel}
                            </Typography>
                        </Box>
                    </MenuStyle>
                </MenuItem>
            )) || []
        }
    </Select>
  );
}
