import { Vehicle } from '../../@types/vehicle';
import { IVehicleSearchCriteria } from '../../@types/vehicles';
import { Project } from '../../@types/project';
import VehicleTable from './table/VehicleTable'

export type VehicleSearchProps = {
    project: Project;
    vehicles: Vehicle[];
    isLoading: boolean;
    totalRows: number;
    showSearch?: boolean;
    search: IVehicleSearchCriteria;
    defaultOrder?: 'asc' | 'desc';
    defaultOrderBy?: string;
    onSetSearch(query: any): void;
}

export default function VehicleSearch({
    vehicles,
    isLoading,
    totalRows,
    search,
    project,
    onSetSearch,
    //onSearch,
    showSearch = true,
    defaultOrder = 'desc',
    defaultOrderBy = 'updated'
}: VehicleSearchProps) {

    function handleChangePage(newPage: number) {
        onSetSearch({
            ...search,
            pageNumber: newPage
        });
    };

    function handleChangePageSize(newPageSize: number) {
        onSetSearch({
            ...search,
            pageSize: newPageSize,
            pageNumber: 0
        });
    };

    function handleSort(orderBy: string, order: 'asc' | 'desc') {
        onSetSearch({
            ...search,
            order: order,
            orderBy: orderBy,
        });
    }

    const handleChangeSearch = (search: IVehicleSearchCriteria) => {
        onSetSearch(search);
    }

    return (
        <VehicleTable
            vehicles={vehicles}
            totalRows={totalRows}
            isLoading={isLoading}
            showSearch={showSearch}
            search={search}
            project={project}
            handleChangePage={handleChangePage}
            handleSearchChange={handleChangeSearch}
            handleChangePageSize={handleChangePageSize}
            handleSort={handleSort}
            defaultRowsToDisplay={50}
        />
    );
}
