import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTheme, styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { IVehicleSearchCriteria } from '../../@types/vehicles';
import { Box, Card, Button, CardHeader, CardContent, Typography, Stack, Skeleton, Chip } from '@material-ui/core';
import Label from '../shared/Label';
import { Project } from '../../@types/project'
import { ComplianceType } from '../../@types/compliance'
import { useGetRecentVehiclesQuery } from '../../services/vehicles'
import { Vehicle } from '../../@types/vehicle';
import { fDateShort } from '../../utils/formatTime';
import { fNumber } from '../../utils/formatNumber';
import flagOutline from '@iconify/icons-eva/flag-fill';
import calendarOutline from '@iconify/icons-eva/calendar-fill';
import SpeedIcon from '@material-ui/icons/Speed';
import ProjectRouterLink from '../shared/ProjectRouterLink';
import Pagination from '@material-ui/core/Pagination';
import EmptyContent from '../shared/EmptyContent';
import { getVehicleDefaultMeter } from '../vehicle/vehicle-utils';

export default function RecentVehicles({ project }: { project: Project }) {

  const [search, setSearch] = useState<IVehicleSearchCriteria>({
    pageNumber: 0,
    pageSize: 20,
    order: 'desc',
    orderBy: 'updated',
    clients: [],
    makes: [],
    vehicleIdentifiers: [],
    text: null,
    projectIdentifier: "",
  });

  const PAGE_SIZE = 5;
  const { data = [], isLoading } = useGetRecentVehiclesQuery(search);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pagedVehicles, setPagedVehicles] = useState<{ page: Number, count: Number, vehicles: Vehicle[] }>({
    page: 1,
    count: 0,
    vehicles: data
  });

  const handleChangePage = (event: any, page: number) => {
    setPage(page);
    setPagedVehicles(prevState => {
      return {
        ...prevState,
        ...{
          vehicles: data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
        }
      };
    });
  }

  useEffect(() => {
    // reset page to 1 when new data received
    //console.log(data)
    setPage(1);
    //const newObj = data;
    setPageCount(Math.ceil(data.length / PAGE_SIZE));
    setPagedVehicles(prevState => {
      return {
        ...prevState,
        ...{
          vehicles: data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
        }
      };
    });
    //console.log('vehicles', data);
  }, [data]);



  if (isLoading) {
    return (
      <Card>
        <CardHeader title="Recent Vehicles" />
        <CardContent>
          <Skeleton height="30px" />
          <Skeleton height="30px" />
          <Skeleton height="30px" />
          <Skeleton height="30px" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Recent Vehicles"
        action={
          <Button
            to={`/group/${project.identifier}/fleet/vehicles`}
            size="small"
            color="inherit"
            component={RouterLink}
            endIcon={<Icon icon={arrowIosForwardFill} />}
          >
            View All
          </Button>
        }
      />

      <CardContent>
        {/* <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Chip
              sx={{ mb: 2 }}
              //icon={<Iconify width={24} height={22} icon="eva:lock-outline" />}
              //onDelete={() => {}}
              label="Expired WOF/COF"
              variant="outlined"
            />
            <Chip
              sx={{ mb: 2, ml: 1, cursor: 'pointer' }}
              //icon={<Iconify width={24} height={22} icon="eva:lock-outline" />}
              //onDelete={() => {}}
              label="Service Due"
              variant="filled"
            />
          </Stack>
          <Typography>Reset</Typography>
        </Stack> */}
        {pagedVehicles.vehicles.length === 0 && (
          <Box>
            <EmptyContent
              title="No vehicles to display"
              description={'No vehicles match your search criteria. '}
              imgHeight={70}
              displayLayout="row"
            />
          </Box>
        )}
        {
          pagedVehicles.vehicles.map((vehicle: Vehicle) => (<VehicleItem key={`vehicle-item-${vehicle.id}`} vehicle={vehicle} />))
        }
        <Stack justifyContent="flex-end" direction="row">
          <Pagination count={pageCount} page={page} onChange={handleChangePage} />
        </Stack>
      </CardContent>

    </Card>
  );
}

type VehicleItemProps = {
  vehicle: Vehicle;
}

const VehicleCardStyle = styled(Stack)(({ theme }) => ({
  padding: '3px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.neutral,
    borderRadius: '8px',
  },
}));

function VehicleItem({ vehicle }: VehicleItemProps) {

  const theme = useTheme();
  const navigate = useNavigate();

  return (
      <VehicleCardStyle direction="row" spacing={2} onClick={() => navigate(`../fleet/vehicles/${vehicle.id}/detail`)}>
        <Stack direction="column" sx={{ mt: 0.5 }} flexGrow={1}>
          <ProjectRouterLink
            to={`fleet/vehicles/${vehicle.id}/detail`}
            showUnderline={false}>
            <Label
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
              variant={'ghost'}
            >
              {vehicle.vehicleIdentifier}
            </Label>
          </ProjectRouterLink>
          <Stack direction="column" sx={{ mt: 0.5, ml: 0.5 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>{vehicle.make || '-'} {vehicle.model || '-'}</Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: -0.5 }}
            >
              {vehicle.entity.name}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexWrap: 'wrap', p: 0.5, m: 0.5, minWidth: 190 }}>
          <Box sx={{ mr: 1, minWidth: '160px' }}>
            <Stack direction="row" justifyContent="flex-start">
              <SpeedIcon fontSize='small'/>
              <Typography variant="caption" sx={{ pl: 1 }}>{getVehicleDefaultMeter(vehicle)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start">
              <Icon icon={flagOutline} style={{ fontSize: '18px' }} />
              <Typography variant="caption" sx={{ pl: 1 }}>{vehicle.lastService?.odometerCompleted ? `Serviced ${fNumber(vehicle.lastService?.odometerCompleted)} km\'s` : '-'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start">
              <Icon icon={calendarOutline} style={{ fontSize: '18px' }} />
              <Typography variant="caption" sx={{ pl: 1 }}>{vehicle.compliance?.expiryDate ? `${vehicle.compliance?.type == ComplianceType.WOF ? 'WOF Due ' : 'COF Due '}${fDateShort(vehicle.compliance?.expiryDate)}` : '-'}</Typography>
            </Stack>
          </Box>
        </Box>
      </VehicleCardStyle>
  );
}
