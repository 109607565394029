import { useState, useEffect } from 'react';
import { Checkbox, List, ListItem, ListItemText } from '@material-ui/core';
import { InvoiceStatus } from '../../../@types/invoice';


type InvoiceStatusSearchMenuProps = {
    statuses: InvoiceStatus[];
    onChange(statuses: InvoiceStatus[]): void;
}

export default function InvoiceStatusSearchMenu({ statuses, onChange }: InvoiceStatusSearchMenuProps) {

    const allStatuses = [InvoiceStatus.Draft, InvoiceStatus.Pending, InvoiceStatus.Submitted, InvoiceStatus.Authorised, InvoiceStatus.Paid, InvoiceStatus.Deleted, InvoiceStatus.Voided]
    const [statusWrappers, setStatusWrappers] = useState<{ status: InvoiceStatus, active: boolean }[]>([]);

    const handleChecked = (checked: boolean, status: InvoiceStatus) => {
        if (checked) {
            onChange([...statusWrappers.filter(_ => _.active).map((wrapper) => wrapper.status), status]);
        } else {
            onChange(statusWrappers.filter(_ => _.active && _.status !== status).map((wrapper) => wrapper.status));
        }
    }

    useEffect(() => {
        const newStatuses: { status: InvoiceStatus, active: boolean }[] = [];
        allStatuses.forEach((status) => {
            newStatuses.push({ status, active: statuses.find(_ => _ === status) !== undefined })
        })
        setStatusWrappers(newStatuses);
    }, [statuses]);

    return (
        <List dense={true}>
            {statusWrappers.map((statusWrapper) => {
                return (
                    <ListItem>
                        <Checkbox checked={statusWrapper.active} onChange={e => handleChecked(e.target.checked, statusWrapper.status)}/>
                        <ListItemText primary={statusWrapper.status} />
                    </ListItem>
                )
            })}
        </List>
    )
}