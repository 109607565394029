
import { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { Icon } from '@iconify/react';
import infoFill from '@iconify/icons-eva/info-fill';
import { useTheme } from '@material-ui/core/styles';

export default function InfoToolTip({ description }: { description?: string }) {

    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    if (!description) {
        return null;
    }
    return (
        <div
            style={{ display: "inline" }}
            onMouseOver={() => handleTooltipOpen()}
            onMouseLeave={() => handleTooltipClose()}
        >
            <Tooltip
                title={description}
                onClose={handleTooltipClose}
                open={open}
                onClick={handleTooltipOpen}
                sx={{ cursor: 'pointer' }}
            >
                <Icon icon={infoFill} color={theme.palette.grey[500]} />
            </Tooltip>
        </div>
    );
}
