import { useState, ReactNode } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import closeFill from '@iconify/icons-eva/close-outline';
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  BoxProps,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  ListItemButtonProps,
  Typography
} from '@material-ui/core';
import Label from './Label';

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: {
    pathname: string,
    search?: string
  };
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const FeatureUpgradeStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
}));

type NavItemProps = {
  title: string;
  path: string;
  search?: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  disabled?: boolean;
  children?: {
    title: string;
    path: string;
    search?: string;
    disabled?: boolean;
  }[];
};

type NavItemWrapperProps = {
  item: NavItemProps;
  isActiveSub: boolean;
  isShow?: boolean | undefined;
};

function NavItem({ item, isShow }: { item: NavItemProps; isShow?: boolean | undefined }) {
  
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, search, icon, info, children, disabled } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);
  const [open2, setOpen2] = useState(true);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          disableGutters
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
              
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path, search, disabled } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                return (
                  <ListItemStyle
                    disableGutters
                    key={title}
                    component={RouterLink}
                    to={{ pathname: path, search: search }}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main'
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
            <Collapse in={open2 && disabled} timeout="auto" unmountOnExit>
              <FeatureUpgradeStyle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Label variant="ghost" color="warning">Upgrade</Label>
                  <Box
                    component={Icon}
                    onClick={() => setOpen2(false)}
                    icon={closeFill}
                    sx={{ width: 20, height: 20, ml: 1, cursor: 'pointer' }}
                  />
                </Box>
                <Typography sx={{ padding: 1 }} variant="body2">This feature is not available on your current plan. Please upgrade your subscription to activate.</Typography>
              </FeatureUpgradeStyle>
            </Collapse>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      disableGutters
      component={RouterLink}
      to={{ pathname: path, search: search }}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={item.title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    subheader: string;
    items: NavItemProps[];
  }[];
}

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {/* {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>} */}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
