import { useState } from 'react'
import { styled } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Card, Stack, Link, Container, Typography } from '@material-ui/core';
import AuthLayout from '../../layouts/AuthLayout';
import Page from '../../components/shared/Page';
import { MHidden } from '../../components/@material-extend';
import { LoadingButton } from '@material-ui/lab';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const welcomes = [
  {
    banner: 'Welcome Back!',
    message: "Let's get you straight to your workshop, it's missed you and we have too"
  },
  {
    banner: 'Hello Again!',
    message: "It's good to see you, let's get you started"
  },
  {
    banner: 'Kia ora!',
    message: "Nau mai haere mai! Are we both keen to get started or is it just me?"
  },
  {
    banner: 'Hello There!',
    message: "Are you looking to manage your workshop like never before? Well you have come to the right place"
  }
]

export default function Login() {

  var welcome = welcomes[Math.floor(Math.random() * welcomes.length)];
  const [loginLoading, setLoginLoading] = useState(false);
  const { loginWithRedirect } = useAuth0();

  const handleSignUpAuth0 = async () => {
    try {
      await loginWithRedirect({ screen_hint: 'signup' });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoginAuth0 = async () => {
    try {
      setLoginLoading(true);
      await loginWithRedirect();
    } catch (error) {
      setLoginLoading(false);
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | AutoHive">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link sx={{ cursor: 'pointer' }} underline="none" variant="subtitle2" onClick={handleSignUpAuth0}>
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 3 }}>
            {welcome.banner}
          </Typography>
          <Typography sx={{ px: 5, mb: 5 }}>
            {welcome.message}
          </Typography>
          <img src="/static/vehicles/vehicle-loading-2.gif" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to AutoHive
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Click login to get started</Typography>
            </Box>
          </Stack>
          <LoadingButton
            loading={loginLoading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleLoginAuth0}
          >
            Login
          </LoadingButton>
          <Typography sx={{ mt: 4 }} variant="body2">
            Don’t have an account? &nbsp;
            <Link style={{ cursor: 'pointer' }} underline="none" variant="subtitle2" component={'a'} onClick={handleSignUpAuth0}>
              Get started
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
