import { motion } from 'framer-motion';
import { styled } from '@material-ui/core/styles';
import { Box, Typography, Container } from '@material-ui/core';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/shared/Page';
import { SeverErrorIllustration } from '../assets';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(10)
}));

export default function Unauthorised() {
  return (
    <RootStyle title="Unauthorised | AutoHive">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <SeverErrorIllustration sx={{ height: 240, my: { xs: 5, sm: 10 } }} />
            </motion.div>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Oops... you're not supposed to be here!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              You are not authorised to see the page you are trying to access. Get in touch with your project administrator to be granted access.
            </Typography>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
