import { visuallyHidden } from '@material-ui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';

type SortingSelectingHeadProps = {
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (property: string) => void;
  onSelectAllClick: (checked: boolean) => void;
  showSelectAll: boolean;
  order: 'asc' | 'desc';
  headLabel: {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    sortable: boolean;
    width?: number
  }[];
};

export default function SortingSelectingHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  showSelectAll
}: SortingSelectingHeadProps) {
  return (
    <TableHead>
      <TableRow>
        { showSelectAll ?
          <TableCell padding="checkbox">
            
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onSelectAllClick(event.target.checked)
                }
                inputProps={{
                  'aria-label': 'select all'
                }}
              /> 
          </TableCell>: <></>
        }
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            width={headCell.width || 'auto'}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            { headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            : headCell.label }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
