import { useState, useEffect, useMemo } from 'react'
import { Autocomplete, TextField } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { SelectOption } from '../../../@types/search'

type GenericMultiSelectListProps = {
    handleSearch(searchValue: string | null): Promise<SelectOption[]>;
    onChange?: (selected: SelectOption[]) => void;
    value?: SelectOption[] | undefined;
    label: string;
    error: any;
};

export default function GenericMultiSelectList({ onChange, error, label, value, handleSearch }: GenericMultiSelectListProps) {

    const [options, setOptions] = useState<SelectOption[]>([]);
    const [loading, setLoading] = useState(false);
    const debouncedChangeHandler = useMemo(() => debounce((userInput) => handleChangeSearch(userInput), 500), []);

    const handleChangeSearch = async (value: string | null) => {
        try {
            setLoading(true);
            var result = await handleSearch(value);
            setOptions(result);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleChangeSearch(null)
      }, [setOptions])

    return (
        <Autocomplete
            fullWidth
            multiple
            value={value}
            filterOptions={(x) => x}
            loading={loading}
            options={options || []}
            getOptionLabel={(option) => option.name}
            onInputChange={(event, value) => debouncedChangeHandler(value)}
            onChange={(event, newValue) => {
                if (newValue) {
                    onChange && onChange(newValue);
                }
            }}
            renderInput={(params) => 
                <TextField
                    {...params}
                    label={label}
                    error={Boolean(error)}
                    helperText={error?.message}
                />
            }
        />
    );
}
