import { forwardRef } from 'react'
import { Typography, Stack, Avatar, AvatarProps, Tooltip } from '@material-ui/core';
import { User } from '../../@types/user'

export interface UserAvatarTextProps extends AvatarProps {
    user: User | null | undefined;
    displayText?: boolean;
    size?: number;
    tooltip?(user: User): string;
  }

  
const UserAvatarText = forwardRef<HTMLDivElement, UserAvatarTextProps>(({ user, size, displayText, tooltip, children, sx, ...other }, ref) => {
      
    if (!user) {
        return <Typography>-</Typography>
    }

    return (
        <Stack direction="row">
            <Tooltip key={user?.id} title={tooltip ? tooltip(user) : user?.displayName}>
                <Avatar
                    ref={ref}
                    key={user?.id}
                    alt={user?.displayName}
                    src={user?.imageUrl}
                    sx={{ width: size ?? 20, height: size ?? 20, mt: 0.2 }}
                />
            </Tooltip>
            {displayText && (<Typography variant="subtitle2" sx={{ ml: 0.5 }}>{user?.displayName || ''}</Typography>)}
        </Stack>
    );
});

export default UserAvatarText;