import { useState } from 'react'
import { Box, Stack, Divider, Typography } from '@material-ui/core';
import { ServiceItem } from '../../@types/maintenance'
import { InventoryItemSelectList } from './index'
import ListSkeletonLoad from '../shared/list/ListSkeletonLoad'
import { useSnackbar } from 'notistack5';
import ServiceItemTable from './tables/ServiceItemTable'
import ServiceItemForm from './forms/ServiceItemForm'
import ModalContainer from '../shared/modal/ModalContainer'
import { fNumberFinancial } from '../../utils/formatNumber';
import { useGetServiceItemsQuery, useDeleteServiceItemMutation, useAddServiceItemMutation } from '../../services/services';

type ServiceItemListProps = {
    serviceId: string;
    disabled?: boolean;
};

function ServiceItemList({ serviceId, disabled = false }: ServiceItemListProps) {

    const { enqueueSnackbar } = useSnackbar();
    const [modalOpen, setModalOpen] = useState(false);
    const [editItem, setEditItem] = useState<ServiceItem | null>(null);
    const { data: items = [], isLoading } = useGetServiceItemsQuery(serviceId);
    const [deleteServiceItem] = useDeleteServiceItemMutation();
    const [addServiceItem] = useAddServiceItemMutation();

    const handleEditItem = async (item: ServiceItem) => {
        setEditItem(item);
        setModalOpen(true);
    }

    const handleItemAdded = async (id: string | undefined, value: string | undefined) => {
        try {
            // add the service item
            await addServiceItem({
                serviceId: serviceId,
                inventoryItemId: id,
                title: '',
                description: value,
                price: 0,
                quantity: 1,
            }).unwrap();
        } catch (response) {
            if (response.data.errors && Object.keys(response.data.errors).length > 0) {
                enqueueSnackbar(`Adding service item failed with error: ${response.data.errors[Object.keys(response.data.errors)[0]]}`, { variant: 'error', autoHideDuration: 3000 });
            } else {
                enqueueSnackbar(`Adding service item failed`, { variant: 'error', autoHideDuration: 3000 });
            }
        }
    }

    const handleItemDeleted = async (item: ServiceItem) => {
        try {
            // add the task
            await deleteServiceItem({
                serviceId: serviceId,
                serviceItemId: item.itemId,
            }).unwrap();
        } catch (error) {
            if (error.errors && Object.keys(error.errors).length > 0) {
                enqueueSnackbar(`Deleting service item failed with error: ${error.errors[Object.keys(error.errors)[0]]}`, { variant: 'error' });
            } else {
                enqueueSnackbar(`Deleting service item failed`, { variant: 'error' });
            }
        }
    }

    if (isLoading) {
        return <ListSkeletonLoad />;
    }

    return (
        <>
            <InventoryItemSelectList
                label="Add service item..."
                excludedIds={[]}
                disabled={disabled}
                onChange={(option) => {
                    if (option) {
                        handleItemAdded(option.id, option.description);
                    }
                }}
            />
            <Box sx={{ height: '20px' }} />
            <ServiceItemTable
                loading={false}
                items={items}
                onEditItem={handleEditItem}
                onDeleteItem={handleItemDeleted}
            />
            <Box sx={{ pl: 2, pr: '100px' }}>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2">Sub-Total</Typography>
                        <Typography variant="subtitle2">${fNumberFinancial(items.reduce((a, v) => a = a + (v.subTotal ?? 0), 0))}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2">GST</Typography>
                        <Typography variant="subtitle2">${fNumberFinancial(items.reduce((a, v) => a = a + (v.total ?? 0) - (v.subTotal ?? 0), 0))}</Typography>
                    </Stack>
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5">Total:</Typography>
                        <Typography variant="h5">${fNumberFinancial(items.reduce((a, v) => a = a + (v.total ?? 0), 0))}</Typography>
                    </Stack>
                </Stack>
            </Box>

            <ModalContainer
                title="Edit Service Item"
                open={modalOpen}
                component={
                    <ServiceItemForm
                        item={editItem}
                        serviceId={serviceId}
                        onItemUpdated={() => {
                            setEditItem(null);
                            setModalOpen(false);
                        }}
                    />
                }
                handleConfirmModalClose={() => setModalOpen(false)}
            />
        </>
    );
}

export default ServiceItemList;