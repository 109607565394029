import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { ComplianceCreateForm } from '../index'
import { RootState, useSelector } from '../../../redux/store';
import { getComplianceCreateModalOpen } from '../../../redux/slices/compliance';


type CreateComplianceModalProps = {
  vehicleId: string;
  handleModalClose(): void;
};

export default function CreateComplianceModal({ vehicleId, handleModalClose } : CreateComplianceModalProps) {
  
  const modalOpen = useSelector(getComplianceCreateModalOpen);
  
  return (
      <Dialog 
        fullWidth
        open={modalOpen}
        onClose={handleModalClose}
      >
        <DialogTitle>Create Compliance</DialogTitle>
        <DialogContent dividers>
          <ComplianceCreateForm vehicleId={vehicleId}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
