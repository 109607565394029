import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Login from '../pages/authentication/Login';

type AuthGuardProps = {
  children: ReactNode;
  requiredPermission?: string;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  
  const { isAuthenticated, isLoading } = useAuth0();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
