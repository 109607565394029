import { User } from '../@types/user'
import { RegistrationCompleteRequest } from '../@types/authentication'
import { api } from './api'

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getUser: builder.query<User, string>({
      query: (id: string) => ({
        url: `users/${id}`,
        method: 'GET',
      }),
      providesTags: ['User']
    }),

    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: `users/current`,
        method: 'GET',
      }),
      providesTags: ['User']
    }),

    refreshCurrentUser: builder.mutation<User, void>({
      query: () => ({
        url: `users/current/refresh`,
        method: 'POST',
      }),
      invalidatesTags: ['User']
    }),

    validateRegistration: builder.mutation<User, RegistrationCompleteRequest>({
      query: (request: RegistrationCompleteRequest) => ({
        url: `auth/register/validate`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['User']
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUserQuery,
  useGetCurrentUserQuery,
  useRefreshCurrentUserMutation,
  useValidateRegistrationMutation,
} = usersApi;