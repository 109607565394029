import { Autocomplete, TextField } from '@material-ui/core';
import { WorkOrderStatus } from '../../@types/workorder'

type WorkOrderStatusMultiSelectProps = {
    onStatusListChanged?: (statusList: WorkOrderStatus[]) => void;
    selectedValue: WorkOrderStatus[];
};

const options : WorkOrderStatus[] = [
    WorkOrderStatus.Complete,
    WorkOrderStatus.Pending,
];

export default function WorkOrderStatusMultiSelect({ onStatusListChanged, selectedValue }: WorkOrderStatusMultiSelectProps) {

  return (
    <Autocomplete
        multiple
        fullWidth
        options={options}
        value={selectedValue}
        getOptionLabel={(option) => option}
        defaultValue={[WorkOrderStatus.Pending, WorkOrderStatus.Complete]}
        filterSelectedOptions
        renderOption={(props, option) => <li {...props}>{option}</li>}
        renderInput={(params) => (
            <TextField {...params} label="Order Status" />
        )}
        onChange={(event, newValue) => {
            onStatusListChanged && onStatusListChanged(newValue);
        }}
    />
  );
}
