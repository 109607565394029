import React, { useState } from "react";
import { styled } from '@material-ui/core/styles';
import ModalContainer from '../modal/ModalContainer'

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.complex
      })
    }
  }));

const DisabledCover = styled('div')(({ theme }) => ({
  position: 'fixed',
  inset: '0px',
  backgroundColor: 'whitesmoke',
  opacity: '0.5',
  'z-index': '2',
}));

export const FeatureOveriew = ({
  children,
  modalContent,
}: React.PropsWithChildren<{
  modalContent?: React.ReactNode
}>): JSX.Element => {

  const [open, setOpen] = useState(true);

  return (
    <RootStyle>
      <DisabledCover />
      {children}
      <ModalContainer
        title=""
        open={open}
        component={modalContent || <div>This feature is not enabled on your current plan</div>}
        handleConfirmModalClose={() => setOpen(false)}
      />
    </RootStyle>
  );
};