import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';

type VerifyEmailFormProps = {
    email?: string | null;
    onEmailUpdated(email: string): Promise<void>;
};

type VerifyEmailFormValues = {
    email: string;
};

export const FormSchema = Yup.object().shape({
    email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email format').required('Email is required')
});

export default function VerifyEmailForm({ email, onEmailUpdated }: VerifyEmailFormProps) {

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, isDirty, isValid }
    } = useForm<VerifyEmailFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            email: email ?? ''
        }
    })

    const onSubmit = async (data: VerifyEmailFormValues) => {
        await onEmailUpdated(data.email);
    };

    return (
        <form
            onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(onSubmit)(e);
            }}>
            <Stack spacing={3}>
                <Typography variant="body2">Updates to this email require verification. This is to ensure that you have enetered the email correctly, and you are the owner of the supplied email.</Typography>
                <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Email"
                            error={Boolean(error)}
                            helperText={error?.message}
                        />
                    )}
                />
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isValid || !isDirty}
                >
                    Send Verification
                </LoadingButton>
            </Stack>
        </form>

    );
}
