import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { checkUserHasPermission } from '../utils/auth/checkUserPermission';
import { FeatureOveriew } from '../components/shared/feature/FeatureOverview';
import useSubscription from '../hooks/useSubscription';
import Unauthorised from '../pages/Unauthorised';

export const RequireAuth = ({
  component,
  disabledComponent,
  requiredPermission,
  route,
  productName,
  modalContent,
}: React.PropsWithChildren<{
  component: any;
  disabledComponent?: React.ReactNode,
  requiredPermission?: string;
  route?: string;
  productName?: string;
  modalContent?: React.ReactNode,
}>): JSX.Element => {

  const { user, isAuthenticated } = useAuth0();
  const { subscription } = useSubscription();
  const Component = component;

  var productAccessDisabled = false;
  if (subscription && route) {
    var product = subscription.products.find((product) => product.productName.toLowerCase() == route);
    productAccessDisabled = !product;
  }

  // if there is a required permission then validate the the user has it
  var hasPermission = user && (requiredPermission && checkUserHasPermission(user, requiredPermission))

  return (
    <>
      {isAuthenticated && (productAccessDisabled) && <FeatureOveriew key={productName ?? 'default-product'} modalContent={modalContent}>{disabledComponent}</FeatureOveriew>}
      {isAuthenticated && (!productAccessDisabled && hasPermission) && <Component />}
      {isAuthenticated && (!productAccessDisabled && !hasPermission) && <Unauthorised />}
    </>
  );
};