import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import { useDispatch } from '../../../redux/store';
import { ServiceItem } from '../../../@types/maintenance';
import { useUpdateServiceItemMutation } from '../../../services/services';

type ServiceItemFormProps = {
  serviceId: string;
  item: ServiceItem | null;
  onItemUpdated(item: ServiceItem): void;
};

type ServiceItemFormValues = {
  itemId: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
};

export const FormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Service item title is required')
    .min(3)
});

export default function ServiceItemForm({ item, serviceId, onItemUpdated }: ServiceItemFormProps) {

  const { enqueueSnackbar } = useSnackbar();
  const [updateServiceItem] = useUpdateServiceItemMutation();
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<ServiceItemFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      itemId: item?.itemId || '',
      title: item?.title || '',
      description: item?.description || '',
      quantity: item?.quantity || 1,
      price: item?.price || 0
    }
  })


  const onSubmit = async (data: ServiceItemFormValues) => {
    try {
      const response = await updateServiceItem({
        serviceId: serviceId,
        itemId: data.itemId,
        title: data.title,
        description: data.description,
        price: data.price,
        quantity: data.quantity,
      }).unwrap();
      enqueueSnackbar('Service Item Updated', { variant: 'success' });
      onItemUpdated(response);
      reset()
    } catch (response) {
      if (response.data.errors) {
        Object.keys(response.data.errors).forEach((key: any) => {
          let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
            return response.data.errors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Controller
            name="title"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                label="Title"
                autoComplete='off'
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState: { error } }) =>
              <TextField
                {...field}
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || "A description of the service item"}
                label="Description"
                autoComplete='off'
              />
            }
          />
          <Controller
            name="quantity"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Quantity"
                type="number"
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'off'}
              />
            )}
          />
          <Controller
            name="price"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Price"
                type="number"
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'off'}
              />
            )}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            {item ? 'Update Service Item' : 'Create Service Item'}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
