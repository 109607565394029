import { useState, useMemo, useEffect } from 'react'
import { Autocomplete, TextField, Avatar, ListItemAvatar } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { UserGroupSelectOption } from '../../../@types/search'
import { ControllerRenderProps } from 'react-hook-form';

type NewSelectListProps = {
    handleSearch(searchValue: string | null): Promise<void>;
    options: UserGroupSelectOption[];
    label: string;
    multiple?: boolean;
    error: any;
    field?: ControllerRenderProps;
    onChange?: (selected: UserGroupSelectOption) => void;
    clearSelectedItemOnChange?: boolean;
    selectedIds?: string[];
    fullWidth?: boolean;
};

export default function NewSelectList({ options, error, label, handleSearch, onChange, multiple = false, field, clearSelectedItemOnChange = false, selectedIds = [], fullWidth = true }: NewSelectListProps) {

    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [value, setValue] = useState<UserGroupSelectOption | UserGroupSelectOption[] | null>(null);
    const debouncedChangeHandler = useMemo(() => debounce((userInput) => { handleSearch(userInput) }, 200), []);

    return (
        <Autocomplete
            value={value}
            fullWidth={fullWidth}
            inputValue={selectedItem}
            //filterSelectedOptions
            filterOptions={(x) => x}
            loading={loading}
            multiple={multiple}
            options={options || []}
            isOptionEqualToValue={(option, value) => {
                if (!value) {
                    return false;
                }

                if (value.id === "") {
                    return false;
                }

                return option.id === value.id;
            }}
            renderOption={(props, option) => (
                <li key={option.id} {...props}>
                    <span>
                        {option.imageUrl && (<ListItemAvatar>
                            <Avatar alt={option.name} src={option.imageUrl} />
                        </ListItemAvatar>)}
                    </span>
                    {option.name} {option.type ? `(${option.type})` : ''}
                </li>
            )}
            getOptionLabel={(option) => option.name}
            onInputChange={(event, value, reason) => {
                // clear the item if required
                if (clearSelectedItemOnChange) {
                    if (event && event.type === 'blur') {
                        setSelectedItem('');
                    } else if (reason !== 'reset') {
                        // reset looks to be triggered on option select
                        setSelectedItem(value);
                        debouncedChangeHandler(value);
                    }
                } else {
                    setSelectedItem(value);
                    debouncedChangeHandler(value);
                }
            }}
            onChange={(event, newValue) => {

                if (multiple) {
                    let enteredText = (newValue as UserGroupSelectOption[]);
                    field?.onChange(enteredText);
                    //onChange && onChange(enteredText);
                    setValue(enteredText);
                } else {

                    let enteredText = (newValue as UserGroupSelectOption);
                    field?.onChange(enteredText);
                    onChange && onChange(enteredText);
                    setValue(enteredText);
                }
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                    error={Boolean(error)}
                    helperText={error?.message}
                />
            }
        />
    );
}
