import { MAvatar } from '../@material-extend';
import { MAvatarProps } from '../@material-extend/MAvatar';
import createAvatar from '../../utils/createAvatar';
import { Project } from '../../@types/project'

interface ProjectAvatarProps extends MAvatarProps {
  project: Project
}

export default function ProjectAvatar({ project, ...other }: ProjectAvatarProps) {
  const avatar = createAvatar(project?.projectName, 2)

  return (
    <MAvatar
      alt={project?.projectName}
      color={avatar.color}
      {...other}
    >
      {avatar.name}
    </MAvatar>
  );
}