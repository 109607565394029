import { CardContent, Card, CardHeader  } from '@material-ui/core';
import { ClientSummary } from '../../@types/client'
import { InvoiceSearch } from '../invoice';
import { useDispatch, useSelector, RootState } from '../../redux/store';
import { setClientInvoiceSearch, fetchClientInvoices } from '../../redux/slices/client';

type ClientInvoicesProps = {
  client: ClientSummary | null;
};

export default function ClientInvoices({ client }: ClientInvoicesProps) {
  
    const dispatch = useDispatch();
    const { data, totalRows, searchCriteria, isLoading } = useSelector((state: RootState) => state.client.invoices);
    
    if (!client) {
        return null
    }

    return (
        <Card sx={{ mb: 4 }}>
          <CardHeader title="Customer Invoices" />
          <CardContent>
            <InvoiceSearch
              invoices={data}
              showCustomer={false}
              search={searchCriteria}
              totalRows={totalRows}
              isLoading={isLoading}
              onSetSearch={(search) => dispatch(setClientInvoiceSearch(search))}
              onSearch={() => dispatch(fetchClientInvoices())}
            />
          </CardContent>
        </Card>
    );
}
