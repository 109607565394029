import { useEffect, useState, useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import axios from '../../../utils/axios';
import TrendChart from '../../charts/TrendChart'
import { TrendData } from '../../../@types/chart'

export default function NewVehiclesTrendChart({ projectIdentifier } : { projectIdentifier: string | null | undefined }) {
  const isMountedRef = useIsMountedRef();
  const [vehicleTrendData, setVehicleTrendData] = useState<TrendData>();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const getVehicleTrendStatistics = useCallback(async () => {
    try {
      const response = await axios.get<any, any>(`/api/vehicle/statistics?projectIdentifier=${projectIdentifier}`);
      if (isMountedRef.current) {
        setVehicleTrendData(response.data.newVehicleTrend);
        setLoading(false)
      }
    } catch (error) {
      console.error(error);
    }
  }, [isMountedRef, projectIdentifier]);

  useEffect(() => {
    getVehicleTrendStatistics();
  }, [getVehicleTrendStatistics]);

  return (
    <TrendChart 
      title={'New Vehicles'}
      description="New vehicles created in the current calendar month. Chart displays the same monthly total for the last 12 months"
      loading={loading}
      percent={vehicleTrendData?.percentage || 0 }
      total={vehicleTrendData?.total || 0 }
      labels={vehicleTrendData?.labels || []}
      chartData={[{ data: vehicleTrendData?.data || [] }]}
      colors={[theme.palette.primary.main]}
    />
  );
}
