import { ServiceTask, ServiceTaskRequest, IServiceTaskSearchCriteria, IServiceTaskSearchResult } from '../@types/maintenance'
import { api } from './api'

export const serviceTasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServiceTasks: builder.query<IServiceTaskSearchResult, IServiceTaskSearchCriteria>({
      query: (request: IServiceTaskSearchCriteria) => ({
        url: `service-tasks/search`,
        method: 'POST',
        body: request,
      }),
      providesTags: ['ServiceTask']
    }),

    addServiceTask: builder.mutation<ServiceTask, ServiceTaskRequest>({
      query: (request: ServiceTaskRequest) => ({
        url: `service-tasks`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['ServiceTask']
    }),

    getServiceTask: builder.query<ServiceTask, string>({
      query: (id: string) => ({
        url: `service-tasks/${id}`,
        method: 'GET',
      }),
      providesTags: ['ServiceTask']
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetServiceTasksQuery,
  useAddServiceTaskMutation,
  useGetServiceTaskQuery,
} = serviceTasksApi;