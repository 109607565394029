import { createContext, ReactNode } from 'react';
import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0ContextType } from '../@types/authentication';
import { auth0Config } from '../config';
import { useNavigate } from 'react-router-dom';


const AuthContext = createContext<Auth0ContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {

  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const getOrgId = () => {
    var projectStorage = localStorage.getItem('veem-project')
    var projectStored = projectStorage ? JSON.parse(projectStorage) : { projectId: null, projectIdentifier: null }
    return projectStored.projectIdentifier;
  };

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      audience={"https://app.autohive.co.nz"}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      organisationId={null}
      organisationIdentifier={getOrgId() === null ? undefined : getOrgId()}
      // need to fix the below, the integration redirect contains oauth params which mess with auth0 callback
      skipRedirectCallback={window.location.pathname === '/apps/xero/oauth'}
    >
      {children}
    </Auth0Provider>
  )
}

export { AuthContext, AuthProvider };
