import axios from '../../utils/axios'
import useProject from '../../hooks/useProject'
import GenericMultiSelectList from '../shared/list/GenericMultiSelectList'
import { SelectOption } from '../../@types/search'

type VehicleIdentifierSelectListProps = {
    onChange: (selected: SelectOption[]) => void;
    value: SelectOption[];
};

export default function VehicleIdentifierSelectList({ onChange, value }: VehicleIdentifierSelectListProps) {

    const { project } = useProject();

    const handleChangeSearch = async (value: string) => {
        try {
            var response = await axios.get<any, any>(`/api/vehicle/identifiers?projectId=${project?.id}&searchValue=${value ? value : ''}`);
            return response.data.map((make: any) => {
                return {
                    id: make,
                    name: make,
                }
            });
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    return (
        <GenericMultiSelectList
            value={value}
            handleSearch={handleChangeSearch}
            onChange={onChange}
            label="Registration/Serial"
            error={null}
        />
    );
}
