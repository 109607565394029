import { Stack, Paper, Card, Typography  } from '@material-ui/core';
import { Compliance } from '../../../@types/compliance'
import { fDate, fDateTime } from '../../../utils/formatTime'
import { ComplianceStatusLabel } from '../../_vehicle/index';
import ComplianceHistoryTable from './grid/ComplianceHistoryTable'

type ComplianceHistoryProps = {
    compliance: Compliance | null;
};

export default function ComplianceHistory({ compliance }: ComplianceHistoryProps) {

    return (
        <Card sx={{ p: 3 }}>
          <Typography variant="overline" sx={{ mb: 3 }}>
            Compliance History
          </Typography>

          { compliance ? <ComplianceHistoryTable histories={compliance.history}/> : null}
      </Card>
    );
}
