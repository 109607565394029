import { User } from './user'
import { ComplianceConfiguration } from './compliance'

export type ProjectState = {
    isLoading: boolean;
    notFound: boolean;
    error: boolean;
    project: null | Project;
    members: {
        current: ProjectMember[];
    }
};

export type ProjectSubscriptionBilling = {
    currentPeriod: ProjectBillingState;
    periods: ProjectBillingState[];
}

export type ProjectBillingState = {
    period: string;
    subscriptionName: string;
    subscriptionCost: number;
    cost: number;
    products: ProductBilling[];
}

export type ProductBilling = {
    product: string;
    subTotal: number;
    charges: ProductBillingCharge[];
}

export type ProductBillingCharge = {
    description: string;
    cost: number;
    quantity: number;
}

export interface Project {
    id: string;
    projectName: string;
    description: string;
    identifier: string;
    accessLevel: ProjectAccessLevel;
    updated: string;
    memberships: ProjectMember[];
    status: ProjectStatus;
    subscription: ProjectSubscription;
    branding: ProjectBranding;
}

export type ProjectSubscription = {
    planId: string;
    stripeSubscriptionId: string | null;
    userLimit: number;
    totalUsers: number;
    additionalUsers: number | null;
    updated: Date;
    periodEnd: Date | null;
    status: ProjectSubscriptionStatus;
}

export type ProjectComplianceConfiguration = {
    complianceConfiguration: ComplianceConfiguration;
}

export type ProjectBranding = {
    logo: string | null;
    contactEmail: string | null;
    contactPhoneNumber: string | null;
}

export enum ProjectStatus {
    Active = "Active",
    Disabled = "Disabled",
    Deleted = "Deleted",
}

export enum ProjectSubscriptionStatus {
    Active = "Active",
    Cancelled = "Cancelled",
}

export interface ProjectMember {
    memberId: string,
    name: string;
    imageUrl: string;
    created: string;
    accessLevel: string;
    membershipState: ProjectMembershipState;
    member: User;
    creator: User;
}

export interface ProjectMemberUpdateRequest {
    email?: string;
    memberId?: string,
    accessLevel?: string,
    projectIdentifier: string;
}

export interface ProjectUpdateRequest {
    projectIdentifier: string;
    projectName?: string;
    contactEmail?: {
        email: string | null,
        verificationId: string | null
    } | null,
    contactPhoneNumber?: string | null;
    logo?: string | null;
    emailVerificationId?: string | null;
}

export interface ProjectSubscriptionUpdateRequest {
    projectIdentifier: string;
    subscriptionId?: string;
    stripSubscriptionId?: string | null;
    additionalUsers: number | null;
}

export enum ProjectAccessLevel {
    NoAccess = "NoAccess",
    Guest = "Guest",
    Default = "Default",
    Workshop = "Workshop",
    WorkshopSupervisor = "WorkshopSupervisor",
    WorkshopManager = "WorkshopManager",
    Maintainer = "Maintainer",
    Administrator = "Administrator",
}

export enum ProjectMembershipState {
    Pending = "Pending",
    Active = "Active",
    Disabled = "Disabled",
}