import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import { LoadingButton, DateTimePicker } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import { useDispatch } from '../../../redux/store';
import { toggleWorkOrderModal } from '../../../redux/slices/workorder';
import { useUpdateWorkOrderMutation} from '../../../services/workorders';

type WorkOrderUpdateFormProps = {
    workOrderId: string;
    vehicleMileage?: number | null;
};

type WorkOrderCreateFormValues = {
    completionDate: Date;
    vehicleKilometers: number;
    createInvoice: boolean;
};

export const FormSchema = Yup.object().shape({
    completionDate: Yup.date().required(),
    createInvoice: Yup.bool().required(),
    vehicleKilometers: Yup
        .number()
        .typeError('Odometer reading must be a valid number')
        .nullable()
        .moreThan(0, 'You must specify the odometer reading of the vehicle')
        .transform((_, val) => (val !== "" ? Number(val) : null))
        .required('You must specify the odometer reading of the vehicle')
});

export default function WorkOrderUpdateForm({ workOrderId, vehicleMileage }: WorkOrderUpdateFormProps) {

    const dispatch = useDispatch();
    const [updateWorkOrder] = useUpdateWorkOrderMutation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        reset,
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, isDirty, isValid }
    } = useForm<WorkOrderCreateFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            completionDate: new Date(),
            vehicleKilometers: vehicleMileage ?? 0,
            createInvoice: true,
        }
    })

    const onSubmit = async (data: WorkOrderCreateFormValues) => {

        try {
            // dispatch the update
            await updateWorkOrder({
                workOrderId: workOrderId,
                completionDate: data.completionDate,
                vehicleKilometers: data.vehicleKilometers,
                createInvoice: data.createInvoice,
            }).unwrap();

            reset();
            dispatch(toggleWorkOrderModal(false));
            enqueueSnackbar('Work Order updated successfully', { variant: 'success', autoHideDuration: 6000 });

        } catch (error) {
            if (error.errors) {
                Object.keys(error.errors).forEach((key: any) => {
                    let errors: string[] = Object.keys(error.errors[key]).map((errorKey: string) => {
                        return error.errors[key][errorKey];
                    })

                    // set the erorrs on the form
                    setError(key, {
                        type: "manual",
                        message: errors.join(',')
                    })
                })
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <Controller
                        name="completionDate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <DateTimePicker
                                {...field}
                                views={['day']}
                                label="Completion Date"
                                renderInput={(params) => (
                                    <TextField fullWidth {...params} error={Boolean(error)} />
                                )}
                                inputFormat="dd/MM/yyyy"
                            />
                        )}
                    />
                    <Controller
                        name="vehicleKilometers"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Odometer Reading"
                                type="number"
                                error={Boolean(error)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                }}
                                helperText={error?.message}
                                autoComplete={'off'}
                            />
                        )}
                    />
                    <Controller
                        name="createInvoice"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Create invoice for this work order (if true this will create an invoice with your accounting platform)"
                                control={
                                    <Checkbox
                                        {...field}
                                        onChange={e => field.onChange(e.target.checked)}
                                        checked={field.value}
                                    />
                                }
                            />
                        )}
                    />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={!isValid}
                    >
                        Complete Work Order
                    </LoadingButton>
                </Stack>
            </form>
        </>
    );
}
