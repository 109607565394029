import { Vehicle, VehicleStatus } from '../@types/vehicle'
import { VehicleSearchResult, IVehicleSearchCriteria } from '../@types/vehicles'
import { VehicleUpdateRequest, VehicleComplianceConfiguration, IVehicleInvoiceSearchCriteria, VehicleCreateRequest } from '../@types/vehicle'
import { InvoiceSearchSearchResult } from '../@types/invoice'
import { Compliance, ComplianceTypeConfiguration, ComplianceCreateRequest, ComplianceUpdateRequest, ComplianceReminderUpdateRequest, ComplianceReminderSendRequest } from '../@types/compliance'
import { Service, ServiceRecord } from '../@types/maintenance'
import { api } from './api'

export const vehiclesApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getVehicles: builder.query<VehicleSearchResult, IVehicleSearchCriteria>({
      query: (request: IVehicleSearchCriteria) => ({
        url: `vehicle/search`,
        method: 'POST',
        body: {
          ...request,
          clients: request.clients.map((client) => client.id),
          makes: request.makes.map((make) => make.id),
          vehicleIdentifiers: request.vehicleIdentifiers.map((identifier) => identifier.id),
        }
      }),
      providesTags: ['Vehicle']
    }),

    getRecentVehicles: builder.query<Vehicle[], IVehicleSearchCriteria>({
      query: (request: IVehicleSearchCriteria) => ({
        url: `vehicle/recent`,
        method: 'POST',
        body: {
          ...request,
          clients: request.clients.map((client) => client.id),
          makes: request.makes.map((make) => make.id),
          vehicleIdentifiers: request.vehicleIdentifiers.map((registration) => registration.id)
        }
      }),
      providesTags: ['Vehicle']
    }),

    getVehicle: builder.query<Vehicle, string>({
      query: (id: string) => ({
        url: `vehicle/${id}`,
        method: 'GET',
      }),
      providesTags: ['Vehicle']
    }),

    addVehicle: builder.mutation<Vehicle, VehicleCreateRequest>({
      query: (request: VehicleCreateRequest) => ({
        url: `vehicle/create`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['Vehicle']
    }),

    updateVehicle: builder.mutation<Vehicle, VehicleUpdateRequest>({
      query: (request: VehicleUpdateRequest) => ({
        url: `vehicle/${request.vehicleId}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Vehicle', 'Job']
    }),

    updateVehicleStatus: builder.mutation<Vehicle, { vehicleId: string, status: VehicleStatus }>({
      query: (request: { vehicleId: string, status: VehicleStatus }) => ({
        url: `vehicle/${request.vehicleId}/status?status=${request.status}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Vehicle', 'Job']
    }),

    getVehicleCompliance: builder.query<Compliance, string>({
      query: (id: string) => ({
        url: `vehicle/${id}/compliance`,
        method: 'GET',
      }),
      providesTags: ['Vehicle']
    }),

    addVehicleCompliance: builder.mutation<Compliance, ComplianceCreateRequest>({
      query: (request: ComplianceCreateRequest) => ({
        url: `vehicle/${request.vehicleId}/compliance`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['Vehicle']
    }),

    importVehicleCompliance: builder.mutation<Compliance, string>({
      query: (id: string) => ({
        url: `vehicle/${id}/compliance/import`,
        method: 'POST',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data: updatedCompliance } = await queryFulfilled;
        dispatch(vehiclesApi.util.updateQueryData('getVehicle', id, (draft) => {
          if (draft.compliance) {
            Object.assign(draft.compliance, updatedCompliance);
          }
        }))
      },
      invalidatesTags: ['Vehicle']
    }),

    updateVehicleCompliance: builder.mutation<Compliance, { vehicleId: string, trackingEnabled: boolean }>({
      query: (request: { vehicleId: string, trackingEnabled: boolean }) => ({
        url: `vehicle/${request.vehicleId}/compliance/tracking?trackingEnabled=${request.trackingEnabled}`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Compliance', 'ComplianceConfig']
    }),

    getVehicleComplianceConfiguration: builder.query<ComplianceTypeConfiguration, string>({
      query: (id: string) => ({
        url: `vehicle/${id}/compliance/configuration`,
        method: 'GET',
      }),
      providesTags: ['Compliance', 'ComplianceConfig']
    }),

    getVehicleInvoices: builder.query<InvoiceSearchSearchResult, IVehicleInvoiceSearchCriteria>({
      query: (request: IVehicleInvoiceSearchCriteria) => ({
        url: `vehicle/${request.vehicleId}/invoices?page=${request.pageNumber}&pageSize=${request.pageSize}&orderBy=${request.orderBy}&order=${request.order}${request.text ? '&text=' + request.text : ''}`,
        method: 'GET',
      }),
      providesTags: ['Vehicle']
    }),

    getVehicleServices: builder.query<Service[], string>({
      query: (id: string) => ({
        url: `service/vehicles/${id}`,
        method: 'GET',
      }),
      providesTags: ['Service']
    }),

    updateCompliance: builder.mutation<Compliance, ComplianceUpdateRequest>({
      query: (request: ComplianceUpdateRequest) => ({
        url: `vehicle/${request.vehicleId}/compliance`,
        method: 'PUT',
        body: request,
      }),
      async onQueryStarted({ vehicleId }, { dispatch, queryFulfilled }) {
        const { data: updatedCompliance } = await queryFulfilled;
        dispatch(vehiclesApi.util.updateQueryData('getVehicle', vehicleId, (draft) => {
          if (draft.compliance) {
            Object.assign(draft.compliance, updatedCompliance);
          }
        }))
      },
      invalidatesTags: ['Vehicle']
    }),

    updateComplianceReminder: builder.mutation<Compliance, ComplianceReminderUpdateRequest>({
      query: (request: ComplianceReminderUpdateRequest) => ({
        url: `vehicle/${request.vehicleId}/compliance/reminder`,
        method: 'PUT',
        body: request,
      }),
      async onQueryStarted({ vehicleId }, { dispatch, queryFulfilled }) {
        const { data: updatedCompliance } = await queryFulfilled;
        dispatch(vehiclesApi.util.updateQueryData('getVehicle', vehicleId, (draft) => {
          if (draft.compliance) {
            Object.assign(draft.compliance, updatedCompliance);
          }
        }))
      },
      invalidatesTags: ['Vehicle']
    }),

    sendComplianceReminder: builder.mutation<Compliance, ComplianceReminderSendRequest>({
      query: (request: ComplianceReminderSendRequest) => ({
        url: `vehicle/${request.vehicleId}/compliance/reminder`,
        method: 'POST',
        body: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data: updatedCompliance } = await queryFulfilled;
        dispatch(vehiclesApi.util.updateQueryData('getVehicle', arg.vehicleId, (draft) => {
          if (draft.compliance) {
            Object.assign(draft.compliance, updatedCompliance);
          }
        }))
      },
      invalidatesTags: ['Vehicle']
    }),

    getVehicleServiceRecords: builder.query<ServiceRecord[], string>({
      query: (id: string) => ({
        url: `vehicle/${id}/services`,
        method: 'GET',
      }),
      providesTags: ['Service']
    }),

    deleteVehicleServiceRecord: builder.mutation<void, { vehicleId: string, serviceId: string }>({
      query: (request: { vehicleId: string, serviceId: string }) => ({
        url: `vehicle/${request.vehicleId}/services/${request.serviceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Job']
    }),

  }),
  overrideExisting: false,
})

export const {
  useGetVehicleQuery,
  useAddVehicleMutation,
  useUpdateVehicleMutation,
  useUpdateVehicleStatusMutation,
  useGetRecentVehiclesQuery,
  useGetVehicleComplianceQuery,
  useAddVehicleComplianceMutation,
  useImportVehicleComplianceMutation,
  useUpdateVehicleComplianceMutation,
  useGetVehicleComplianceConfigurationQuery,
  useGetVehicleInvoicesQuery,
  useGetVehiclesQuery,
  useGetVehicleServicesQuery,
  useUpdateComplianceMutation,
  useUpdateComplianceReminderMutation,
  useSendComplianceReminderMutation,
  useGetVehicleServiceRecordsQuery,
  useDeleteVehicleServiceRecordMutation,
} = vehiclesApi;