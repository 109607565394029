import { User } from '@auth0/auth0-react';

export const checkUserHasPermission = (user: User | undefined, permission: string): boolean => {

    if (user) {
        let permissions: string[] = [];
        if (user["https://veem.co.nz/permissions"]) {
            permissions = user["https://veem.co.nz/permissions"];
        }
        
        if (!permission || permissions.length === 0) {
            return true;
        } else {
            return (
                Array.isArray(permissions) &&
                permissions.filter((value) => value === permission).length > 0
            );
        }
    } else {
        return false;
    }
};
