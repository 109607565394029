import VehicleCreateForm from './form/VehicleCreateForm'
import useProject from '../../hooks/useProject'

type VehicleCreateProps = {
  //group: Group | null;
};

export default function VehicleCreate({  }: VehicleCreateProps) {

    const { project } = useProject();

    if (!project) {
        return null;
    }

    return (
      <>
        <VehicleCreateForm project={project}/>
      </>
  );
}
