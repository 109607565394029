import {  ReactNode } from 'react';
import useProject from '../hooks/useProject';
import Unauthorised from '../pages/Unauthorised';
import { ProjectAccessLevel } from '../@types/project'

type AuthGuardProps = {
    children: ReactNode;
};

export default function AdministratorAccessLevelGuard({ children }: AuthGuardProps) {
  const { project } = useProject();

  if (!project) {
      return <></>
  }

  if (project.accessLevel !== (ProjectAccessLevel.Administrator || ProjectAccessLevel.Maintainer)) {
    return <Unauthorised />;
  }

  return <>{children}</>;
}
