import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 329 284" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M203.5 14H94.6603C89.3013 14 84.3494 16.859 81.6699 21.5L16.3301 134.672C13.6506 139.313 13.6506 145.031 16.3301 149.672L81.6699 262.844C84.3494 267.485 89.3013 270.344 94.6602 270.344H234" stroke="#3D3D3D" stroke-width="27" stroke-linecap="round" />
        <path d="M170.438 202.255L258.468 49.7839" stroke="#0133B3" stroke-width="35" stroke-linecap="round" />
        <path d="M226.67 202.393L285.257 100.917" stroke="#0133B3" stroke-opacity="0.8" stroke-width="35" stroke-linecap="round" />
        <path d="M281.836 202.498L311.25 151.552" stroke="#0133B3" stroke-opacity="0.8" stroke-width="35" stroke-linecap="round" />
        <path d="M113.363 202.215L191.322 67.1851" stroke="#012A94" stroke-width="35" stroke-linecap="round" />
      </svg>

    </Box>
  );
}
