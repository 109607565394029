import { useTheme } from '@material-ui/core/styles';
import Label from '../shared/Label';
import { sentenceCase } from 'change-case';
import { ExternalSyncStatus } from '../../@types/tracking'

function ExternalSyncStatusLabel({ status }: { status: ExternalSyncStatus }) {
    const theme = useTheme();
  
    return (
        <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
                (status === ExternalSyncStatus.NotApplicable && 'default') ||
                (status === ExternalSyncStatus.Error && 'error') ||
                (status === ExternalSyncStatus.Requested && 'warning') ||
                (status === ExternalSyncStatus.RetryRequested && 'warning') ||
                (status === ExternalSyncStatus.Synchronised && 'success') ||
                (status === ExternalSyncStatus.Unknown && 'default') ||
                'success'
            }
        >
            {sentenceCase(status)}
      </Label>
    );
  }
  
  export default ExternalSyncStatusLabel;

