import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { Project } from '../../@types/project';

type ProjectsState = {
  isLoading: boolean;
  notFound: boolean;
  error: boolean;
  projects: Project[];
};

const initialState: ProjectsState = {
  isLoading: false,
  notFound: false,
  error: false,
  projects: []
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProjectsSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getProjects() {
  return async () => {
    
    try {
      dispatch(slice.actions.startLoading());

      // get the projects
      const response = await axios.post(`/api/projects/search`);

      dispatch(slice.actions.getProjectsSuccess(response.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
  };
}
