import { Page, View, Text, Font, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { fNumberFinancial, fCurrencyDecimal } from '../../utils/formatNumber';
import { Invoice } from '../../@types/invoice';
import { Project } from '../../@types/project';


Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }]
});

const styles = StyleSheet.create({
  col4: { width: '25%' },
  col8: { width: '75%' },
  col6: { width: '50%' },
  mb6: { marginBottom: 6 },
  mb8: { marginBottom: 8 },
  mb20: { marginBottom: 20 },
  mb30: { marginBottom: 30 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: 'uppercase'
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  body1: { fontSize: 10 },
  body2: { fontSize: 9 },
  body: { fontSize: 10 },
  subtitle2: { fontSize: 9, fontWeight: 700 },
  alignRight: { textAlign: 'right' },
  page: {
    padding: '40px 24px 0 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff',
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    padding: 24,
    margin: 'auto',
    borderTopWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    borderColor: '#DFE3E8'
  },
  gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  table: { display: 'flex', width: 'auto' },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: '5px 0',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8'
  },
  noBorder: { paddingTop: 5, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: '5%' },
  tableCell_2: { width: '50%', paddingRight: 10 },
  tableCell_3: { width: '15%' }
});

type InvoicePDFProps = {
  invoice: Invoice;
  project: Project;
};

function InvoicePDF({ invoice, project }: InvoicePDFProps) {

  const { invoiceNumber, status, lineItems, total, subTotal } = invoice;
  const { logo } = project.branding;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb20]}>
          <View>
            {logo && (<Image style={{ height: '50px', objectFit: 'contain' }} src={logo} />)}
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h3}>{invoiceNumber}</Text>
            <Text>{status}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb6]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb6]}>Invoice from</Text>
            <Text style={styles.body1}>{project?.projectName}</Text>
            <Text style={styles.body2}>{project.branding.contactEmail}</Text>
            <Text style={styles.body2}>{project.branding.contactPhoneNumber}</Text>
          </View>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb6]}>Invoice to</Text>
            <Text style={styles.body1}>{invoice.entity.name}</Text>
          </View>
        </View>
        <View style={[styles.gridContainer, styles.mb6]}>
          <View style={styles.col8}>
            <Text style={[styles.overline, styles.mb8]}>Reference</Text>
            <Text style={styles.body1}>{invoice.reference}</Text>
          </View>
        </View>
        <View style={[styles.gridContainer, styles.mb20]}>
          <View style={styles.col8}>
            <Text style={[styles.overline, styles.mb8]}>Description</Text>
            <Text style={styles.body2}>{invoice.description}</Text>
          </View>
        </View>

        <Text style={[styles.overline, styles.mb8]}>Invoice Items</Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Code</Text>
              </View>
              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Qty</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Price</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {lineItems.map((item, index) => (
              <View style={styles.tableRow} key={item.lineItemId}>
                <View style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>{item.code}</Text>
                </View>
                <View style={styles.tableCell_2}>
                  <Text>{item.description}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>{item.unitAmount ? fNumberFinancial(item.quantity) : '-'}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>{item.unitAmount ? fCurrencyDecimal(item.unitAmount) : '-'}</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{item.lineAmount ? fCurrencyDecimal(item.lineAmount) : '-'}</Text>
                </View>
              </View>
            ))}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Subtotal</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>${fNumberFinancial(subTotal)}</Text>
              </View>
            </View>
            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>GST.</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>${fNumberFinancial(total - subTotal)}</Text>
              </View>
            </View>

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text style={styles.h4}>Total</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>${fNumberFinancial(total)}</Text>
              </View>
            </View>
          </View>
        </View>

        {/* <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col6}>
            <Text style={styles.subtitle2}>NOTES</Text>
            <Text>
              We appreciate your business. Should you need us to add VAT or extra notes let us know!
            </Text>
          </View>
          <View style={[styles.col6, styles.alignRight]}>
            <Text style={styles.subtitle2}>Have a Question?</Text>
            <Text>{project.contactEmail}</Text>
          </View>
        </View> */}
      </Page>
    </Document>
  );
}

export default InvoicePDF;
