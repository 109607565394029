import { Grid, Alert } from '@material-ui/core';
import { Vehicle } from '../../../@types/vehicle'
import { ComplianceDetail, ComplianceReminder, ComplianceHistory } from '../index'

type VehicleComplianceDetailProps = {
  vehicle: Vehicle;
  isLoading: boolean;
  disabled?: boolean;
};

export default function VehicleComplianceDetail({ vehicle, isLoading, disabled = false }: VehicleComplianceDetailProps) {

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <ComplianceDetail
          vehicleId={vehicle.id}
          compliance={vehicle.compliance}
          loading={isLoading}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {disabled && (<Alert sx={{ mb: 2 }} severity="info">This compliance is not active as the vehicle is currently disabled. This information will remain read-only and no compliance remiders will be sent.</Alert>)}
        <ComplianceReminder
          vehicleId={vehicle.id}
          compliance={vehicle.compliance}
          loading={isLoading}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ComplianceHistory compliance={vehicle.compliance} />
      </Grid>
    </Grid>
  );
}
