import { useEffect, useState } from 'react'
import { Stack, Skeleton } from '@material-ui/core';
import ClientSelectListControlled, { ClientOptionType } from './ClientSelectListControlled'
import { useGetClientQuery, } from '../../services/clients';
import { FieldValues, Control, Path, Controller } from 'react-hook-form';

type ClientSelectListProps<T extends FieldValues> = {
    client: { id: string, name: string | null } | null;
    control: Control<T>;
    name: Path<T>;
}

const ClientSelectList = <T extends FieldValues>({ client, control, name }: ClientSelectListProps<T>) => {

    const [clientSearch, setClientSearch] = useState<string>(client?.id || '');
    const { data: customer, isLoading } = useGetClientQuery(clientSearch, { skip: !client?.id || client?.name !== null });
    const [value, setValue] = useState<ClientOptionType | null>(null);

    useEffect(() => {
        setValue({
            id: client?.id,
            name: customer?.name || '',
        })
    }, [customer]);

    useEffect(() => {
        if (client) {
            if (!client.name) {
                setClientSearch(client.id);
            } else {
                setValue({
                    id: client.id,
                    name: client.name,
                });
            }
        } else {
            setValue(null)
        }
    }, [client]);

    if (isLoading) {
        return (
            <Stack>
                <Skeleton height="60px" />
            </Stack>
        )
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <ClientSelectListControlled
                    label={'Customer'}
                    field={field}
                />
            )}
        />
    )
}

export default ClientSelectList;