import { ReactNode, forwardRef } from 'react'
import { useTheme } from '@material-ui/core/styles';
import useProject from '../../hooks/useProject';
import { Link, LinkProps } from 'react-router-dom';

type GroupRouterLinkProps = LinkProps & {
    children?: ReactNode;
    showUnderline?: boolean;
}

/*
    This component provides a route relative to the rout of the app
    that populates the subroute for the current group
*/
const ProjectRouterLink = ({ to, children, showUnderline = true, ...props  }: GroupRouterLinkProps) => {
    
    const theme = useTheme();
    const { project } = useProject();
    
    return (
        <Link {...props} to={`/group/${project?.identifier}/${to}`} style={{ textDecoration: showUnderline ? 'underline' : 'none', color: theme.palette.primary.dark }}>
            {children}
        </Link>
    );
}

export default forwardRef(ProjectRouterLink);
