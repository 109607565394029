import ModalContainer from '../../shared/modal/ModalContainer'
import ClientUpdateForm from '../form/ClientUpdateForm'
import { ClientSummary } from '../../../@types/client'

type ClientUpdateModalProps = {
    open: boolean;
    client: ClientSummary;
    onModalClose(): void;
    onUpdated(item: ClientSummary): void;
}

export default function ClientUpdateModal({ open, client, onModalClose, onUpdated }: ClientUpdateModalProps) {

    return (
        <ModalContainer
            title={`Update Customer`}
            open={open}
            component={
                <ClientUpdateForm
                    client={client}
                    onUpdated={onUpdated}
                />
            }
            handleConfirmModalClose={onModalClose}
        />
    );
}

