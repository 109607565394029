
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { checkUserHasPermission } from '../utils/auth/checkUserPermission';
import useSubscription from '../hooks/useSubscription';

export const ProductAccessGuard = ({
  productName,
  noAccessComponent,
  children
}: React.PropsWithChildren<{
  productName: string,
  noAccessComponent: JSX.Element,
}>): JSX.Element => {

  const { user } = useAuth0();
  const { subscription } = useSubscription();
  const product = subscription.products.find((product) => product.productName.toLowerCase() == productName);
  const productAccessDisabled = !product;
  
  // if there is a required permission then validate the the user has it
  var hasPermission = user && (product && checkUserHasPermission(user, product.permission))

  // check the project subscription contains the requested product
  if (productAccessDisabled) {
    return <>{noAccessComponent}</>
  }

  if (!hasPermission) {
    return <>You have no access</>
  }

  return <>{children}</>
};

export default ProductAccessGuard;
