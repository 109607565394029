import { Icon } from '@iconify/react';
import { useState, useMemo, useEffect } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled, alpha } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { Slide, ClickAwayListener } from '@material-ui/core';
import { MIconButton } from '../../components/@material-extend';
import AutoCompleteHighlighted from '../../components/shared/autocomplete/AutoCompleteHighlighted';
import { Vehicle } from '../../@types/vehicle'
import axios from '../../utils/axios';
import useProject from '../../hooks/useProject';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from '../constants';

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APP_BAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

export default function Searchbar() {

  const [isOpen, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setloading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { project } = useProject();
  const navigate = useNavigate();

  const debouncedChangeHandler = useMemo(
    () => debounce((userInput) => handleChangeSearch(userInput), 200), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const handleChangeSearch = async (value: string) => {
    try {
      setSearchQuery(value);
      if (value) {
        setloading(true)
        const response = await axios.post<any, any>('/api/vehicle/search', {
          pageNumber: 0,
          pageSize: 15,
          text: value,
          vehicleIdentifiers: [],
          makes: [],
          clients: [],
          orderBy: 'updated',
          order: 'asc'
        });
        setSearchResults(response.data.results);
        setloading(false)
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <AutoCompleteHighlighted<Vehicle>
              query={searchQuery}
              loading={loading}
              options={searchResults}
              getOptionLabel={(option) => `${option.make} ${option.model} ${option.vehicleIdentifier}`}
              getOptionItemMainDescription={(option) => `${option.make} - ${option.model} (${option.vehicleIdentifier})`}
              getOptionItemSecondaryDescription={(option) => `${option.client.name}`}
              handleChangeSearch={(value) => debouncedChangeHandler(value)}
              onItemSelected={(value) => {
                navigate(`/group/${project?.identifier}/fleet/vehicles/${value.id}/detail`);
                handleClose()
              }}

            />
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
