
import { Card, CardContent, Stack, Typography } from '@material-ui/core';
import ProductAccessGuard from '../../guards/ProductAccessGuard';
import ComingSoonIllustration from '../../assets/illustration_coming_soon';

export const MaintenanceProductNoAccess = (): JSX.Element => {

    return (
        <Stack flexDirection={{ xs: 'column', md: 'row', lg: 'row' }} sx={{ padding: 4 }}>
            <Stack>
                <ComingSoonIllustration />
            </Stack>
            <Stack spacing={2} sx={{ marginLeft: 3, maxWidth: '600px' }}>
                <Typography variant="h3">This product is coming soon...</Typography>
                <Typography variant="body2">Soon we will be releasing a new product offering to manage jobs for your vehicles. Jobs will allow you to schedule work to be completed, manage your upcoming work in a calendar and charge jobs using invoices.</Typography>
                <Typography variant="subtitle2">We will be in touch by email when this product is released.</Typography>
            </Stack>
        </Stack>
    )
};

export const MaintenanceProductNoAccessVertical = (): JSX.Element => {

    return (
        <Stack flexDirection="column" sx={{ padding: 4 }}>
            <Stack>
                <ComingSoonIllustration sx={{ maxWidth: 400 }} />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
                <Typography variant="h3">This product is coming soon...</Typography>
                <Typography variant="body2">Soon we will be releasing a new product offering to manage jobs for your vehicles. Jobs will allow you to schedule work to be completed, manage your upcoming work in a calendar and charge jobs using invoices.
                </Typography>
                <Typography variant="subtitle2">We will be in touch by email when this product is released.</Typography>
            </Stack>
        </Stack>
    )
};

export const MaintenanceProductNoAccessCard = (): JSX.Element => {

    return (
        <Card>
            <CardContent>
                <MaintenanceProductNoAccess />
            </CardContent>
        </Card>
    )
};

export const MaintenanceProductGuard = ({
    children
}: React.PropsWithChildren<{

}>): JSX.Element => {

    return (
        <ProductAccessGuard productName="maintenance" noAccessComponent={<MaintenanceProductNoAccessCard />}>
            {children}
        </ProductAccessGuard>
    )
};

export default MaintenanceProductGuard;
