import ModalContainer from '../../shared/modal/ModalContainer'
import VehicleUpdateForm from '../form/VehicleUpdateForm'
import useProject from '../../../hooks/useProject';
import { Vehicle } from '../../../@types/vehicle';

export default function UpdateVehicleModal({ modalOpen, vehicle, onClose } : { modalOpen: boolean, vehicle: Vehicle, onClose(): void }) {

  const { project } = useProject();

  return (
    <ModalContainer
        title="Update Vehicle"
        open={modalOpen}
        component={
          <VehicleUpdateForm
            project={project}
            vehicle={vehicle}
            onVehicleCreated={() => onClose()}
          />
        }
        handleConfirmModalClose={() => onClose()}
      />
  );
}

