import {
  Job,
  JobCompleteRequest,
  JobCreateRequest,
  JobUpdateRequest,
  IJobSearchCriteria,
  IJobSearchResult,
  JobStatus,
  JobServiceTypeRequest,
  JobApprovalRequest,
  JobApprovalPendingRequest
} from '../@types/job'
import { Invoice } from '../@types/invoice'
import { api } from './api'

export const jobsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getJobs: builder.query<IJobSearchResult, IJobSearchCriteria>({
      query: (request: IJobSearchCriteria) => ({
        url: `jobs/search`,
        method: 'POST',
        body: request,
      }),
      providesTags: ['Job']
    }),

    getCalendarJobs: builder.query<IJobSearchResult, void>({
      query: () => ({
        url: `jobs/search`,
        method: 'POST',
        body: {
          pageNumber: 0,
          pageSize: 25,
          orderBy: 'updated',
          order: 'desc',
          text: '',
          vehicles: [],
          statusList: []
        },
      }),
      providesTags: ['Job']
    }),

    getJob: builder.query<Job, string>({
      query: (id: string) => ({
        url: `jobs/${id}`,
        method: 'GET',
      }),
      providesTags: ['Job']
    }),

    createJobInvoice: builder.mutation<Invoice, string>({
      query: (id: string) => ({
        url: `jobs/${id}/invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['Job']
    }),

    createJob: builder.mutation<Job, JobCreateRequest>({
      query: (request: JobCreateRequest) => ({
        url: `jobs`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Job', 'Service']
    }),

    updateJob: builder.mutation<Job, JobUpdateRequest>({
      query: (request: JobUpdateRequest) => ({
        url: `jobs/${request.jobId}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Job', 'Invoice', 'Vehicle', 'Service']
    }),

    updateJobDescription: builder.mutation<Job, { id: string, description: string | null }>({
      query: (request: { id: string, description: string | null }) => ({
        url: `jobs/${request.id}/description`,
        method: 'PUT',
        body: request,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(jobsApi.util.updateQueryData('getJob', id, (draft) => {
          draft.description = data.description;
        }))
      },
    }),

    updateJobStatus: builder.mutation<Job, { id: string, status: JobStatus }>({
      query: (request: { id: string, status: JobStatus }) => ({
        url: `jobs/${request.id}/status`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Job', 'Invoice']
    }),

    setJobPendingApproval: builder.mutation<Job, JobApprovalPendingRequest>({
      query: (request: JobApprovalRequest) => ({
        url: `jobs/${request.jobId}/pending-approval`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Job', 'Invoice']
    }),

    approveJob: builder.mutation<Job, JobApprovalRequest>({
      query: (request: JobApprovalRequest) => ({
        url: `jobs/${request.jobId}/approve`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Job', 'Invoice']
    }),

    updateJobSchedule: builder.mutation<Job, JobUpdateRequest>({
      query: (request: JobUpdateRequest) => ({
        url: `jobs/${request.jobId}/schedule`,
        method: 'PUT',
        body: request,
      }),
      async onQueryStarted({ jobId, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(jobsApi.util.updateQueryData('getCalendarJobs', undefined, (draft) => {
          const index = draft.results.findIndex(item => item.id === jobId)
          if (index > -1) {
            draft.results[index].date = patch.date ?? draft.results[index].date;
            draft.results[index].endDate = patch.endDate;
          }
        }))

        const { data: updatedPost } = await queryFulfilled;
        dispatch(jobsApi.util.updateQueryData('getCalendarJobs', undefined, (draft) => {
          const index = draft.results.findIndex(item => item.id === jobId);
          if (index > -1) {
            draft.results[index].date = updatedPost.date;
            draft.results[index].endDate = updatedPost.endDate;
          }
        }))
      },
    }),

    deleteJob: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `jobs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Job']
    }),

    addJobServiceType: builder.mutation<Job, JobServiceTypeRequest>({
      query: (request: JobServiceTypeRequest) => ({
        url: `jobs/${request.jobId}/service-types`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['Job', 'Service', 'Invoice']
    }),

    deleteJobServiceType: builder.mutation<void, { jobId: string, serviceInstanceId: string }>({
      query: (request: { jobId: string, serviceInstanceId: string }) => ({
        url: `jobs/${request.jobId}/service-types/${request.serviceInstanceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Job', 'Service']
    }),

    completeJob: builder.mutation<Job, JobCompleteRequest>({
      query: (request: JobCompleteRequest) => ({
        url: `jobs/${request.jobId}/complete`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Job', 'Vehicle', 'Invoice', 'Service']
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetJobsQuery,
  useGetCalendarJobsQuery,
  useGetJobQuery,
  useUpdateJobMutation,
  useApproveJobMutation,
  useUpdateJobDescriptionMutation,
  useUpdateJobStatusMutation,
  useUpdateJobScheduleMutation,
  useDeleteJobMutation,
  useAddJobServiceTypeMutation,
  useDeleteJobServiceTypeMutation,
  useCompleteJobMutation,
  useCreateJobInvoiceMutation,
  useCreateJobMutation,
  useSetJobPendingApprovalMutation
} = jobsApi;