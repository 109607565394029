import GenericMultiSelectList from '../shared/list/GenericMultiSelectList'
import axios from '../../utils/axios'
import useProject from '../../hooks/useProject'
import { SelectOption } from '../../@types/search'

type ClientMultiSelectListProps = {
    onChangeMultiple?: (selected: SelectOption[]) => void;
    value?: SelectOption[];
    error: any;
};

export default function ClientMultiSelectList({ onChangeMultiple, value }: ClientMultiSelectListProps) {

    const { project } = useProject();

    const handleChangeSearch = async (value: string) => {
        try {
            var response = await axios.post<any, any>(`/api/clients/search`, {
                projectIdentifier: project?.identifier,
                pageNumber: 0,
                pageSize: 50,
                text: value,
                order: 'asc',
                orderBy: 'name'
            });

            return response.data.entities.map((client: any) => {
                return {
                    id: client.id,
                    name: client.name,
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <GenericMultiSelectList
            value={value}
            handleSearch={handleChangeSearch}
            onChange={(selected) => onChangeMultiple && onChangeMultiple(selected)}
            label="Client"
            error={null}
        />
    );
}
