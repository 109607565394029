import React, { useState } from 'react';
import { alpha, styled } from '@material-ui/core/styles';
import EdiText from 'react-editext';

interface TextEditProps {
    value: string;
    placeholder?: string;
    id: string;
    disabled?: boolean;
    onValueUpdated(value: string): void;
}

const StyledEdiText = styled(EdiText)`
  button {
    border-radius: 5px;
  }
  button[editext="edit-button"] {
    color: #000;
    width: 50px;
  }
  button[editext="save-button"] {
    width: 50px;
    &:hover {
      background: greenyellow;
    }
  }
  button[editext="cancel-button"] {
    &:hover {
      background: crimson;
      color: #fff;
    }
  }
  input, textarea {
    background: ${props => props.theme.palette.background.neutral};
    border-radius: 5px;
    width: 100%;
  }
  div[editext="view-container"], div[editext="edit-container"] {
    flex-direction: column;
    align-items: flex-start;
    background: ${props => props.theme.palette.background.neutral};
    padding: 15px;
    border-radius: 5px;

    div:last-child {
        align-self: flex-end;
    }
  },
  div[editext="view"]{
    white-space: pre-wrap;
  },
`

export default function TextEdit({ value, placeholder, disabled, onValueUpdated }: TextEditProps) {

    const handleSave = (val: string) => {
        onValueUpdated(val);
    };
    return (
        <StyledEdiText
            showButtonsOnHover
            submitOnUnfocus
            editOnViewClick={true}
            type="textarea"
            canEdit={!disabled}
            inputProps={{
                className: 'textarea',
                placeholder: placeholder,
                style: {
                    outline: 'none',
                    minWidth: 'auto',
                    fontSize: '13px',
                },
                rows: 8
            }}
            containerProps={{
              style: {
                fontSize: '13px',
            },
            }}
            value={value}
            onSave={handleSave}
        />
    );
}