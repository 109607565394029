import { useState } from 'react'
import VehicleSearchInput from '../VehicleSearchInput'
import { Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Vehicle } from '../../../@types/vehicle'
import { useUpdateInvoiceLinkMutation } from '../../../services/invoice'

interface VehicleLinkCreateProps 
{
    invoiceId: string;
    onVehicleLinkChanged?(invoiceId: string): void;
}

export default function VehicleLinkCreate({ invoiceId, onVehicleLinkChanged }: VehicleLinkCreateProps) {

  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [loading, setLoading] = useState(false);
  const [updateInvoiceLink] = useUpdateInvoiceLinkMutation();

  const createInvoiceLink = async () => {
    try {
      setLoading(true);
      await updateInvoiceLink({
        vehicleId: vehicle?.id ?? '',
        invoiceId: invoiceId,
      }).unwrap();
      onVehicleLinkChanged && onVehicleLinkChanged(invoiceId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Typography variant="caption" sx={{ color: 'text.disabled', mb: 1, mt: 1, display: 'flex'  }}>
          Search for a vehicle to link this invoice to. This link can be updated or deleted at anytime. An invoice can only be linked to one vehicle at a time.
      </Typography>
      <VehicleSearchInput onVehicleSelected={(vehicle) => { setVehicle(vehicle)}}/>
      <LoadingButton
        onClick={createInvoiceLink}
        sx={{ mt: 2 }}
        size="small"
        fullWidth
        type="submit"
        variant="contained"
        loading={loading}
        disabled={!vehicle}
      >
        Create Link
      </LoadingButton>
    </>
  );
}
