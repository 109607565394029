import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ServiceItem } from '../../../@types/maintenance'
import EmptyContent from '../../shared/EmptyContent'
import ServiceItemListItem from './ServiceItemListItem'

type ServiceItemListComponentProps = {
    items: ServiceItem[];
    loading?: boolean;
    onItemRemoved?: (item: ServiceItem) => Promise<boolean>;
};

const ListStyle = styled(Box)(({ theme }) => ({

}));

function ServiceItemList({ items, onItemRemoved }: ServiceItemListComponentProps) {

    if (items.length == 0) {
        return <EmptyContent
            imgHeight={100}
            displayLayout="row"
            title="No service items"
            description="Add or create service items for this plan using the list above."
        />
    }

    return (
        <ListStyle>
            {
                items.map((item: ServiceItem) => (
                    <ServiceItemListItem
                        key={`service-item-list-${item.itemId}`}
                        item={item}
                        onItemRemoved={onItemRemoved}
                    />
                ))
            }
        </ListStyle >
    );
}

export default ServiceItemList;