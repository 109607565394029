import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { PATH_APP } from '../routes/paths';
import { useAuth0 } from '@auth0/auth0-react';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to={PATH_APP.root} />;
  }

  return <>{children}</>;
}
