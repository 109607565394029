import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';

export type FormValuesProps = {
    confirmPhrase: string;
    phrase: string;
  };

export const FormSchema = Yup.object().shape({
    confirmPhrase: Yup.string()
      .required('Confirm phrase is required'),
    phrase: Yup.string()
      .oneOf([Yup.ref('confirmPhrase')], "Please type confirm phrase")
      .required("Please type confirm phrase")
  });

export type ConfirmPhraseDeleteModalProps = {
    confirmPhrase: string;
    onCancel: () => void;
    onConfirm: () => void;
    open: boolean;
}

export default function ConfirmPhraseDeleteModal({ confirmPhrase, open, onCancel, onConfirm } : ConfirmPhraseDeleteModalProps) {

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValuesProps>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
        confirmPhrase: confirmPhrase,
        phrase: '',
    }
  });
  
  const onSubmit = async (data: FormValuesProps) => {
    reset();
    onConfirm();
  };

  const handleCancel = async () => {
    reset();
    onCancel();
  };

  return (
      <Dialog 
        fullWidth
        open={open}
        onClose={handleCancel}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>
            <Typography sx={{ mb: 2 }}>
                This action is destructive and irreversible. Please type <b>{confirmPhrase}</b> to confirm you really want to continue.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                <Controller
                    name="phrase"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        autoComplete='off'
                        label={`Type: ${confirmPhrase} to confirm`}
                        error={Boolean(error)}
                        helperText={error?.message}
                    />
                    )}
                />
                <LoadingButton
                    fullWidth
                    color="error"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                >
                    Delete
                </LoadingButton>
                </Stack>
            </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
