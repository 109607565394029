import { useTheme } from '@material-ui/core/styles';
import Label from '../shared/Label';
import { sentenceCase } from 'change-case';
import { InvoiceStatus } from '../../@types/invoice'

function InvoiceStatusLabel({ status }: { status: InvoiceStatus }) {
    const theme = useTheme();
  
    return (
        <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
            (status === InvoiceStatus.Deleted && 'default') ||
            (status === InvoiceStatus.Voided && 'default') ||
            (status === InvoiceStatus.Authorised && 'info') ||
            (status === InvoiceStatus.Draft && 'default') ||
            (status === InvoiceStatus.Deleted && 'error') ||
            (status === InvoiceStatus.Pending && 'warning') ||
            'success'
            }
        >
            {sentenceCase(status)}
      </Label>
    );
  }
  
  export default InvoiceStatusLabel;

