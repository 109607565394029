import { Entity } from './entity'
import { Compliance, ComplianceType } from './compliance'
import { ClientSummary } from './client'
import { InvoiceSearchCriteria, Invoice } from './invoice'
import { Service } from './maintenance'
import { ServiceRecord } from './maintenance'

export type VehicleState = {
  recent: {
    isLoading: boolean;
    data: Vehicle[]
  },
  create: {
    selectedOwnerEntity: {
      id: string,
      name: string,
    },
    modalOpen: boolean;
  },
  isLoading: boolean;
  error: boolean;
  vehicle: null | Vehicle;
  invoices: {
    isFirstTime: boolean;
    isLoading: boolean;
    data: Invoice[];
    totalRows: number;
    searchCriteria: IVehicleInvoiceSearchCriteria;
  },
  service: {
    modalOpen: boolean;
    loading: boolean;
    data: Service | null;
  },
  configuration: {
    compliance: VehicleComplianceConfiguration
  }
};

export interface IVehicleInvoiceSearchCriteria extends InvoiceSearchCriteria {
  projectIdentifier: string | undefined;
  vehicleId: string;
}

export interface IVehicleSearchResult {
  vehicles: Vehicle[];
  totalCount: number;
}

export type Vehicle = {
  id: string;
  vehicleIdentifier: string;
  type: VehicleType;
  make: string;
  model: string;
  subModel: string;
  updated: string;
  status: VehicleStatus;
  colour: string;
  power: number;
  engineCapacity: number;
  odometer?: number | null;
  hubometer?: number | null;
  hourmeter?: number | null;
  engineNumber: string;
  chassisNumber: string;
  vehicleIdentificationNumber: string
  manufactureYear: number | null;
  whenExternalDetailsLastRefreshed: string;
  entity: Entity;
  client: ClientSummary;
  complianceType: ComplianceType;
  complianceRemindersEnabled: boolean;
  compliance: Compliance | null;
  lastService?: ServiceRecord | null;
}

export enum VehicleType {
  Vehicle = "Vehicle",
  Machine = "Machine",
}

export enum VehicleStatus {
  Active = "Active",
  Disabled = "Disabled",
}

export type VehicleSummary = {
  id: string;
  registration: string;
  make: string;
  model: string;
  subModel: string;
  colour: string;
}

export type VehicleUpdateRequest = {
  ownerEntityId: string;
  vehicleId: string;
  vehicleIdentifier: string;
  make: string;
  model: string;
  type: VehicleType;
  subModel: string;
  complianceType: ComplianceType;
  complianceExpiryDate?: Date | null;
  colour: string;
  engineCapacity?: number | null;
  odometer?: number | null;
  hubometer?: number | null;
  hourmeter?: number | null;
  engineNumber: string;
  chassisNumber: string;
  vehicleIdentificationNumber: string
  manufactureYear?: number | null;
}

export type VehicleCreateRequest = {
  projectId: string;
  ownerEntityId: string;
  type: VehicleType;
  vehicleIdentifier: string;
  make?: string | null;
  model?: string | null;
  subModel?: string | null;
  odometer?: number | null;
  hubometer?: number | null;
  hourmeter?: number | null;
  complianceType: string;
  manualRequest: boolean;
  dateOfLastCompliance?: Date | null;
  colour?: string | null;
}

export type VehicleServiceCreateRequest = {
  vehicleId: string;
  date: Date;
  serviceMileage: Number;
  servicePlanId?: string;
}

export type VehicleComplianceConfiguration = {
  trackingEnabled: boolean;
  complianceRemindersEnabled: boolean;
  emailRemindersEnabled?: boolean;
  textRemindersEnabled?: boolean;
}