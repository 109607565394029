import useProject from '../../hooks/useProject'
import { UserAvatarText } from '../_user'
import { User } from '../../@types/user'

type ProjectUserAvatarTextProps = {
    userId: string | null | undefined;
    displayText?: boolean;
    size?: number;
    tooltip?(user: User): string;
}

export default function ProjectUserAvatarText({ userId, size, displayText = true, tooltip }: ProjectUserAvatarTextProps) {

    const { project } = useProject();
    const user = project.memberships.find(t => t.member.id === userId)?.member;

    return (
        <UserAvatarText user={user} size={size} displayText={displayText} tooltip={tooltip} />
    );
}
