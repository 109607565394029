
const path = '/static/vehicles/'

var images = {
    ISUZU: `${path}ISUZU.png`,
    VOLKSWAGEN: `${path}VOLKSWAGEN.png`,
    TOYOTA: `${path}TOYOTA.png`,
    FORD: `${path}FORD.png`,
    SCANIA: `${path}SCANIA.png`,
    NISSAN: `${path}NISSAN.png`,
    MITSUBISHI: `${path}MITSUBISHI.png`,
    VOLVO: `${path}VOLVO.png`,
    'MERCEDES-BENZ': `${path}MERCEDES-BENZ.png`,
    'MITSUBISHI FUSO': `${path}MITSUBISHI-FUSO.png`,
    FUSO: `${path}MITSUBISHI-FUSO.png`,
    HYUNDAI: `${path}HYUNDAI.jpeg`
}

export function getImagePath(make) {

    if (make) {
        var path = images[make.toUpperCase()];
        if (path) {
            return path;
        }
    }

    return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBCXXGjUq9jvMOMprzdzgPqDsY4DKSh-OOkg&usqp=CAU';
}