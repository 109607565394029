import { motion } from 'framer-motion';
import { Box} from '@material-ui/core';

type IconCollapseProps = {
    width?: number
};

export default function LogoImageFull({ width = 200 }: IconCollapseProps) {
    return (
        <Box
            component={motion.img}
            whileTap="tap"
            whileHover="hover"
            variants={{
                hover: { scale: 1.02 },
                tap: { scale: 0.98 }
            }}
            width={width}
            sx={{ pl: 1}}
            src="/static/brand/full_noborder_tr.png"
        />);
}
