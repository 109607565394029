import { TableCell } from '@material-ui/core';
import Label from '../../../shared/Label';
import { ComplianceNotification, ComplianceReminderNotification } from '../../../../@types/compliance'
import VeemTable from '../../../table/VeemTable';
import NotificationStatusLabel from '../../../_notification/NotificationStatusLabel';
import { ProjectUserAvatarText } from '../../../project'
import { fDateTime, fDateShort } from '../../../../utils/formatTime'

const TABLE_HEAD = [
    {
        id: 'whenNotificationSent',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        sortable: false,
        width: 150,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sortable: false,
        width: 100,
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
        sortable: false,
        width: 70,
    },
    {
        id: 'user',
        numeric: false,
        disablePadding: false,
        label: 'User',
        sortable: false,
        width: 70,
    },
    {
        id: 'expiryDate',
        numeric: false,
        disablePadding: false,
        label: 'Expiry Date',
        sortable: false,
        width: 120,
    },
    {
        id: 'address',
        numeric: false,
        disablePadding: false,
        label: 'Address',
        sortable: false,
        width: 200,
    },
    {
        id: 'message',
        numeric: false,
        disablePadding: false,
        label: 'Message',
        sortable: false,
        width: 200,
    }
];

type ComplianceReminderHistoryTableProps = {
    complianceNotification: ComplianceNotification;
}

export default function ComplianceReminderHistoryTable({ complianceNotification }: ComplianceReminderHistoryTableProps) {

    return (
        <VeemTable<ComplianceReminderNotification>
            header={TABLE_HEAD}
            data={complianceNotification.notifications}
            page={0}
            loading={false}
            totalRows={complianceNotification.notifications.length}
            rowsPerPage={10}
            showSelectAll={false}
            getSelectedItemKey={(row: ComplianceReminderNotification) => row.whenNotifiedUtc.toString()}
            order={"asc"}
            orderBy={"whenNotificationSent"}
            handleChangePage={() => { }}
            handleSort={() => { }}
            onRowsPerPageChange={() => { }}
            renderTableRow={(row: ComplianceReminderNotification, selected: boolean, labelId: string) => {
                return (
                    <>
                        <TableCell>{fDateTime(row.whenNotifiedUtc)}</TableCell>
                        <TableCell align="left"><NotificationStatusLabel status={row.status} /></TableCell>
                        <TableCell>
                            <Label
                                variant={'ghost'}
                            >
                                {row.type}
                            </Label>
                        </TableCell>
                        <TableCell>
                            <ProjectUserAvatarText
                                userId={row.requestorId}
                                size={26}
                                displayText={false}
                                tooltip={(user) => `Requested by ${user.displayName}`} />
                        </TableCell>
                        <TableCell>{row.expiryDate ? fDateShort(row.whenNotifiedUtc) : ''}</TableCell>
                        <TableCell>{row.address}</TableCell>
                        <TableCell>{row.message}</TableCell>
                    </>
                )
            }}
        />
    );
}
