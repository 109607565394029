import { PATH_APP } from '../../routes/paths';
import SvgIconStyle from '../../components/shared/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  fleet: getIcon('ic_vehicle'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  groups: getIcon('ic_groups'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // {
      //   title: 'dashboard',
      //   path: PATH_APP.general.dashboard,
      //   icon: ICONS.dashboard
      // },
      { 
        title: 'projects',
        path: PATH_APP.general.projects,
        icon: ICONS.groups,
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_APP.user.root,
        icon: ICONS.user,
      },
    ]
  }
];

export default sidebarConfig;
