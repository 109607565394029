import { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { styled, useTheme } from '@material-ui/core/styles';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import SettingsSidebar from './SettingsSidebar';
import ProjectNavBar from '../project/ProjectNavBar';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import ErrorComponent from '../../components/shared/error/ErrorComponent';
import { ProjectProvider } from '../../contexts/ProjectContext';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { useGetProjectQuery } from '../../services/projects'
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from '../constants';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 14,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 14,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function SettingsLayout() {
  
  const theme = useTheme();
  const { id } = useParams();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { isLoading, error } = useGetProjectQuery(id);

  if (error) {
    const err = error as FetchBaseQueryError;
    if (err.status == 404) {
      return (
        <RootStyle>
          <MainStyle
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex
              }),
              ...(collapseClick && {
                ml: '102px'
              })
            }}
          >
            <ErrorComponent
              title="Hmm... We can't seem to find that project"
              description="Please check you are trying to access the correct project" />
          </MainStyle>
        </RootStyle>
      )
    }

    if (err.status == 500) {
      return (
        <RootStyle>
          <MainStyle
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex
              }),
              ...(collapseClick && {
                ml: '102px'
              })
            }}
          >
            <ErrorComponent
              showMaintenance={true}
              description="We are running into some trouble loading this project. Please check your network connection and try refreshing the page. If the issues persists please get in touch with support." />
          </MainStyle>
        </RootStyle>
      )
    }

    return (
      <RootStyle>
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <ErrorComponent
            showMaintenance={true}
            description="We seem to be having trouble connecting with our data services. Please check your network connection and try refreshing the page. If the issues persists please get in touch with support." />
        </MainStyle>
      </RootStyle>
    )
  }

  if (isLoading) {
    return (
      <RootStyle>
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <LoadingScreen />
        </MainStyle>
      </RootStyle>
    )
  }

  return (
    <RootStyle>
      <ProjectProvider identifier={id}>
        <ProjectNavBar onOpenSidebar={() => setOpen(true)} />
        <SettingsSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <Outlet />
        </MainStyle>
      </ProjectProvider>
    </RootStyle>
  );
}
